<template>
  <div :class="componentClass.root">
    <PmKeyValueListPure :is-loading="isLoading">
      <template v-if="details">
        <PmKeyValuePure v-if="IS_DEVELOPMENT" label="Id" variant="debug">
          {{ id }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Typ"> Fremddienstleistung </PmKeyValuePure>

        <PmKeyValuePure label="Name">
          {{ details.name }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Datum">
          {{
            startEndDateForText(details.startDate, details.endDate, {
              showTime: true,
            })
          }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Name der Produktion">
          {{ details.nameOfProduction }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Auftraggeber">
          {{ details.client }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Kunde des Auftraggebers">
          {{ details.customerOfClient }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Einsatzort">
          {{ details.location }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Aufgabe/Tätigkeit">
          {{ details.task }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Status">
          <PmStatusPillPure
            type="leaveRequest"
            :leave-request-status="details.status"
          />
        </PmKeyValuePure>

        <PmKeyValuePure label="Verlauf">
          <PmTimelinePure>
            <PmTimelineItemPure
              v-for="item in details.communication"
              :key="`${item.status}.${item.date.getTime()}`"
              :date="item.date"
              :label="item.label"
              :additional-meta="item.additionalMeta"
            >
              <template #header>
                <template v-if="item.status">
                  <PmStatusPillPure
                    type="externalServiceRequest"
                    :external-service-request-status="item.status"
                  />
                </template>
              </template>

              <PmNotePure v-if="item.note" :note="item.note" />
            </PmTimelineItemPure>
          </PmTimelinePure>
        </PmKeyValuePure>
      </template>
    </PmKeyValueListPure>
  </div>
</template>

<script setup lang="ts">
import { useComponentClass } from '@thomasaull-shared/composables'

import { startEndDateForText } from '@/utilities/date'
import { IS_DEVELOPMENT } from '@/constants/general'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmKeyValueListPure from '@/components/basics/PmKeyValueList/PmKeyValueListPure.vue'
import PmKeyValuePure from '@/components/basics/PmKeyValueList/PmKeyValuePure.vue'
import PmTimelinePure from '@/components/basics/PmTimeline/PmTimelinePure.vue'
import PmTimelineItemPure, {
  type Props as PropsTimelineItemPure,
} from '@/components/basics/PmTimeline/PmTimelineItemPure.vue'
import PmNotePure from '@/components/PmNote/PmNotePure.vue'
import type { Nilable } from '@/types/misc'
import type { ExternalServiceRequestStatus } from '@/constants/externalServiceRequest'

export interface Props {
  id: number
  isLoading?: boolean
  details?: {
    name: Nilable<string>
    startDate: Date
    endDate: Date
    status?: ExternalServiceRequestStatus
    nameOfProduction: Nilable<string>
    client: Nilable<string>
    customerOfClient: Nilable<string>
    location: Nilable<string>
    task: Nilable<string>
    communication?: {
      date: Date
      label?: string
      status?: ExternalServiceRequestStatus
      note?: Nilable<string>
      additionalMeta?: PropsTimelineItemPure['additionalMeta']
    }[]
  }
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  close: []
}>()

const componentClass = useComponentClass()
</script>

<style lang="scss">
.PmExternalServiceRequestDetailPure {
  $block: &;

  &-timelineItemContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
