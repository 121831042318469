import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmLogoutPureState.typegen'

export type State = Typegen0['matchesStates']

export const PmLogoutPureState = createMachine({
  tsTypes: {} as import('./PmLogoutPureState.typegen').Typegen0,

  id: 'PmLogoutPureState',
  initial: 'default',

  states: {
    default: {},
    signOut: {
      initial: 'signingOut',
      states: {
        signingOut: {},
        success: {},
        failed: {
          meta: {
            error: true,
            errorMessage: 'Es gab einen unbekannten Fehler bei der Abmeldung',
          },
        },
      },
    },
  },
})

export const states = extractAllStates(PmLogoutPureState)
