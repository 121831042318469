import { assertEvent, fromPromise, setup } from 'xstate5'
import { type Emits as EmitsSendMessagePure } from '@/components/persoplan/PmSendMessage/PmSendMessagePure.vue'
import { assertFriendlyError } from '@/functional/friendlyErrors'

export const PmSendMessageState = setup({
  types: {
    events: {} as {
      type: 'send'
      variables: EmitsSendMessagePure['send'][0]
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  actions: {
    close: () => {
      throw new Error('close not implemented')
    },
    showSuccessNotification: () => {
      throw new Error('showSuccessNotification not implemented')
    },
  },

  actors: {
    send: fromPromise<void, { variables: EmitsSendMessagePure['send'][0] }>(
      async () => {
        throw new Error('sendMessage not implemented')
      }
    ),
  },
}).createMachine({
  id: 'PmSendMessageState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        send: 'send',
      },
    },

    send: {
      initial: 'sending',

      states: {
        sending: {
          invoke: {
            id: 'send',
            src: 'send',
            input: ({ event }) => {
              assertEvent(event, 'send')
              return { variables: event.variables }
            },
            onDone: {
              target: 'success',
            },
            onError: {
              target: 'error',
              actions: ({ context, event }) => {
                assertFriendlyError(event.error)
                context.error = event.error.message
                context.errorDetails = event.error.details
              },
            },
          },
        },

        success: {
          type: 'final',
          entry: ['close', 'showSuccessNotification'],
        },

        error: {},
      },
    },
  },
})
