import { get } from 'lodash-es'
import { ApolloError } from '@apollo/client'
import {
  knownErrors as KNOWN_ERRORS,
  type KnownError,
  type Operation,
} from '@/constants/errors'
import type { GraphQLError, GraphQLErrorExtensions } from 'graphql'

export type FriendlyError = FriendlyErrorItem | FriendlyErrorItem[]

type FriendlyErrorItem = {
  message: string
  details?: string[]
}

type Options = {
  operation?: Operation
}

export function getFriendlyErrors(
  errors: unknown,
  options?: Options
): FriendlyError | undefined {
  if (!errors) return

  const errorsAsArray = Array.isArray(errors) ? errors : [errors]
  const friendlyErrors: FriendlyErrorItem[] = []

  errorsAsArray.forEach((error) => {
    const result = getFriendlyError(error, options)

    Array.isArray(result)
      ? friendlyErrors.push(...result)
      : friendlyErrors.push(result)
  })

  return friendlyErrors
}

function getFriendlyError(error: unknown, options?: Options) {
  if (error instanceof Error && !(error instanceof ApolloError)) {
    return getRegularError(error.message, options)
  }

  if (error instanceof ApolloError) {
    return getGraphQLErrors(error, options)
  }

  throw new Error('Unknown error type')
}

function getRegularError(
  errorMessage: string,
  options?: Options
): FriendlyErrorItem {
  const knownError = findKnownError(errorMessage, options)

  if (!knownError) {
    return {
      message: errorMessage,
      details: undefined,
    }
  }

  return {
    message: knownError.message,
    details: normalizeDetails(knownError.details),
  }
}

function getGraphQLErrors(error: ApolloError, options?: Options) {
  const friendlyErrors: FriendlyErrorItem[] = []

  error.graphQLErrors.forEach((graphQLError) => {
    const errorMessages = getGraphQLErrorMessages(graphQLError)

    errorMessages.forEach((errorMessage) => {
      const friendlyError = getRegularError(errorMessage, options)
      friendlyErrors.push(friendlyError)
    })
  })

  return friendlyErrors
}

function getGraphQLErrorMessages(error: GraphQLError) {
  const message = error.message
  const normalizedExtensions: string[] = []

  if (Array.isArray(error.extensions)) {
    error.extensions.forEach((extension: GraphQLErrorExtensions) => {
      /**
       * Template is an error code from a validation error in the API
       */
      if ('template' in extension && typeof extension.template === 'string') {
        normalizedExtensions.push(extension.template)
      }
    })
  }

  return [message, ...normalizedExtensions]
}

function findKnownError(errorMessage: string, options?: Options) {
  const knownError = KNOWN_ERRORS[errorMessage]
  if (!knownError) return

  let knownErrorForOperation: KnownError | undefined

  if (
    options?.operation &&
    knownError.operations &&
    options.operation in knownError.operations
  ) {
    knownErrorForOperation = knownError.operations[options.operation]
    if (knownErrorForOperation) return knownErrorForOperation
  }

  // Strip operations from found known error
  const knownErrorWithoutOperations: KnownError = {
    message: knownError.message,
    details: normalizeDetails(knownError.details),
    report: knownError.report,
  }

  return knownErrorWithoutOperations
}

/**
 * Normalizes a details string | string[] to string[]
 */
function normalizeDetails(details: string[] | string | undefined) {
  if (!details) return

  if (!Array.isArray(details)) {
    details = [details]
  }

  return details.filter((detail) => {
    // Check if string is empty
    if (!detail) return false
    return true
  })
}

export function throwFriendlyError(error: unknown, options?: Options) {
  if (!(error instanceof Error)) {
    console.log(error)
    throw new Error('error ist not instance of Error')
  }

  console.error(error)

  const friendlyErrors = getFriendlyErrors(error, options)
  if (!friendlyErrors) return

  /**
   * For legacy reasons we fill some stuff with data
   * @todo This should be removed at some point
   */
  let message: string | undefined
  let details: string[] | undefined

  if (Array.isArray(friendlyErrors)) {
    message = friendlyErrors[0].message
    details = friendlyErrors[0].details
  } else {
    message = friendlyErrors.message
    details = friendlyErrors.details
  }

  throw new FriendlyErrorError(message, {
    details,
    errors: friendlyErrors,
    cause: error,
  })
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#es6_custom_error_class
 */
export class FriendlyErrorError extends Error {
  errors: FriendlyError
  details?: string[]

  constructor(
    message?: string,
    options?: ErrorOptions & {
      details?: string[]
      errors?: FriendlyError
    }
  ) {
    super(message, options)

    // https://ashsmith.io/handling-custom-error-classes-in-typescript
    Object.setPrototypeOf(this, FriendlyErrorError.prototype)

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#es6_custom_error_class
    // @ts-ignore No idea how this would work
    if (Error.captureStackTrace) {
      // @ts-ignore No idea how this would work
      Error.captureStackTrace(this, Error)
    }

    this.name = 'FriendlyError'
    this.errors = []

    /**
     * For legacy reasons this is included
     * @todo This should be removed at some point
     */
    this.details = options?.details

    if (options?.errors) {
      if (Array.isArray(options.errors) && options.errors.length > 0) {
        this.errors = options.errors
      }

      if (!Array.isArray(options.errors)) {
        this.errors = options.errors
      }
    } else {
      if (message) {
        this.errors = {
          message: message,
          details: options?.details,
        }
      }
    }
  }
}

export function assertFriendlyError(
  error: unknown
): asserts error is FriendlyErrorError {
  if (!(error instanceof FriendlyErrorError)) {
    throw new Error('error ist not instance of FriendlyError')
  }
}
