<template>
  <div class="PmButtonListDividerPure" :class="classes"></div>
</template>

<script lang="ts">
const COMPONENT_NAME = 'PmButtonListDividerPure'

export const propTypes = {}
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { injectStrict, buttonListPureKey } from '@/utilities/inject'

export interface Props {
  visible?: boolean
}

const props = withDefaults(defineProps<Props>(), {})
const { align, fullWidthOnMobile, layout } = injectStrict(buttonListPureKey)

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--fullWidthOnMobile`]: fullWidthOnMobile.value === true,
    [`${COMPONENT_NAME}--alignLeft`]: align.value === 'left',
    [`${COMPONENT_NAME}--alignRight`]: align.value === 'right',
    [`${COMPONENT_NAME}--layoutDistribute`]: layout.value === 'distribute',
    [`${COMPONENT_NAME}--visible`]: !!props.visible,
  }
})
</script>

<style lang="scss">
.PmButtonListDividerPure {
  $block: &;

  width: 16px;
  position: relative;
  display: flex;
  justify-content: center;

  &::before {
    content: '';
    background: color.$gray-300;
    width: 1px;
    display: none;
  }

  &--visible {
    &::before {
      display: block;
    }
  }

  &--fullWidthOnMobile {
    @include mixin.media('<=buttons-full-width') {
      display: none;
    }
  }

  &--layoutDistribute {
    display: none;
  }

  &--alignLeft {
    margin-right: auto;
  }

  &--alignRight {
    margin-left: auto;
  }
}
</style>
