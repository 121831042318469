<template>
  <PmDataModalResourceRequestSendMessagePure
    :state="state.path.value"
    :error="state.snapshot.context.error"
    @new-message="state.send({ type: 'newMessage' })"
    @send="(variables) => state.send({ type: 'send', variables })"
    @cancel="state.send({ type: 'cancel' })"
  />
</template>

<script setup lang="ts">
import { useComponentClass } from '@thomasaull-shared/composables'
import { fromPromise } from 'xstate5'
import { useXState } from '@/composition/useXState5'
import { PmDataModalResourceRequestSendMessageState } from '@/components/persoplan/DataModal/PmDataModalResourceRequest/PmDataModalResourceRequestSendMessage/PmDataModalResourceRequestSendMessageState'
import PmDataModalResourceRequestSendMessagePure, {
  type Emits as EmitsDataModalResourceRequestSendMessagePure,
} from '@/components/persoplan/DataModal/PmDataModalResourceRequest/PmDataModalResourceRequestSendMessage/PmDataModalResourceRequestSendMessagePure.vue'
import { ICONS } from '@/constants/icons'
import { useStore } from 'vuex'
import { throwFriendlyError } from '@/functional/friendlyErrors'
import { useMutation } from '@vue/apollo-composable'
import { AddResourceRequestCommentDocument } from '@/../generated/graphql'

export interface Props {
  resourceAllocationId: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  messageSent: []
}>()

const store = useStore()
const componentClass = useComponentClass()

const state = useXState(
  PmDataModalResourceRequestSendMessageState.provide({
    actions: {
      showSuccessNotification: () => {
        store.commit('notification/add', {
          variant: 'success',
          icon: ICONS.CHECK,
          title: 'Nachricht gesendet',
        })
      },
    },
    actors: {
      send: fromPromise(async ({ input }) => send(input.variables)),
    },
  })
)

const addResourceRequestCommentMutation = useMutation(
  AddResourceRequestCommentDocument
)

async function send(
  payload: EmitsDataModalResourceRequestSendMessagePure['send'][0]
) {
  try {
    if (!props.resourceAllocationId)
      throw new Error('no resourceAllocationId to send a message to')

    await addResourceRequestCommentMutation.mutate({
      resourceAllocationIds: [props.resourceAllocationId],
      message: payload.message,
    })

    emit('messageSent')
  } catch (error) {
    throwFriendlyError(error)
  }
}
</script>

<style lang="scss">
.PmDataModalResourceRequestSendMessage {
  $block: &;
}
</style>
