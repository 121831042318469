<template>
  <PmAppNotificationPure variant="danger" :title="messageNormalized">
    <template v-if="detailsNormalized && detailsNormalized.length > 1">
      <ul>
        <li v-for="detail in detailsNormalized" :key="detail.message">
          <template v-if="detail.message">
            {{ detail.message }}
          </template>

          <template v-if="detail.details">
            <ul>
              <li v-for="moreDetail in detail.details" :key="moreDetail">
                {{ moreDetail }}
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </template>

    <template v-if="detailsNormalized && detailsNormalized.length === 1">
      <template v-if="detailsNormalized[0].message">
        {{ detailsNormalized[0].message }}
      </template>
    </template>

    <slot />
  </PmAppNotificationPure>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import type { FriendlyError } from '@/functional/friendlyErrors'

export interface Props {
  /** When the error prop is used this message is ignored */
  message?: string
  details?: string[]
  error?: FriendlyError
}

const props = withDefaults(defineProps<Props>(), {})

const errorsNormalized = computed(() => {
  if (!props.error) return
  if (!Array.isArray(props.error)) return [props.error]
  return props.error
})

const messageNormalized = computed(() => {
  const numberOfFriendlyErrors =
    errorsNormalized.value && errorsNormalized.value.length

  if (numberOfFriendlyErrors && numberOfFriendlyErrors > 1) {
    return 'Es sind mehrere Fehler aufgetreten'
  }

  if (numberOfFriendlyErrors && numberOfFriendlyErrors === 1) {
    const message = errorsNormalized.value[0].message
    if (message) return message
  }

  if (props.message) return props.message

  return 'Unbekannter Fehler'
})

type Detail = {
  message?: string
  details?: string[]
}

const friendlyErrorDetailsNormalized = computed(() => {
  if (!errorsNormalized.value) return
  if (errorsNormalized.value.length === 0) return

  const details: Detail[] = []

  if (errorsNormalized.value.length === 1) {
    errorsNormalized.value[0].details?.forEach((detail) => {
      details.push({
        message: detail,
      })
    })

    return details
  }

  errorsNormalized.value.forEach((error) => {
    const detail: Detail = {
      message: error.message,
      details: error.details,
    }

    details.push(detail)
  })

  return details
})

const regularDetailsNormalized = computed(() => {
  if (!props.details) return
  if (props.details.length === 0) return

  const details: Detail[] = []

  props.details.forEach((detail) => {
    details.push({
      message: detail,
    })
  })

  return details
})

const detailsNormalized = computed(() => {
  const details: Detail[] = []

  if (friendlyErrorDetailsNormalized.value) {
    details.push(...friendlyErrorDetailsNormalized.value)
  }

  if (regularDetailsNormalized.value) {
    details.push(...regularDetailsNormalized.value)
  }

  if (details.length === 0) return
  return details
})
</script>
