<template>
  <div ref="elRoot" class="PmMainNavigationPure">
    <router-link to="/" class="PmMainNavigationPure-logoContainer">
      <img :src="logoUrl" class="PmMainNavigationPure-logo" />
    </router-link>

    <div class="PmMainNavigationPure-listPrimary">
      <PmMainNavigationItemPure
        id="home"
        class="PmMainNavigationPure-item"
        label="Start"
        :icon="ICONS.HOME"
        to="/"
      />

      <PmMainNavigationItemPure
        id="persoplan"
        class="PmMainNavigationPure-item"
        label="Persoplan"
        :icon="ICONS.CALENDAR"
        :to="{ name: 'persoplan' }"
      />

      <PmMainNavigationItemPure
        v-if="canViewResourceRequestOverview"
        id="requests"
        class="PmMainNavigationPure-item"
        label="Anfragen"
        :icon="ICONS.MAIL_QUESTION"
        :to="{ name: 'resource-requests' }"
      />

      <PmMainNavigationItemPure
        v-if="canViewRequestOverview"
        id="requests"
        class="PmMainNavigationPure-item"
        label="Anträge"
        icon="vacation"
        :to="{ name: 'requests' }"
      />

      <PmMainNavigationDropdownPure
        id="reports"
        class="PmMainNavigationPure-item"
        label="Berichte"
        :icon="ICONS.REPORT"
        :items="reports"
        :is-loading="reportsLoading"
      />

      <PmMainNavigationItemPure
        id="intranet"
        class="PmMainNavigationPure-item"
        label="Intranet"
        :icon="ICONS.WRENCH"
        href="https://intranet.promusik.de"
        :link-open-in-new-window="true"
      />
    </div>

    <div class="PmMainNavigationPure-listMobile">
      <PmMainNavigationDropdownPure
        label="Menu"
        :icon="ICONS.MENU"
        :items="[
          {
            id: 'start',
            icon: ICONS.HOME,
            label: 'Start',
            to: '/',
          },
          {
            id: 'persoplan',
            icon: ICONS.CALENDAR,
            label: 'Persoplan',
            to: { name: 'persoplan' },
          },
          {
            id: 'resource-requests',
            icon: 'mailQuestion',
            label: 'Anfragen',
            to: { name: 'resource-requests' },
            isVisible: canViewResourceRequestOverview,
          },
          {
            id: 'requests',
            icon: 'vacation',
            label: 'Anträge',
            to: { name: 'requests' },
            isVisible: canViewRequestOverview,
          },
          {
            id: 'intranet',
            icon: ICONS.WRENCH,
            label: 'Intranet',
            href: 'https://intranet.promusik.de',
            linkOpenInNewWindow: true,
          },
        ]"
      />
    </div>

    <div class="PmMainNavigationPure-listSecondary">
      <div
        v-if="environmentBadge"
        class="PmMainNavigationPure-environmentBadge"
        :class="{
          [componentClass.className({
            element: 'environmentBadge',
            modifier: 'danger',
          })]: props.environmentBadgeVariant === 'danger',
        }"
      >
        {{ environmentBadge }}
      </div>

      <PmMainNavigationDropdownPure
        id="notifications"
        class="PmMainNavigationPure-item"
        :icon="ICONS.BELL"
        :has-notification="hasUnreadNotifications"
      >
        <slot name="notifications" />
      </PmMainNavigationDropdownPure>

      <PmMainNavigationDropdownPure
        id="help"
        class="PmMainNavigationPure-item"
        :icon="ICONS.HELP"
        :items="itemsHelp"
      >
        <div class="PmMainNavigationPure-versionInfo">
          <PmVersionInfoPure
            :version-frontend="versionFrontend"
            :build-frontend="buildFrontend"
            :version-api="versionApi ?? undefined"
          />
        </div>
      </PmMainNavigationDropdownPure>

      <PmMainNavigationDropdownPure
        id="account"
        class="PmMainNavigationPure-item"
        :label="username"
        :icon="ICONS.ACCOUNT"
        :items="itemsAccount"
        :hide-label-on-mobile="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useComponentClass } from '@thomasaull-shared/composables'

import logoUrl from '@/assets/images/logo_notext.svg?url'

import { ICONS } from '@/constants/icons'
import { FEATURE_FLAG } from '@/constants/featureFlags'

import PmMainNavigationItemPure from '@/components/PmMainNavigation/PmMainNavigationItemPure.vue'
import PmMainNavigationDropdownPure, {
  type Props as MainNavigationDropdownPureProps,
} from '@/components/PmMainNavigation/PmMainNavigationDropdownPure.vue'
import PmVersionInfoPure from '@/components/PmVersionInfoPure.vue'

import type { Nilable } from '@/types/misc'

export interface Props {
  username?: string
  reports?: MainNavigationDropdownPureProps['items']
  reportsLoading?: boolean
  versionFrontend?: string
  buildFrontend?: string
  versionApi?: Nilable<string>
  urlAdmin?: string
  adminAreaVisible?: boolean
  environmentBadge?: string
  environmentBadgeVariant?: 'default' | 'danger'
  featureRequestsVisible?: boolean
  feedbackName?: string
  feedbackEmail?: string
  hasUnreadNotifications?: boolean
  canViewResourceRequestOverview?: boolean
  canViewRequestOverview?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const store = useStore()
const componentClass = useComponentClass({ props })

const itemsAccount = computed(() => {
  let items: MainNavigationDropdownPureProps['items'] = [
    {
      id: 'account',
      label: 'Mein Konto',
      icon: 'fingerPrint',
      to: { name: 'account' },
    },
    {
      id: 'signOut',
      label: 'Abmelden',
      icon: 'signOut',
      to: { name: 'logout' },
      variant: 'danger',
    },
  ]

  if (props.adminAreaVisible && props.urlAdmin) {
    items = [
      {
        id: 'admin',
        label: 'Adminbereich',
        icon: 'wrench',
        href: props.urlAdmin,
        linkOpenInNewWindow: true,
      },
      ...items,
    ]
  }

  return items
})

const itemsHelp = computed(() => {
  let items: MainNavigationDropdownPureProps['items'] = [
    {
      id: 'releaseNotes',
      label: 'Release Notes',
      icon: ICONS.CLIPBOARD,
      href: 'https://www.notion.so/thomasaull/Promusik-Persoplan-Release-Notes-cfa8ee6567b34b56b7215b03197022fd',
      linkOpenInNewWindow: true,
    },
    {
      id: 'operatingManual',
      label: 'Operating Manuel',
      icon: ICONS.BOOK,
      href: 'https://thomasaull.notion.site/PRO-MUSIK-operation-manual-7c34e7eadb064a0b944e268f34195313',
      linkOpenInNewWindow: true,
    },
    {
      id: 'feedback',
      label: 'Feedback',
      icon: ICONS.FEEDBACK,
      href: feedbackMailto.value,
    },
  ]

  if (props.featureRequestsVisible) {
    items = [
      ...items,
      {
        id: 'featureRequest',
        label: 'Feature Request / Stories',
        icon: ICONS.SPARKLES,
        href: 'https://www.notion.so/thomasaull/PRO-MUSIK-feature-request-stories-aee8bf1bda7f444d82f20c4f58faab69',
        linkOpenInNewWindow: true,
      },
    ]
  }

  return items
})

const feedbackMailto = computed(() => {
  const to = 'wkornberg@promusik.de'
  const subject = encodeURIComponent('Persoplan Feedback/Fehler')

  const name = props.feedbackName ?? ''
  const email = props.feedbackEmail ?? ''

  let body = `Ich habe folgende Anmerkungen zum Persoplan oder möchte einen Fehler berichten.

        So können sie mich kontaktieren:

        Name: ${name}
        Email: ${email}

        Falls es sich um einen Fehler handelt, versuche ich hier zu beschreiben, wie der Fehler reproduziert werden kann:

        [ggf. Screenshot einfügen]`

  /**
   * Match every whitespace character except newline, two or more occurances
   * @see https://regex101.com/r/ucDdk4/1
   */
  const regex = /[^\S\r\n]{2,}/gm
  body = body.replace(regex, '')
  body = encodeURIComponent(body)

  const mailto = `mailto:${to}?subject=${subject}&body=${body}`
  return mailto
})

/**
 * Observe navigation height
 */
const elRoot = ref<HTMLDivElement>()

onMounted(() => {
  if (!elRoot.value) return

  const resizeObserver = new ResizeObserver(update)
  resizeObserver.observe(elRoot.value)
  update()
})

function update() {
  if (!elRoot.value) return

  const height = elRoot.value.getBoundingClientRect().height

  store.commit('cssVar/set', {
    name: 'navigationHeight',
    value: height,
  })
}
</script>

<style lang="scss">
.PmMainNavigationPure {
  $block: &;

  background-color: color.$gray-700;
  display: flex;
  position: relative;

  &-logoContainer {
    position: relative;
    padding: 12px;
    display: flex;
    flex-shrink: 0;

    @include mixin.media('<=mobile') {
      display: none;
    }
  }

  &-logo {
    height: 20px;
  }

  &-environmentBadge {
    background-color: color.$gray-500;
    color: color.$white;
    font-weight: 650;
    text-transform: uppercase;
    width: auto;
    border-radius: constant.$borderRadius-default;
    height: 20px;
    line-height: 20px;
    padding-left: 6px;
    padding-right: 5px;
    padding-bottom: 1px;
    flex-shrink: 0;
    font-size: constant.$fontSize-default;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    pointer-events: none;

    &--danger {
      background-color: color.$danger-600;
    }

    @include mixin.media('>mobile') {
      margin-right: 12px;
    }

    @include mixin.media('<=mobile') {
      margin-right: 8px;
    }
  }

  &-listPrimary {
    display: flex;
    align-items: center;
    margin-right: auto;

    @include mixin.media('<=mobile') {
      outline: 2px solid red;
      display: none;
    }
  }

  &-listMobile {
    margin-right: auto;

    @include mixin.media('>mobile') {
      display: none;
    }
  }

  &-listSecondary {
    display: flex;
    align-items: center;
  }

  &-versionInfo {
    padding: 12px;
    border-bottom: 1px solid color.$gray-300;
  }
}
</style>
