<template>
  <div
    ref="elRoot"
    class="PmAppointmentPure"
    :data-lazy-loading-id="lazyLoadingId"
    :style="isLoading ? null : styles"
    :class="isLoading ? null : classes"
    @mouseenter="emit('mouseenter')"
    @mouseleave="emit('mouseleave')"
  >
    <div v-if="!isLoading" class="PmAppointmentPure-content">
      <div v-if="USE_TIME_INDICATOR" class="PmAppointmentPure-timeIndicator">
        <PmTimeIndicatorPure
          :start-date="startDate"
          :end-date="endDate"
          :container-start-date="constrainedStartDate"
          :container-end-date="constrainedEndDate"
        />
      </div>

      <div class="PmAppointmentPure-header">
        <div
          v-if="isConstrainedStartMarkerVisible"
          class="PmAppointmentPure-constrainedMarker PmAppointmentPure-constrainedMarker--start"
          :title="`Start: ${startDateFormatted}`"
        >
          <PmIconPure :name="ICONS.ARROW_LEFT" />
        </div>

        <div class="PmAppointmentPure-headerContent">
          <div class="PmAppointmentPure-headerMetaAndTime">
            <div v-if="hasMeta" class="PmAppointmentPure-metaContainer">
              <div class="PmAppointmentPure-meta">
                <template v-if="canEditResourceRequest">
                  <PmContextNavigationPure
                    :items="[
                      {
                        id: 'selectConsidered',
                        icon: ICONS.DUPLICATE,
                        label: 'Angedachte auswählen',
                        note: 'Alle angedachten Personen des Termins auswählen. Nicht Angezeigte werden ignoriert.',
                      },
                      {
                        id: 'requestConsidered',
                        icon: ICONS.MAIL_QUESTION,
                        label: `Alle Angedachte des gesamten Termins anfragen.`,
                        note: `Nicht Angezeigte werden eingeschlossen.`,
                      },
                    ]"
                    @select-considered="selectConsidered"
                    @request-considered="emit('requestConsidered')"
                  >
                    <template #trigger="slotProps">
                      <PmResourceActionPure
                        :icon="ICONS_SMALL.CONTEXT_NAVIGATION"
                        :is-active="slotProps.isOpen"
                        @click="slotProps.togglePopover"
                      />
                    </template>
                  </PmContextNavigationPure>
                </template>

                <PmPillPure
                  v-if="hasAlerts"
                  class="PmAppointmentPure-alerts"
                  variant="danger"
                  :label="numberOfAlerts"
                />

                <PmPillPure
                  v-if="number"
                  class="PmAppointmentPure-number"
                  :class="classesNumber"
                  :color="jobStatus.color.value"
                  :underline-on-hover="true"
                  :label="number"
                  @click="emit('showDetailsForNumber')"
                />

                <div
                  v-if="title"
                  class="PmAppointmentPure-title"
                  :title="title"
                  @click="isWithDetails && emit('showDetails')"
                >
                  {{ title }}
                </div>

                <PmNoteIconPure
                  v-if="note"
                  class="PmAppointmentPure-noteIcon"
                  :note="note"
                  :note-additional="noteOnJob ?? undefined"
                  :name="noteOnJob ? 'Bemerkung Termin' : undefined"
                  name-additional="Bemerkung Job"
                  @open="emit('openNote')"
                />

                <PmLoadingPure
                  v-if="isLoadingContents"
                  class="PmAppointmentPure-loading"
                />
              </div>
            </div>

            <div class="PmAppointmentPure-time" :title="timeDetails">
              {{ time }}
            </div>
          </div>

          <div v-if="note" class="PmAppointmentPure-noteInlineContainer">
            <div class="PmAppointmentPure-noteInline">
              {{ note }}
            </div>
          </div>
        </div>

        <div
          v-if="isConstrainedEnd"
          class="PmAppointmentPure-constrainedMarker PmAppointmentPure-constrainedMarker--end"
          :title="constrainedEndMarkerProps.title"
        >
          <PmIconPure :name="constrainedEndMarkerProps.icon" />
        </div>
      </div>

      <div class="PmAppointmentPure-slot">
        <slot :is-in-viewport="isInViewport" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, provide, computed, toRef } from 'vue'
import { isWithinInterval, isSameDay } from 'date-fns'

import { FORMAT_DATETIME_SHORT, type JobStatus } from '@/constants/persoplan'
import { ICONS, ICONS_SMALL, type Icon } from '@/constants/icons'
import { FEATURE_FLAG } from '@/constants/featureFlags'

import { isValidDate, formatWithLocale, startEndDate } from '@/utilities/date'
import { selectionAppointmentHelperKey } from '@/utilities/inject'

import {
  default as useDateConstrain,
  type Props as PropsUseDateConstrain,
  propDefaults as propDefaultsUseDateConstrain,
} from '@/composition/useDateConstrain'

import { useLazyloading } from '@/components/persoplan/PmAppointment/useLazyloading'
import { useJobStatus } from '@/composition/useJobStatus'

// import PmResourcePure from '@/components/persoplan/PmResource/PmResourcePure.vue'
import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmTimeIndicatorPure from '@/components/basics/PmTimeIndicator/PmTimeIndicatorPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmNoteIconPure from '@/components/PmNote/PmNoteIconPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import PmContextNavigationPure from '@/components/basics/PmContextNavigation/PmContextNavigationPure.vue'
import PmResourceActionPure from '@/components/persoplan/PmResourceActionPure.vue'

import { type Nullable } from '@/types/misc'

const USE_TIME_INDICATOR = false
const COMPONENT_NAME = 'PmAppointmentPure'

export interface Props extends PropsUseDateConstrain {
  id?: number | string
  title?: Nullable<string>
  numberOfAlerts?: number
  startDate: Date
  endDate: Date
  isLoading?: boolean
  isLoadingContents?: boolean
  number?: string
  /**
   * @todo: Refactor to `jobStatus`
   */
  status?: JobStatus
  disableDateConstrainStyles?: boolean
  containerStartDateUnconstrained?: Date | undefined
  containerEndDateUnconstrained?: Date | undefined
  isWithDetails?: boolean
  note?: Nullable<string>
  noteOnJob?: Nullable<string>
  canEditResourceRequest?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  ...propDefaultsUseDateConstrain,
  isWithDetails: true,
})

const emit = defineEmits<{
  (event: 'mouseenter'): void
  (event: 'mouseleave'): void
  (event: 'showDetailsForNumber'): void
  (event: 'showDetails'): void
  (event: 'openNote'): void
  (event: 'enterViewport'): void
  (event: 'requestConsidered'): void
}>()

const {
  containerNumberOfDays,
  isConstrainedStart,
  isConstrainedEnd,
  constrainedStartDate,
  constrainedEndDate,
  numberOfDaysConstrained,
  constrainedStartInDirection,
  constrainedEndInDirection,
  styles: stylesDateConstrain,
} = useDateConstrain(props)

const jobStatus = useJobStatus({
  status: toRef(props, 'status'),
})

const elRoot = ref<HTMLElement>()

const { id: lazyLoadingId, isInViewport } = useLazyloading({
  element: elRoot,
  onFirstEnter: () => emit('enterViewport'),
})

/**
 * Selection helper
 */
const selectionTrigger = ref(0)

function selectConsidered() {
  selectionTrigger.value += 1
}

provide(selectionAppointmentHelperKey, {
  selectionTrigger: selectionTrigger,
})

const classes = computed(() => {
  return {
    'is-outsideOfContainer': isOutsideOfContainer.value,
    'is-constrainedStart': isConstrainedStart.value,
    'is-constrainedEnd': isConstrainedEnd.value,
    'is-onFirstDayOfContainer': isOnFirstDayOfContainer.value,
    'is-onLastDayOfContainer': isOnLastDayOfContainer.value,
    'is-withDetails': props.isWithDetails,
  }
})

const styles = computed(() => {
  let styles = {
    [`--${COMPONENT_NAME}-numberOfDays`]: numberOfDaysConstrained.value,
  }

  if (!props.disableDateConstrainStyles) {
    styles = {
      ...styles,
      ...stylesDateConstrain.value,
    }
  }

  return styles
})

const classesNumber = computed(() => {
  return jobStatus.classes.value
})

const isOutsideOfContainer = computed(() => {
  if (!isValidDate(props.containerStartDateUnconstrained)) return
  if (!isValidDate(props.containerEndDateUnconstrained)) return

  const intervalStartDate = props.containerStartDateUnconstrained
  const intervalEndDate = props.containerEndDateUnconstrained

  const startDateIsInsideOfUnconstrainedContainer = isWithinInterval(
    props.startDate,
    {
      start: intervalStartDate,
      end: intervalEndDate,
    }
  )

  const endDateIsInsideOfUnconstrainedContainer = isWithinInterval(
    props.endDate,
    {
      start: intervalStartDate,
      end: intervalEndDate,
    }
  )

  return (
    !startDateIsInsideOfUnconstrainedContainer ||
    !endDateIsInsideOfUnconstrainedContainer
  )
})

const isOnFirstDayOfContainer = computed(() => {
  if (!props.containerStartDate) return false
  return isSameDay(props.containerStartDate, constrainedStartDate.value)
})

const isOnLastDayOfContainer = computed(() => {
  if (!props.containerEndDate) return false
  return isSameDay(props.containerEndDate, constrainedEndDate.value)
})

const startDateFormatted = computed(() => {
  return formatWithLocale(props.startDate, FORMAT_DATETIME_SHORT)
})

const endDateFormatted = computed(() => {
  return formatWithLocale(props.endDate, FORMAT_DATETIME_SHORT)
})

const hasAlerts = computed(() => {
  if (props.numberOfAlerts === undefined) return

  return props.numberOfAlerts > 0
})

const hasMeta = computed(() => {
  if (hasAlerts.value) return true
  if (props.number) return true
  if (props.title) return true
  if (props.note) return true

  console.log(props.note)

  return false
})

const isConstrainedStartMarkerVisible = computed(() => {
  if (isConstrainedEndMarkerForBoth.value) return false
  return isConstrainedStart.value
})

const isConstrainedEndMarkerForBoth = computed(() => {
  return (
    isConstrainedStart.value &&
    constrainedStartInDirection.value === 'right' &&
    isConstrainedEnd.value &&
    constrainedEndInDirection.value === 'right'
  )
})

const constrainedEndMarkerProps = computed<{ title: string; icon: Icon }>(
  () => {
    if (isConstrainedEndMarkerForBoth.value) {
      return {
        title: `${startDateFormatted.value} — ${endDateFormatted.value}`,
        icon: 'doubleArrowRight',
      }
    }

    return {
      title: `Ende: ${endDateFormatted.value}`,
      icon: 'arrowRight',
    }
  }
)

const time = computed(() => {
  return startEndDate(props.startDate, props.endDate)
})

const timeDetails = computed(() => {
  return startEndDate(props.startDate, props.endDate, {
    showDateAnyway: true,
    showFull: true,
  })
})
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmAppointmentPure {
  $block: &;
  $name: function.getBlockName($block);

  .App:not(.is-dragAndDropInProgress) &:hover {
    z-index: 3;
  }

  &-content {
    // @include mixin.transition-hover((background-color), $block);

    // outline: 1px solid color.$gray-300--alpha; // This has som rounding issues as it seems
    box-shadow: inset 0 0 0 1px color.$gray-300--alpha; // This looks cleaner, but show double borders on touching items
    background-color: rgba(#fff, 0.6);
    position: relative;
    display: inline-block;
    min-width: 100%;
    width: 100%;

    #{$block}.is-onFirstDayOfContainer & {
      margin-left: 3px;
      min-width: calc(100% - 3px);
      width: calc(100% - 3px);
    }

    #{$block}.is-onLastDayOfContainer & {
      min-width: calc(100% - 3px);
      width: calc(100% - 3px);
    }

    #{$block}.is-onFirstDayOfContainer#{$block}.is-onLastDayOfContainer & {
      margin-left: 3px;
      width: calc(100% - 6px);
      min-width: calc(100% - 6px);
    }

    &::before {
      @include shadow.default('low', $outline: false);

      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }

    .App:not(.is-dragAndDropInProgress) #{$block}:hover & {
      background-color: rgba(#fff, 1);
      opacity: 1;
      width: auto !important;

      &::before {
        opacity: 1;
      }
    }

    #{$block}.is-outsideOfContainer & {
      &::after {
        pointer-events: none;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        box-shadow:
          inset 0 0 0 1px color.$danger-500,
          0 0 20px 5px rgba(color.$danger-500, 0.75);
      }
    }
  }

  &-timeIndicator {
    @include cssVar.define(
      $block,
      'timeIndicator-width',
      calc(#{cssVar.use($block, 'numberOfDays')} * var(--dayWidth))
    );

    position: absolute;
    top: -3px;
    width: cssVar.use($block, 'timeIndicator-width');

    #{$block}.is-onFirstDayOfContainer & {
      left: -3px;
    }
  }

  &-header {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 8px;
    gap: 4px;
  }

  &-constrainedMarker {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    padding: 4px;
    position: relative;

    &::after {
      $offset: 0;

      content: '';
      top: $offset;
      right: $offset;
      bottom: $offset;
      left: $offset;
      position: absolute;
      border-radius: constant.$borderRadius-default + 2px;
      box-shadow: inset 0 0 0 2px color.$key;
      opacity: 0;
      transition: opacity constant.$duration-hoverOut;
    }

    &:hover {
      &::after {
        opacity: 1;
        transition-duration: constant.$duration-hoverIn;
      }
    }

    &--start {
      margin-left: -5px;
    }

    &--end {
      margin-right: -5px;
    }
  }

  &-headerContent {
    padding-top: 0;
    min-width: 0;
    font-size: constant.$fontSize-large;
    flex-shrink: 1;
    width: 100%;
    min-height: 20px;
  }

  &-headerMetaAndTime {
    display: flex;
  }

  &-metaContainer {
    display: flex;
    min-width: 0;
    max-width: 100%;
    flex-grow: 1;

    .App:not(.is-dragAndDropInProgress) #{$block}:hover & {
      min-width: max-content;
    }
  }

  &-meta {
    display: flex;
    align-self: start;
    gap: 4px;
    align-items: center;
    position: sticky;
    left: calc(var(--sidebarLeftWidthWithVisibility) + 8px);
    min-height: 20px;
    max-width: 100%;
  }

  &-number {
    flex-shrink: 0;
  }

  &-icon {
    width: 20px;
    height: 20px;
  }

  &-title {
    @include mixin.truncateText;

    line-height: 1;
    font-weight: 500;

    #{$block}.is-withDetails & {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-noteIcon {
    flex-shrink: 0;
  }

  &-loading {
    width: 18px;
    height: 18px;
  }

  &-noteInlineContainer {
    display: flex;
  }

  &-noteInline {
    @include mixin.truncateText($lines: 3);

    white-space: pre-line;
    font-size: constant.$fontSize-default;
    position: sticky;
    left: calc(var(--sidebarLeftWidthWithVisibility) + 8px);
    max-width: calc(
      100vw - var(--sidebarLeftWidthWithVisibility) - 12px - 12px
    );
    margin-top: 4px;
  }

  &-time {
    @include mixin.truncateText;

    white-space: nowrap;
    padding-left: 8px;
    height: 20px;
    line-height: 20px;
    min-width: 0;
    font-weight: 500;
    outline-offset: 1px;

    #{$block}:hover & {
      min-width: max-content;
    }
  }

  &-slot {
    font-size: constant.$fontSize-default;
    // Causes problems when toggling editmode, nut sure why it's needed
    // display: inline-flex;
    // Causes problems when toggling editmode, nut sure why it's needed
    // flex-direction: column;

    // min-width: 100%;
    width: 100%;
    padding-bottom: 10px;
  }
}
</style>
