<template>
  <div ref="elRoot" :class="[componentClass.root, classes]">
    <div :class="componentClass.element('content')">
      <PmLoadingPure size="small" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject } from 'vue'

import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import { useElementSize } from '@vueuse/core'
import { PmModalPureKey } from '@/components/basics/PmModalPure'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()
const modal = inject(PmModalPureKey)
const elRoot = ref<HTMLElement>()
const elementSize = useElementSize(elRoot)

const isTallContainer = computed(() => {
  return elementSize.height.value > 60
})

const classes = computed(() => {
  return {
    'is-tallContainer': isTallContainer.value,
    [componentClass.modifier('inModal')]: modal === true,
  }
})
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmLoadingOverlayPure {
  $block: &;

  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  padding-top: 8px;
  padding-bottom: 8px;

  &-content {
    @include shadow.default('medium', $outline: false);

    padding: 12px;
    border-radius: constant.$borderRadius-large;
    z-index: 1;
    background: color.$white;
    outline: 1px solid color.$gray-300--alpha;
    position: sticky;
    top: calc(8px + var(--navigationHeight));

    #{$block}--inModal & {
      top: calc(8px + 36px); // 36px = height of collapsed sticky header
    }
  }
}
</style>
