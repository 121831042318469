import { subDays } from 'date-fns'
import { cloneDeep, groupBy, orderBy } from 'lodash-es'

import { FORMAT_DATE_DEFAULT } from '@/constants/persoplan'

import {
  parseServerDateString,
  formatWithLocale,
  isAfterMidnightAndBeforeAlternativeEndOfDay,
  isValidDate,
  formatToServerDateString,
} from '@/utilities/date'

import type { Nilable } from '@/types/misc'

type Resource = {
  id: number
  startDate?: any
  endDate?: any
  resourceFunction?: Nilable<{
    sortOrder?: Nilable<number>
  }>
}

export function createArtificialAppointments({
  resources,
}: {
  resources?: (Resource | null)[]
}) {
  if (!resources) return

  /**
   * Create a clone to not mess with graphql cache and filter out
   * null resources
   */
  const filteredResources = cloneDeep(resources).filter(
    (resource): resource is Resource => {
      if (!resource) return false
      return true
    }
  )

  // Parse start/end dates if necessary
  filteredResources.forEach((resource) => {
    if (!resource.startDate) return
    if (!resource.endDate) return

    if (!isValidDate(resource.startDate)) {
      resource.startDate = parseServerDateString(resource.startDate)
    }
    if (!isValidDate(resource.endDate)) {
      resource.endDate = parseServerDateString(resource.endDate)
    }
  })

  // Group by identical start and end dates
  const groupedResources = groupBy(filteredResources, (resource) => {
    if (!isValidDate(resource.startDate)) return
    if (!isValidDate(resource.endDate)) return

    const startDayString = getStartDayString(resource.startDate)
    const endDayString = getEndDayString(resource.endDate)

    return `${startDayString}-${endDayString}`
  })

  // Normalize
  return Object.entries(groupedResources).map(([datesString, resources]) => {
    const allStartDates = resources.reduce<Date[]>((dates, resource) => {
      if (!isValidDate(resource.startDate)) return dates
      dates.push(resource.startDate)
      return dates
    }, [])

    const earliestStartDate = orderBy(allStartDates, undefined, ['asc'])[0]

    const allEndDates = resources.reduce<Date[]>((dates, resource) => {
      if (!isValidDate(resource.endDate)) return dates
      dates.push(resource.endDate)
      return dates
    }, [])

    const latestEndDate = orderBy(allEndDates, undefined, ['desc'])[0]

    console.table({
      earliestStartDate: formatToServerDateString(earliestStartDate),
      latestEndDate: formatToServerDateString(latestEndDate),
    })

    // Sort resources by resourceFunction.sortOrder and start date
    resources = orderBy(
      resources,
      ['resourceFunction.sortOrder', 'startDate'],
      ['asc', 'asc']
    )

    return {
      key: datesString,
      startDate: earliestStartDate,
      endDate: latestEndDate,
      resources,
    }
  })
}

function getStartDayString(date: Date) {
  let startDate = new Date(date)

  /**
   * If it starts after midnight and before the alternative end of day it should belong
   * to the previous day
   */
  if (isAfterMidnightAndBeforeAlternativeEndOfDay(date)) {
    startDate = subDays(startDate, 1)
  }

  const startDayString = formatWithLocale(startDate, FORMAT_DATE_DEFAULT)
  return startDayString
}

function getEndDayString(date: Date) {
  let endDate = new Date(date)

  /**
   * If it ends after midnight and before the alternative end of day it should belong
   * to the previous day
   */
  if (isAfterMidnightAndBeforeAlternativeEndOfDay(date)) {
    endDate = subDays(endDate, 1)
  }

  const startDayString = formatWithLocale(endDate, FORMAT_DATE_DEFAULT)
  return startDayString
}
