<template>
  <PmResourceRequestUpdatePure
    :action="action"
    :number-of-items="resourceAllocationIds.length"
    :state="xstate.path.value"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    @confirm="xstate.service.value.send('CONFIRM')"
    @close="emit('close')"
    @cancel="emit('close')"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useMutation } from '@vue/apollo-composable'

import { ICONS } from '@/constants/icons'
import {
  STATUS_RESOURCE_ALLOCATION_ID_LOOKUP,
  STATUS_RESOURCE_ALLOCATION,
} from '@/constants/persoplan'
import { useXState } from '@/composition/useXState'
import {
  FriendlyErrorError,
  throwFriendlyError,
} from '@/functional/friendlyErrors'
import PmResourceRequestUpdatePure from '@/components/PmResourceRequestUpdate/PmResourceRequestUpdatePure.vue'
import { PmResourceRequestUpdateState } from '@/components/PmResourceRequestUpdate/PmResourceRequestUpdateState'
import { UpdateResourceAllocationAddressDocument } from '@/../generated/graphql'

export interface Props {
  action: 'confirm' | 'reserve' | 'decline'
  resourceAllocationIds: number[]
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const { t } = useI18n({
  messages: {
    de: {
      successNotificationConfirm:
        'Die Ressourcen-Anfrage wurde zugesagt | Die Ressourcen-Anfragen wurden zugesagt',
      successNotificationReserve:
        'Die Ressourcen-Anfrage wurde reserviert | Die Ressourcen-Anfragen wurden reserviert',
      successNotificationDecline:
        'Die Ressourcen-Anfrage wurde abgesagt | Die Ressourcen-Anfragen wurden abgesagt',
    },
  },
})

const store = useStore()

const xstate = useXState(PmResourceRequestUpdateState, {
  services: {
    save: updateResourceAllocations,
  },

  actions: {
    emitClose: () => emit('close'),
    showSuccessNotification: () => {
      if (!successNotificationText.value)
        throw new Error('successNotificationText is undefined')

      store.commit('notification/add', {
        variant: 'success',
        icon: ICONS.CHECK,
        title: successNotificationText.value,
      })
    },
  },
})

const successNotificationText = computed(() => {
  if (props.action === 'confirm')
    return t('successNotificationConfirm', props.resourceAllocationIds.length)
  if (props.action === 'reserve')
    return t('successNotificationReserve', props.resourceAllocationIds.length)
  if (props.action === 'decline')
    return t('successNotificationDecline', props.resourceAllocationIds.length)

  return undefined
})

/**
 * Update resource allocations
 */
const indexCurrentlyUpdating = ref<number>(0)
const hasErrors = ref(false)

// Lookup state ids for different actions
const lookupStateId: Record<
  Props['action'],
  (typeof STATUS_RESOURCE_ALLOCATION_ID_LOOKUP)[keyof typeof STATUS_RESOURCE_ALLOCATION_ID_LOOKUP]
> = {
  confirm:
    STATUS_RESOURCE_ALLOCATION_ID_LOOKUP[STATUS_RESOURCE_ALLOCATION.CONFIRMED],
  reserve:
    STATUS_RESOURCE_ALLOCATION_ID_LOOKUP[STATUS_RESOURCE_ALLOCATION.RESERVED],
  decline:
    STATUS_RESOURCE_ALLOCATION_ID_LOOKUP[STATUS_RESOURCE_ALLOCATION.CANCELLED],
}

async function updateResourceAllocations() {
  if (!props.resourceAllocationIds) {
    throw new Error('No resource allocations to update')
  }

  const items: {
    resourceAllocationId: number
    stateId: number
  }[] = []

  props.resourceAllocationIds.forEach((resourceAllocationId) => {
    items.push({
      resourceAllocationId,
      stateId: lookupStateId[props.action],
    })
  })

  indexCurrentlyUpdating.value = 0

  for (const item of items) {
    indexCurrentlyUpdating.value = indexCurrentlyUpdating.value + 1

    try {
      await updateResourceAllocation(item)
    } catch (error) {
      hasErrors.value = true
    }
  }

  // Throw general error, when there was an error on one of the items
  if (hasErrors.value === true) {
    throw new FriendlyErrorError(
      'Es gab einen oder mehrere Fehler beim Aktualisieren der Ressourcen-Anfragen',
      {
        details: [
          'Bitte prüfe ob deine Änderungen durchgeführt wurden und versuche es gegebenenfalls noch einmal',
        ],
      }
    )
  }
}

const updateResourceAllocationAddressMutation = useMutation(
  UpdateResourceAllocationAddressDocument
)

async function updateResourceAllocation({
  resourceAllocationId,
  stateId,
}: {
  resourceAllocationId: number
  stateId: number
}) {
  try {
    await updateResourceAllocationAddressMutation.mutate({
      resourceAllocationId,
      stateId,
    })
  } catch (error) {
    throwFriendlyError(error)
  }
}
</script>
