<template>
  <div
    class="PmResourceTimelineStatePure"
    :style="styles as StyleValue"
    :class="classes"
    :data-date="constrainedEndDate"
  >
    <div class="PmResourceTimelineStatePure-content" :style="stylesContent">
      <div class="PmResourceTimelineStatePure-line">
        <div
          v-if="isConstrainedStart"
          class="PmResourceTimelineStatePure-overflowIndicator PmResourceTimelineStatePure-overflowIndicator--start"
        >
          <PmIconPure name="arrowLeft" />
        </div>

        <PmStatusDotPure
          v-if="resourceAllocationStatus"
          size="none"
          class="PmResourceTimelineStatePure-status"
          type="resourceAllocation"
          :resource-allocation-status="resourceAllocationStatus"
          :with-border="true"
        />

        <div class="PmResourceTimelineStatePure-label">
          {{ label }}
        </div>

        <div v-if="hasNote" class="PmResourceTimelineStatePure-icon">
          <PmIconPure :name="ICONS.NOTE" />
        </div>

        <div
          v-if="isConstrainedEnd"
          class="PmResourceTimelineStatePure-overflowIndicator PmResourceTimelineStatePure-overflowIndicator--end"
        >
          <PmIconPure name="arrowRight" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceTimelineStatePure'

export const propTypes = {
  type: {
    allowed: Object.values(RESOURCE_DAY_ITEM_TYPE),
  } as const,

  resourceAllocationStatus: {
    allowed: Object.values(STATUS_RESOURCE_ALLOCATION),
  } as const,
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, type StyleValue } from 'vue'
import { differenceInMinutes } from 'date-fns'

import { ICONS } from '@/constants/icons'
import {
  RESOURCE_DAY_ITEM_TYPE,
  type ResourceDayItemType,
  type ResourceAllocationStatus,
  STATUS_RESOURCE_ALLOCATION,
} from '@/constants/persoplan'

import { getHoursAsFloat, getTotalHoursOfDay } from '@/utilities/date'
import { default as useDateConstrainComposable } from '@/composition/useDateConstrain'

import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmStatusDotPure from '@/components/persoplan/PmStatusDot/PmStatusDotPure.vue'

export interface Props {
  startDate: Date
  endDate: Date
  label?: string
  hasNote?: boolean
  isConflict?: boolean
  isHighlighted?: boolean
  type: ResourceDayItemType
  resourceAllocationStatus?: ResourceAllocationStatus
  // Prop for useDateConstrain
  containerStartDate: Date
  containerEndDate: Date
  useDateConstrain?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  useDateConstrain: true,
})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const {
  isConstrainedStart,
  isConstrainedEnd,
  constrainedStartDate,
  constrainedEndDate,
  numberOfDaysConstrained,
  styles,
} = useDateConstrainComposable(props, { useAlternativeEndOfDay: false })

const isInViewport = computed(() => {
  return true
  // return isBefore(constrainedEndDate.value, new Date('2022-03-08'))
})

const classes = computed(() => {
  return {
    'is-conflict': props.isConflict,
    'is-inViewport': isInViewport.value,
    'is-highlighted': props.isHighlighted,
    'is-constrainedStart': isConstrainedStart.value === true,
    'is-constrainedEnd': isConstrainedEnd.value === true,
  }
})

const stylesContent = computed<StyleValue>(() => {
  /** Start time of the day, as float hours */
  const startHours = getHoursAsFloat(constrainedStartDate.value)

  const durationInMinutes = differenceInMinutes(
    constrainedEndDate.value,
    constrainedStartDate.value
  )

  const durationInHours = durationInMinutes / 60

  /** The total hours of all days, where the indicator is visible */
  const totalHours = getTotalHoursOfDay() * numberOfDaysConstrained.value

  const left = startHours / totalHours
  const width = durationInHours / totalHours

  return {
    position: 'absolute',
    left: `${left * 100}%`,
    width: `${width * 100}%`,
  }
})
</script>

<style lang="scss">
// TODO: Figure out a better way for hover states of parent components

.PmResourceTimelineStatePure {
  $block: &;

  @include cssVar.define($block, 'height', 14px);
  @include cssVar.define($block, 'colorText', color.$text-default);
  @include cssVar.define($block, 'colorText--hover', color.$text-default);
  @include cssVar.define($block, 'colorLine', rgba(color.$white, 0.5));
  @include cssVar.define($block, 'colorLine--hover', rgba(color.$white, 0.9));
  @include cssVar.define($block, 'colorBorder', rgba(color.$black, 0.1));
  @include cssVar.define($block, 'colorBorder--hover', rgba(color.$black, 0.2));

  // @include mixin.transition-hover(color);

  &.is-conflict {
    @include cssVar.define($block, 'colorLine', color.$danger-400);
    @include cssVar.define($block, 'colorLine--hover', color.$danger-600);
  }

  &.is-highlighted {
    @include cssVar.define(
      $block,
      'colorLine',
      cssVar.use($block, 'colorLine--hover')
    );
    @include cssVar.define(
      $block,
      'colorBorder',
      cssVar.use($block, 'colorBorder--hover')
    );
  }

  width: 100%;
  height: cssVar.use($block, 'height');
  z-index: 1;
  color: cssVar.use($block, 'colorText');
  position: relative;
  // content-visibility: auto; // This hides overflowing items in chrome, don't enable until there's a better solution generally

  &:hover,
  &.is-hover {
    color: cssVar.use($block, 'colorText--hover');
  }

  &-inner {
    height: cssVar.use($block, 'height');
  }

  &-content {
    position: absolute;
    left: 0;
    top: 0;
    height: cssVar.use($block, 'height');
    display: flex;
  }

  &-line {
    border-radius: cssVar.use($block, 'height');
    background-color: cssVar.use($block, 'colorLine');
    outline: 1px solid cssVar.use($block, 'colorBorder');
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    #{$block}.is-constrainedStart & {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    #{$block}.is-constrainedEnd & {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    #{$block}.is-notAvailable & {
      background-color: transparent;
    }

    #{$block}:hover &,
    #{$block}.is-hover & {
      background-color: cssVar.use($block, 'colorLine--hover');
    }

    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    .PmResourceTimelinePure:hover & {
      background-color: cssVar.use($block, 'colorLine--hover');
    }
  }

  &-overflowIndicator {
    @include cssVar.define($block, 'overflowIndicator-padding', 3px);
    @include cssVar.define(
      $block,
      'overflowIndicator-border',
      cssVar.use($block, 'colorBorder')
    );

    width: calc(cssVar.use($block, 'height') - 2px);
    height: cssVar.use($block, 'height');
    color: color.$gray-400--alpha;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    flex: none;

    #{$block}:hover &,
    #{$block}.is-hover &,
    #{$block}.is-highlighted & {
      @include cssVar.define(
        $block,
        'overflowIndicator-border',
        cssVar.use($block, 'colorBorder--hover')
      );

      color: color.$text-default;
    }

    &--start {
      left: 0;
    }

    &--end {
      margin-left: auto;
    }

    & svg {
      width: 8px;
      height: 8px;
    }

    // stylelint-disable-next-line plugin/selector-bem-pattern
    & *[id*='.fill'] {
      stroke-width: 1px;
      stroke: cssVar.use($block, 'overflowIndicator-border');
    }
  }

  &-status {
    width: 14px - 4px;
    height: 14px - 4px;
    flex: none;
    margin-left: 2px;
  }

  &-label {
    @include mixin.truncateText;

    font-size: constant.$fontSize-small;
    cursor: default;
    color: currentColor;
    line-height: 100%;
    margin-left: 4px;
    margin-right: 4px;

    #{$block}-overflowIndicator + & {
      margin-left: 0;
    }
  }

  &-icon {
    width: 14px;
    height: 14px;
    padding: 1px;
    flex: none;
    margin-right: 2px;
  }
}
</style>
