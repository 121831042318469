import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  AddressDisplayName: { input: any; output: any }
  Date: { input: any; output: any }
  DateTime: { input: any; output: any }
  FileUpload: { input: any; output: any }
  HexColor: { input: any; output: any }
  RelatedAddresses: { input: any; output: any }
  ResourceSource: { input: any; output: any }
  Time: { input: any; output: any }
}

export type Address = {
  __typename?: 'Address'
  addressResourceType?: Maybe<AddressResourceType>
  addressTypes?: Maybe<Array<Maybe<AddressType>>>
  birthday?: Maybe<Scalars['Date']['output']>
  city?: Maybe<Scalars['String']['output']>
  comment?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  contact?: Maybe<Contact>
  country?: Maybe<Country>
  custom1?: Maybe<Scalars['String']['output']>
  custom2?: Maybe<Scalars['String']['output']>
  custom3?: Maybe<Scalars['String']['output']>
  custom4?: Maybe<Scalars['String']['output']>
  custom5?: Maybe<Scalars['String']['output']>
  customNumber?: Maybe<Scalars['String']['output']>
  deliveryAddress?: Maybe<Address>
  displayName?: Maybe<Scalars['AddressDisplayName']['output']>
  easyJobUser?: Maybe<EasyJobUser>
  email?: Maybe<Scalars['String']['output']>
  fax?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  industry?: Maybe<Industry>
  invoiceAddress?: Maybe<Address>
  language?: Maybe<Language>
  manualSort?: Maybe<Scalars['Int']['output']>
  messenger?: Maybe<Scalars['String']['output']>
  name2?: Maybe<Scalars['String']['output']>
  number?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  phoneCompany?: Maybe<Scalars['String']['output']>
  phoneMobile?: Maybe<Scalars['String']['output']>
  phonePrivate?: Maybe<Scalars['String']['output']>
  resourceFunction2Address?: Maybe<Array<Maybe<ResourceFunction2Address>>>
  resourceQualifications?: Maybe<Array<Maybe<ResourceQualification>>>
  salutation?: Maybe<Salutation>
  social?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  surname?: Maybe<Scalars['String']['output']>
  wwwAddress?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export type AddressCalendarItem =
  | CalendarEvent
  | Event
  | ResourceAllocation
  | ResourceState

export type AddressFilter = {
  addressIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  addressResourceTypeIds?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >
  addressTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  countryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  excludeAddressIds?: InputMaybe<Scalars['Boolean']['input']>
  onlyEmployees?: InputMaybe<Scalars['Boolean']['input']>
  qualificationIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  resourceFunctionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  search?: InputMaybe<Scalars['String']['input']>
}

export type AddressFormat = {
  __typename?: 'AddressFormat'
  caption: Scalars['String']['output']
  captionDe?: Maybe<Scalars['String']['output']>
  captionEn?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  type?: Maybe<Scalars['String']['output']>
}

export type AddressResourceType = FilterEntity & {
  __typename?: 'AddressResourceType'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  type: Scalars['String']['output']
}

export type AddressSort = {
  direction?: InputMaybe<Sort>
  field?: InputMaybe<AddressSortFields>
}

export enum AddressSortFields {
  birthday = 'birthday',
  firstName = 'firstName',
  manualSort = 'manualSort',
  surname = 'surname',
}

export type AddressType = {
  __typename?: 'AddressType'
  addresses?: Maybe<Array<Maybe<Address>>>
  caption: Scalars['String']['output']
  captionLong2?: Maybe<Scalars['String']['output']>
  captionLong3?: Maybe<Scalars['String']['output']>
  captionLong4?: Maybe<Scalars['String']['output']>
  captionLong5?: Maybe<Scalars['String']['output']>
  captionLong6?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
}

export type AddressTypeAddressesArgs = {
  sort?: InputMaybe<Array<InputMaybe<AddressSort>>>
}

export type AdjacentResourceAllocationInformation = {
  __typename?: 'AdjacentResourceAllocationInformation'
  hoursFromPrevious?: Maybe<Scalars['Int']['output']>
  hoursToNext?: Maybe<Scalars['Int']['output']>
  next?: Maybe<ResourceAllocation>
  previous?: Maybe<ResourceAllocation>
}

export type Agenda = {
  __typename?: 'Agenda'
  address?: Maybe<Address>
  calendarEvents?: Maybe<Array<Maybe<CalendarEvent>>>
  date?: Maybe<Scalars['Date']['output']>
  events?: Maybe<Array<Maybe<Event>>>
  resourceAllocations?: Maybe<Array<Maybe<ResourceAllocation>>>
  resourceStates?: Maybe<Array<Maybe<ResourceState>>>
  vehicle?: Maybe<Vehicle>
}

export type AgendaCalendarEventsArgs = {
  filter?: InputMaybe<CalendarEventFilter>
}

export type AgendaEventsArgs = {
  filter?: InputMaybe<EventFilter>
}

export type AgendaResourceAllocationsArgs = {
  filter?: InputMaybe<ResourceAllocationFilter>
}

export type AgendaResourceStatesArgs = {
  filter?: InputMaybe<ResourceStateFilter>
}

export type AppSetting = {
  __typename?: 'AppSetting'
  coreTimeEnd?: Maybe<Scalars['Time']['output']>
  coreTimeStart?: Maybe<Scalars['Time']['output']>
  openingHours?: Maybe<Array<Maybe<OpeningHour>>>
}

export type Attendee = {
  __typename?: 'Attendee'
  address?: Maybe<Address>
  displayName?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type Calendar = {
  __typename?: 'Calendar'
  caption: Scalars['String']['output']
  color?: Maybe<Scalars['HexColor']['output']>
  id: Scalars['String']['output']
}

export type CalendarEvent = {
  __typename?: 'CalendarEvent'
  address?: Maybe<Address>
  caption?: Maybe<Scalars['String']['output']>
  created?: Maybe<Scalars['DateTime']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  isAllDay?: Maybe<Scalars['Boolean']['output']>
  startDate?: Maybe<Scalars['DateTime']['output']>
  updated?: Maybe<Scalars['DateTime']['output']>
}

export type CalendarEventFilter = {
  endDate: Scalars['DateTime']['input']
  startDate: Scalars['DateTime']['input']
}

export type Company = {
  __typename?: 'Company'
  caption?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
}

export type Contact = {
  __typename?: 'Contact'
  custom1?: Maybe<Scalars['String']['output']>
  custom2?: Maybe<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  fax?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  jobName?: Maybe<Scalars['String']['output']>
  language?: Maybe<Language>
  middleName?: Maybe<Scalars['String']['output']>
  mobile?: Maybe<Scalars['String']['output']>
  number?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  salutation?: Maybe<Salutation>
  surname?: Maybe<Scalars['String']['output']>
}

export type Continent = {
  __typename?: 'Continent'
  caption: Scalars['String']['output']
  captionDe?: Maybe<Scalars['String']['output']>
  captionEn?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  type?: Maybe<Scalars['String']['output']>
}

export type CostCenter = {
  __typename?: 'CostCenter'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  number: Scalars['String']['output']
}

export type Country = {
  __typename?: 'Country'
  addressFormat?: Maybe<AddressFormat>
  caption?: Maybe<Scalars['String']['output']>
  continent?: Maybe<Continent>
  id: Scalars['Int']['output']
  innerEu?: Maybe<Scalars['Boolean']['output']>
  isoCode?: Maybe<Scalars['String']['output']>
  phonePrefix?: Maybe<Scalars['String']['output']>
}

export type Currency = {
  __typename?: 'Currency'
  calculationBaseExchangeRate?: Maybe<Scalars['Float']['output']>
  culture?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  isBase?: Maybe<Scalars['Boolean']['output']>
  isDisabled?: Maybe<Scalars['Boolean']['output']>
  isMain?: Maybe<Scalars['Boolean']['output']>
  isTarget?: Maybe<Scalars['Boolean']['output']>
  symbol?: Maybe<Scalars['String']['output']>
  threeLetterCode?: Maybe<Scalars['String']['output']>
}

export type CurrentRelease = {
  __typename?: 'CurrentRelease'
  api?: Maybe<Scalars['String']['output']>
  frontend?: Maybe<Scalars['String']['output']>
}

export type DeleteAllocationDriverResponse = {
  __typename?: 'DeleteAllocationDriverResponse'
  previousResourceAllocationDriver?: Maybe<ResourceAllocation>
  resourceAllocation: ResourceAllocation
}

export type DeprecationForm = {
  __typename?: 'DeprecationForm'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  isEditMasterDataAllowed?: Maybe<Scalars['Boolean']['output']>
  isManualInsertAllowed?: Maybe<Scalars['Boolean']['output']>
  multiplier?: Maybe<Scalars['Int']['output']>
  type: Scalars['String']['output']
}

export type DeprecationMethod = {
  __typename?: 'DeprecationMethod'
  caption: Scalars['String']['output']
  deprecationForm?: Maybe<DeprecationForm>
  id: Scalars['Int']['output']
  isSpecial?: Maybe<Scalars['Boolean']['output']>
  period?: Maybe<Scalars['Int']['output']>
  rate?: Maybe<Scalars['String']['output']>
}

export type DutyOrder = {
  __typename?: 'DutyOrder'
  order?: Maybe<Scalars['Int']['output']>
  overlappingAllocationIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
}

export type EasyJobEvent = {
  __typename?: 'EasyJobEvent'
  address?: Maybe<Address>
  calendar?: Maybe<Calendar>
  caption?: Maybe<Scalars['String']['output']>
  color?: Maybe<Scalars['HexColor']['output']>
  description?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  number?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['DateTime']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type EasyJobUser = {
  __typename?: 'EasyJobUser'
  address?: Maybe<Address>
  department?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  surname?: Maybe<Scalars['String']['output']>
  weeklyWorkingTime?: Maybe<Scalars['Float']['output']>
}

export type EasyJobUserWeeklyWorkingTimeArgs = {
  end: Scalars['DateTime']['input']
  start: Scalars['DateTime']['input']
}

export type EasyJobUserFilter = {
  excludeDeactivated?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type EasyJobUserSort = {
  direction?: InputMaybe<Sort>
  field?: InputMaybe<EasyJobUserSortFields>
}

export enum EasyJobUserSortFields {
  firstName = 'firstName',
  surname = 'surname',
}

export type Event = {
  __typename?: 'Event'
  attendees?: Maybe<Array<Maybe<Attendee>>>
  calendar?: Maybe<OutlookCalendar>
  caption?: Maybe<Scalars['String']['output']>
  created?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  importance?: Maybe<Importance>
  isAllDay?: Maybe<Scalars['Boolean']['output']>
  isPrivate?: Maybe<Scalars['Boolean']['output']>
  link?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  onlineMeetingUrl?: Maybe<Scalars['String']['output']>
  organizer?: Maybe<Address>
  relatedAddresses?: Maybe<Scalars['RelatedAddresses']['output']>
  showAs?: Maybe<FreeBusyStatus>
  startDate?: Maybe<Scalars['DateTime']['output']>
  type?: Maybe<EventType>
  updated?: Maybe<Scalars['DateTime']['output']>
}

export type EventFilter = {
  endDate: Scalars['DateTime']['input']
  includePrivateEvents?: InputMaybe<Scalars['Boolean']['input']>
  startDate: Scalars['DateTime']['input']
}

export type EventSort = {
  direction?: InputMaybe<Sort>
  field?: InputMaybe<EventSortFields>
}

export enum EventSortFields {
  endDate = 'endDate',
  startDate = 'startDate',
}

export enum EventType {
  exception = 'exception',
  occurrence = 'occurrence',
  seriesMaster = 'seriesMaster',
  singleInstance = 'singleInstance',
}

export type ExpenseReport = {
  __typename?: 'ExpenseReport'
  address?: Maybe<Address>
  created: Scalars['DateTime']['output']
  date?: Maybe<Scalars['DateTime']['output']>
  dayOfCatering?: Maybe<Scalars['DateTime']['output']>
  details?: Maybe<Scalars['String']['output']>
  expenseType?: Maybe<ExpenseType>
  host?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  invoiceAmount?: Maybe<Scalars['Float']['output']>
  job?: Maybe<Job>
  kilometer?: Maybe<Scalars['Float']['output']>
  location?: Maybe<Scalars['String']['output']>
  paymentInfo?: Maybe<Scalars['String']['output']>
  paymentType?: Maybe<PaymentType>
  publicTransportation?: Maybe<Scalars['Boolean']['output']>
  reason?: Maybe<Scalars['String']['output']>
  route?: Maybe<Scalars['String']['output']>
  servedPersons?: Maybe<Scalars['String']['output']>
  tipAmount?: Maybe<Scalars['Float']['output']>
  totalAmount?: Maybe<Scalars['Float']['output']>
  updated: Scalars['DateTime']['output']
}

export type ExpenseType = {
  __typename?: 'ExpenseType'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type ExternalServiceRequest = {
  __typename?: 'ExternalServiceRequest'
  client: Scalars['String']['output']
  communications?: Maybe<Array<ExternalServiceRequestCommunication>>
  created: Scalars['DateTime']['output']
  customerOfClient: Scalars['String']['output']
  endDate: Scalars['DateTime']['output']
  eventName: Scalars['String']['output']
  id: Scalars['Int']['output']
  jobFunction: Scalars['String']['output']
  location: Scalars['String']['output']
  startDate: Scalars['DateTime']['output']
  state: ExternalServiceRequestState
  updated: Scalars['DateTime']['output']
  user: User
}

export type ExternalServiceRequestAccepted = {
  __typename?: 'ExternalServiceRequestAccepted'
  comment?: Maybe<Scalars['String']['output']>
  stateAfter: ExternalServiceRequestState
  stateBefore: ExternalServiceRequestState
}

export enum ExternalServiceRequestAction {
  abort = 'abort',
  accept = 'accept',
  create = 'create',
  decline = 'decline',
  update = 'update',
}

export type ExternalServiceRequestCommunication = {
  __typename?: 'ExternalServiceRequestCommunication'
  action: ExternalServiceRequestAction
  comment?: Maybe<Scalars['String']['output']>
  newState: ExternalServiceRequestState
  previousState?: Maybe<ExternalServiceRequestState>
  timestamp: Scalars['DateTime']['output']
  user: User
}

export type ExternalServiceRequestCreated = {
  __typename?: 'ExternalServiceRequestCreated'
  comment?: Maybe<Scalars['String']['output']>
  endDate: Scalars['DateTime']['output']
  startDate: Scalars['DateTime']['output']
  user: Address
}

export type ExternalServiceRequestDeclined = {
  __typename?: 'ExternalServiceRequestDeclined'
  comment?: Maybe<Scalars['String']['output']>
  stateAfter: ExternalServiceRequestState
  stateBefore: ExternalServiceRequestState
}

export enum ExternalServiceRequestState {
  aborted = 'aborted',
  accepted = 'accepted',
  created = 'created',
  declined = 'declined',
  updated = 'updated',
}

export type ExternalServiceRequestUpdated = {
  __typename?: 'ExternalServiceRequestUpdated'
  comment?: Maybe<Scalars['String']['output']>
  stateAfter: ExternalServiceRequestState
  stateBefore: ExternalServiceRequestState
}

export type FilterEntity = {
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export enum FreeBusyStatus {
  busy = 'busy',
  free = 'free',
  oof = 'oof',
  tentative = 'tentative',
  unknown = 'unknown',
  workingElsewhere = 'workingElsewhere',
}

export type GlobalSetting = {
  __typename?: 'GlobalSetting'
  key: Scalars['ID']['output']
  value?: Maybe<Scalars['String']['output']>
}

export enum Importance {
  high = 'high',
  low = 'low',
  normal = 'normal',
}

export type Industry = {
  __typename?: 'Industry'
  caption: Scalars['String']['output']
  captionLong2?: Maybe<Scalars['String']['output']>
  captionLong3?: Maybe<Scalars['String']['output']>
  captionLong4?: Maybe<Scalars['String']['output']>
  captionLong5?: Maybe<Scalars['String']['output']>
  captionLong6?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
}

export type InvoiceAccount = {
  __typename?: 'InvoiceAccount'
  account?: Maybe<Scalars['String']['output']>
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  invoiceAccountType?: Maybe<InvoiceAccountType>
}

export type InvoiceAccountType = {
  __typename?: 'InvoiceAccountType'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  type: Scalars['String']['output']
}

export type Job = {
  __typename?: 'Job'
  arrangedBy?: Maybe<EasyJobUser>
  caption: Scalars['String']['output']
  comment?: Maybe<Scalars['String']['output']>
  commitmentPeriod?: Maybe<Scalars['DateTime']['output']>
  created?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<EasyJobUser>
  deliveryAddress?: Maybe<Address>
  deliveryAddressNote?: Maybe<Scalars['String']['output']>
  endDate: Scalars['DateTime']['output']
  globalSetting?: Maybe<GlobalSetting>
  id: Scalars['Int']['output']
  jobAppointments?: Maybe<Array<Maybe<JobAppointment>>>
  jobService?: Maybe<JobService>
  jobState?: Maybe<JobState>
  logs?: Maybe<Array<Maybe<LogItem>>>
  number: Scalars['String']['output']
  numberOfJobAppointments?: Maybe<Scalars['Int']['output']>
  numberOfMissingResources?: Maybe<Scalars['Int']['output']>
  numberOfResourceFunctionAllocations?: Maybe<Scalars['Int']['output']>
  project?: Maybe<Project>
  rentalPriceSum?: Maybe<Scalars['Float']['output']>
  /** Resources without appointment */
  resourceFunctionAllocations?: Maybe<Array<Maybe<ResourceFunctionAllocation>>>
  sortOrder?: Maybe<Scalars['Int']['output']>
  startDate: Scalars['DateTime']['output']
  updated?: Maybe<Scalars['DateTime']['output']>
  updatedBy?: Maybe<EasyJobUser>
}

export type JobJobAppointmentsArgs = {
  filter?: InputMaybe<JobAppointmentFilter>
  pagination?: InputMaybe<Pagination>
  sort?: InputMaybe<JobAppointmentSort>
}

export type JobLogsArgs = {
  filter?: InputMaybe<LogFilter>
  pagination?: InputMaybe<Pagination>
}

export type JobNumberOfJobAppointmentsArgs = {
  filter?: InputMaybe<JobAppointmentFilter>
}

export type JobNumberOfResourceFunctionAllocationsArgs = {
  filter?: InputMaybe<ResourceFunctionAllocationFilter>
}

export type JobResourceFunctionAllocationsArgs = {
  filter?: InputMaybe<ResourceFunctionAllocationFilter>
}

export type JobAppointment = {
  __typename?: 'JobAppointment'
  caption?: Maybe<Scalars['String']['output']>
  comment?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  globalSetting?: Maybe<GlobalSetting>
  id: Scalars['Int']['output']
  job?: Maybe<Job>
  jobAppointmentType?: Maybe<JobAppointmentType>
  logs?: Maybe<Array<Maybe<LogItem>>>
  numberOfResourceFunctionAllocations?: Maybe<Scalars['Int']['output']>
  resourceFunctionAllocations?: Maybe<Array<Maybe<ResourceFunctionAllocation>>>
  startDate?: Maybe<Scalars['DateTime']['output']>
}

export type JobAppointmentLogsArgs = {
  filter?: InputMaybe<LogFilter>
  pagination?: InputMaybe<Pagination>
}

export type JobAppointmentNumberOfResourceFunctionAllocationsArgs = {
  filter?: InputMaybe<ResourceFunctionAllocationFilter>
}

export type JobAppointmentResourceFunctionAllocationsArgs = {
  filter?: InputMaybe<ResourceFunctionAllocationFilter>
}

export type JobAppointmentFilter = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  jobAppointmentTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  jobIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  onlyWithResources?: InputMaybe<Scalars['Boolean']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type JobAppointmentSort = {
  direction?: InputMaybe<Sort>
  field?: InputMaybe<JobAppointmentSortFields>
}

export enum JobAppointmentSortFields {
  caption = 'caption',
  endDate = 'endDate',
  startDate = 'startDate',
}

export type JobAppointmentType = FilterEntity & {
  __typename?: 'JobAppointmentType'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type JobFilter = {
  arrangedByIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  excludeArrangedByIds?: InputMaybe<Scalars['Boolean']['input']>
  excludeInactive?: InputMaybe<Scalars['Boolean']['input']>
  excludeJobServiceIds?: InputMaybe<Scalars['Boolean']['input']>
  excludeJobStateIds?: InputMaybe<Scalars['Boolean']['input']>
  excludeProjectIds?: InputMaybe<Scalars['Boolean']['input']>
  jobIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  jobServiceIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  jobStateIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  onlyWithResources?: InputMaybe<Scalars['Boolean']['input']>
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  search?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type JobService = FilterEntity & {
  __typename?: 'JobService'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type JobSort = {
  direction?: InputMaybe<Sort>
  field?: InputMaybe<JobSortFields>
}

export enum JobSortFields {
  caption = 'caption',
  endDate = 'endDate',
  number = 'number',
  startDate = 'startDate',
}

export type JobState = FilterEntity & {
  __typename?: 'JobState'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  jobType?: Maybe<JobType>
}

export type JobType = FilterEntity & {
  __typename?: 'JobType'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type KeyValue = {
  __typename?: 'KeyValue'
  key: Scalars['String']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type Language = {
  __typename?: 'Language'
  caption?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  isoCode?: Maybe<Scalars['String']['output']>
}

export type LeaveRequest = {
  __typename?: 'LeaveRequest'
  approvalType?: Maybe<LeaveRequestApprovalType>
  communications?: Maybe<Array<LeaveRequestCommunication>>
  created: Scalars['DateTime']['output']
  endDate: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  startDate: Scalars['DateTime']['output']
  state: LeaveRequestState
  type: LeaveRequestType
  updated: Scalars['DateTime']['output']
  user: User
}

export type LeaveRequestAccepted = {
  __typename?: 'LeaveRequestAccepted'
  comment?: Maybe<Scalars['String']['output']>
  stateAfter: LeaveRequestState
  stateBefore: LeaveRequestState
  typeAfter: LeaveRequestType
  typeBefore: LeaveRequestType
}

export enum LeaveRequestAction {
  abort = 'abort',
  accept = 'accept',
  create = 'create',
  decline = 'decline',
  update = 'update',
}

export enum LeaveRequestApprovalType {
  hr = 'hr',
  supervisor = 'supervisor',
}

export type LeaveRequestCommunication = {
  __typename?: 'LeaveRequestCommunication'
  action: LeaveRequestAction
  comment?: Maybe<Scalars['String']['output']>
  newState: LeaveRequestState
  newType?: Maybe<LeaveRequestType>
  previousState?: Maybe<LeaveRequestState>
  previousType?: Maybe<LeaveRequestType>
  timestamp: Scalars['DateTime']['output']
  user: User
}

export type LeaveRequestCreated = {
  __typename?: 'LeaveRequestCreated'
  comment?: Maybe<Scalars['String']['output']>
  endDate: Scalars['DateTime']['output']
  startDate: Scalars['DateTime']['output']
  type: LeaveRequestType
  user: Address
}

export type LeaveRequestDeclined = {
  __typename?: 'LeaveRequestDeclined'
  comment?: Maybe<Scalars['String']['output']>
  stateAfter: LeaveRequestState
  stateBefore: LeaveRequestState
  typeAfter: LeaveRequestType
  typeBefore: LeaveRequestType
}

export enum LeaveRequestState {
  aborted = 'aborted',
  accepted = 'accepted',
  created = 'created',
  declined = 'declined',
  updated = 'updated',
}

export enum LeaveRequestType {
  overtimeCompensation = 'overtimeCompensation',
  specialVacation = 'specialVacation',
  vacation = 'vacation',
}

export type LeaveRequestUpdated = {
  __typename?: 'LeaveRequestUpdated'
  comment?: Maybe<Scalars['String']['output']>
  stateAfter: LeaveRequestState
  stateBefore: LeaveRequestState
  typeAfter: LeaveRequestType
  typeBefore: LeaveRequestType
}

export type LogAction = FilterEntity & {
  __typename?: 'LogAction'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  type: Scalars['String']['output']
}

export type LogFilter = {
  actionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  excludeActionIds?: InputMaybe<Scalars['Boolean']['input']>
}

export type LogItem = {
  __typename?: 'LogItem'
  action: LogAction
  date?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  newValue?: Maybe<Scalars['String']['output']>
  user?: Maybe<EasyJobUser>
}

export type MenuItem = {
  __typename?: 'MenuItem'
  href?: Maybe<Scalars['String']['output']>
  label: Scalars['String']['output']
  params?: Maybe<Array<Maybe<MenuItemParam>>>
}

export type MenuItemParam = {
  __typename?: 'MenuItemParam'
  extra?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type Milestone = {
  __typename?: 'Milestone'
  created?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  dueOn?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  overdue?: Maybe<Scalars['Boolean']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type Mutation = {
  __typename?: 'Mutation'
  addResourceRequestComment?: Maybe<ResourceRequest>
  changePassword?: Maybe<Scalars['Boolean']['output']>
  createAddressAllocation?: Maybe<ResourceAllocation>
  createExpenseReport?: Maybe<ExpenseReport>
  createExternalServiceRequest?: Maybe<ExternalServiceRequest>
  createLeaveRequest?: Maybe<LeaveRequest>
  createPersoPlanView?: Maybe<PersoPlanView>
  createResourceRequest?: Maybe<ResourceRequest>
  createResourceState?: Maybe<ResourceState>
  createVehicleAllocation?: Maybe<ResourceAllocation>
  createWebfleetOrder?: Maybe<WebfleetOrder>
  deleteAllocation?: Maybe<Scalars['Boolean']['output']>
  deleteAllocationDriver?: Maybe<DeleteAllocationDriverResponse>
  deleteGlobalSettings?: Maybe<Scalars['Boolean']['output']>
  deletePersoPlanView?: Maybe<Scalars['Boolean']['output']>
  deleteResourceState?: Maybe<Scalars['Boolean']['output']>
  deleteWebfleetOrder?: Maybe<Scalars['Boolean']['output']>
  markAllNotificationAsRead?: Maybe<Scalars['Boolean']['output']>
  saveGlobalSetting?: Maybe<GlobalSetting>
  saveSetting?: Maybe<Setting>
  setAllocationDriver?: Maybe<SetAllocationDriverResponse>
  setProjectGroup?: Maybe<Scalars['Int']['output']>
  setProjectSetting?: Maybe<ProjectSetting>
  subscribeProject?: Maybe<Scalars['Boolean']['output']>
  unsubscribeProject?: Maybe<Scalars['Boolean']['output']>
  updateAddressAllocation?: Maybe<ResourceAllocation>
  updateExpenseReport?: Maybe<ExpenseReport>
  updateExternalServiceRequest?: Maybe<ExternalServiceRequest>
  updateJob?: Maybe<Job>
  updateJobAppointment?: Maybe<JobAppointment>
  updateLeaveRequest?: Maybe<LeaveRequest>
  updateNotificationState?: Maybe<Notification>
  updatePersoPlanView?: Maybe<PersoPlanView>
  updateProject?: Maybe<Project>
  updateResourceFunctionAllocation?: Maybe<ResourceFunctionAllocation>
  updateResourceRequest?: Maybe<ResourceRequest>
  updateResourceRequestState?: Maybe<ResourceRequest>
  updateResourceState?: Maybe<ResourceState>
  updateVehicleAllocation?: Maybe<ResourceAllocation>
  updateWebfleetOrder?: Maybe<WebfleetOrder>
}

export type MutationAddResourceRequestCommentArgs = {
  comment?: InputMaybe<Scalars['String']['input']>
  resourceAllocationIds?: InputMaybe<Array<Scalars['Int']['input']>>
  resourceRequestId?: InputMaybe<Scalars['Int']['input']>
}

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input']
  oldPassword: Scalars['String']['input']
}

export type MutationCreateAddressAllocationArgs = {
  addressId: Scalars['Int']['input']
  custom1?: InputMaybe<Scalars['String']['input']>
  custom2?: InputMaybe<Scalars['String']['input']>
  notice?: InputMaybe<Scalars['String']['input']>
  resourceAllocationStateId: Scalars['Int']['input']
  resourceFunctionAllocationId: Scalars['Int']['input']
}

export type MutationCreateExpenseReportArgs = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  date: Scalars['DateTime']['input']
  dayOfCatering?: InputMaybe<Scalars['DateTime']['input']>
  details?: InputMaybe<Scalars['String']['input']>
  expenseTypeId: Scalars['Int']['input']
  files?: InputMaybe<Array<Scalars['FileUpload']['input']>>
  host?: InputMaybe<Scalars['String']['input']>
  invoiceAmount?: InputMaybe<Scalars['Float']['input']>
  jobId: Scalars['Int']['input']
  kilometer?: InputMaybe<Scalars['Float']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  paymentInfo?: InputMaybe<Scalars['String']['input']>
  paymentType?: InputMaybe<PaymentType>
  publicTransportation?: InputMaybe<Scalars['Boolean']['input']>
  reason?: InputMaybe<Scalars['String']['input']>
  route?: InputMaybe<Scalars['String']['input']>
  servedPersons?: InputMaybe<Scalars['String']['input']>
  tipAmount?: InputMaybe<Scalars['Float']['input']>
  totalAmount?: InputMaybe<Scalars['Float']['input']>
}

export type MutationCreateExternalServiceRequestArgs = {
  client: Scalars['String']['input']
  comment?: InputMaybe<Scalars['String']['input']>
  customerOfClient: Scalars['String']['input']
  endDate: Scalars['DateTime']['input']
  eventName: Scalars['String']['input']
  jobFunction: Scalars['String']['input']
  location: Scalars['String']['input']
  startDate: Scalars['DateTime']['input']
}

export type MutationCreateLeaveRequestArgs = {
  comment?: InputMaybe<Scalars['String']['input']>
  endDate: Scalars['DateTime']['input']
  startDate: Scalars['DateTime']['input']
  type: LeaveRequestType
}

export type MutationCreatePersoPlanViewArgs = {
  public?: InputMaybe<Scalars['Boolean']['input']>
  query: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type MutationCreateResourceRequestArgs = {
  dateOfExpire?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  resourceAllocationIds: Array<InputMaybe<Scalars['Int']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateResourceStateArgs = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  caption?: InputMaybe<Scalars['String']['input']>
  comment?: InputMaybe<Scalars['String']['input']>
  endDate: Scalars['String']['input']
  resourceStateTypeId: Scalars['Int']['input']
  startDate: Scalars['String']['input']
  vehicleId?: InputMaybe<Scalars['Int']['input']>
}

export type MutationCreateVehicleAllocationArgs = {
  custom1?: InputMaybe<Scalars['String']['input']>
  custom2?: InputMaybe<Scalars['String']['input']>
  notice?: InputMaybe<Scalars['String']['input']>
  resourceAllocationStateId: Scalars['Int']['input']
  resourceFunctionAllocationId: Scalars['Int']['input']
  vehicleId: Scalars['Int']['input']
}

export type MutationCreateWebfleetOrderArgs = {
  notice?: InputMaybe<Scalars['String']['input']>
  resourceAllocationId: Scalars['Int']['input']
}

export type MutationDeleteAllocationArgs = {
  resourceAllocationId: Scalars['Int']['input']
}

export type MutationDeleteAllocationDriverArgs = {
  resourceAllocationId: Scalars['Int']['input']
}

export type MutationDeleteGlobalSettingsArgs = {
  keys: Array<InputMaybe<Scalars['String']['input']>>
}

export type MutationDeletePersoPlanViewArgs = {
  id: Scalars['Int']['input']
}

export type MutationDeleteResourceStateArgs = {
  id: Scalars['Int']['input']
}

export type MutationDeleteWebfleetOrderArgs = {
  resourceAllocationId: Scalars['Int']['input']
}

export type MutationSaveGlobalSettingArgs = {
  key: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type MutationSaveSettingArgs = {
  name: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type MutationSetAllocationDriverArgs = {
  resourceAllocationDriverId: Scalars['Int']['input']
  resourceAllocationId: Scalars['Int']['input']
}

export type MutationSetProjectGroupArgs = {
  group: Scalars['Int']['input']
  id: Scalars['Int']['input']
}

export type MutationSetProjectSettingArgs = {
  group?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['Int']['input']
  isJobLayerHidden?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSubscribeProjectArgs = {
  projectId: Scalars['Int']['input']
}

export type MutationUnsubscribeProjectArgs = {
  projectId: Scalars['Int']['input']
}

export type MutationUpdateAddressAllocationArgs = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  custom1?: InputMaybe<Scalars['String']['input']>
  custom2?: InputMaybe<Scalars['String']['input']>
  notice?: InputMaybe<Scalars['String']['input']>
  resourceAllocationId: Scalars['Int']['input']
  resourceAllocationStateId?: InputMaybe<Scalars['Int']['input']>
  resourceRequestMessage?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpdateExpenseReportArgs = {
  addressId: Scalars['Int']['input']
  date: Scalars['DateTime']['input']
  dayOfCatering?: InputMaybe<Scalars['DateTime']['input']>
  details?: InputMaybe<Scalars['String']['input']>
  expenseTypeId: Scalars['Int']['input']
  files?: InputMaybe<Array<Scalars['FileUpload']['input']>>
  host?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Int']['input']
  invoiceAmount?: InputMaybe<Scalars['Float']['input']>
  jobId: Scalars['Int']['input']
  kilometer?: InputMaybe<Scalars['Float']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  paymentInfo?: InputMaybe<Scalars['String']['input']>
  paymentType?: InputMaybe<PaymentType>
  publicTransportation?: InputMaybe<Scalars['Boolean']['input']>
  reason?: InputMaybe<Scalars['String']['input']>
  route?: InputMaybe<Scalars['String']['input']>
  servedPersons?: InputMaybe<Scalars['String']['input']>
  tipAmount?: InputMaybe<Scalars['Float']['input']>
  totalAmount?: InputMaybe<Scalars['Float']['input']>
}

export type MutationUpdateExternalServiceRequestArgs = {
  action?: InputMaybe<ExternalServiceRequestAction>
  client?: InputMaybe<Scalars['String']['input']>
  comment?: InputMaybe<Scalars['String']['input']>
  customerOfClient?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  eventName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Int']['input']
  location?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type MutationUpdateJobArgs = {
  comment?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Int']['input']
}

export type MutationUpdateJobAppointmentArgs = {
  comment?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Int']['input']
}

export type MutationUpdateLeaveRequestArgs = {
  action?: InputMaybe<LeaveRequestAction>
  comment?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  type?: InputMaybe<LeaveRequestType>
}

export type MutationUpdateNotificationStateArgs = {
  id: Scalars['Int']['input']
  state: NotificationStatus
}

export type MutationUpdatePersoPlanViewArgs = {
  id: Scalars['Int']['input']
  public?: InputMaybe<Scalars['Boolean']['input']>
  query?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpdateProjectArgs = {
  comment?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Int']['input']
}

export type MutationUpdateResourceFunctionAllocationArgs = {
  id: Scalars['Int']['input']
  timesScheduled: Scalars['Boolean']['input']
}

export type MutationUpdateResourceRequestArgs = {
  dateOfExpire?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  resourceAllocationIds: Array<InputMaybe<Scalars['Int']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpdateResourceRequestStateArgs = {
  message?: InputMaybe<Scalars['String']['input']>
  resourceAllocationIds: Array<InputMaybe<Scalars['Int']['input']>>
  state?: InputMaybe<ResourceRequestState>
}

export type MutationUpdateResourceStateArgs = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  caption?: InputMaybe<Scalars['String']['input']>
  comment?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Int']['input']
  resourceStateTypeId?: InputMaybe<Scalars['Int']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  vehicleId?: InputMaybe<Scalars['Int']['input']>
}

export type MutationUpdateVehicleAllocationArgs = {
  custom1?: InputMaybe<Scalars['String']['input']>
  custom2?: InputMaybe<Scalars['String']['input']>
  notice?: InputMaybe<Scalars['String']['input']>
  resourceAllocationId: Scalars['Int']['input']
  resourceAllocationStateId?: InputMaybe<Scalars['Int']['input']>
  vehicleId?: InputMaybe<Scalars['Int']['input']>
}

export type MutationUpdateWebfleetOrderArgs = {
  notice?: InputMaybe<Scalars['String']['input']>
  resourceAllocationId: Scalars['Int']['input']
}

export type Notification = {
  __typename?: 'Notification'
  created: Scalars['DateTime']['output']
  createdAt: Scalars['DateTime']['output']
  details?: Maybe<NotificationDetailItem>
  expires?: Maybe<Scalars['DateTime']['output']>
  expiresAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  message?: Maybe<Scalars['String']['output']>
  meta?: Maybe<NotificationMetaItem>
  state: NotificationStatus
  type: NotificationType
  updated: Scalars['DateTime']['output']
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export type NotificationDetailItem =
  | ExternalServiceRequest
  | Job
  | LeaveRequest
  | Project
  | ResourceRequest

export type NotificationFilter = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  includeRead?: InputMaybe<Scalars['Boolean']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type NotificationMetaItem =
  | ExternalServiceRequestAccepted
  | ExternalServiceRequestCreated
  | ExternalServiceRequestDeclined
  | ExternalServiceRequestUpdated
  | LeaveRequestAccepted
  | LeaveRequestCreated
  | LeaveRequestDeclined
  | LeaveRequestUpdated
  | ResourceRequestFeedbackStateUpdated
  | ResourceRequestUpdated

export enum NotificationStatus {
  read = 'read',
  unread = 'unread',
}

export enum NotificationType {
  AddressUpdated = 'AddressUpdated',
  ExternalServiceRequestAccepted = 'ExternalServiceRequestAccepted',
  ExternalServiceRequestCreated = 'ExternalServiceRequestCreated',
  ExternalServiceRequestDeclined = 'ExternalServiceRequestDeclined',
  ExternalServiceRequestUpdated = 'ExternalServiceRequestUpdated',
  LeaveRequestAccepted = 'LeaveRequestAccepted',
  LeaveRequestCreated = 'LeaveRequestCreated',
  LeaveRequestDeclined = 'LeaveRequestDeclined',
  LeaveRequestUpdated = 'LeaveRequestUpdated',
  ResourceRequestFeedbackStateUpdated = 'ResourceRequestFeedbackStateUpdated',
  ResourceRequestUpdated = 'ResourceRequestUpdated',
}

export type OpeningHour = {
  __typename?: 'OpeningHour'
  closingTime?: Maybe<Scalars['Time']['output']>
  day?: Maybe<Scalars['Int']['output']>
  openingTime?: Maybe<Scalars['Time']['output']>
}

export type OptionalStartEndFilter = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type OutlookCalendar = {
  __typename?: 'OutlookCalendar'
  caption: Scalars['String']['output']
  color?: Maybe<Scalars['HexColor']['output']>
  id: Scalars['String']['output']
  lastResult?: Maybe<Scalars['String']['output']>
}

export type Owner = {
  __typename?: 'Owner'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  isEditMasterDataAllowed?: Maybe<Scalars['Boolean']['output']>
  isManualInsertAllowed?: Maybe<Scalars['Boolean']['output']>
  multiplier?: Maybe<Scalars['Int']['output']>
  type: Scalars['String']['output']
}

export type Pagination = {
  from?: InputMaybe<Scalars['Int']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
}

export type PaymentCondition = {
  __typename?: 'PaymentCondition'
  caption: Scalars['String']['output']
  captionLong2?: Maybe<Scalars['String']['output']>
  captionLong3?: Maybe<Scalars['String']['output']>
  captionLong4?: Maybe<Scalars['String']['output']>
  captionLong5?: Maybe<Scalars['String']['output']>
  captionLong6?: Maybe<Scalars['String']['output']>
  firstDays?: Maybe<Scalars['Int']['output']>
  firstPercent?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  isAdvance?: Maybe<Scalars['Boolean']['output']>
  isPaymentPlan?: Maybe<Scalars['Boolean']['output']>
  nextDays?: Maybe<Scalars['Int']['output']>
  nextPercent?: Maybe<Scalars['Float']['output']>
}

export enum PaymentType {
  companyCreditCard = 'companyCreditCard',
  private = 'private',
  supplierCreditCard = 'supplierCreditCard',
}

export type PersoPlanView = {
  __typename?: 'PersoPlanView'
  id: Scalars['Int']['output']
  public?: Maybe<Scalars['Boolean']['output']>
  query: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type Priority = FilterEntity & {
  __typename?: 'Priority'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type Project = {
  __typename?: 'Project'
  arrangedBy?: Maybe<EasyJobUser>
  budget?: Maybe<Scalars['Float']['output']>
  caption: Scalars['String']['output']
  comment?: Maybe<Scalars['String']['output']>
  created?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<EasyJobUser>
  customer?: Maybe<Address>
  endDate?: Maybe<Scalars['DateTime']['output']>
  event?: Maybe<EasyJobEvent>
  globalSetting?: Maybe<GlobalSetting>
  group?: Maybe<ProjectGroup>
  id: Scalars['Int']['output']
  isSubscribedByCurrentUser?: Maybe<Scalars['Boolean']['output']>
  jobs?: Maybe<Array<Maybe<Job>>>
  logs?: Maybe<Array<Maybe<LogItem>>>
  number: Scalars['String']['output']
  numberOfMissingResources?: Maybe<Scalars['Int']['output']>
  priority?: Maybe<Priority>
  projectState?: Maybe<ProjectState>
  projectType?: Maybe<ProjectType>
  setting?: Maybe<ProjectSetting>
  startDate?: Maybe<Scalars['DateTime']['output']>
  updated?: Maybe<Scalars['DateTime']['output']>
  updatedBy?: Maybe<EasyJobUser>
}

export type ProjectJobsArgs = {
  filter?: InputMaybe<JobFilter>
  sort?: InputMaybe<Array<InputMaybe<JobSort>>>
}

export type ProjectLogsArgs = {
  filter?: InputMaybe<LogFilter>
  pagination?: InputMaybe<Pagination>
}

export type ProjectFilter = {
  customerAddressIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  excludeCustomerAddressIds?: InputMaybe<Scalars['Boolean']['input']>
  excludeInactive?: InputMaybe<Scalars['Boolean']['input']>
  excludeProjectArrangedByIds?: InputMaybe<Scalars['Boolean']['input']>
  excludeProjectIds?: InputMaybe<Scalars['Boolean']['input']>
  excludeProjectStateIds?: InputMaybe<Scalars['Boolean']['input']>
  excludeProjectTypeIds?: InputMaybe<Scalars['Boolean']['input']>
  onlyWithResources?: InputMaybe<Scalars['Boolean']['input']>
  projectArrangedByIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  projectStateIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  projectTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  search?: InputMaybe<Scalars['String']['input']>
  showBlocker?: InputMaybe<Scalars['Boolean']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type ProjectGroup = {
  __typename?: 'ProjectGroup'
  isSortedManually?: Maybe<Scalars['Boolean']['output']>
  number?: Maybe<Scalars['Int']['output']>
}

export type ProjectSetting = {
  __typename?: 'ProjectSetting'
  isJobLayerHidden?: Maybe<Scalars['Boolean']['output']>
  isSortedManually?: Maybe<Scalars['Boolean']['output']>
  number?: Maybe<Scalars['Int']['output']>
}

export type ProjectSort = {
  direction?: InputMaybe<Sort>
  field?: InputMaybe<ProjectSortFields>
}

export enum ProjectSortFields {
  budget = 'budget',
  caption = 'caption',
  created = 'created',
  endDate = 'endDate',
  number = 'number',
  startDate = 'startDate',
  updated = 'updated',
}

export type ProjectState = FilterEntity & {
  __typename?: 'ProjectState'
  caption: Scalars['String']['output']
  color?: Maybe<Scalars['HexColor']['output']>
  id: Scalars['Int']['output']
  sortOrder?: Maybe<Scalars['Int']['output']>
}

export type ProjectType = FilterEntity & {
  __typename?: 'ProjectType'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type PublicHoliday = {
  __typename?: 'PublicHoliday'
  caption?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  isAllDay?: Maybe<Scalars['Boolean']['output']>
  startDate?: Maybe<Scalars['DateTime']['output']>
}

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder'
  arrangedBy?: Maybe<EasyJobUser>
  caption?: Maybe<Scalars['String']['output']>
  company?: Maybe<Company>
  contactSupplier?: Maybe<Contact>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<EasyJobUser>
  currencyBase?: Maybe<Currency>
  currencyTarget?: Maybe<Currency>
  customNumber?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  endAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  isCleared?: Maybe<Scalars['Boolean']['output']>
  number?: Maybe<Scalars['String']['output']>
  orderedAt?: Maybe<Scalars['DateTime']['output']>
  paymentCondition?: Maybe<PaymentCondition>
  purchaseOrderState?: Maybe<PurchaseOrderState>
  purchaseOrderType?: Maybe<PurchaseOrderType>
  receivedAt?: Maybe<Scalars['DateTime']['output']>
  resourceFunctionAllocations?: Maybe<Array<Maybe<ResourceFunctionAllocation>>>
  stock?: Maybe<Stock>
  subHireJob?: Maybe<Job>
  supplier?: Maybe<Address>
  supplierNote?: Maybe<Scalars['String']['output']>
  totalPrice?: Maybe<Scalars['Float']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  updatedBy?: Maybe<EasyJobUser>
  vatType?: Maybe<VatType>
}

export type PurchaseOrderFilter = {
  addressIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  onlyWithResources?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  vehicleIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type PurchaseOrderSort = {
  direction?: InputMaybe<Sort>
  field?: InputMaybe<PurchaseOrderSortFields>
}

export enum PurchaseOrderSortFields {
  caption = 'caption',
  createdAt = 'createdAt',
  number = 'number',
  orderDate = 'orderDate',
  receiveDate = 'receiveDate',
  updatedAt = 'updatedAt',
}

export type PurchaseOrderState = {
  __typename?: 'PurchaseOrderState'
  caption: Scalars['String']['output']
  id: Scalars['ID']['output']
  sortOrder?: Maybe<Scalars['Int']['output']>
  type: Scalars['String']['output']
}

export type PurchaseOrderType = {
  __typename?: 'PurchaseOrderType'
  caption: Scalars['String']['output']
  id: Scalars['ID']['output']
  sortOrder?: Maybe<Scalars['Int']['output']>
  type: Scalars['String']['output']
}

export type Qualification = {
  __typename?: 'Qualification'
  caption?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  isRated?: Maybe<Scalars['Boolean']['output']>
  resourceQualifications?: Maybe<Array<Maybe<ResourceQualification>>>
}

export type Query = {
  __typename?: 'Query'
  address?: Maybe<Address>
  addressResourceTypes?: Maybe<Array<Maybe<AddressResourceType>>>
  addressTypes?: Maybe<Array<Maybe<AddressType>>>
  addresses?: Maybe<Array<Maybe<Address>>>
  agenda?: Maybe<Agenda>
  appSetting?: Maybe<AppSetting>
  birthdays?: Maybe<Array<Maybe<Address>>>
  countries?: Maybe<Array<Maybe<Country>>>
  currentRelease?: Maybe<CurrentRelease>
  currentUser?: Maybe<User>
  easyJobEvent?: Maybe<EasyJobEvent>
  easyJobEvents?: Maybe<Array<Maybe<EasyJobEvent>>>
  easyJobUsers?: Maybe<Array<Maybe<EasyJobUser>>>
  employeeCalendar?: Maybe<Array<Maybe<AddressCalendarItem>>>
  employeeGroups?: Maybe<Array<Maybe<AddressType>>>
  event?: Maybe<Event>
  events?: Maybe<Array<Maybe<Event>>>
  expenseReport?: Maybe<ExpenseReport>
  expenseReports?: Maybe<Array<Maybe<ExpenseReport>>>
  expenseTypes?: Maybe<Array<Maybe<ExpenseType>>>
  externalServiceRequest?: Maybe<ExternalServiceRequest>
  externalServiceRequests?: Maybe<Array<Maybe<ExternalServiceRequest>>>
  externalServiceRequestsToRevise?: Maybe<Array<Maybe<ExternalServiceRequest>>>
  freelancerCalendar?: Maybe<Array<Maybe<AddressCalendarItem>>>
  globalSettings?: Maybe<Array<Maybe<GlobalSetting>>>
  job?: Maybe<Job>
  jobAppointment?: Maybe<JobAppointment>
  jobAppointmentTypes?: Maybe<Array<Maybe<JobAppointmentType>>>
  jobAppointments?: Maybe<Array<Maybe<JobAppointment>>>
  jobServices?: Maybe<Array<Maybe<JobService>>>
  jobStates?: Maybe<Array<Maybe<JobState>>>
  jobs?: Maybe<Array<Maybe<Job>>>
  leaveRequest?: Maybe<LeaveRequest>
  leaveRequests?: Maybe<Array<Maybe<LeaveRequest>>>
  leaveRequestsToRevise?: Maybe<Array<Maybe<LeaveRequest>>>
  logActions?: Maybe<Array<Maybe<LogAction>>>
  milestones?: Maybe<Array<Maybe<Milestone>>>
  notifications?: Maybe<Array<Maybe<Notification>>>
  persoPlanViews?: Maybe<Array<Maybe<PersoPlanView>>>
  priorities?: Maybe<Array<Maybe<Priority>>>
  project?: Maybe<Project>
  projectStates?: Maybe<Array<Maybe<ProjectState>>>
  projectTypes?: Maybe<Array<Maybe<ProjectType>>>
  projects?: Maybe<Array<Maybe<Project>>>
  publicHolidays?: Maybe<Array<Maybe<PublicHoliday>>>
  purchaseOrder?: Maybe<PurchaseOrder>
  purchaseOrders?: Maybe<Array<Maybe<PurchaseOrder>>>
  qualifications?: Maybe<Array<Maybe<Qualification>>>
  releases?: Maybe<Releases>
  reports?: Maybe<Array<Maybe<MenuItem>>>
  resourceAllocation?: Maybe<ResourceAllocation>
  resourceAllocationStates?: Maybe<Array<Maybe<ResourceAllocationState>>>
  resourceAllocations?: Maybe<Array<Maybe<ResourceAllocation>>>
  resourceFunctionAllocation?: Maybe<ResourceFunctionAllocation>
  resourceFunctionAllocations?: Maybe<Array<Maybe<ResourceFunctionAllocation>>>
  resourceFunctions?: Maybe<Array<Maybe<ResourceFunction>>>
  resourceRequest?: Maybe<ResourceRequest>
  resourceRequestFeedback?: Maybe<ResourceRequestFeedback>
  resourceState?: Maybe<ResourceState>
  resourceStateTypes?: Maybe<Array<Maybe<ResourceStateType>>>
  resourceStates?: Maybe<Array<Maybe<ResourceState>>>
  resourceTypes?: Maybe<Array<Maybe<ResourceType>>>
  salutations?: Maybe<Array<Maybe<Salutation>>>
  settings?: Maybe<Array<Maybe<Setting>>>
  stockTypeService?: Maybe<StockTypeService>
  stockTypeServices?: Maybe<Array<Maybe<StockTypeService>>>
  vehicle?: Maybe<Vehicle>
  vehicleCalendar?: Maybe<Array<Maybe<VehicleCalendarItem>>>
  vehicles?: Maybe<Array<Maybe<Vehicle>>>
  webfleetOrder?: Maybe<WebfleetOrder>
}

export type QueryAddressArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryAddressesArgs = {
  filter?: InputMaybe<AddressFilter>
  pagination?: InputMaybe<Pagination>
  sort?: InputMaybe<Array<InputMaybe<AddressSort>>>
}

export type QueryAgendaArgs = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  date?: InputMaybe<Scalars['Date']['input']>
  endDate?: InputMaybe<Scalars['Date']['input']>
  startDate?: InputMaybe<Scalars['Date']['input']>
  vehicleId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryBirthdaysArgs = {
  filter: StartEndFilter
}

export type QueryEasyJobEventArgs = {
  id: Scalars['Int']['input']
}

export type QueryEasyJobEventsArgs = {
  filter?: InputMaybe<StartEndFilter>
  pagination?: InputMaybe<Pagination>
  sort?: InputMaybe<Array<InputMaybe<EventSort>>>
}

export type QueryEasyJobUsersArgs = {
  filter?: InputMaybe<EasyJobUserFilter>
  pagination?: InputMaybe<Pagination>
  sort?: InputMaybe<Array<InputMaybe<EasyJobUserSort>>>
}

export type QueryEmployeeCalendarArgs = {
  endDate: Scalars['DateTime']['input']
  excludeInactive?: InputMaybe<Scalars['Boolean']['input']>
  startDate: Scalars['DateTime']['input']
}

export type QueryEventArgs = {
  id: Scalars['Int']['input']
}

export type QueryEventsArgs = {
  filter: EventFilter
}

export type QueryExpenseReportArgs = {
  id: Scalars['Int']['input']
}

export type QueryExpenseReportsArgs = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<OptionalStartEndFilter>
  userId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryExternalServiceRequestArgs = {
  id: Scalars['Int']['input']
}

export type QueryExternalServiceRequestsArgs = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<OptionalStartEndFilter>
  userId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryExternalServiceRequestsToReviseArgs = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<OptionalStartEndFilter>
  userId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryFreelancerCalendarArgs = {
  endDate: Scalars['DateTime']['input']
  startDate: Scalars['DateTime']['input']
}

export type QueryGlobalSettingsArgs = {
  keys: Array<InputMaybe<Scalars['String']['input']>>
}

export type QueryJobArgs = {
  id: Scalars['Int']['input']
}

export type QueryJobAppointmentArgs = {
  id: Scalars['Int']['input']
}

export type QueryJobAppointmentsArgs = {
  filter?: InputMaybe<JobAppointmentFilter>
  pagination?: InputMaybe<Pagination>
  sort?: InputMaybe<Array<InputMaybe<JobAppointmentSort>>>
}

export type QueryJobsArgs = {
  filter?: InputMaybe<JobFilter>
  pagination?: InputMaybe<Pagination>
  sort?: InputMaybe<Array<InputMaybe<JobSort>>>
}

export type QueryLeaveRequestArgs = {
  id: Scalars['Int']['input']
}

export type QueryLeaveRequestsArgs = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<OptionalStartEndFilter>
  userId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryLeaveRequestsToReviseArgs = {
  addressId?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<OptionalStartEndFilter>
  userId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationFilter>
}

export type QueryProjectArgs = {
  id: Scalars['Int']['input']
}

export type QueryProjectsArgs = {
  filter?: InputMaybe<ProjectFilter>
  pagination?: InputMaybe<Pagination>
  sort?: InputMaybe<Array<InputMaybe<ProjectSort>>>
}

export type QueryPublicHolidaysArgs = {
  filter?: InputMaybe<StartEndFilter>
  pagination?: InputMaybe<Pagination>
}

export type QueryPurchaseOrderArgs = {
  id: Scalars['Int']['input']
}

export type QueryPurchaseOrdersArgs = {
  filter?: InputMaybe<PurchaseOrderFilter>
  pagination?: InputMaybe<Pagination>
  sort?: InputMaybe<Array<InputMaybe<PurchaseOrderSort>>>
}

export type QueryResourceAllocationArgs = {
  id: Scalars['Int']['input']
}

export type QueryResourceAllocationsArgs = {
  filter?: InputMaybe<ResourceAllocationFilter>
}

export type QueryResourceFunctionAllocationArgs = {
  id: Scalars['Int']['input']
}

export type QueryResourceFunctionAllocationsArgs = {
  filter?: InputMaybe<ResourceFunctionAllocationFilter>
}

export type QueryResourceRequestArgs = {
  resourceAllocationId?: InputMaybe<Scalars['Int']['input']>
  resourceAllocationIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type QueryResourceRequestFeedbackArgs = {
  resourceRequestId: Scalars['Int']['input']
}

export type QueryResourceStateArgs = {
  id: Scalars['Int']['input']
}

export type QueryResourceStatesArgs = {
  filter?: InputMaybe<ResourceStateFilter>
  pagination?: InputMaybe<Pagination>
  sort?: InputMaybe<Array<InputMaybe<ResourceStateSort>>>
}

export type QueryStockTypeServiceArgs = {
  id: Scalars['Int']['input']
}

export type QueryStockTypeServicesArgs = {
  filter?: InputMaybe<StockTypeServiceFilter>
  pagination?: InputMaybe<Pagination>
  sort?: InputMaybe<Array<InputMaybe<StockTypeServiceSort>>>
}

export type QueryVehicleArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryVehicleCalendarArgs = {
  endDate: Scalars['DateTime']['input']
  excludeInactive?: InputMaybe<Scalars['Boolean']['input']>
  startDate: Scalars['DateTime']['input']
}

export type QueryVehiclesArgs = {
  filter?: InputMaybe<VehicleFilter>
  sort?: InputMaybe<Array<InputMaybe<VehicleSort>>>
}

export type QueryWebfleetOrderArgs = {
  resourceAllocationId: Scalars['Int']['input']
}

export type Release = {
  __typename?: 'Release'
  body?: Maybe<Scalars['String']['output']>
  createdBy?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['String']['output']>
  version?: Maybe<Scalars['String']['output']>
}

export type Releases = {
  __typename?: 'Releases'
  api?: Maybe<Array<Maybe<Release>>>
  frontend?: Maybe<Array<Maybe<Release>>>
}

export type ResourceAllocation = {
  __typename?: 'ResourceAllocation'
  address?: Maybe<Address>
  adjacencyInformation?: Maybe<AdjacentResourceAllocationInformation>
  custom1?: Maybe<Scalars['String']['output']>
  custom2?: Maybe<Scalars['String']['output']>
  dutyOrder?: Maybe<DutyOrder>
  extra?: Maybe<ResourceAllocationExtra>
  factor?: Maybe<Scalars['Int']['output']>
  globalSetting?: Maybe<GlobalSetting>
  id: Scalars['Int']['output']
  logs?: Maybe<Array<Maybe<ResourceAllocationLogItem>>>
  resourceAllocationDriver?: Maybe<ResourceAllocation>
  resourceAllocationState?: Maybe<ResourceAllocationState>
  resourceFunctionAllocation?: Maybe<ResourceFunctionAllocation>
  resourceRequest?: Maybe<ResourceRequest>
  vehicle?: Maybe<Vehicle>
  webfleetOrder?: Maybe<WebfleetOrder>
}

export type ResourceAllocationDutyOrderArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type ResourceAllocationLogsArgs = {
  filter?: InputMaybe<LogFilter>
  pagination?: InputMaybe<Pagination>
}

export type ResourceAllocationExtra = {
  __typename?: 'ResourceAllocationExtra'
  id: Scalars['Int']['output']
  notice?: Maybe<Scalars['String']['output']>
  webfleetNotice?: Maybe<Scalars['String']['output']>
  webfleetState?: Maybe<WebfleetState>
}

export type ResourceAllocationFilter = {
  addressIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  excludeAddressIds?: InputMaybe<Scalars['Boolean']['input']>
  excludeAddressResourceTypeIds?: InputMaybe<Scalars['Boolean']['input']>
  excludeInactive?: InputMaybe<Scalars['Boolean']['input']>
  excludeResourceAllocationStateIds?: InputMaybe<Scalars['Boolean']['input']>
  resourceAllocationIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  resourceAllocationStateIds?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >
  resourceFunctionAllocationIds?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >
  vehicleIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ResourceAllocationLogItem = {
  __typename?: 'ResourceAllocationLogItem'
  action: LogAction
  date?: Maybe<Scalars['DateTime']['output']>
  factor?: Maybe<Scalars['Int']['output']>
  id: Scalars['Int']['output']
  resourceAllocationState?: Maybe<ResourceAllocationState>
  resourceFunctionAllocation?: Maybe<ResourceFunctionAllocation>
  user?: Maybe<EasyJobUser>
}

export type ResourceAllocationState = FilterEntity & {
  __typename?: 'ResourceAllocationState'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type ResourceFunction = {
  __typename?: 'ResourceFunction'
  abbreviation?: Maybe<Scalars['String']['output']>
  caption?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  resourceType?: Maybe<ResourceType>
  sortOrder?: Maybe<Scalars['Int']['output']>
}

export type ResourceFunction2Address = {
  __typename?: 'ResourceFunction2Address'
  address?: Maybe<Address>
  id: Scalars['Int']['output']
  ratingQuality?: Maybe<Scalars['Int']['output']>
  ratingSelf?: Maybe<Scalars['Int']['output']>
  resourceFunction?: Maybe<ResourceFunction>
}

export type ResourceFunctionAllocation = {
  __typename?: 'ResourceFunctionAllocation'
  allocationStatistics?: Maybe<Array<Maybe<KeyValue>>>
  created?: Maybe<Scalars['DateTime']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  globalSetting?: Maybe<GlobalSetting>
  id: Scalars['Int']['output']
  job?: Maybe<Job>
  jobAppointment?: Maybe<JobAppointment>
  jobAppointments?: Maybe<Array<Maybe<JobAppointment>>>
  logs?: Maybe<Array<Maybe<ResourceFunctionAllocationLogItem>>>
  numberOfResourceAllocations?: Maybe<Scalars['Int']['output']>
  purchaseOrder?: Maybe<PurchaseOrder>
  quantity?: Maybe<Scalars['Int']['output']>
  resourceAllocations?: Maybe<Array<Maybe<ResourceAllocation>>>
  resourceFunction?: Maybe<ResourceFunction>
  startDate?: Maybe<Scalars['DateTime']['output']>
  stockTypeService?: Maybe<StockTypeService>
  timesScheduled?: Maybe<Scalars['Boolean']['output']>
  updated?: Maybe<Scalars['DateTime']['output']>
}

export type ResourceFunctionAllocationLogsArgs = {
  filter?: InputMaybe<LogFilter>
  pagination?: InputMaybe<Pagination>
}

export type ResourceFunctionAllocationResourceAllocationsArgs = {
  filter?: InputMaybe<ResourceAllocationFilter>
}

export type ResourceFunctionAllocationFilter = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  excludeHavingJobAppointment?: InputMaybe<Scalars['Boolean']['input']>
  excludeResourceFunctionIds?: InputMaybe<Scalars['Boolean']['input']>
  excludeResourceTypeIds?: InputMaybe<Scalars['Boolean']['input']>
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  jobAppointmentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  jobIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  resourceFunctionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  resourceTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type ResourceFunctionAllocationLogItem = {
  __typename?: 'ResourceFunctionAllocationLogItem'
  action: LogAction
  date?: Maybe<Scalars['DateTime']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  quantity?: Maybe<Scalars['Int']['output']>
  resourceFunction?: Maybe<ResourceFunction>
  resourceFunctionAllocation?: Maybe<ResourceFunctionAllocation>
  startDate?: Maybe<Scalars['DateTime']['output']>
  totalCosts?: Maybe<Scalars['Float']['output']>
  totalPrice?: Maybe<Scalars['Float']['output']>
  user?: Maybe<EasyJobUser>
}

export type ResourceQualification = {
  __typename?: 'ResourceQualification'
  address?: Maybe<Address>
  description?: Maybe<Scalars['String']['output']>
  expirationDate: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  qualification?: Maybe<Qualification>
  ratingQuality?: Maybe<Scalars['Int']['output']>
  ratingSelf?: Maybe<Scalars['Int']['output']>
}

export type ResourceRequest = {
  __typename?: 'ResourceRequest'
  communications?: Maybe<Array<Maybe<ResourceRequestCommunication>>>
  created?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<Address>
  dateOfExpire?: Maybe<Scalars['DateTime']['output']>
  feedback?: Maybe<ResourceRequestFeedback>
  foreignId?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  resourceAllocationIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  resourceAllocations?: Maybe<Array<Maybe<ResourceAllocation>>>
  state?: Maybe<ResourceRequestState>
  title?: Maybe<Scalars['String']['output']>
  updated?: Maybe<Scalars['DateTime']['output']>
  updatedBy?: Maybe<Address>
}

export enum ResourceRequestAction {
  cancel = 'cancel',
  confirm = 'confirm',
  create = 'create',
  decline = 'decline',
  update = 'update',
}

export type ResourceRequestCommunication = {
  __typename?: 'ResourceRequestCommunication'
  action: ResourceRequestAction
  availableFrom?: Maybe<Scalars['DateTime']['output']>
  availableTo?: Maybe<Scalars['DateTime']['output']>
  comment?: Maybe<Scalars['String']['output']>
  newProMusikState?: Maybe<ResourceRequestState>
  newUserState?: Maybe<ResourceRequestUserStatus>
  oneDriveLink?: Maybe<Scalars['String']['output']>
  previousProMusikState?: Maybe<ResourceRequestState>
  previousUserState?: Maybe<ResourceRequestUserStatus>
  resourceCount?: Maybe<Scalars['Int']['output']>
  timestamp: Scalars['DateTime']['output']
  user: Address
}

export type ResourceRequestFeedback = {
  __typename?: 'ResourceRequestFeedback'
  availableFrom?: Maybe<Scalars['DateTime']['output']>
  availableTo?: Maybe<Scalars['DateTime']['output']>
  comment?: Maybe<Scalars['String']['output']>
  userState?: Maybe<ResourceRequestUserStatus>
}

export type ResourceRequestFeedbackStateUpdated = {
  __typename?: 'ResourceRequestFeedbackStateUpdated'
  stateAfter?: Maybe<ResourceRequestUserStatus>
  stateBefore?: Maybe<ResourceRequestUserStatus>
}

export enum ResourceRequestState {
  accept = 'accept',
  cancel = 'cancel',
  decline = 'decline',
  reserve = 'reserve',
}

export type ResourceRequestUpdated = {
  __typename?: 'ResourceRequestUpdated'
  action?: Maybe<ResourceRequestUpdatedAction>
}

export enum ResourceRequestUpdatedAction {
  created = 'created',
  deleted = 'deleted',
  unknown = 'unknown',
  updated = 'updated',
}

export enum ResourceRequestUserStatus {
  accept = 'accept',
  decline = 'decline',
  maybe = 'maybe',
  not_available = 'not_available',
  unknown = 'unknown',
}

export type ResourceState = {
  __typename?: 'ResourceState'
  address?: Maybe<Address>
  caption?: Maybe<Scalars['String']['output']>
  comment?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  locked?: Maybe<Scalars['Boolean']['output']>
  resourceAllocationState?: Maybe<ResourceAllocationState>
  resourceSource?: Maybe<Scalars['ResourceSource']['output']>
  resourceStateType?: Maybe<ResourceStateType>
  startDate?: Maybe<Scalars['DateTime']['output']>
  vehicle?: Maybe<Vehicle>
}

export type ResourceStateFilter = {
  addressIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  resourceSource?: InputMaybe<Scalars['ResourceSource']['input']>
  resourceStateIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  resourceStateTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  vehicleIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ResourceStateSort = {
  direction?: InputMaybe<Sort>
  field?: InputMaybe<ResourceStateSortFields>
}

export enum ResourceStateSortFields {
  caption = 'caption',
  endDate = 'endDate',
  startDate = 'startDate',
}

export type ResourceStateType = FilterEntity & {
  __typename?: 'ResourceStateType'
  caption: Scalars['String']['output']
  color?: Maybe<Scalars['HexColor']['output']>
  id: Scalars['Int']['output']
  resourceType: ResourceType
}

export type ResourceType = FilterEntity & {
  __typename?: 'ResourceType'
  caption: Scalars['String']['output']
  captionDe: Scalars['String']['output']
  captionEn: Scalars['String']['output']
  id: Scalars['Int']['output']
  type: Scalars['String']['output']
}

export type Salutation = {
  __typename?: 'Salutation'
  caption: Scalars['String']['output']
  captionLong2?: Maybe<Scalars['String']['output']>
  captionLong3?: Maybe<Scalars['String']['output']>
  captionLong4?: Maybe<Scalars['String']['output']>
  captionLong5?: Maybe<Scalars['String']['output']>
  captionLong6?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
}

export type SetAllocationDriverResponse = {
  __typename?: 'SetAllocationDriverResponse'
  previousResourceAllocationDriver?: Maybe<ResourceAllocation>
  resourceAllocation: ResourceAllocation
  resourceAllocationDriver: ResourceAllocation
}

export type Setting = {
  __typename?: 'Setting'
  name: Scalars['String']['output']
  value?: Maybe<Scalars['String']['output']>
}

export enum Sort {
  asc = 'asc',
  desc = 'desc',
}

export type StartEndFilter = {
  endDate: Scalars['DateTime']['input']
  startDate: Scalars['DateTime']['input']
}

export type Stock = {
  __typename?: 'Stock'
  address?: Maybe<Address>
  bitPosition?: Maybe<Scalars['Int']['output']>
  caption: Scalars['String']['output']
  company?: Maybe<Company>
  currencyBase?: Maybe<Currency>
  defaultManager?: Maybe<EasyJobUser>
  defaultOwner?: Maybe<Owner>
  id: Scalars['ID']['output']
  isStockTypeLocationEnabled?: Maybe<Scalars['Boolean']['output']>
  token?: Maybe<Scalars['String']['output']>
}

export type StockType = {
  __typename?: 'StockType'
  addressManufacturer?: Maybe<Address>
  apparentPower?: Maybe<Scalars['Float']['output']>
  areStatsIgnored?: Maybe<Scalars['Boolean']['output']>
  barcode?: Maybe<Scalars['String']['output']>
  bookingWarning?: Maybe<Scalars['String']['output']>
  caption: Scalars['String']['output']
  categorySortOrder?: Maybe<Scalars['Int']['output']>
  comment?: Maybe<Scalars['String']['output']>
  costCenter?: Maybe<CostCenter>
  costCenterSales?: Maybe<CostCenter>
  costCenterSalesUsed?: Maybe<CostCenter>
  countryManufacturer?: Maybe<Country>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<EasyJobUser>
  customNumber?: Maybe<Scalars['String']['output']>
  deprecationMethod?: Maybe<DeprecationMethod>
  depth?: Maybe<Scalars['Float']['output']>
  economicLife?: Maybe<Scalars['String']['output']>
  hasFlightCase?: Maybe<Scalars['Boolean']['output']>
  hasNoDiscount?: Maybe<Scalars['Boolean']['output']>
  hasSingleDeviceManagement?: Maybe<Scalars['Boolean']['output']>
  height?: Maybe<Scalars['Float']['output']>
  id: Scalars['Int']['output']
  inventoryBase?: Maybe<Scalars['Int']['output']>
  inventorySales?: Maybe<Scalars['Int']['output']>
  isAvailabilityCheckDisabled?: Maybe<Scalars['Boolean']['output']>
  isAvailabilityCheckIgnored?: Maybe<Scalars['Boolean']['output']>
  isDefaultLocationForced?: Maybe<Scalars['Boolean']['output']>
  isDrained?: Maybe<Scalars['Boolean']['output']>
  isMarketingStockType?: Maybe<Scalars['Boolean']['output']>
  isPrintable?: Maybe<Scalars['Boolean']['output']>
  isPublishedToWebShop?: Maybe<Scalars['Boolean']['output']>
  isRentalStockType?: Maybe<Scalars['Boolean']['output']>
  isSalesStockType?: Maybe<Scalars['Boolean']['output']>
  isServiceEnabled?: Maybe<Scalars['Boolean']['output']>
  isSparePart?: Maybe<Scalars['Boolean']['output']>
  isStockTypeLocationEnabled?: Maybe<Scalars['Boolean']['output']>
  maxAllowance?: Maybe<Scalars['Int']['output']>
  netDepth?: Maybe<Scalars['Float']['output']>
  netHeight?: Maybe<Scalars['Float']['output']>
  netVolume?: Maybe<Scalars['Float']['output']>
  netWeight?: Maybe<Scalars['Float']['output']>
  netWidth?: Maybe<Scalars['Float']['output']>
  number: Scalars['String']['output']
  owner?: Maybe<Owner>
  packingUnit?: Maybe<Scalars['Int']['output']>
  policyComment?: Maybe<Scalars['String']['output']>
  postProcessingMinutes?: Maybe<Scalars['Int']['output']>
  powerConnection?: Maybe<Scalars['String']['output']>
  preparationMinutes?: Maybe<Scalars['Int']['output']>
  rateOfDutyNumber?: Maybe<Scalars['String']['output']>
  realPower?: Maybe<Scalars['Float']['output']>
  rentalsPerYear?: Maybe<Scalars['Float']['output']>
  stockTypeCategory?: Maybe<StockTypeCategory>
  taxValue?: Maybe<TaxValue>
  taxValueSales?: Maybe<TaxValue>
  timeFactor?: Maybe<TimeFactor>
  unit?: Maybe<Unit>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  updatedBy?: Maybe<EasyJobUser>
  usage?: Maybe<Scalars['Float']['output']>
  utilisationPlan?: Maybe<Scalars['Float']['output']>
  volume?: Maybe<Scalars['Float']['output']>
  weight?: Maybe<Scalars['Float']['output']>
  width?: Maybe<Scalars['Float']['output']>
}

export type StockTypeCategory = {
  __typename?: 'StockTypeCategory'
  caption: Scalars['String']['output']
  costCenter?: Maybe<CostCenter>
  costCenterSales?: Maybe<CostCenter>
  costCenterSalesUsed?: Maybe<CostCenter>
  id: Scalars['Int']['output']
  invoiceAccount?: Maybe<InvoiceAccount>
  invoiceAccountLeaseSale?: Maybe<InvoiceAccount>
  invoiceAccountPurchaseRentalInventory?: Maybe<InvoiceAccount>
  invoiceAccountPurchaseSalesInventory?: Maybe<InvoiceAccount>
  invoiceAccountSales?: Maybe<InvoiceAccount>
  invoiceAccountSubHire?: Maybe<InvoiceAccount>
  invoiceAccountWorkshopActivityMaintenance?: Maybe<InvoiceAccount>
  invoiceAccountWorkshopActivityMaintenanceIncome?: Maybe<InvoiceAccount>
  invoiceAccountWorkshopActivityRepair?: Maybe<InvoiceAccount>
  invoiceAccountWorkshopActivityRepairIncome?: Maybe<InvoiceAccount>
  mainSortOrder?: Maybe<Scalars['Int']['output']>
  sortOrder?: Maybe<Scalars['Int']['output']>
  stockTypeCategoryParent?: Maybe<StockTypeCategoryParent>
  subSection?: Maybe<SubSection>
  taxValue?: Maybe<TaxValue>
  taxValueSales?: Maybe<TaxValue>
  timeFactor?: Maybe<TimeFactor>
}

export type StockTypeCategoryParent = {
  __typename?: 'StockTypeCategoryParent'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  sortOrder?: Maybe<Scalars['Int']['output']>
}

export type StockTypeService = {
  __typename?: 'StockTypeService'
  caption: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<EasyJobUser>
  custom1?: Maybe<Scalars['String']['output']>
  custom2?: Maybe<Scalars['String']['output']>
  customNumber?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  endDate: Scalars['DateTime']['output']
  externalServiceAddress?: Maybe<Address>
  factor?: Maybe<Scalars['Int']['output']>
  hasExternalService?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['Int']['output']
  isClosed?: Maybe<Scalars['Boolean']['output']>
  job?: Maybe<Job>
  jobSource?: Maybe<Job>
  number: Scalars['String']['output']
  operatingHours?: Maybe<Scalars['Int']['output']>
  protocol?: Maybe<Scalars['String']['output']>
  resourceFunctionAllocations?: Maybe<Array<Maybe<ResourceFunctionAllocation>>>
  startDate: Scalars['DateTime']['output']
  stockType?: Maybe<StockType>
  stockTypeServiceType?: Maybe<StockTypeServiceType>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  updatedBy?: Maybe<EasyJobUser>
  usageDays?: Maybe<Scalars['Int']['output']>
  user?: Maybe<EasyJobUser>
}

export type StockTypeServiceFilter = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  excludeJobIds?: InputMaybe<Scalars['Boolean']['input']>
  jobIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  onlyWithResources?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type StockTypeServiceSort = {
  direction?: InputMaybe<Sort>
  field?: InputMaybe<StockTypeServiceSortFields>
}

export enum StockTypeServiceSortFields {
  caption = 'caption',
  endDate = 'endDate',
  number = 'number',
  startDate = 'startDate',
}

export type StockTypeServiceType = FilterEntity & {
  __typename?: 'StockTypeServiceType'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type SubSection = {
  __typename?: 'SubSection'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  number: Scalars['String']['output']
  sortOrder?: Maybe<Scalars['Int']['output']>
  token?: Maybe<Scalars['String']['output']>
}

export type TaxValue = {
  __typename?: 'TaxValue'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  isDefault?: Maybe<Scalars['Boolean']['output']>
  type: Scalars['String']['output']
}

export type TimeFactor = {
  __typename?: 'TimeFactor'
  caption: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<EasyJobUser>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  roundItems?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  updatedBy?: Maybe<EasyJobUser>
}

export type Unit = {
  __typename?: 'Unit'
  caption: Scalars['String']['output']
  id: Scalars['ID']['output']
  sortOrder?: Maybe<Scalars['Int']['output']>
  type: Scalars['String']['output']
}

export type User = {
  __typename?: 'User'
  address?: Maybe<Address>
  changePasswordNextLogin?: Maybe<Scalars['Boolean']['output']>
  easyJobRoles?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  email: Scalars['String']['output']
  firstName?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  groups?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  id: Scalars['Int']['output']
  idAddress: Scalars['Int']['output']
  lastName?: Maybe<Scalars['String']['output']>
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  subscribedProjects?: Maybe<Array<Maybe<Project>>>
  surname?: Maybe<Scalars['String']['output']>
  username: Scalars['String']['output']
}

export type VatType = {
  __typename?: 'VatType'
  caption: Scalars['String']['output']
  id: Scalars['Int']['output']
  isSuccessive?: Maybe<Scalars['Boolean']['output']>
  isSum?: Maybe<Scalars['Boolean']['output']>
  type: Scalars['String']['output']
}

export type Vehicle = {
  __typename?: 'Vehicle'
  abbreviation?: Maybe<Scalars['String']['output']>
  caption?: Maybe<Scalars['String']['output']>
  comment?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  isWebfleetVehicle?: Maybe<Scalars['Boolean']['output']>
  loadingCapacity?: Maybe<Scalars['Float']['output']>
  manufacturer?: Maybe<Scalars['String']['output']>
  netVolume?: Maybe<Scalars['Float']['output']>
  numberSign?: Maybe<Scalars['String']['output']>
  resourceFunction?: Maybe<ResourceFunction>
  resourceType?: Maybe<ResourceType>
  supplier?: Maybe<Address>
  vehicleType?: Maybe<Scalars['String']['output']>
}

export type VehicleCalendarItem = ResourceAllocation | ResourceState

export type VehicleFilter = {
  onlyProMusik?: InputMaybe<Scalars['Boolean']['input']>
  onlyResourcePlan?: InputMaybe<Scalars['Boolean']['input']>
  resourceFunctionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  search?: InputMaybe<Scalars['String']['input']>
  vehicleIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type VehicleSort = {
  direction?: InputMaybe<Sort>
  field?: InputMaybe<VehicleSortFields>
}

export enum VehicleSortFields {
  caption = 'caption',
  loadingCapacity = 'loadingCapacity',
  numberSign = 'numberSign',
}

export type WebfleetOrder = {
  __typename?: 'WebfleetOrder'
  contactPerson?: Maybe<Scalars['String']['output']>
  contactPhoneNumber?: Maybe<Scalars['String']['output']>
  deliveryAddress?: Maybe<Address>
  id?: Maybe<Scalars['String']['output']>
  notice?: Maybe<Scalars['String']['output']>
  orderDateTime?: Maybe<Scalars['DateTime']['output']>
  orderId?: Maybe<Scalars['String']['output']>
  orderText?: Maybe<Scalars['String']['output']>
  orderType?: Maybe<Scalars['String']['output']>
  resourceAllocation?: Maybe<ResourceAllocation>
  state?: Maybe<WebfleetState>
  vehicle?: Maybe<Vehicle>
}

export enum WebfleetState {
  aborted = 'aborted',
  created = 'created',
  deleted = 'deleted',
  draft = 'draft',
  updated = 'updated',
  workInProgress = 'workInProgress',
}

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}>

export type ChangePasswordMutation = {
  __typename?: 'Mutation'
  changePassword?: boolean | null
}

export type CreateExpenseReportMutationVariables = Exact<{
  expenseTypeId: Scalars['Int']['input']
  date: Scalars['DateTime']['input']
  jobId: Scalars['Int']['input']
  details?: InputMaybe<Scalars['String']['input']>
  paymentType: PaymentType
  paymentInfo?: InputMaybe<Scalars['String']['input']>
  files?: InputMaybe<
    Array<Scalars['FileUpload']['input']> | Scalars['FileUpload']['input']
  >
  route?: InputMaybe<Scalars['String']['input']>
  kilometer?: InputMaybe<Scalars['Float']['input']>
  publicTransportation?: InputMaybe<Scalars['Boolean']['input']>
  dayOfCatering?: InputMaybe<Scalars['DateTime']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  servedPersons?: InputMaybe<Scalars['String']['input']>
  reason?: InputMaybe<Scalars['String']['input']>
  invoiceAmount?: InputMaybe<Scalars['Float']['input']>
  tipAmount?: InputMaybe<Scalars['Float']['input']>
  totalAmount?: InputMaybe<Scalars['Float']['input']>
}>

export type CreateExpenseReportMutation = {
  __typename?: 'Mutation'
  createExpenseReport?: { __typename?: 'ExpenseReport'; id: number } | null
}

export type CreateExpenseReportStaticDataQueryVariables = Exact<{
  [key: string]: never
}>

export type CreateExpenseReportStaticDataQuery = {
  __typename?: 'Query'
  expenseTypes?: Array<{
    __typename?: 'ExpenseType'
    id: number
    caption: string
  } | null> | null
}

export type CreateExternalServiceRequestMutationVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
  eventName: Scalars['String']['input']
  client: Scalars['String']['input']
  customerOfClient: Scalars['String']['input']
  location: Scalars['String']['input']
  jobFunction: Scalars['String']['input']
  note?: InputMaybe<Scalars['String']['input']>
}>

export type CreateExternalServiceRequestMutation = {
  __typename?: 'Mutation'
  createExternalServiceRequest?: {
    __typename?: 'ExternalServiceRequest'
    id: number
    startDate: any
    endDate: any
    state: ExternalServiceRequestState
  } | null
}

export type CreateLeaveRequestMutationVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
  type: LeaveRequestType
  note?: InputMaybe<Scalars['String']['input']>
}>

export type CreateLeaveRequestMutation = {
  __typename?: 'Mutation'
  createLeaveRequest?: {
    __typename?: 'LeaveRequest'
    id: number
    type: LeaveRequestType
    startDate: any
    endDate: any
    state: LeaveRequestState
  } | null
}

export type DropdownAddressDataQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DropdownAddressDataQuery = {
  __typename?: 'Query'
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null
}

export type DropdownAddressSearchQueryQueryVariables = Exact<{
  searchterm?: InputMaybe<Scalars['String']['input']>
  onlyEmployees?: InputMaybe<Scalars['Boolean']['input']>
}>

export type DropdownAddressSearchQueryQuery = {
  __typename?: 'Query'
  addresses?: Array<{
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null> | null
}

export type DropdownJobDataQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DropdownJobDataQuery = {
  __typename?: 'Query'
  job?: {
    __typename?: 'Job'
    id: number
    caption: string
    number: string
    startDate: any
    endDate: any
  } | null
}

export type DropdownJobSearchQueryQueryVariables = Exact<{
  searchterm?: InputMaybe<Scalars['String']['input']>
}>

export type DropdownJobSearchQueryQuery = {
  __typename?: 'Query'
  jobs?: Array<{
    __typename?: 'Job'
    id: number
    caption: string
    number: string
    startDate: any
    endDate: any
  } | null> | null
}

export type ApiVersionQueryVariables = Exact<{ [key: string]: never }>

export type ApiVersionQuery = {
  __typename?: 'Query'
  currentRelease?: { __typename?: 'CurrentRelease'; api?: string | null } | null
}

export type ReportsQueryVariables = Exact<{ [key: string]: never }>

export type ReportsQuery = {
  __typename?: 'Query'
  reports?: Array<{
    __typename?: 'MenuItem'
    label: string
    href?: string | null
  } | null> | null
}

export type AbortExternalServiceRequestMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type AbortExternalServiceRequestMutation = {
  __typename?: 'Mutation'
  updateExternalServiceRequest?: {
    __typename?: 'ExternalServiceRequest'
    id: number
    state: ExternalServiceRequestState
  } | null
}

export type AbortLeaveRequestMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type AbortLeaveRequestMutation = {
  __typename?: 'Mutation'
  updateLeaveRequest?: {
    __typename?: 'LeaveRequest'
    id: number
    state: LeaveRequestState
  } | null
}

export type MyRequestsListQueryVariables = Exact<{
  addressId: Scalars['Int']['input']
}>

export type MyRequestsListQuery = {
  __typename?: 'Query'
  leaveRequests?: Array<{
    __typename?: 'LeaveRequest'
    created: any
    updated: any
    id: number
    type: LeaveRequestType
    startDate: any
    endDate: any
    state: LeaveRequestState
  } | null> | null
  externalServiceRequests?: Array<{
    __typename?: 'ExternalServiceRequest'
    created: any
    updated: any
    id: number
    startDate: any
    endDate: any
    state: ExternalServiceRequestState
  } | null> | null
  expenseReports?: Array<{
    __typename?: 'ExpenseReport'
    created: any
    updated: any
    id: number
    date?: any | null
  } | null> | null
}

export type NoteAppointmentQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type NoteAppointmentQuery = {
  __typename?: 'Query'
  appointment?: {
    __typename?: 'JobAppointment'
    id: number
    comment?: string | null
  } | null
}

export type NoteJobQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type NoteJobQuery = {
  __typename?: 'Query'
  job?: { __typename?: 'Job'; id: number; comment?: string | null } | null
}

export type NoteProjectQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type NoteProjectQuery = {
  __typename?: 'Query'
  project?: {
    __typename?: 'Project'
    id: number
    comment?: string | null
  } | null
}

export type UpdateNoteAppointmentMutationVariables = Exact<{
  id: Scalars['Int']['input']
  note: Scalars['String']['input']
}>

export type UpdateNoteAppointmentMutation = {
  __typename?: 'Mutation'
  updateJobAppointment?: {
    __typename?: 'JobAppointment'
    id: number
    comment?: string | null
  } | null
}

export type UpdateNoteJobMutationVariables = Exact<{
  id: Scalars['Int']['input']
  note: Scalars['String']['input']
}>

export type UpdateNoteJobMutation = {
  __typename?: 'Mutation'
  updateJob?: { __typename?: 'Job'; id: number; comment?: string | null } | null
}

export type UpdateNoteProjectMutationVariables = Exact<{
  id: Scalars['Int']['input']
  note: Scalars['String']['input']
}>

export type UpdateNoteProjectMutation = {
  __typename?: 'Mutation'
  updateProject?: {
    __typename?: 'Project'
    id: number
    comment?: string | null
  } | null
}

export type ExpenseReportDetailsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ExpenseReportDetailsQuery = {
  __typename?: 'Query'
  expenseReport?: {
    __typename?: 'ExpenseReport'
    id: number
    created: any
    updated: any
    date?: any | null
    details?: string | null
    paymentType?: PaymentType | null
    paymentInfo?: string | null
    route?: string | null
    kilometer?: number | null
    publicTransportation?: boolean | null
    dayOfCatering?: any | null
    location?: string | null
    host?: string | null
    servedPersons?: string | null
    reason?: string | null
    invoiceAmount?: number | null
    tipAmount?: number | null
    totalAmount?: number | null
    expenseType?: {
      __typename?: 'ExpenseType'
      id: number
      caption: string
    } | null
    job?: {
      __typename?: 'Job'
      id: number
      caption: string
      number: string
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
    } | null
  } | null
}

export type ExternalServiceRequestDetailsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ExternalServiceRequestDetailsQuery = {
  __typename?: 'Query'
  externalServiceRequest?: {
    __typename?: 'ExternalServiceRequest'
    id: number
    startDate: any
    endDate: any
    state: ExternalServiceRequestState
    eventName: string
    client: string
    customerOfClient: string
    location: string
    jobFunction: string
    user: { __typename?: 'User'; id: number; fullName?: string | null }
    communications?: Array<{
      __typename?: 'ExternalServiceRequestCommunication'
      newState: ExternalServiceRequestState
      action: ExternalServiceRequestAction
      timestamp: any
      comment?: string | null
      user: { __typename?: 'User'; id: number; fullName?: string | null }
    }> | null
  } | null
}

export type LeaveRequestDetailsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type LeaveRequestDetailsQuery = {
  __typename?: 'Query'
  leaveRequest?: {
    __typename?: 'LeaveRequest'
    approvalType?: LeaveRequestApprovalType | null
    id: number
    type: LeaveRequestType
    startDate: any
    endDate: any
    state: LeaveRequestState
    user: { __typename?: 'User'; id: number; fullName?: string | null }
    communications?: Array<{
      __typename?: 'LeaveRequestCommunication'
      previousState?: LeaveRequestState | null
      newState: LeaveRequestState
      previousType?: LeaveRequestType | null
      newType?: LeaveRequestType | null
      action: LeaveRequestAction
      timestamp: any
      comment?: string | null
      user: { __typename?: 'User'; id: number; fullName?: string | null }
    }> | null
  } | null
}

export type ApproveExternalServiceRequestMutationVariables = Exact<{
  id: Scalars['Int']['input']
  comment?: InputMaybe<Scalars['String']['input']>
}>

export type ApproveExternalServiceRequestMutation = {
  __typename?: 'Mutation'
  updateExternalServiceRequest?: {
    __typename?: 'ExternalServiceRequest'
    id: number
    startDate: any
    endDate: any
    state: ExternalServiceRequestState
    eventName: string
    client: string
    customerOfClient: string
    location: string
    jobFunction: string
    user: { __typename?: 'User'; id: number; fullName?: string | null }
    communications?: Array<{
      __typename?: 'ExternalServiceRequestCommunication'
      newState: ExternalServiceRequestState
      action: ExternalServiceRequestAction
      timestamp: any
      comment?: string | null
      user: { __typename?: 'User'; id: number; fullName?: string | null }
    }> | null
  } | null
}

export type ApproveLeaveRequestMutationVariables = Exact<{
  id: Scalars['Int']['input']
  comment?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<LeaveRequestType>
}>

export type ApproveLeaveRequestMutation = {
  __typename?: 'Mutation'
  updateLeaveRequest?: {
    __typename?: 'LeaveRequest'
    id: number
    type: LeaveRequestType
    startDate: any
    endDate: any
    state: LeaveRequestState
    user: { __typename?: 'User'; id: number; fullName?: string | null }
    communications?: Array<{
      __typename?: 'LeaveRequestCommunication'
      previousState?: LeaveRequestState | null
      newState: LeaveRequestState
      previousType?: LeaveRequestType | null
      newType?: LeaveRequestType | null
      action: LeaveRequestAction
      timestamp: any
      comment?: string | null
      user: { __typename?: 'User'; id: number; fullName?: string | null }
    }> | null
  } | null
}

export type RejectExternalServiceRequestMutationVariables = Exact<{
  id: Scalars['Int']['input']
  comment: Scalars['String']['input']
}>

export type RejectExternalServiceRequestMutation = {
  __typename?: 'Mutation'
  updateExternalServiceRequest?: {
    __typename?: 'ExternalServiceRequest'
    id: number
    startDate: any
    endDate: any
    state: ExternalServiceRequestState
    eventName: string
    client: string
    customerOfClient: string
    location: string
    jobFunction: string
    user: { __typename?: 'User'; id: number; fullName?: string | null }
    communications?: Array<{
      __typename?: 'ExternalServiceRequestCommunication'
      newState: ExternalServiceRequestState
      action: ExternalServiceRequestAction
      timestamp: any
      comment?: string | null
      user: { __typename?: 'User'; id: number; fullName?: string | null }
    }> | null
  } | null
}

export type RejectLeaveRequestMutationVariables = Exact<{
  id: Scalars['Int']['input']
  comment: Scalars['String']['input']
}>

export type RejectLeaveRequestMutation = {
  __typename?: 'Mutation'
  updateLeaveRequest?: {
    __typename?: 'LeaveRequest'
    id: number
    type: LeaveRequestType
    startDate: any
    endDate: any
    state: LeaveRequestState
    user: { __typename?: 'User'; id: number; fullName?: string | null }
    communications?: Array<{
      __typename?: 'LeaveRequestCommunication'
      previousState?: LeaveRequestState | null
      newState: LeaveRequestState
      previousType?: LeaveRequestType | null
      newType?: LeaveRequestType | null
      action: LeaveRequestAction
      timestamp: any
      comment?: string | null
      user: { __typename?: 'User'; id: number; fullName?: string | null }
    }> | null
  } | null
}

export type RequestArchiveListQueryVariables = Exact<{
  addressId?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<OptionalStartEndFilter>
}>

export type RequestArchiveListQuery = {
  __typename?: 'Query'
  leaveRequests?: Array<{
    __typename?: 'LeaveRequest'
    id: number
    created: any
    type: LeaveRequestType
    startDate: any
    endDate: any
    state: LeaveRequestState
    user: { __typename?: 'User'; id: number; fullName?: string | null }
  } | null> | null
  externalServiceRequests?: Array<{
    __typename?: 'ExternalServiceRequest'
    id: number
    created: any
    startDate: any
    endDate: any
    state: ExternalServiceRequestState
    user: { __typename?: 'User'; id: number; fullName?: string | null }
  } | null> | null
  expenseReports?: Array<{
    __typename?: 'ExpenseReport'
    created: any
    updated: any
    id: number
    date?: any | null
    address?: {
      __typename?: 'Address'
      id: number
      firstName?: string | null
      surname?: string | null
    } | null
  } | null> | null
}

export type RequestOverviewListQueryVariables = Exact<{ [key: string]: never }>

export type RequestOverviewListQuery = {
  __typename?: 'Query'
  leaveRequestsToRevise?: Array<{
    __typename?: 'LeaveRequest'
    id: number
    type: LeaveRequestType
    created: any
    startDate: any
    endDate: any
    state: LeaveRequestState
    user: { __typename?: 'User'; id: number; fullName?: string | null }
  } | null> | null
  externalServiceRequestsToRevise?: Array<{
    __typename?: 'ExternalServiceRequest'
    id: number
    created: any
    startDate: any
    endDate: any
    state: ExternalServiceRequestState
    user: { __typename?: 'User'; id: number; fullName?: string | null }
  } | null> | null
}

export type ResourceRequestOverviewFilterJobQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ResourceRequestOverviewFilterJobQuery = {
  __typename?: 'Query'
  job?: {
    __typename?: 'Job'
    id: number
    caption: string
    number: string
    startDate: any
    endDate: any
  } | null
}

export type ResourceRequestOverviewFilterJobSearchQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']['input']>
}>

export type ResourceRequestOverviewFilterJobSearchQuery = {
  __typename?: 'Query'
  jobs?: Array<{
    __typename?: 'Job'
    id: number
    caption: string
    number: string
    startDate: any
    endDate: any
  } | null> | null
}

export type ResourceRequestOverviewFilterProjectQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ResourceRequestOverviewFilterProjectQuery = {
  __typename?: 'Query'
  project?: {
    __typename?: 'Project'
    id: number
    caption: string
    number: string
    startDate?: any | null
    endDate?: any | null
  } | null
}

export type ResourceRequestOverviewFilterProjectSearchQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']['input']>
}>

export type ResourceRequestOverviewFilterProjectSearchQuery = {
  __typename?: 'Query'
  projects?: Array<{
    __typename?: 'Project'
    id: number
    caption: string
    number: string
    startDate?: any | null
    endDate?: any | null
  } | null> | null
}

export type ResourceRequestOverviewFilterStaticQueryVariables = Exact<{
  [key: string]: never
}>

export type ResourceRequestOverviewFilterStaticQuery = {
  __typename?: 'Query'
  easyJobUsers?: Array<{
    __typename?: 'EasyJobUser'
    id: number
    displayName?: string | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
  } | null> | null
  resourceFunctions?: Array<{
    __typename?: 'ResourceFunction'
    id: number
    caption?: string | null
    abbreviation?: string | null
  } | null> | null
}

export type ResourceRequestOverviewListQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
}>

export type ResourceRequestOverviewListQuery = {
  __typename?: 'Query'
  projects?: Array<{
    __typename?: 'Project'
    id: number
    caption: string
    arrangedBy?: { __typename?: 'EasyJobUser'; id: number } | null
    jobs?: Array<{
      __typename?: 'Job'
      id: number
      caption: string
      arrangedBy?: { __typename?: 'EasyJobUser'; id: number } | null
      jobAppointments?: Array<{
        __typename?: 'JobAppointment'
        id: number
        caption?: string | null
        resourceFunctionAllocations?: Array<{
          __typename?: 'ResourceFunctionAllocation'
          id: number
          resourceFunction?: {
            __typename?: 'ResourceFunction'
            id: number
            caption?: string | null
            abbreviation?: string | null
          } | null
          resourceAllocations?: Array<{
            __typename?: 'ResourceAllocation'
            id: number
            address?: {
              __typename?: 'Address'
              id: number
              displayName?: any | null
              firstName?: string | null
              surname?: string | null
              company?: string | null
              addressResourceType?: {
                __typename?: 'AddressResourceType'
                id: number
                caption: string
              } | null
            } | null
            resourceRequest?: {
              __typename?: 'ResourceRequest'
              id: number
              state?: ResourceRequestState | null
              updated?: any | null
              created?: any | null
              feedback?: {
                __typename?: 'ResourceRequestFeedback'
                userState?: ResourceRequestUserStatus | null
              } | null
              updatedBy?: {
                __typename?: 'Address'
                id: number
                displayName?: any | null
                firstName?: string | null
                surname?: string | null
                company?: string | null
                addressResourceType?: {
                  __typename?: 'AddressResourceType'
                  id: number
                  caption: string
                } | null
              } | null
              createdBy?: {
                __typename?: 'Address'
                id: number
                displayName?: any | null
                firstName?: string | null
                surname?: string | null
                company?: string | null
                addressResourceType?: {
                  __typename?: 'AddressResourceType'
                  id: number
                  caption: string
                } | null
              } | null
            } | null
          } | null> | null
        } | null> | null
      } | null> | null
    } | null> | null
  } | null> | null
}

export type FullAddressQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type FullAddressQuery = {
  __typename?: 'Query'
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    street?: string | null
    zip?: string | null
    city?: string | null
    phone?: string | null
    phoneCompany?: string | null
    phoneMobile?: string | null
    phonePrivate?: string | null
    wwwAddress?: string | null
    email?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
    country?: {
      __typename?: 'Country'
      id: number
      caption?: string | null
    } | null
  } | null
}

export type ActionbarAddressFilterQueryVariables = Exact<{
  [key: string]: never
}>

export type ActionbarAddressFilterQuery = {
  __typename?: 'Query'
  resourceFunctions?: Array<{
    __typename?: 'ResourceFunction'
    id: number
    caption?: string | null
    resourceType?: {
      __typename?: 'ResourceType'
      id: number
      type: string
      caption: string
    } | null
  } | null> | null
  qualifications?: Array<{
    __typename?: 'Qualification'
    id: number
    caption?: string | null
  } | null> | null
  addressResourceTypes?: Array<{
    __typename?: 'AddressResourceType'
    id: number
    caption: string
  } | null> | null
}

export type ActionbarAddressesQueryVariables = Exact<{
  searchterm?: InputMaybe<Scalars['String']['input']>
  resourceFunctionIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  qualificationIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  addressResourceTypeIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
}>

export type ActionbarAddressesQuery = {
  __typename?: 'Query'
  itemsAddress?: Array<{
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    resourceFunction2Address?: Array<{
      __typename?: 'ResourceFunction2Address'
      ratingQuality?: number | null
      ratingSelf?: number | null
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        id: number
        caption?: string | null
      } | null
    } | null> | null
    resourceQualifications?: Array<{
      __typename?: 'ResourceQualification'
      id: number
      ratingSelf?: number | null
      ratingQuality?: number | null
      qualification?: {
        __typename?: 'Qualification'
        id: number
        caption?: string | null
      } | null
    } | null> | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null> | null
}

export type ActionbarVehicleFilterQueryVariables = Exact<{
  [key: string]: never
}>

export type ActionbarVehicleFilterQuery = {
  __typename?: 'Query'
  resourceFunctions?: Array<{
    __typename?: 'ResourceFunction'
    id: number
    caption?: string | null
    resourceType?: {
      __typename?: 'ResourceType'
      id: number
      type: string
      caption: string
    } | null
  } | null> | null
}

export type ActionbarVehiclesQueryVariables = Exact<{
  searchterm?: InputMaybe<Scalars['String']['input']>
  resourceFunctionIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
}>

export type ActionbarVehiclesQuery = {
  __typename?: 'Query'
  itemsVehicle?: Array<{
    __typename?: 'Vehicle'
    id: number
    caption?: string | null
    comment?: string | null
    abbreviation?: string | null
    loadingCapacity?: number | null
    loadingVolume?: number | null
    supplier?: {
      __typename?: 'Address'
      id: number
      company?: string | null
    } | null
    resourceFunction?: {
      __typename?: 'ResourceFunction'
      id: number
      caption?: string | null
    } | null
  } | null> | null
}

export type AppointmentDetailsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type AppointmentDetailsQuery = {
  __typename?: 'Query'
  jobAppointment?: {
    __typename?: 'JobAppointment'
    id: number
    caption?: string | null
    startDate?: any | null
    endDate?: any | null
    comment?: string | null
    jobAppointmentType?: {
      __typename?: 'JobAppointmentType'
      id: number
      caption: string
    } | null
    job?: {
      __typename?: 'Job'
      id: number
      number: string
      caption: string
      jobState?: { __typename?: 'JobState'; id: number; caption: string } | null
      jobAppointments?: Array<{
        __typename?: 'JobAppointment'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
      } | null> | null
      project?: {
        __typename?: 'Project'
        id: number
        number: string
        caption: string
        projectState?: {
          __typename?: 'ProjectState'
          id: number
          caption: string
        } | null
      } | null
    } | null
    resourceFunctionAllocations?: Array<{
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        id: number
        caption?: string | null
      } | null
      resourceAllocations?: Array<{
        __typename?: 'ResourceAllocation'
        id: number
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          firstName?: string | null
          surname?: string | null
          displayName?: any | null
        } | null
        vehicle?: {
          __typename?: 'Vehicle'
          id: number
          caption?: string | null
        } | null
        resourceAllocationDriver?: {
          __typename?: 'ResourceAllocation'
          id: number
          address?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            surname?: string | null
            company?: string | null
          } | null
        } | null
      } | null> | null
    } | null> | null
    logs?: Array<{
      __typename?: 'LogItem'
      id: number
      date?: any | null
      newValue?: string | null
      action: { __typename?: 'LogAction'; id: number; caption: string }
      user?: {
        __typename?: 'EasyJobUser'
        id: number
        displayName?: string | null
      } | null
    } | null> | null
  } | null
}

export type DataModalEventDetailsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DataModalEventDetailsQuery = {
  __typename?: 'Query'
  event?: {
    __typename?: 'Event'
    id: number
    startDate?: any | null
    endDate?: any | null
    caption?: string | null
    description?: string | null
    location?: string | null
    importance?: Importance | null
    attendees?: Array<{
      __typename?: 'Attendee'
      displayName?: string | null
      status?: string | null
      address?: { __typename?: 'Address'; id: number } | null
    } | null> | null
  } | null
}

export type DataModalJobQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DataModalJobQuery = {
  __typename?: 'Query'
  job?: {
    __typename?: 'Job'
    id: number
    number: string
    caption: string
    startDate: any
    endDate: any
    comment?: string | null
    created?: any | null
    updated?: any | null
    deliveryAddressNote?: string | null
    createdBy?: {
      __typename?: 'EasyJobUser'
      id: number
      displayName?: string | null
    } | null
    updatedBy?: {
      __typename?: 'EasyJobUser'
      id: number
      displayName?: string | null
    } | null
    jobState?: {
      __typename?: 'JobState'
      id: number
      caption: string
      jobType?: { __typename?: 'JobType'; id: number; caption: string } | null
    } | null
    jobService?: {
      __typename?: 'JobService'
      id: number
      caption: string
    } | null
    deliveryAddress?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      street?: string | null
      zip?: string | null
      city?: string | null
      phone?: string | null
      phoneCompany?: string | null
      phoneMobile?: string | null
      phonePrivate?: string | null
      wwwAddress?: string | null
      email?: string | null
      contact?: {
        __typename?: 'Contact'
        id: number
        firstName?: string | null
        surname?: string | null
        mobile?: string | null
        phone?: string | null
        email?: string | null
        custom1?: string | null
        custom2?: string | null
      } | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
      country?: {
        __typename?: 'Country'
        id: number
        caption?: string | null
      } | null
    } | null
    arrangedBy?: {
      __typename?: 'EasyJobUser'
      id: number
      address?: {
        __typename?: 'Address'
        id: number
        company?: string | null
        firstName?: string | null
        surname?: string | null
        phoneMobile?: string | null
      } | null
    } | null
    project?: {
      __typename?: 'Project'
      id: number
      number: string
      caption: string
      customer?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        street?: string | null
        zip?: string | null
        city?: string | null
        phone?: string | null
        phoneCompany?: string | null
        phoneMobile?: string | null
        phonePrivate?: string | null
        wwwAddress?: string | null
        email?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
        country?: {
          __typename?: 'Country'
          id: number
          caption?: string | null
        } | null
      } | null
      projectState?: {
        __typename?: 'ProjectState'
        id: number
        caption: string
      } | null
      jobs?: Array<{
        __typename?: 'Job'
        id: number
        caption: string
        number: string
        jobState?: {
          __typename?: 'JobState'
          id: number
          caption: string
        } | null
      } | null> | null
    } | null
    resourceFunctionAllocations?: Array<{
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        id: number
        caption?: string | null
      } | null
    } | null> | null
    logs?: Array<{
      __typename?: 'LogItem'
      id: number
      date?: any | null
      newValue?: string | null
      action: { __typename?: 'LogAction'; id: number; caption: string }
      user?: {
        __typename?: 'EasyJobUser'
        id: number
        displayName?: string | null
      } | null
    } | null> | null
  } | null
}

export type DataModalProjectQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DataModalProjectQuery = {
  __typename?: 'Query'
  project?: {
    __typename?: 'Project'
    id: number
    number: string
    caption: string
    startDate?: any | null
    endDate?: any | null
    created?: any | null
    updated?: any | null
    comment?: string | null
    customer?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      street?: string | null
      zip?: string | null
      city?: string | null
      phone?: string | null
      phoneCompany?: string | null
      phoneMobile?: string | null
      phonePrivate?: string | null
      wwwAddress?: string | null
      email?: string | null
      contact?: {
        __typename?: 'Contact'
        id: number
        firstName?: string | null
        surname?: string | null
        mobile?: string | null
        phone?: string | null
        email?: string | null
        custom1?: string | null
        custom2?: string | null
      } | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
      country?: {
        __typename?: 'Country'
        id: number
        caption?: string | null
      } | null
    } | null
    projectState?: {
      __typename?: 'ProjectState'
      id: number
      caption: string
    } | null
    projectType?: {
      __typename?: 'ProjectType'
      id: number
      caption: string
    } | null
    arrangedBy?: {
      __typename?: 'EasyJobUser'
      id: number
      displayName?: string | null
    } | null
    createdBy?: {
      __typename?: 'EasyJobUser'
      id: number
      displayName?: string | null
    } | null
    updatedBy?: {
      __typename?: 'EasyJobUser'
      id: number
      displayName?: string | null
    } | null
    event?: {
      __typename?: 'EasyJobEvent'
      id: string
      number?: string | null
      caption?: string | null
    } | null
    priority?: { __typename?: 'Priority'; id: number; caption: string } | null
    setting?: {
      __typename?: 'ProjectSetting'
      isJobLayerHidden?: boolean | null
    } | null
    jobs?: Array<{
      __typename?: 'Job'
      id: number
      caption: string
      number: string
      jobState?: { __typename?: 'JobState'; id: number; caption: string } | null
    } | null> | null
  } | null
}

export type ProjectSettingsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ProjectSettingsQuery = {
  __typename?: 'Query'
  project?: {
    __typename?: 'Project'
    id: number
    startDate?: any | null
    endDate?: any | null
    numberOfMissingResources?: number | null
    caption: string
    number: string
    comment?: string | null
    group?: {
      __typename?: 'ProjectGroup'
      number?: number | null
      isSortedManually?: boolean | null
    } | null
    projectState?: { __typename?: 'ProjectState'; id: number } | null
    globalSetting?: {
      __typename?: 'GlobalSetting'
      key: string
      value?: string | null
    } | null
    setting?: {
      __typename?: 'ProjectSetting'
      isJobLayerHidden?: boolean | null
    } | null
  } | null
}

export type UpdateProjectSettingsMutationVariables = Exact<{
  projectId: Scalars['Int']['input']
  collectAllJobsOfProject: Scalars['Boolean']['input']
}>

export type UpdateProjectSettingsMutation = {
  __typename?: 'Mutation'
  setProjectSetting?: {
    __typename?: 'ProjectSetting'
    isJobLayerHidden?: boolean | null
  } | null
}

export type ResourceSettingsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ResourceSettingsQuery = {
  __typename?: 'Query'
  resource?: {
    __typename?: 'ResourceFunctionAllocation'
    id: number
    timesScheduled?: boolean | null
  } | null
}

export type UpdateResourceSettingsMutationVariables = Exact<{
  id: Scalars['Int']['input']
  timesScheduled: Scalars['Boolean']['input']
}>

export type UpdateResourceSettingsMutation = {
  __typename?: 'Mutation'
  updateResourceFunctionAllocation?: {
    __typename?: 'ResourceFunctionAllocation'
    id: number
    startDate?: any | null
    endDate?: any | null
    quantity?: number | null
    numberOfResourceAllocations?: number | null
    timesScheduled?: boolean | null
    resourceFunction?: {
      __typename?: 'ResourceFunction'
      id: number
      abbreviation?: string | null
      caption?: string | null
      sortOrder?: number | null
      resourceType?: {
        __typename?: 'ResourceType'
        id: number
        caption: string
      } | null
    } | null
    allocationStatistics?: Array<{
      __typename?: 'KeyValue'
      key: string
      value?: string | null
    } | null> | null
    job?: { __typename?: 'Job'; id: number } | null
    jobAppointment?: {
      __typename?: 'JobAppointment'
      id: number
      startDate?: any | null
      endDate?: any | null
    } | null
  } | null
}

export type DataModalResourceDetailsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DataModalResourceDetailsQuery = {
  __typename?: 'Query'
  resource?: {
    __typename?: 'ResourceFunctionAllocation'
    created?: any | null
    updated?: any | null
    id: number
    startDate?: any | null
    endDate?: any | null
    quantity?: number | null
    numberOfResourceAllocations?: number | null
    timesScheduled?: boolean | null
    job?: {
      __typename?: 'Job'
      id: number
      number: string
      caption: string
      project?: {
        __typename?: 'Project'
        id: number
        number: string
        caption: string
      } | null
    } | null
    jobAppointment?: {
      __typename?: 'JobAppointment'
      id: number
      caption?: string | null
      startDate?: any | null
      endDate?: any | null
    } | null
    resourceAllocations?: Array<{
      __typename?: 'ResourceAllocation'
      id: number
      resourceAllocationState?: {
        __typename?: 'ResourceAllocationState'
        id: number
        caption: string
      } | null
      address?: {
        __typename?: 'Address'
        id: number
        firstName?: string | null
        surname?: string | null
        company?: string | null
      } | null
      vehicle?: {
        __typename?: 'Vehicle'
        id: number
        caption?: string | null
      } | null
      resourceAllocationDriver?: {
        __typename?: 'ResourceAllocation'
        id: number
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          surname?: string | null
          company?: string | null
        } | null
      } | null
    } | null> | null
    logs?: Array<{
      __typename?: 'ResourceFunctionAllocationLogItem'
      id: number
      date?: any | null
      action: { __typename?: 'LogAction'; id: number; caption: string }
      user?: {
        __typename?: 'EasyJobUser'
        id: number
        displayName?: string | null
      } | null
    } | null> | null
    resourceFunction?: {
      __typename?: 'ResourceFunction'
      id: number
      abbreviation?: string | null
      caption?: string | null
      sortOrder?: number | null
      resourceType?: {
        __typename?: 'ResourceType'
        id: number
        caption: string
      } | null
    } | null
    allocationStatistics?: Array<{
      __typename?: 'KeyValue'
      key: string
      value?: string | null
    } | null> | null
  } | null
}

export type DataModalAppointmentQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DataModalAppointmentQuery = {
  __typename?: 'Query'
  jobAppointment?: {
    __typename?: 'JobAppointment'
    id: number
    caption?: string | null
    startDate?: any | null
    endDate?: any | null
    comment?: string | null
    jobAppointmentType?: {
      __typename?: 'JobAppointmentType'
      id: number
      caption: string
    } | null
    job?: {
      __typename?: 'Job'
      id: number
      number: string
      caption: string
      jobState?: { __typename?: 'JobState'; id: number; caption: string } | null
      deliveryAddress?: {
        __typename?: 'Address'
        id: number
        street?: string | null
        zip?: string | null
        city?: string | null
        country?: {
          __typename?: 'Country'
          id: number
          caption?: string | null
        } | null
      } | null
      jobAppointments?: Array<{
        __typename?: 'JobAppointment'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
      } | null> | null
      project?: {
        __typename?: 'Project'
        id: number
        number: string
        caption: string
        projectState?: {
          __typename?: 'ProjectState'
          id: number
          caption: string
        } | null
      } | null
    } | null
    resourceFunctionAllocations?: Array<{
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        id: number
        caption?: string | null
        abbreviation?: string | null
        resourceType?: {
          __typename?: 'ResourceType'
          id: number
          caption: string
        } | null
      } | null
      resourceAllocations?: Array<{
        __typename?: 'ResourceAllocation'
        id: number
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          firstName?: string | null
          surname?: string | null
          displayName?: any | null
        } | null
        vehicle?: {
          __typename?: 'Vehicle'
          id: number
          caption?: string | null
        } | null
        resourceAllocationDriver?: {
          __typename?: 'ResourceAllocation'
          id: number
          address?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            surname?: string | null
            company?: string | null
          } | null
          vehicle?: {
            __typename?: 'Vehicle'
            id: number
            caption?: string | null
          } | null
        } | null
      } | null> | null
    } | null> | null
  } | null
}

export type DataModalResourceAllocationQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DataModalResourceAllocationQuery = {
  __typename?: 'Query'
  resourceAllocation?: {
    __typename?: 'ResourceAllocation'
    id: number
    factor?: number | null
    travel?: string | null
    hotel?: string | null
    extra?: {
      __typename?: 'ResourceAllocationExtra'
      notice?: string | null
      webfleetState?: WebfleetState | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      company?: string | null
      street?: string | null
      zip?: string | null
      city?: string | null
      phone?: string | null
      email?: string | null
      phoneCompany?: string | null
      phoneMobile?: string | null
      phonePrivate?: string | null
      country?: {
        __typename?: 'Country'
        id: number
        caption?: string | null
      } | null
    } | null
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
      isWebfleetVehicle?: boolean | null
    } | null
    resourceAllocationDriver?: {
      __typename?: 'ResourceAllocation'
      id: number
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        surname?: string | null
        company?: string | null
      } | null
    } | null
    resourceAllocationState?: {
      __typename?: 'ResourceAllocationState'
      id: number
      caption: string
    } | null
    resourceFunctionAllocation?: {
      __typename?: 'ResourceFunctionAllocation'
      id: number
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        id: number
        caption?: string | null
      } | null
      jobAppointment?: {
        __typename?: 'JobAppointment'
        id: number
        caption?: string | null
      } | null
      job?: {
        __typename?: 'Job'
        id: number
        number: string
        caption: string
        jobState?: {
          __typename?: 'JobState'
          id: number
          caption: string
        } | null
        project?: {
          __typename?: 'Project'
          id: number
          number: string
          caption: string
          projectState?: {
            __typename?: 'ProjectState'
            id: number
            caption: string
          } | null
        } | null
      } | null
    } | null
    resourceRequest?: {
      __typename?: 'ResourceRequest'
      id: number
      title?: string | null
      state?: ResourceRequestState | null
      created?: any | null
      dateOfExpire?: any | null
      resourceAllocationIds?: Array<number | null> | null
      feedback?: {
        __typename?: 'ResourceRequestFeedback'
        userState?: ResourceRequestUserStatus | null
      } | null
    } | null
    logs?: Array<{
      __typename?: 'ResourceAllocationLogItem'
      id: number
      date?: any | null
      action: { __typename?: 'LogAction'; id: number; caption: string }
      user?: {
        __typename?: 'EasyJobUser'
        id: number
        displayName?: string | null
      } | null
    } | null> | null
  } | null
}

export type DataModalResourceRequestQueryVariables = Exact<{
  resourceAllocationId: Scalars['Int']['input']
}>

export type DataModalResourceRequestQuery = {
  __typename?: 'Query'
  resourceRequest?: {
    __typename?: 'ResourceRequest'
    id: number
    created?: any | null
    updated?: any | null
    title?: string | null
    dateOfExpire?: any | null
    state?: ResourceRequestState | null
    resourceAllocationIds?: Array<number | null> | null
    createdBy?: {
      __typename?: 'Address'
      id: number
      firstName?: string | null
      lastName?: string | null
    } | null
    updatedBy?: {
      __typename?: 'Address'
      id: number
      firstName?: string | null
      lastName?: string | null
    } | null
    resourceAllocations?: Array<{
      __typename?: 'ResourceAllocation'
      id: number
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      resourceAllocationState?: {
        __typename?: 'ResourceAllocationState'
        id: number
        caption: string
      } | null
      resourceFunctionAllocation?: {
        __typename?: 'ResourceFunctionAllocation'
        startDate?: any | null
        endDate?: any | null
        resourceFunction?: {
          __typename?: 'ResourceFunction'
          id: number
          caption?: string | null
        } | null
        job?: {
          __typename?: 'Job'
          id: number
          number: string
          caption: string
          jobState?: {
            __typename?: 'JobState'
            id: number
            caption: string
          } | null
          project?: {
            __typename?: 'Project'
            id: number
            number: string
            caption: string
            projectState?: {
              __typename?: 'ProjectState'
              id: number
              caption: string
            } | null
          } | null
        } | null
      } | null
    } | null> | null
    communications?: Array<{
      __typename?: 'ResourceRequestCommunication'
      timestamp: any
      action: ResourceRequestAction
      previousUserState?: ResourceRequestUserStatus | null
      newUserState?: ResourceRequestUserStatus | null
      previousProMusikState?: ResourceRequestState | null
      newProMusikState?: ResourceRequestState | null
      comment?: string | null
      availableFrom?: any | null
      availableTo?: any | null
      user: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      }
    } | null> | null
    feedback?: {
      __typename?: 'ResourceRequestFeedback'
      userState?: ResourceRequestUserStatus | null
      availableFrom?: any | null
      availableTo?: any | null
      comment?: string | null
    } | null
  } | null
}

export type AppointmentQueryVariables = Exact<{
  id: Scalars['Int']['input']
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
  resourceTypeIds:
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  excludeResourceTypeIds: Scalars['Boolean']['input']
  resourceFunctionIds:
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  excludeResourceFunctionIds: Scalars['Boolean']['input']
}>

export type AppointmentQuery = {
  __typename?: 'Query'
  appointment?: {
    __typename?: 'JobAppointment'
    id: number
    caption?: string | null
    startDate?: any | null
    endDate?: any | null
    comment?: string | null
    resources?: Array<{
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        abbreviation?: string | null
        sortOrder?: number | null
      } | null
    } | null> | null
    job?: {
      __typename?: 'Job'
      id: number
      number: string
      comment?: string | null
      jobState?: {
        __typename?: 'JobState'
        id: number
        jobType?: { __typename?: 'JobType'; id: number } | null
      } | null
      project?: { __typename?: 'Project'; id: number; number: string } | null
    } | null
  } | null
}

export type CalendarCacheQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
  startDateAlternativeStartOfDay: Scalars['DateTime']['input']
  clientIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeClientIds?: InputMaybe<Scalars['Boolean']['input']>
  projectLeaderIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeProjectLeaderIds?: InputMaybe<Scalars['Boolean']['input']>
  projectIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeProjectIds?: InputMaybe<Scalars['Boolean']['input']>
  projectStateIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeProjectStateIds?: InputMaybe<Scalars['Boolean']['input']>
  projectTypeIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeProjectTypeIds?: InputMaybe<Scalars['Boolean']['input']>
  jobLeaderIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeJobLeaderIds?: InputMaybe<Scalars['Boolean']['input']>
  jobStateIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeJobStateIds?: InputMaybe<Scalars['Boolean']['input']>
  jobServiceIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeJobServiceIds?: InputMaybe<Scalars['Boolean']['input']>
  resourceTypeIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceTypeIds?: InputMaybe<Scalars['Boolean']['input']>
  resourceFunctionIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceFunctionIds?: InputMaybe<Scalars['Boolean']['input']>
  resourceAllocationIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceAllocationIds?: InputMaybe<Scalars['Boolean']['input']>
  resourceAllocationStateIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceAllocationStateIds?: InputMaybe<Scalars['Boolean']['input']>
  showOnlyWithResources?: InputMaybe<Scalars['Boolean']['input']>
  showOnlyActive?: InputMaybe<Scalars['Boolean']['input']>
  skipFreelancers?: InputMaybe<Scalars['Boolean']['input']>
  skipEmployees?: InputMaybe<Scalars['Boolean']['input']>
  skipVehicles?: InputMaybe<Scalars['Boolean']['input']>
  skipOperations?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CalendarCacheQuery = {
  __typename?: 'Query'
  freelancerCalendar?: Array<
    | {
        __typename?: 'CalendarEvent'
        id: number
        caption?: string | null
        endDate?: any | null
        startDate?: any | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
      }
    | {
        __typename?: 'Event'
        id: number
        location?: string | null
        endDate?: any | null
        startDate?: any | null
        caption?: string | null
        relatedAddresses?: any | null
        attendees?: Array<{
          __typename?: 'Attendee'
          displayName?: string | null
          status?: string | null
          address?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
        } | null> | null
      }
    | {
        __typename?: 'ResourceAllocation'
        id: number
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        extra?: {
          __typename?: 'ResourceAllocationExtra'
          notice?: string | null
        } | null
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          job?: {
            __typename?: 'Job'
            id: number
            caption: string
            jobState?: {
              __typename?: 'JobState'
              id: number
              caption: string
            } | null
          } | null
        } | null
      }
    | {
        __typename?: 'ResourceState'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
        comment?: string | null
        resourceStateType?: {
          __typename?: 'ResourceStateType'
          id: number
          caption: string
          color?: any | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: { __typename?: 'Vehicle'; id: number } | null
      }
    | null
  > | null
  employeeCalendar?: Array<
    | {
        __typename?: 'CalendarEvent'
        id: number
        caption?: string | null
        endDate?: any | null
        startDate?: any | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
      }
    | {
        __typename?: 'Event'
        id: number
        location?: string | null
        endDate?: any | null
        startDate?: any | null
        caption?: string | null
        relatedAddresses?: any | null
        attendees?: Array<{
          __typename?: 'Attendee'
          displayName?: string | null
          status?: string | null
          address?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
        } | null> | null
      }
    | {
        __typename?: 'ResourceAllocation'
        id: number
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        extra?: {
          __typename?: 'ResourceAllocationExtra'
          notice?: string | null
        } | null
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          job?: {
            __typename?: 'Job'
            id: number
            caption: string
            jobState?: {
              __typename?: 'JobState'
              id: number
              caption: string
            } | null
          } | null
        } | null
      }
    | {
        __typename?: 'ResourceState'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
        comment?: string | null
        resourceStateType?: {
          __typename?: 'ResourceStateType'
          id: number
          caption: string
          color?: any | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: { __typename?: 'Vehicle'; id: number } | null
      }
    | null
  > | null
  vehicleCalendar?: Array<
    | {
        __typename?: 'ResourceAllocation'
        id: number
        vehicle?: { __typename?: 'Vehicle'; id: number } | null
        extra?: {
          __typename?: 'ResourceAllocationExtra'
          notice?: string | null
        } | null
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          job?: {
            __typename?: 'Job'
            id: number
            caption: string
            jobState?: {
              __typename?: 'JobState'
              id: number
              caption: string
            } | null
          } | null
        } | null
      }
    | {
        __typename?: 'ResourceState'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
        comment?: string | null
        resourceStateType?: {
          __typename?: 'ResourceStateType'
          id: number
          caption: string
          color?: any | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: { __typename?: 'Vehicle'; id: number } | null
      }
    | null
  > | null
  projects?: Array<{
    __typename?: 'Project'
    id: number
    startDate?: any | null
    endDate?: any | null
    numberOfMissingResources?: number | null
    caption: string
    number: string
    comment?: string | null
    jobs?: Array<{
      __typename?: 'Job'
      id: number
      caption: string
      number: string
      startDate: any
      endDate: any
      numberOfMissingResources?: number | null
      comment?: string | null
      appointments?: Array<{
        __typename?: 'JobAppointment'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
        comment?: string | null
        resourceFunctionAllocations?: Array<{
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          quantity?: number | null
          numberOfResourceAllocations?: number | null
          timesScheduled?: boolean | null
          resourceAllocations?: Array<{
            __typename?: 'ResourceAllocation'
            id: number
            factor?: number | null
            travel?: string | null
            hotel?: string | null
            extra?: {
              __typename?: 'ResourceAllocationExtra'
              notice?: string | null
            } | null
            address?: {
              __typename?: 'Address'
              id: number
              displayName?: any | null
              firstName?: string | null
              surname?: string | null
              company?: string | null
              addressResourceType?: {
                __typename?: 'AddressResourceType'
                id: number
                caption: string
              } | null
            } | null
            vehicle?: {
              __typename?: 'Vehicle'
              id: number
              caption?: string | null
              isWebfleetVehicle?: boolean | null
            } | null
            resourceAllocationState?: {
              __typename?: 'ResourceAllocationState'
              id: number
              caption: string
            } | null
            resourceFunctionAllocation?: {
              __typename?: 'ResourceFunctionAllocation'
              id: number
              startDate?: any | null
              endDate?: any | null
              job?: { __typename?: 'Job'; id: number } | null
            } | null
            resourceAllocationDriver?: {
              __typename?: 'ResourceAllocation'
              id: number
              resourceFunctionAllocation?: {
                __typename?: 'ResourceFunctionAllocation'
                id: number
                job?: { __typename?: 'Job'; id: number; number: string } | null
              } | null
              address?: {
                __typename?: 'Address'
                id: number
                displayName?: any | null
                firstName?: string | null
                surname?: string | null
                company?: string | null
                addressResourceType?: {
                  __typename?: 'AddressResourceType'
                  id: number
                  caption: string
                } | null
              } | null
              vehicle?: {
                __typename?: 'Vehicle'
                id: number
                caption?: string | null
              } | null
            } | null
            resourceRequest?: {
              __typename?: 'ResourceRequest'
              id: number
              dateOfExpire?: any | null
              state?: ResourceRequestState | null
              updated?: any | null
              subject?: string | null
              updatedBy?: {
                __typename?: 'Address'
                id: number
                displayName?: any | null
                firstName?: string | null
                surname?: string | null
                company?: string | null
                addressResourceType?: {
                  __typename?: 'AddressResourceType'
                  id: number
                  caption: string
                } | null
              } | null
              feedback?: {
                __typename?: 'ResourceRequestFeedback'
                availableFrom?: any | null
                availableTo?: any | null
                comment?: string | null
                userState?: ResourceRequestUserStatus | null
              } | null
            } | null
          } | null> | null
          resourceFunction?: {
            __typename?: 'ResourceFunction'
            id: number
            abbreviation?: string | null
            caption?: string | null
            sortOrder?: number | null
            resourceType?: {
              __typename?: 'ResourceType'
              id: number
              caption: string
            } | null
          } | null
          allocationStatistics?: Array<{
            __typename?: 'KeyValue'
            key: string
            value?: string | null
          } | null> | null
          job?: { __typename?: 'Job'; id: number } | null
          jobAppointment?: {
            __typename?: 'JobAppointment'
            id: number
            startDate?: any | null
            endDate?: any | null
          } | null
        } | null> | null
        job?: {
          __typename?: 'Job'
          id: number
          number: string
          comment?: string | null
          jobState?: {
            __typename?: 'JobState'
            id: number
            jobType?: { __typename?: 'JobType'; id: number } | null
          } | null
          project?: {
            __typename?: 'Project'
            id: number
            number: string
          } | null
        } | null
      } | null> | null
      resourcesWithoutAppointment?: Array<{
        __typename?: 'ResourceFunctionAllocation'
        id: number
        startDate?: any | null
        endDate?: any | null
        quantity?: number | null
        numberOfResourceAllocations?: number | null
        timesScheduled?: boolean | null
        resourceAllocations?: Array<{
          __typename?: 'ResourceAllocation'
          id: number
          factor?: number | null
          travel?: string | null
          hotel?: string | null
          extra?: {
            __typename?: 'ResourceAllocationExtra'
            notice?: string | null
          } | null
          address?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
          vehicle?: {
            __typename?: 'Vehicle'
            id: number
            caption?: string | null
            isWebfleetVehicle?: boolean | null
          } | null
          resourceAllocationState?: {
            __typename?: 'ResourceAllocationState'
            id: number
            caption: string
          } | null
          resourceFunctionAllocation?: {
            __typename?: 'ResourceFunctionAllocation'
            id: number
            startDate?: any | null
            endDate?: any | null
            job?: { __typename?: 'Job'; id: number } | null
          } | null
          resourceAllocationDriver?: {
            __typename?: 'ResourceAllocation'
            id: number
            resourceFunctionAllocation?: {
              __typename?: 'ResourceFunctionAllocation'
              id: number
              job?: { __typename?: 'Job'; id: number; number: string } | null
            } | null
            address?: {
              __typename?: 'Address'
              id: number
              displayName?: any | null
              firstName?: string | null
              surname?: string | null
              company?: string | null
              addressResourceType?: {
                __typename?: 'AddressResourceType'
                id: number
                caption: string
              } | null
            } | null
            vehicle?: {
              __typename?: 'Vehicle'
              id: number
              caption?: string | null
            } | null
          } | null
          resourceRequest?: {
            __typename?: 'ResourceRequest'
            id: number
            dateOfExpire?: any | null
            state?: ResourceRequestState | null
            updated?: any | null
            subject?: string | null
            updatedBy?: {
              __typename?: 'Address'
              id: number
              displayName?: any | null
              firstName?: string | null
              surname?: string | null
              company?: string | null
              addressResourceType?: {
                __typename?: 'AddressResourceType'
                id: number
                caption: string
              } | null
            } | null
            feedback?: {
              __typename?: 'ResourceRequestFeedback'
              availableFrom?: any | null
              availableTo?: any | null
              comment?: string | null
              userState?: ResourceRequestUserStatus | null
            } | null
          } | null
        } | null> | null
        resourceFunction?: {
          __typename?: 'ResourceFunction'
          id: number
          abbreviation?: string | null
          caption?: string | null
          sortOrder?: number | null
          resourceType?: {
            __typename?: 'ResourceType'
            id: number
            caption: string
          } | null
        } | null
        allocationStatistics?: Array<{
          __typename?: 'KeyValue'
          key: string
          value?: string | null
        } | null> | null
        job?: { __typename?: 'Job'; id: number } | null
        jobAppointment?: {
          __typename?: 'JobAppointment'
          id: number
          startDate?: any | null
          endDate?: any | null
        } | null
      } | null> | null
      jobState?: {
        __typename?: 'JobState'
        id: number
        jobType?: { __typename?: 'JobType'; id: number } | null
      } | null
      globalSetting?: {
        __typename?: 'GlobalSetting'
        key: string
        value?: string | null
      } | null
      project?: {
        __typename?: 'Project'
        id: number
        caption: string
        number: string
        projectState?: { __typename?: 'ProjectState'; id: number } | null
        setting?: {
          __typename?: 'ProjectSetting'
          isJobLayerHidden?: boolean | null
        } | null
        group?: {
          __typename?: 'ProjectGroup'
          number?: number | null
          isSortedManually?: boolean | null
        } | null
      } | null
    } | null> | null
    group?: {
      __typename?: 'ProjectGroup'
      number?: number | null
      isSortedManually?: boolean | null
    } | null
    projectState?: { __typename?: 'ProjectState'; id: number } | null
    globalSetting?: {
      __typename?: 'GlobalSetting'
      key: string
      value?: string | null
    } | null
    setting?: {
      __typename?: 'ProjectSetting'
      isJobLayerHidden?: boolean | null
    } | null
  } | null> | null
  stockTypeServices?: Array<{
    __typename?: 'StockTypeService'
    id: number
    startDate: any
    endDate: any
    caption: string
    number: string
    resourceFunctionAllocations?: Array<{
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      quantity?: number | null
      numberOfResourceAllocations?: number | null
      timesScheduled?: boolean | null
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        id: number
        abbreviation?: string | null
        caption?: string | null
        sortOrder?: number | null
        resourceType?: {
          __typename?: 'ResourceType'
          id: number
          caption: string
        } | null
      } | null
      allocationStatistics?: Array<{
        __typename?: 'KeyValue'
        key: string
        value?: string | null
      } | null> | null
      job?: { __typename?: 'Job'; id: number } | null
      jobAppointment?: {
        __typename?: 'JobAppointment'
        id: number
        startDate?: any | null
        endDate?: any | null
      } | null
    } | null> | null
    stockType?: {
      __typename?: 'StockType'
      number: string
      caption: string
    } | null
    job?: {
      __typename?: 'Job'
      number: string
      caption: string
      id: number
    } | null
  } | null> | null
  purchaseOrders?: Array<{
    __typename?: 'PurchaseOrder'
    id: number
    caption?: string | null
    number?: string | null
    orderedAt?: any | null
    resourceFunctionAllocations?: Array<{
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      quantity?: number | null
      numberOfResourceAllocations?: number | null
      timesScheduled?: boolean | null
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        id: number
        abbreviation?: string | null
        caption?: string | null
        sortOrder?: number | null
        resourceType?: {
          __typename?: 'ResourceType'
          id: number
          caption: string
        } | null
      } | null
      allocationStatistics?: Array<{
        __typename?: 'KeyValue'
        key: string
        value?: string | null
      } | null> | null
      job?: { __typename?: 'Job'; id: number } | null
      jobAppointment?: {
        __typename?: 'JobAppointment'
        id: number
        startDate?: any | null
        endDate?: any | null
      } | null
    } | null> | null
  } | null> | null
}

export type ResourceDetailsQueryVariables = Exact<{
  ids:
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  resourceAllocationIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceAllocationIds?: InputMaybe<Scalars['Boolean']['input']>
  resourceAllocationStateIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceAllocationStateIds?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ResourceDetailsQuery = {
  __typename?: 'Query'
  lazyloadedResourceDetails?: Array<{
    __typename?: 'ResourceFunctionAllocation'
    id: number
    resourceAllocations?: Array<{
      __typename?: 'ResourceAllocation'
      id: number
      dutyOrder?: {
        __typename?: 'DutyOrder'
        order?: number | null
        overlappingAllocationIds?: Array<number | null> | null
      } | null
    } | null> | null
  } | null> | null
}

export type BirthdaysQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
}>

export type BirthdaysQuery = {
  __typename?: 'Query'
  birthdays?: Array<{
    __typename?: 'Address'
    id: number
    displayName?: any | null
    birthday?: any | null
  } | null> | null
}

export type CalendarEventsQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
}>

export type CalendarEventsQuery = {
  __typename?: 'Query'
  calendarEvents?: Array<{
    __typename?: 'Event'
    id: number
    startDate?: any | null
    endDate?: any | null
    caption?: string | null
    description?: string | null
    location?: string | null
    importance?: Importance | null
    attendees?: Array<{
      __typename?: 'Attendee'
      displayName?: string | null
      status?: string | null
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
      } | null
    } | null> | null
  } | null> | null
}

export type GeneralEventsQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
}>

export type GeneralEventsQuery = {
  __typename?: 'Query'
  publicHolidays?: Array<{
    __typename?: 'PublicHoliday'
    id: string
    caption?: string | null
    isAllDay?: boolean | null
    startDate?: any | null
    endDate?: any | null
  } | null> | null
  easyJobEvents?: Array<{
    __typename?: 'EasyJobEvent'
    id: string
    caption?: string | null
    startDate?: any | null
    endDate?: any | null
    description?: string | null
    color?: any | null
    number?: string | null
    calendar?: { __typename?: 'Calendar'; id: string; caption: string } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      street?: string | null
      zip?: string | null
      city?: string | null
    } | null
  } | null> | null
}

export type JobQueryVariables = Exact<{
  id: Scalars['Int']['input']
  endDate: Scalars['DateTime']['input']
  startDateAlternativeStartOfDay: Scalars['DateTime']['input']
  resourceTypeIds:
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  excludeResourceTypeIds: Scalars['Boolean']['input']
  resourceFunctionIds:
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  excludeResourceFunctionIds: Scalars['Boolean']['input']
}>

export type JobQuery = {
  __typename?: 'Query'
  job?: {
    __typename?: 'Job'
    id: number
    caption: string
    number: string
    startDate: any
    endDate: any
    numberOfMissingResources?: number | null
    comment?: string | null
    appointments?: Array<{
      __typename?: 'JobAppointment'
      id: number
      startDate?: any | null
      endDate?: any | null
    } | null> | null
    resourcesWithoutAppointment?: Array<{
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        sortOrder?: number | null
      } | null
    } | null> | null
    jobState?: {
      __typename?: 'JobState'
      id: number
      jobType?: { __typename?: 'JobType'; id: number } | null
    } | null
    globalSetting?: {
      __typename?: 'GlobalSetting'
      key: string
      value?: string | null
    } | null
    project?: {
      __typename?: 'Project'
      id: number
      caption: string
      number: string
      projectState?: { __typename?: 'ProjectState'; id: number } | null
      setting?: {
        __typename?: 'ProjectSetting'
        isJobLayerHidden?: boolean | null
      } | null
      group?: {
        __typename?: 'ProjectGroup'
        number?: number | null
        isSortedManually?: boolean | null
      } | null
    } | null
  } | null
}

export type UpdateAlternativeColorMutationVariables = Exact<{
  key: Scalars['String']['input']
  value: Scalars['String']['input']
}>

export type UpdateAlternativeColorMutation = {
  __typename?: 'Mutation'
  saveGlobalSetting?: {
    __typename?: 'GlobalSetting'
    key: string
    value?: string | null
  } | null
}

export type AddressQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type AddressQuery = {
  __typename?: 'Query'
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null
}

export type ResourcesQueryVariables = Exact<{
  ids:
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
}>

export type ResourcesQuery = {
  __typename?: 'Query'
  resources?: Array<{
    __typename?: 'ResourceFunctionAllocation'
    id: number
    job?: { __typename?: 'Job'; id: number; caption: string } | null
    jobAppointment?: {
      __typename?: 'JobAppointment'
      id: number
      caption?: string | null
    } | null
    resourceFunction?: {
      __typename?: 'ResourceFunction'
      id: number
      caption?: string | null
      abbreviation?: string | null
    } | null
  } | null> | null
}

export type VehicleQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type VehicleQuery = {
  __typename?: 'Query'
  vehicle?: {
    __typename?: 'Vehicle'
    id: number
    caption?: string | null
    comment?: string | null
    abbreviation?: string | null
    loadingCapacity?: number | null
    loadingVolume?: number | null
    supplier?: {
      __typename?: 'Address'
      id: number
      company?: string | null
    } | null
    resourceFunction?: {
      __typename?: 'ResourceFunction'
      id: number
      caption?: string | null
    } | null
  } | null
}

export type CreateResourceRequestMutationVariables = Exact<{
  resourceAllocationIds:
    | Array<Scalars['Int']['input']>
    | Scalars['Int']['input']
  dateOfExpire?: InputMaybe<Scalars['String']['input']>
  subject?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
}>

export type CreateResourceRequestMutation = {
  __typename?: 'Mutation'
  createResourceRequest?: {
    __typename?: 'ResourceRequest'
    id: number
    dateOfExpire?: any | null
    state?: ResourceRequestState | null
    updated?: any | null
    subject?: string | null
    updatedBy?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
  } | null
}

export type ProjectQueryVariables = Exact<{
  id: Scalars['Int']['input']
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
  startDateAlternativeStartOfDay: Scalars['DateTime']['input']
  jobLeaderIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeJobLeaderIds?: InputMaybe<Scalars['Boolean']['input']>
  jobStateIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeJobStateIds?: InputMaybe<Scalars['Boolean']['input']>
  jobServiceIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeJobServiceIds?: InputMaybe<Scalars['Boolean']['input']>
  resourceTypeIds:
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  excludeResourceTypeIds: Scalars['Boolean']['input']
  resourceFunctionIds:
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  excludeResourceFunctionIds: Scalars['Boolean']['input']
  showOnlyWithResources: Scalars['Boolean']['input']
  showOnlyActive?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ProjectQuery = {
  __typename?: 'Query'
  project?: {
    __typename?: 'Project'
    id: number
    startDate?: any | null
    endDate?: any | null
    numberOfMissingResources?: number | null
    caption: string
    number: string
    comment?: string | null
    jobs?: Array<{
      __typename?: 'Job'
      id: number
      caption: string
      number: string
      startDate: any
      endDate: any
      numberOfMissingResources?: number | null
      comment?: string | null
      appointments?: Array<{
        __typename?: 'JobAppointment'
        id: number
        startDate?: any | null
        endDate?: any | null
      } | null> | null
      resourcesWithoutAppointment?: Array<{
        __typename?: 'ResourceFunctionAllocation'
        id: number
        startDate?: any | null
        endDate?: any | null
        resourceFunction?: {
          __typename?: 'ResourceFunction'
          sortOrder?: number | null
        } | null
      } | null> | null
      jobState?: {
        __typename?: 'JobState'
        id: number
        jobType?: { __typename?: 'JobType'; id: number } | null
      } | null
      globalSetting?: {
        __typename?: 'GlobalSetting'
        key: string
        value?: string | null
      } | null
      project?: {
        __typename?: 'Project'
        id: number
        caption: string
        number: string
        projectState?: { __typename?: 'ProjectState'; id: number } | null
        setting?: {
          __typename?: 'ProjectSetting'
          isJobLayerHidden?: boolean | null
        } | null
        group?: {
          __typename?: 'ProjectGroup'
          number?: number | null
          isSortedManually?: boolean | null
        } | null
      } | null
    } | null> | null
    group?: {
      __typename?: 'ProjectGroup'
      number?: number | null
      isSortedManually?: boolean | null
    } | null
    projectState?: { __typename?: 'ProjectState'; id: number } | null
    globalSetting?: {
      __typename?: 'GlobalSetting'
      key: string
      value?: string | null
    } | null
    setting?: {
      __typename?: 'ProjectSetting'
      isJobLayerHidden?: boolean | null
    } | null
  } | null
}

export type PurchaseOrderQueryVariables = Exact<{
  id: Scalars['Int']['input']
  resourceAllocationIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceAllocationIds?: InputMaybe<Scalars['Boolean']['input']>
  resourceAllocationStateIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceAllocationStateIds?: InputMaybe<Scalars['Boolean']['input']>
}>

export type PurchaseOrderQuery = {
  __typename?: 'Query'
  purchaseOrder?: {
    __typename?: 'PurchaseOrder'
    id: number
    caption?: string | null
    number?: string | null
    orderedAt?: any | null
    resources?: Array<{
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      quantity?: number | null
      numberOfResourceAllocations?: number | null
      timesScheduled?: boolean | null
      resourceAllocations?: Array<{
        __typename?: 'ResourceAllocation'
        id: number
        factor?: number | null
        travel?: string | null
        hotel?: string | null
        extra?: {
          __typename?: 'ResourceAllocationExtra'
          notice?: string | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: {
          __typename?: 'Vehicle'
          id: number
          caption?: string | null
          isWebfleetVehicle?: boolean | null
        } | null
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          job?: { __typename?: 'Job'; id: number } | null
        } | null
        resourceAllocationDriver?: {
          __typename?: 'ResourceAllocation'
          id: number
          resourceFunctionAllocation?: {
            __typename?: 'ResourceFunctionAllocation'
            id: number
            job?: { __typename?: 'Job'; id: number; number: string } | null
          } | null
          address?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
          vehicle?: {
            __typename?: 'Vehicle'
            id: number
            caption?: string | null
          } | null
        } | null
        resourceRequest?: {
          __typename?: 'ResourceRequest'
          id: number
          dateOfExpire?: any | null
          state?: ResourceRequestState | null
          updated?: any | null
          subject?: string | null
          updatedBy?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
          feedback?: {
            __typename?: 'ResourceRequestFeedback'
            availableFrom?: any | null
            availableTo?: any | null
            comment?: string | null
            userState?: ResourceRequestUserStatus | null
          } | null
        } | null
      } | null> | null
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        id: number
        abbreviation?: string | null
        caption?: string | null
        sortOrder?: number | null
        resourceType?: {
          __typename?: 'ResourceType'
          id: number
          caption: string
        } | null
      } | null
      allocationStatistics?: Array<{
        __typename?: 'KeyValue'
        key: string
        value?: string | null
      } | null> | null
      job?: { __typename?: 'Job'; id: number } | null
      jobAppointment?: {
        __typename?: 'JobAppointment'
        id: number
        startDate?: any | null
        endDate?: any | null
      } | null
    } | null> | null
  } | null
}

export type AllConsideredOfAppointmentQueryVariables = Exact<{
  appointmentId: Scalars['Int']['input']
}>

export type AllConsideredOfAppointmentQuery = {
  __typename?: 'Query'
  resources?: Array<{
    __typename?: 'ResourceFunctionAllocation'
    id: number
    resourceFunction?: {
      __typename?: 'ResourceFunction'
      id: number
      resourceType?: { __typename?: 'ResourceType'; id: number } | null
    } | null
    resourceAllocations?: Array<{
      __typename?: 'ResourceAllocation'
      id: number
      resourceAllocationState?: {
        __typename?: 'ResourceAllocationState'
        id: number
        caption: string
      } | null
    } | null> | null
  } | null> | null
}

export type AllConsideredOfJobQueryVariables = Exact<{
  jobId: Scalars['Int']['input']
}>

export type AllConsideredOfJobQuery = {
  __typename?: 'Query'
  resources?: Array<{
    __typename?: 'ResourceFunctionAllocation'
    id: number
    resourceFunction?: {
      __typename?: 'ResourceFunction'
      id: number
      resourceType?: { __typename?: 'ResourceType'; id: number } | null
    } | null
    resourceAllocations?: Array<{
      __typename?: 'ResourceAllocation'
      id: number
      resourceAllocationState?: {
        __typename?: 'ResourceAllocationState'
        id: number
        caption: string
      } | null
    } | null> | null
  } | null> | null
}

export type AllConsideredOfProjectQueryVariables = Exact<{
  jobIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type AllConsideredOfProjectQuery = {
  __typename?: 'Query'
  resources?: Array<{
    __typename?: 'ResourceFunctionAllocation'
    id: number
    resourceFunction?: {
      __typename?: 'ResourceFunction'
      id: number
      resourceType?: { __typename?: 'ResourceType'; id: number } | null
    } | null
    resourceAllocations?: Array<{
      __typename?: 'ResourceAllocation'
      id: number
      resourceAllocationState?: {
        __typename?: 'ResourceAllocationState'
        id: number
        caption: string
      } | null
    } | null> | null
  } | null> | null
}

export type AllJobIdsOfProjectQueryVariables = Exact<{
  projectId: Scalars['Int']['input']
}>

export type AllJobIdsOfProjectQuery = {
  __typename?: 'Query'
  project?: {
    __typename?: 'Project'
    jobs?: Array<{ __typename?: 'Job'; id: number } | null> | null
  } | null
}

export type CreateResourceAllocationAddressMutationVariables = Exact<{
  resourceId: Scalars['Int']['input']
  addressId: Scalars['Int']['input']
  resourceAllocationStateId: Scalars['Int']['input']
  notice?: InputMaybe<Scalars['String']['input']>
  travel?: InputMaybe<Scalars['String']['input']>
  hotel?: InputMaybe<Scalars['String']['input']>
}>

export type CreateResourceAllocationAddressMutation = {
  __typename?: 'Mutation'
  createAddressAllocation?: {
    __typename?: 'ResourceAllocation'
    id: number
    factor?: number | null
    travel?: string | null
    hotel?: string | null
    extra?: {
      __typename?: 'ResourceAllocationExtra'
      notice?: string | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
      isWebfleetVehicle?: boolean | null
    } | null
    resourceAllocationState?: {
      __typename?: 'ResourceAllocationState'
      id: number
      caption: string
    } | null
    resourceFunctionAllocation?: {
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      job?: { __typename?: 'Job'; id: number } | null
    } | null
    resourceAllocationDriver?: {
      __typename?: 'ResourceAllocation'
      id: number
      resourceFunctionAllocation?: {
        __typename?: 'ResourceFunctionAllocation'
        id: number
        job?: { __typename?: 'Job'; id: number; number: string } | null
      } | null
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      vehicle?: {
        __typename?: 'Vehicle'
        id: number
        caption?: string | null
      } | null
    } | null
    resourceRequest?: {
      __typename?: 'ResourceRequest'
      id: number
      dateOfExpire?: any | null
      state?: ResourceRequestState | null
      updated?: any | null
      subject?: string | null
      updatedBy?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      feedback?: {
        __typename?: 'ResourceRequestFeedback'
        availableFrom?: any | null
        availableTo?: any | null
        comment?: string | null
        userState?: ResourceRequestUserStatus | null
      } | null
    } | null
  } | null
}

export type CreateResourceAllocationVehicleMutationVariables = Exact<{
  resourceId: Scalars['Int']['input']
  vehicleId: Scalars['Int']['input']
  resourceAllocationStateId: Scalars['Int']['input']
  notice?: InputMaybe<Scalars['String']['input']>
}>

export type CreateResourceAllocationVehicleMutation = {
  __typename?: 'Mutation'
  createVehicleAllocation?: {
    __typename?: 'ResourceAllocation'
    id: number
    factor?: number | null
    travel?: string | null
    hotel?: string | null
    extra?: {
      __typename?: 'ResourceAllocationExtra'
      notice?: string | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
      isWebfleetVehicle?: boolean | null
    } | null
    resourceAllocationState?: {
      __typename?: 'ResourceAllocationState'
      id: number
      caption: string
    } | null
    resourceFunctionAllocation?: {
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      job?: { __typename?: 'Job'; id: number } | null
    } | null
    resourceAllocationDriver?: {
      __typename?: 'ResourceAllocation'
      id: number
      resourceFunctionAllocation?: {
        __typename?: 'ResourceFunctionAllocation'
        id: number
        job?: { __typename?: 'Job'; id: number; number: string } | null
      } | null
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      vehicle?: {
        __typename?: 'Vehicle'
        id: number
        caption?: string | null
      } | null
    } | null
    resourceRequest?: {
      __typename?: 'ResourceRequest'
      id: number
      dateOfExpire?: any | null
      state?: ResourceRequestState | null
      updated?: any | null
      subject?: string | null
      updatedBy?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      feedback?: {
        __typename?: 'ResourceRequestFeedback'
        availableFrom?: any | null
        availableTo?: any | null
        comment?: string | null
        userState?: ResourceRequestUserStatus | null
      } | null
    } | null
  } | null
}

export type ResourceQueryVariables = Exact<{
  id: Scalars['Int']['input']
  resourceAllocationIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceAllocationIds?: InputMaybe<Scalars['Boolean']['input']>
  resourceAllocationStateIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceAllocationStateIds?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ResourceQuery = {
  __typename?: 'Query'
  resource?: {
    __typename?: 'ResourceFunctionAllocation'
    id: number
    startDate?: any | null
    endDate?: any | null
    quantity?: number | null
    numberOfResourceAllocations?: number | null
    timesScheduled?: boolean | null
    resourceAllocations?: Array<{
      __typename?: 'ResourceAllocation'
      id: number
      factor?: number | null
      travel?: string | null
      hotel?: string | null
      extra?: {
        __typename?: 'ResourceAllocationExtra'
        notice?: string | null
      } | null
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      vehicle?: {
        __typename?: 'Vehicle'
        id: number
        caption?: string | null
        isWebfleetVehicle?: boolean | null
      } | null
      resourceAllocationState?: {
        __typename?: 'ResourceAllocationState'
        id: number
        caption: string
      } | null
      resourceFunctionAllocation?: {
        __typename?: 'ResourceFunctionAllocation'
        id: number
        startDate?: any | null
        endDate?: any | null
        job?: { __typename?: 'Job'; id: number } | null
      } | null
      resourceAllocationDriver?: {
        __typename?: 'ResourceAllocation'
        id: number
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          job?: { __typename?: 'Job'; id: number; number: string } | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: {
          __typename?: 'Vehicle'
          id: number
          caption?: string | null
        } | null
      } | null
      resourceRequest?: {
        __typename?: 'ResourceRequest'
        id: number
        dateOfExpire?: any | null
        state?: ResourceRequestState | null
        updated?: any | null
        subject?: string | null
        updatedBy?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        feedback?: {
          __typename?: 'ResourceRequestFeedback'
          availableFrom?: any | null
          availableTo?: any | null
          comment?: string | null
          userState?: ResourceRequestUserStatus | null
        } | null
      } | null
    } | null> | null
    resourceFunction?: {
      __typename?: 'ResourceFunction'
      id: number
      abbreviation?: string | null
      caption?: string | null
      sortOrder?: number | null
      resourceType?: {
        __typename?: 'ResourceType'
        id: number
        caption: string
      } | null
    } | null
    allocationStatistics?: Array<{
      __typename?: 'KeyValue'
      key: string
      value?: string | null
    } | null> | null
    job?: { __typename?: 'Job'; id: number } | null
    jobAppointment?: {
      __typename?: 'JobAppointment'
      id: number
      startDate?: any | null
      endDate?: any | null
    } | null
  } | null
}

export type DeleteConnectionToDriverMutationVariables = Exact<{
  vehicleAllocationId: Scalars['Int']['input']
}>

export type DeleteConnectionToDriverMutation = {
  __typename?: 'Mutation'
  deleteAllocationDriver?: {
    __typename?: 'DeleteAllocationDriverResponse'
    resourceAllocation: { __typename?: 'ResourceAllocation'; id: number }
  } | null
}

export type DeleteConnectionToVehicleMutationVariables = Exact<{
  driverAllocationId: Scalars['Int']['input']
}>

export type DeleteConnectionToVehicleMutation = {
  __typename?: 'Mutation'
  deleteAllocationDriver?: {
    __typename?: 'DeleteAllocationDriverResponse'
    resourceAllocation: { __typename?: 'ResourceAllocation'; id: number }
  } | null
}

export type DeleteResourceAllocationMutationVariables = Exact<{
  resourceAllocationId: Scalars['Int']['input']
}>

export type DeleteResourceAllocationMutation = {
  __typename?: 'Mutation'
  deleteAllocation?: boolean | null
}

export type DriverAllocationQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DriverAllocationQuery = {
  __typename?: 'Query'
  driverAllocation?: {
    __typename?: 'ResourceAllocation'
    id: number
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    resourceFunctionAllocation?: {
      __typename?: 'ResourceFunctionAllocation'
      id: number
      job?: { __typename?: 'Job'; number: string } | null
    } | null
  } | null
}

export type ResourceAllocationDetailsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ResourceAllocationDetailsQuery = {
  __typename?: 'Query'
  resourceAllocationDetails?: {
    __typename?: 'ResourceAllocation'
    id: number
    dutyOrder?: {
      __typename?: 'DutyOrder'
      order?: number | null
      overlappingAllocationIds?: Array<number | null> | null
    } | null
  } | null
}

export type ResourceAllocationQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ResourceAllocationQuery = {
  __typename?: 'Query'
  resourceAllocation?: {
    __typename?: 'ResourceAllocation'
    id: number
    factor?: number | null
    travel?: string | null
    hotel?: string | null
    extra?: {
      __typename?: 'ResourceAllocationExtra'
      notice?: string | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
      isWebfleetVehicle?: boolean | null
    } | null
    resourceAllocationState?: {
      __typename?: 'ResourceAllocationState'
      id: number
      caption: string
    } | null
    resourceFunctionAllocation?: {
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      job?: { __typename?: 'Job'; id: number } | null
    } | null
    resourceAllocationDriver?: {
      __typename?: 'ResourceAllocation'
      id: number
      resourceFunctionAllocation?: {
        __typename?: 'ResourceFunctionAllocation'
        id: number
        job?: { __typename?: 'Job'; id: number; number: string } | null
      } | null
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      vehicle?: {
        __typename?: 'Vehicle'
        id: number
        caption?: string | null
      } | null
    } | null
    resourceRequest?: {
      __typename?: 'ResourceRequest'
      id: number
      dateOfExpire?: any | null
      state?: ResourceRequestState | null
      updated?: any | null
      subject?: string | null
      updatedBy?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      feedback?: {
        __typename?: 'ResourceRequestFeedback'
        availableFrom?: any | null
        availableTo?: any | null
        comment?: string | null
        userState?: ResourceRequestUserStatus | null
      } | null
    } | null
  } | null
}

export type ResourceAllocationStatesQueryVariables = Exact<{
  [key: string]: never
}>

export type ResourceAllocationStatesQuery = {
  __typename?: 'Query'
  resourceAllocationStates?: Array<{
    __typename?: 'ResourceAllocationState'
    id: number
    caption: string
  } | null> | null
}

export type UpdateConnectionToDriverMutationVariables = Exact<{
  vehicleAllocationId: Scalars['Int']['input']
  driverAllocationId: Scalars['Int']['input']
}>

export type UpdateConnectionToDriverMutation = {
  __typename?: 'Mutation'
  setAllocationDriver?: {
    __typename?: 'SetAllocationDriverResponse'
    resourceAllocation: { __typename?: 'ResourceAllocation'; id: number }
  } | null
}

export type UpdateConnectionToVehicleMutationVariables = Exact<{
  vehicleAllocationId: Scalars['Int']['input']
  driverAllocationId: Scalars['Int']['input']
}>

export type UpdateConnectionToVehicleMutation = {
  __typename?: 'Mutation'
  setAllocationDriver?: {
    __typename?: 'SetAllocationDriverResponse'
    resourceAllocation: { __typename?: 'ResourceAllocation'; id: number }
  } | null
}

export type UpdateResourceAllocationAddressMutationVariables = Exact<{
  resourceAllocationId: Scalars['Int']['input']
  addressId?: InputMaybe<Scalars['Int']['input']>
  stateId?: InputMaybe<Scalars['Int']['input']>
  notice?: InputMaybe<Scalars['String']['input']>
  travel?: InputMaybe<Scalars['String']['input']>
  hotel?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateResourceAllocationAddressMutation = {
  __typename?: 'Mutation'
  updateAddressAllocation?: {
    __typename?: 'ResourceAllocation'
    id: number
    factor?: number | null
    travel?: string | null
    hotel?: string | null
    extra?: {
      __typename?: 'ResourceAllocationExtra'
      notice?: string | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
      isWebfleetVehicle?: boolean | null
    } | null
    resourceAllocationState?: {
      __typename?: 'ResourceAllocationState'
      id: number
      caption: string
    } | null
    resourceFunctionAllocation?: {
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      job?: { __typename?: 'Job'; id: number } | null
    } | null
    resourceAllocationDriver?: {
      __typename?: 'ResourceAllocation'
      id: number
      resourceFunctionAllocation?: {
        __typename?: 'ResourceFunctionAllocation'
        id: number
        job?: { __typename?: 'Job'; id: number; number: string } | null
      } | null
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      vehicle?: {
        __typename?: 'Vehicle'
        id: number
        caption?: string | null
      } | null
    } | null
    resourceRequest?: {
      __typename?: 'ResourceRequest'
      id: number
      dateOfExpire?: any | null
      state?: ResourceRequestState | null
      updated?: any | null
      subject?: string | null
      updatedBy?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      feedback?: {
        __typename?: 'ResourceRequestFeedback'
        availableFrom?: any | null
        availableTo?: any | null
        comment?: string | null
        userState?: ResourceRequestUserStatus | null
      } | null
    } | null
  } | null
}

export type UpdateResourceAllocationVehicleMutationVariables = Exact<{
  resourceAllocationId: Scalars['Int']['input']
  vehicleId?: InputMaybe<Scalars['Int']['input']>
  stateId?: InputMaybe<Scalars['Int']['input']>
  notice?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateResourceAllocationVehicleMutation = {
  __typename?: 'Mutation'
  updateVehicleAllocation?: {
    __typename?: 'ResourceAllocation'
    id: number
    factor?: number | null
    travel?: string | null
    hotel?: string | null
    extra?: {
      __typename?: 'ResourceAllocationExtra'
      notice?: string | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
      isWebfleetVehicle?: boolean | null
    } | null
    resourceAllocationState?: {
      __typename?: 'ResourceAllocationState'
      id: number
      caption: string
    } | null
    resourceFunctionAllocation?: {
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      job?: { __typename?: 'Job'; id: number } | null
    } | null
    resourceAllocationDriver?: {
      __typename?: 'ResourceAllocation'
      id: number
      resourceFunctionAllocation?: {
        __typename?: 'ResourceFunctionAllocation'
        id: number
        job?: { __typename?: 'Job'; id: number; number: string } | null
      } | null
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      vehicle?: {
        __typename?: 'Vehicle'
        id: number
        caption?: string | null
      } | null
    } | null
    resourceRequest?: {
      __typename?: 'ResourceRequest'
      id: number
      dateOfExpire?: any | null
      state?: ResourceRequestState | null
      updated?: any | null
      subject?: string | null
      updatedBy?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      feedback?: {
        __typename?: 'ResourceRequestFeedback'
        availableFrom?: any | null
        availableTo?: any | null
        comment?: string | null
        userState?: ResourceRequestUserStatus | null
      } | null
    } | null
  } | null
}

export type UpdateResourceRequestStatusMutationVariables = Exact<{
  resourceAllocationId: Scalars['Int']['input']
  status: ResourceRequestState
  message?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateResourceRequestStatusMutation = {
  __typename?: 'Mutation'
  updateResourceRequestState?: {
    __typename?: 'ResourceRequest'
    id: number
    dateOfExpire?: any | null
    state?: ResourceRequestState | null
    updated?: any | null
    subject?: string | null
    updatedBy?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
  } | null
}

export type VehicleAllocationQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type VehicleAllocationQuery = {
  __typename?: 'Query'
  vehicleAllocation?: {
    __typename?: 'ResourceAllocation'
    id: number
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
    } | null
    resourceFunctionAllocation?: {
      __typename?: 'ResourceFunctionAllocation'
      id: number
      job?: { __typename?: 'Job'; number: string } | null
    } | null
  } | null
}

export type FreelancerConflictsQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
}>

export type FreelancerConflictsQuery = {
  __typename?: 'Query'
  freelancerCalendar?: Array<
    | { __typename?: 'CalendarEvent' }
    | { __typename?: 'Event' }
    | {
        __typename?: 'ResourceAllocation'
        id: number
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
        } | null
        extra?: {
          __typename?: 'ResourceAllocationExtra'
          notice?: string | null
        } | null
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          job?: {
            __typename?: 'Job'
            id: number
            caption: string
            jobState?: {
              __typename?: 'JobState'
              id: number
              caption: string
            } | null
          } | null
        } | null
      }
    | {
        __typename?: 'ResourceState'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
        comment?: string | null
        resourceStateType?: {
          __typename?: 'ResourceStateType'
          id: number
          caption: string
          color?: any | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: { __typename?: 'Vehicle'; id: number } | null
      }
    | null
  > | null
}

export type ResourceDayAddressQueryVariables = Exact<{
  id: Scalars['Int']['input']
  date?: InputMaybe<Scalars['Date']['input']>
  startDate?: InputMaybe<Scalars['Date']['input']>
  endDate?: InputMaybe<Scalars['Date']['input']>
  excludeInactive?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ResourceDayAddressQuery = {
  __typename?: 'Query'
  agenda?: {
    __typename?: 'Agenda'
    address?: {
      __typename?: 'Address'
      id: number
      firstName?: string | null
      surname?: string | null
      displayName?: any | null
    } | null
    events?: Array<{
      __typename?: 'Event'
      id: number
      caption?: string | null
      location?: string | null
      startDate?: any | null
      endDate?: any | null
    } | null> | null
    calendarEvents?: Array<{
      __typename?: 'CalendarEvent'
      id: number
      caption?: string | null
      startDate?: any | null
      endDate?: any | null
    } | null> | null
    resourceAllocations?: Array<{
      __typename?: 'ResourceAllocation'
      id: number
      extra?: {
        __typename?: 'ResourceAllocationExtra'
        notice?: string | null
      } | null
      dutyOrder?: {
        __typename?: 'DutyOrder'
        order?: number | null
        overlappingAllocationIds?: Array<number | null> | null
      } | null
      resourceAllocationState?: {
        __typename?: 'ResourceAllocationState'
        id: number
        caption: string
      } | null
      resourceFunctionAllocation?: {
        __typename?: 'ResourceFunctionAllocation'
        id: number
        startDate?: any | null
        endDate?: any | null
        resourceFunction?: {
          __typename?: 'ResourceFunction'
          id: number
          caption?: string | null
          abbreviation?: string | null
        } | null
        job?: {
          __typename?: 'Job'
          id: number
          caption: string
          number: string
          jobState?: {
            __typename?: 'JobState'
            id: number
            caption: string
          } | null
        } | null
        jobAppointment?: {
          __typename?: 'JobAppointment'
          id: number
          caption?: string | null
        } | null
      } | null
    } | null> | null
    resourceStates?: Array<{
      __typename?: 'ResourceState'
      locked?: boolean | null
      id: number
      caption?: string | null
      startDate?: any | null
      endDate?: any | null
      comment?: string | null
      resourceStateType?: {
        __typename?: 'ResourceStateType'
        id: number
        caption: string
        color?: any | null
      } | null
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      vehicle?: { __typename?: 'Vehicle'; id: number } | null
    } | null> | null
  } | null
}

export type ResourceDayVehicleQueryVariables = Exact<{
  id: Scalars['Int']['input']
  date?: InputMaybe<Scalars['Date']['input']>
  startDate?: InputMaybe<Scalars['Date']['input']>
  endDate?: InputMaybe<Scalars['Date']['input']>
  excludeInactive?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ResourceDayVehicleQuery = {
  __typename?: 'Query'
  agenda?: {
    __typename?: 'Agenda'
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
    } | null
    resourceAllocations?: Array<{
      __typename?: 'ResourceAllocation'
      id: number
      extra?: {
        __typename?: 'ResourceAllocationExtra'
        notice?: string | null
      } | null
      dutyOrder?: {
        __typename?: 'DutyOrder'
        order?: number | null
        overlappingAllocationIds?: Array<number | null> | null
      } | null
      resourceAllocationState?: {
        __typename?: 'ResourceAllocationState'
        id: number
        caption: string
      } | null
      resourceFunctionAllocation?: {
        __typename?: 'ResourceFunctionAllocation'
        id: number
        startDate?: any | null
        endDate?: any | null
        resourceFunction?: {
          __typename?: 'ResourceFunction'
          id: number
          caption?: string | null
          abbreviation?: string | null
        } | null
        job?: {
          __typename?: 'Job'
          id: number
          caption: string
          number: string
          jobState?: {
            __typename?: 'JobState'
            id: number
            caption: string
          } | null
        } | null
        jobAppointment?: {
          __typename?: 'JobAppointment'
          id: number
          caption?: string | null
        } | null
      } | null
    } | null> | null
    resourceStates?: Array<{
      __typename?: 'ResourceState'
      id: number
      caption?: string | null
      startDate?: any | null
      endDate?: any | null
      comment?: string | null
      resourceStateType?: {
        __typename?: 'ResourceStateType'
        id: number
        caption: string
        color?: any | null
      } | null
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      vehicle?: { __typename?: 'Vehicle'; id: number } | null
    } | null> | null
  } | null
}

export type AddressesQueryVariables = Exact<{
  addressIds:
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
}>

export type AddressesQuery = {
  __typename?: 'Query'
  addresses?: Array<{
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null> | null
}

export type VehiclesQueryVariables = Exact<{
  vehicleIds:
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
}>

export type VehiclesQuery = {
  __typename?: 'Query'
  vehicles?: Array<{
    __typename?: 'Vehicle'
    id: number
    caption?: string | null
    comment?: string | null
    abbreviation?: string | null
    loadingCapacity?: number | null
    loadingVolume?: number | null
    supplier?: {
      __typename?: 'Address'
      id: number
      company?: string | null
    } | null
    resourceFunction?: {
      __typename?: 'ResourceFunction'
      id: number
      caption?: string | null
    } | null
  } | null> | null
}

export type CreateResourceStateAddressMutationVariables = Exact<{
  addressId: Scalars['Int']['input']
  resourceStateTypeId: Scalars['Int']['input']
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
  note?: InputMaybe<Scalars['String']['input']>
}>

export type CreateResourceStateAddressMutation = {
  __typename?: 'Mutation'
  createResourceState?: {
    __typename?: 'ResourceState'
    id: number
    caption?: string | null
    startDate?: any | null
    endDate?: any | null
    comment?: string | null
    resourceStateType?: {
      __typename?: 'ResourceStateType'
      id: number
      caption: string
      color?: any | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: { __typename?: 'Vehicle'; id: number } | null
  } | null
}

export type CreateResourceStateVehicleMutationVariables = Exact<{
  vehicleId: Scalars['Int']['input']
  resourceStateTypeId: Scalars['Int']['input']
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
  note?: InputMaybe<Scalars['String']['input']>
}>

export type CreateResourceStateVehicleMutation = {
  __typename?: 'Mutation'
  createResourceState?: {
    __typename?: 'ResourceState'
    id: number
    caption?: string | null
    startDate?: any | null
    endDate?: any | null
    comment?: string | null
    resourceStateType?: {
      __typename?: 'ResourceStateType'
      id: number
      caption: string
      color?: any | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: { __typename?: 'Vehicle'; id: number } | null
  } | null
}

export type DeleteResourceStateMutationVariables = Exact<{
  resourceStateId: Scalars['Int']['input']
}>

export type DeleteResourceStateMutation = {
  __typename?: 'Mutation'
  deleteResourceState?: boolean | null
}

export type FreelancersQueryVariables = Exact<{
  searchterm?: InputMaybe<Scalars['String']['input']>
}>

export type FreelancersQuery = {
  __typename?: 'Query'
  freelancerSearchResults?: Array<{
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null> | null
}

export type ResourceStateQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ResourceStateQuery = {
  __typename?: 'Query'
  resourceState?: {
    __typename?: 'ResourceState'
    id: number
    caption?: string | null
    startDate?: any | null
    endDate?: any | null
    comment?: string | null
    resourceStateType?: {
      __typename?: 'ResourceStateType'
      id: number
      caption: string
      color?: any | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: { __typename?: 'Vehicle'; id: number } | null
  } | null
}

export type SelectedFreelancerQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type SelectedFreelancerQuery = {
  __typename?: 'Query'
  selectedFreelancer?: Array<{
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null> | null
}

export type UpdateResourceStateMutationVariables = Exact<{
  resourceStateId: Scalars['Int']['input']
  resourceStateTypeId?: InputMaybe<Scalars['Int']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  note?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateResourceStateMutation = {
  __typename?: 'Mutation'
  updateResourceState?: {
    __typename?: 'ResourceState'
    id: number
    caption?: string | null
    startDate?: any | null
    endDate?: any | null
    comment?: string | null
    resourceStateType?: {
      __typename?: 'ResourceStateType'
      id: number
      caption: string
      color?: any | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: { __typename?: 'Vehicle'; id: number } | null
  } | null
}

export type ResourceTimelineAddressQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
}>

export type ResourceTimelineAddressQuery = {
  __typename?: 'Query'
  resourceTimeline?: Array<
    | {
        __typename?: 'CalendarEvent'
        id: number
        caption?: string | null
        endDate?: any | null
        startDate?: any | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
      }
    | {
        __typename?: 'Event'
        id: number
        location?: string | null
        endDate?: any | null
        startDate?: any | null
        caption?: string | null
        relatedAddresses?: any | null
        attendees?: Array<{
          __typename?: 'Attendee'
          displayName?: string | null
          status?: string | null
          address?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
        } | null> | null
      }
    | {
        __typename?: 'ResourceAllocation'
        id: number
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        extra?: {
          __typename?: 'ResourceAllocationExtra'
          notice?: string | null
        } | null
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          job?: {
            __typename?: 'Job'
            id: number
            caption: string
            jobState?: {
              __typename?: 'JobState'
              id: number
              caption: string
            } | null
          } | null
        } | null
      }
    | {
        __typename?: 'ResourceState'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
        comment?: string | null
        resourceStateType?: {
          __typename?: 'ResourceStateType'
          id: number
          caption: string
          color?: any | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: { __typename?: 'Vehicle'; id: number } | null
      }
    | null
  > | null
}

export type ResourceTimelineFreelancerQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
}>

export type ResourceTimelineFreelancerQuery = {
  __typename?: 'Query'
  resourceTimeline?: Array<
    | {
        __typename?: 'CalendarEvent'
        id: number
        caption?: string | null
        endDate?: any | null
        startDate?: any | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
      }
    | {
        __typename?: 'Event'
        id: number
        location?: string | null
        endDate?: any | null
        startDate?: any | null
        caption?: string | null
        relatedAddresses?: any | null
        attendees?: Array<{
          __typename?: 'Attendee'
          displayName?: string | null
          status?: string | null
          address?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
        } | null> | null
      }
    | {
        __typename?: 'ResourceAllocation'
        id: number
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        extra?: {
          __typename?: 'ResourceAllocationExtra'
          notice?: string | null
        } | null
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          job?: {
            __typename?: 'Job'
            id: number
            caption: string
            jobState?: {
              __typename?: 'JobState'
              id: number
              caption: string
            } | null
          } | null
        } | null
      }
    | {
        __typename?: 'ResourceState'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
        comment?: string | null
        resourceStateType?: {
          __typename?: 'ResourceStateType'
          id: number
          caption: string
          color?: any | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: { __typename?: 'Vehicle'; id: number } | null
      }
    | null
  > | null
}

export type ResourceTimelineVehicleQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
}>

export type ResourceTimelineVehicleQuery = {
  __typename?: 'Query'
  resourceTimeline?: Array<
    | {
        __typename?: 'ResourceAllocation'
        id: number
        vehicle?: { __typename?: 'Vehicle'; id: number } | null
        extra?: {
          __typename?: 'ResourceAllocationExtra'
          notice?: string | null
        } | null
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          job?: {
            __typename?: 'Job'
            id: number
            caption: string
            jobState?: {
              __typename?: 'JobState'
              id: number
              caption: string
            } | null
          } | null
        } | null
      }
    | {
        __typename?: 'ResourceState'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
        comment?: string | null
        resourceStateType?: {
          __typename?: 'ResourceStateType'
          id: number
          caption: string
          color?: any | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: { __typename?: 'Vehicle'; id: number } | null
      }
    | null
  > | null
}

export type AddResourceRequestCommentMutationVariables = Exact<{
  resourceAllocationIds:
    | Array<Scalars['Int']['input']>
    | Scalars['Int']['input']
  message?: InputMaybe<Scalars['String']['input']>
}>

export type AddResourceRequestCommentMutation = {
  __typename?: 'Mutation'
  addResourceRequestComment?: {
    __typename?: 'ResourceRequest'
    id: number
  } | null
}

export type MessageRecipientsQueryVariables = Exact<{
  resourceAllocationIds:
    | Array<Scalars['Int']['input']>
    | Scalars['Int']['input']
}>

export type MessageRecipientsQuery = {
  __typename?: 'Query'
  resourceAllocations?: Array<{
    __typename?: 'ResourceAllocation'
    id: number
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    resourceRequest?: { __typename?: 'ResourceRequest'; id: number } | null
  } | null> | null
}

export type DeleteInWebfleetMutationVariables = Exact<{
  resourceAllocationId: Scalars['Int']['input']
}>

export type DeleteInWebfleetMutation = {
  __typename?: 'Mutation'
  deleteWebfleetOrder?: boolean | null
}

export type SendToWebfleetMutationVariables = Exact<{
  resourceAllocationId: Scalars['Int']['input']
  note?: InputMaybe<Scalars['String']['input']>
}>

export type SendToWebfleetMutation = {
  __typename?: 'Mutation'
  createWebfleetOrder?: {
    __typename?: 'WebfleetOrder'
    state?: WebfleetState | null
  } | null
}

export type SendToWebfleetPreviewQueryVariables = Exact<{
  resourceAllocationId: Scalars['Int']['input']
}>

export type SendToWebfleetPreviewQuery = {
  __typename?: 'Query'
  webfleetOrder?: {
    __typename?: 'WebfleetOrder'
    id?: string | null
    orderDateTime?: any | null
    orderText?: string | null
    contactPerson?: string | null
    contactPhoneNumber?: string | null
    notice?: string | null
    state?: WebfleetState | null
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
    } | null
    deliveryAddress?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      street?: string | null
      zip?: string | null
      city?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
      country?: {
        __typename?: 'Country'
        id: number
        caption?: string | null
      } | null
    } | null
  } | null
}

export type ViewsQueryVariables = Exact<{ [key: string]: never }>

export type ViewsQuery = {
  __typename?: 'Query'
  views?: Array<{
    __typename?: 'PersoPlanView'
    id: number
    title: string
    public?: boolean | null
    query: string
  } | null> | null
}

export type SidebarCacheQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
  clientIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeClientIds?: InputMaybe<Scalars['Boolean']['input']>
  projectLeaderIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeProjectLeaderIds?: InputMaybe<Scalars['Boolean']['input']>
  projectIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeProjectIds?: InputMaybe<Scalars['Boolean']['input']>
  projectStateIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeProjectStateIds?: InputMaybe<Scalars['Boolean']['input']>
  projectTypeIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeProjectTypeIds?: InputMaybe<Scalars['Boolean']['input']>
  jobLeaderIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeJobLeaderIds?: InputMaybe<Scalars['Boolean']['input']>
  jobStateIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeJobStateIds?: InputMaybe<Scalars['Boolean']['input']>
  jobServiceIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeJobServiceIds?: InputMaybe<Scalars['Boolean']['input']>
  showOnlyWithResources?: InputMaybe<Scalars['Boolean']['input']>
  showOnlyActive?: InputMaybe<Scalars['Boolean']['input']>
  skipFreelancers?: InputMaybe<Scalars['Boolean']['input']>
}>

export type SidebarCacheQuery = {
  __typename?: 'Query'
  freelancerCalendar?: Array<
    | {
        __typename?: 'CalendarEvent'
        id: number
        caption?: string | null
        endDate?: any | null
        startDate?: any | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
      }
    | {
        __typename?: 'Event'
        id: number
        location?: string | null
        endDate?: any | null
        startDate?: any | null
        caption?: string | null
        relatedAddresses?: any | null
        attendees?: Array<{
          __typename?: 'Attendee'
          displayName?: string | null
          status?: string | null
          address?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
        } | null> | null
      }
    | {
        __typename?: 'ResourceAllocation'
        id: number
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        extra?: {
          __typename?: 'ResourceAllocationExtra'
          notice?: string | null
        } | null
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          job?: {
            __typename?: 'Job'
            id: number
            caption: string
            jobState?: {
              __typename?: 'JobState'
              id: number
              caption: string
            } | null
          } | null
        } | null
      }
    | {
        __typename?: 'ResourceState'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
        comment?: string | null
        resourceStateType?: {
          __typename?: 'ResourceStateType'
          id: number
          caption: string
          color?: any | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: { __typename?: 'Vehicle'; id: number } | null
      }
    | null
  > | null
  projects?: Array<{
    __typename?: 'Project'
    id: number
    startDate?: any | null
    endDate?: any | null
    numberOfMissingResources?: number | null
    caption: string
    number: string
    comment?: string | null
    jobs?: Array<{
      __typename?: 'Job'
      id: number
      caption: string
      number: string
      startDate: any
      endDate: any
      numberOfMissingResources?: number | null
      comment?: string | null
      jobState?: {
        __typename?: 'JobState'
        id: number
        jobType?: { __typename?: 'JobType'; id: number } | null
      } | null
      globalSetting?: {
        __typename?: 'GlobalSetting'
        key: string
        value?: string | null
      } | null
      project?: {
        __typename?: 'Project'
        id: number
        caption: string
        number: string
        projectState?: { __typename?: 'ProjectState'; id: number } | null
        setting?: {
          __typename?: 'ProjectSetting'
          isJobLayerHidden?: boolean | null
        } | null
        group?: {
          __typename?: 'ProjectGroup'
          number?: number | null
          isSortedManually?: boolean | null
        } | null
      } | null
    } | null> | null
    group?: {
      __typename?: 'ProjectGroup'
      number?: number | null
      isSortedManually?: boolean | null
    } | null
    projectState?: { __typename?: 'ProjectState'; id: number } | null
    globalSetting?: {
      __typename?: 'GlobalSetting'
      key: string
      value?: string | null
    } | null
    setting?: {
      __typename?: 'ProjectSetting'
      isJobLayerHidden?: boolean | null
    } | null
  } | null> | null
}

export type SidebarFreelancersQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
}>

export type SidebarFreelancersQuery = {
  __typename?: 'Query'
  freelancerCalendar?: Array<
    | {
        __typename?: 'CalendarEvent'
        id: number
        caption?: string | null
        endDate?: any | null
        startDate?: any | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
      }
    | {
        __typename?: 'Event'
        id: number
        location?: string | null
        endDate?: any | null
        startDate?: any | null
        caption?: string | null
        relatedAddresses?: any | null
        attendees?: Array<{
          __typename?: 'Attendee'
          displayName?: string | null
          status?: string | null
          address?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
        } | null> | null
      }
    | {
        __typename?: 'ResourceAllocation'
        id: number
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        extra?: {
          __typename?: 'ResourceAllocationExtra'
          notice?: string | null
        } | null
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          job?: {
            __typename?: 'Job'
            id: number
            caption: string
            jobState?: {
              __typename?: 'JobState'
              id: number
              caption: string
            } | null
          } | null
        } | null
      }
    | {
        __typename?: 'ResourceState'
        id: number
        caption?: string | null
        startDate?: any | null
        endDate?: any | null
        comment?: string | null
        resourceStateType?: {
          __typename?: 'ResourceStateType'
          id: number
          caption: string
          color?: any | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: { __typename?: 'Vehicle'; id: number } | null
      }
    | null
  > | null
}

export type SidebarJobQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type SidebarJobQuery = {
  __typename?: 'Query'
  job?: {
    __typename?: 'Job'
    id: number
    caption: string
    number: string
    startDate: any
    endDate: any
    numberOfMissingResources?: number | null
    comment?: string | null
    jobState?: {
      __typename?: 'JobState'
      id: number
      jobType?: { __typename?: 'JobType'; id: number } | null
    } | null
    globalSetting?: {
      __typename?: 'GlobalSetting'
      key: string
      value?: string | null
    } | null
    project?: {
      __typename?: 'Project'
      id: number
      caption: string
      number: string
      projectState?: { __typename?: 'ProjectState'; id: number } | null
      setting?: {
        __typename?: 'ProjectSetting'
        isJobLayerHidden?: boolean | null
      } | null
      group?: {
        __typename?: 'ProjectGroup'
        number?: number | null
        isSortedManually?: boolean | null
      } | null
    } | null
  } | null
}

export type SidebarOperationsQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
  showOnlyWithResources?: InputMaybe<Scalars['Boolean']['input']>
}>

export type SidebarOperationsQuery = {
  __typename?: 'Query'
  stockTypeServices?: Array<{
    __typename?: 'StockTypeService'
    id: number
    startDate: any
    endDate: any
    caption: string
    number: string
    stockType?: {
      __typename?: 'StockType'
      number: string
      caption: string
    } | null
    job?: {
      __typename?: 'Job'
      number: string
      caption: string
      id: number
    } | null
  } | null> | null
  purchaseOrders?: Array<{
    __typename?: 'PurchaseOrder'
    id: number
    caption?: string | null
    number?: string | null
    orderedAt?: any | null
  } | null> | null
}

export type SidebarProjectQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type SidebarProjectQuery = {
  __typename?: 'Query'
  project?: {
    __typename?: 'Project'
    id: number
    startDate?: any | null
    endDate?: any | null
    numberOfMissingResources?: number | null
    caption: string
    number: string
    comment?: string | null
    group?: {
      __typename?: 'ProjectGroup'
      number?: number | null
      isSortedManually?: boolean | null
    } | null
    projectState?: { __typename?: 'ProjectState'; id: number } | null
    globalSetting?: {
      __typename?: 'GlobalSetting'
      key: string
      value?: string | null
    } | null
    setting?: {
      __typename?: 'ProjectSetting'
      isJobLayerHidden?: boolean | null
    } | null
  } | null
}

export type SidebarProjectsQueryVariables = Exact<{
  projectIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type SidebarProjectsQuery = {
  __typename?: 'Query'
  projects?: Array<{
    __typename?: 'Project'
    id: number
    startDate?: any | null
    endDate?: any | null
    numberOfMissingResources?: number | null
    caption: string
    number: string
    comment?: string | null
    group?: {
      __typename?: 'ProjectGroup'
      number?: number | null
      isSortedManually?: boolean | null
    } | null
    projectState?: { __typename?: 'ProjectState'; id: number } | null
    globalSetting?: {
      __typename?: 'GlobalSetting'
      key: string
      value?: string | null
    } | null
    setting?: {
      __typename?: 'ProjectSetting'
      isJobLayerHidden?: boolean | null
    } | null
  } | null> | null
}

export type SidebarPurchaseOrderQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type SidebarPurchaseOrderQuery = {
  __typename?: 'Query'
  purchaseOrder?: {
    __typename?: 'PurchaseOrder'
    id: number
    caption?: string | null
    number?: string | null
    orderedAt?: any | null
  } | null
}

export type SidebarStockTypeServiceQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type SidebarStockTypeServiceQuery = {
  __typename?: 'Query'
  stockTypeService?: {
    __typename?: 'StockTypeService'
    id: number
    startDate: any
    endDate: any
    caption: string
    number: string
    stockType?: {
      __typename?: 'StockType'
      number: string
      caption: string
    } | null
    job?: {
      __typename?: 'Job'
      number: string
      caption: string
      id: number
    } | null
  } | null
}

export type StockTypeServiceQueryVariables = Exact<{
  id: Scalars['Int']['input']
  resourceAllocationIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceAllocationIds?: InputMaybe<Scalars['Boolean']['input']>
  resourceAllocationStateIds?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
  excludeResourceAllocationStateIds?: InputMaybe<Scalars['Boolean']['input']>
}>

export type StockTypeServiceQuery = {
  __typename?: 'Query'
  stockTypeService?: {
    __typename?: 'StockTypeService'
    id: number
    startDate: any
    endDate: any
    caption: string
    number: string
    resources?: Array<{
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      quantity?: number | null
      numberOfResourceAllocations?: number | null
      timesScheduled?: boolean | null
      resourceAllocations?: Array<{
        __typename?: 'ResourceAllocation'
        id: number
        factor?: number | null
        travel?: string | null
        hotel?: string | null
        extra?: {
          __typename?: 'ResourceAllocationExtra'
          notice?: string | null
        } | null
        address?: {
          __typename?: 'Address'
          id: number
          displayName?: any | null
          firstName?: string | null
          surname?: string | null
          company?: string | null
          addressResourceType?: {
            __typename?: 'AddressResourceType'
            id: number
            caption: string
          } | null
        } | null
        vehicle?: {
          __typename?: 'Vehicle'
          id: number
          caption?: string | null
          isWebfleetVehicle?: boolean | null
        } | null
        resourceAllocationState?: {
          __typename?: 'ResourceAllocationState'
          id: number
          caption: string
        } | null
        resourceFunctionAllocation?: {
          __typename?: 'ResourceFunctionAllocation'
          id: number
          startDate?: any | null
          endDate?: any | null
          job?: { __typename?: 'Job'; id: number } | null
        } | null
        resourceAllocationDriver?: {
          __typename?: 'ResourceAllocation'
          id: number
          resourceFunctionAllocation?: {
            __typename?: 'ResourceFunctionAllocation'
            id: number
            job?: { __typename?: 'Job'; id: number; number: string } | null
          } | null
          address?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
          vehicle?: {
            __typename?: 'Vehicle'
            id: number
            caption?: string | null
          } | null
        } | null
        resourceRequest?: {
          __typename?: 'ResourceRequest'
          id: number
          dateOfExpire?: any | null
          state?: ResourceRequestState | null
          updated?: any | null
          subject?: string | null
          updatedBy?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
          feedback?: {
            __typename?: 'ResourceRequestFeedback'
            availableFrom?: any | null
            availableTo?: any | null
            comment?: string | null
            userState?: ResourceRequestUserStatus | null
          } | null
        } | null
      } | null> | null
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        id: number
        abbreviation?: string | null
        caption?: string | null
        sortOrder?: number | null
        resourceType?: {
          __typename?: 'ResourceType'
          id: number
          caption: string
        } | null
      } | null
      allocationStatistics?: Array<{
        __typename?: 'KeyValue'
        key: string
        value?: string | null
      } | null> | null
      job?: { __typename?: 'Job'; id: number } | null
      jobAppointment?: {
        __typename?: 'JobAppointment'
        id: number
        startDate?: any | null
        endDate?: any | null
      } | null
    } | null> | null
    stockType?: {
      __typename?: 'StockType'
      number: string
      caption: string
    } | null
    job?: {
      __typename?: 'Job'
      number: string
      caption: string
      id: number
    } | null
  } | null
}

export type CreateViewMutationVariables = Exact<{
  title: Scalars['String']['input']
  query: Scalars['String']['input']
  public: Scalars['Boolean']['input']
}>

export type CreateViewMutation = {
  __typename?: 'Mutation'
  createPersoPlanView?: {
    __typename?: 'PersoPlanView'
    id: number
    title: string
    public?: boolean | null
    query: string
  } | null
}

export type DeleteViewMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DeleteViewMutation = {
  __typename?: 'Mutation'
  deletePersoPlanView?: boolean | null
}

export type UpdateViewMutationVariables = Exact<{
  id: Scalars['Int']['input']
  title: Scalars['String']['input']
  public?: InputMaybe<Scalars['Boolean']['input']>
  query: Scalars['String']['input']
}>

export type UpdateViewMutation = {
  __typename?: 'Mutation'
  updatePersoPlanView?: {
    __typename?: 'PersoPlanView'
    id: number
    title: string
    public?: boolean | null
    query: string
  } | null
}

export type SearchQueryVariables = Exact<{
  searchterm: Scalars['String']['input']
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
}>

export type SearchQuery = {
  __typename?: 'Query'
  searchResults?: Array<{
    __typename?: 'Job'
    id: number
    caption: string
    startDate: any
    endDate: any
    project?: {
      __typename?: 'Project'
      id: number
      caption: string
      number: string
      setting?: {
        __typename?: 'ProjectSetting'
        isJobLayerHidden?: boolean | null
      } | null
    } | null
    arrangedBy?: {
      __typename?: 'EasyJobUser'
      id: number
      displayName?: string | null
    } | null
    jobState?: {
      __typename?: 'JobState'
      id: number
      caption: string
      jobType?: { __typename?: 'JobType'; id: number } | null
    } | null
  } | null> | null
}

export type ViewEditorClientsQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
}>

export type ViewEditorClientsQuery = {
  __typename?: 'Query'
  clients?: Array<{
    __typename?: 'Address'
    id: number
    displayName?: any | null
  } | null> | null
}

export type ViewEditorClientsSearchQueryVariables = Exact<{
  searchterm?: InputMaybe<Scalars['String']['input']>
}>

export type ViewEditorClientsSearchQuery = {
  __typename?: 'Query'
  clientsSearch?: Array<{
    __typename?: 'Address'
    id: number
    displayName?: any | null
  } | null> | null
}

export type ViewEditorProjectsQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
}>

export type ViewEditorProjectsQuery = {
  __typename?: 'Query'
  projects?: Array<{
    __typename?: 'Project'
    id: number
    caption: string
    number: string
  } | null> | null
}

export type ViewEditorProjectsSearchQueryVariables = Exact<{
  searchterm?: InputMaybe<Scalars['String']['input']>
}>

export type ViewEditorProjectsSearchQuery = {
  __typename?: 'Query'
  projectsSearch?: Array<{
    __typename?: 'Project'
    id: number
    caption: string
    number: string
  } | null> | null
}

export type ViewEditorResourceAllocationsQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >
}>

export type ViewEditorResourceAllocationsQuery = {
  __typename?: 'Query'
  resourceAllocations?: Array<{
    __typename?: 'Address'
    id: number
    displayName?: any | null
  } | null> | null
}

export type ViewEditorResourceAllocationsSearchQueryVariables = Exact<{
  searchterm?: InputMaybe<Scalars['String']['input']>
}>

export type ViewEditorResourceAllocationsSearchQuery = {
  __typename?: 'Query'
  resourceAllocationsSearch?: Array<{
    __typename?: 'Address'
    id: number
    displayName?: any | null
  } | null> | null
}

export type ViewEditorStaticQueryVariables = Exact<{ [key: string]: never }>

export type ViewEditorStaticQuery = {
  __typename?: 'Query'
  employeeGroups?: Array<{
    __typename?: 'AddressType'
    id: number
    caption: string
  } | null> | null
  easyJobUsers?: Array<{
    __typename?: 'EasyJobUser'
    id: number
    displayName?: string | null
  } | null> | null
  projectStates?: Array<{
    __typename?: 'ProjectState'
    id: number
    caption: string
  } | null> | null
  projectTypes?: Array<{
    __typename?: 'ProjectType'
    id: number
    caption: string
  } | null> | null
  jobStates?: Array<{
    __typename?: 'JobState'
    id: number
    caption: string
    jobType?: { __typename?: 'JobType'; id: number; caption: string } | null
  } | null> | null
  jobServices?: Array<{
    __typename?: 'JobService'
    id: number
    caption: string
  } | null> | null
  resourceAllocationStates?: Array<{
    __typename?: 'ResourceAllocationState'
    id: number
    caption: string
  } | null> | null
  resourceTypes?: Array<{
    __typename?: 'ResourceType'
    id: number
    caption: string
  } | null> | null
  resourceFunctions?: Array<{
    __typename?: 'ResourceFunction'
    id: number
    caption?: string | null
    abbreviation?: string | null
  } | null> | null
}

export type AppSettingsQueryVariables = Exact<{ [key: string]: never }>

export type AppSettingsQuery = {
  __typename?: 'Query'
  appSettings?: {
    __typename?: 'AppSetting'
    coureHoursStart?: any | null
    coreHoursEnd?: any | null
    openingHours?: Array<{
      __typename?: 'OpeningHour'
      openingTime?: any | null
      closingTime?: any | null
      day?: number | null
    } | null> | null
  } | null
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    id: number
    username: string
    idAddress: number
    firstName?: string | null
    surname?: string | null
    email: string
    roles?: Array<string | null> | null
    easyJobRoles?: Array<string | null> | null
    groups?: Array<string | null> | null
    changePasswordNextLogin?: boolean | null
    address?: { __typename?: 'Address'; id: number } | null
  } | null
}

export type EmployeeGroupsQueryVariables = Exact<{ [key: string]: never }>

export type EmployeeGroupsQuery = {
  __typename?: 'Query'
  employeeGroups?: Array<{
    __typename?: 'AddressType'
    id: number
    caption: string
    addresses?: Array<{
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null> | null
  } | null> | null
}

export type GetSettingsQueryVariables = Exact<{ [key: string]: never }>

export type GetSettingsQuery = {
  __typename?: 'Query'
  settings?: Array<{
    __typename?: 'Setting'
    name: string
    value?: string | null
  } | null> | null
}

export type ProMusikVehiclesQueryVariables = Exact<{ [key: string]: never }>

export type ProMusikVehiclesQuery = {
  __typename?: 'Query'
  vehicles?: Array<{
    __typename?: 'Vehicle'
    id: number
    caption?: string | null
    comment?: string | null
    abbreviation?: string | null
    loadingCapacity?: number | null
    loadingVolume?: number | null
    supplier?: {
      __typename?: 'Address'
      id: number
      company?: string | null
    } | null
    resourceFunction?: {
      __typename?: 'ResourceFunction'
      id: number
      caption?: string | null
    } | null
  } | null> | null
}

export type ResourceAllocationsQueryVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type ResourceAllocationsQuery = {
  __typename?: 'Query'
  resourceAllocations?: Array<{
    __typename?: 'ResourceAllocation'
    id: number
    factor?: number | null
    travel?: string | null
    hotel?: string | null
    resourceFunctionAllocation?: {
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      resourceFunction?: {
        __typename?: 'ResourceFunction'
        id: number
        caption?: string | null
      } | null
      jobAppointment?: {
        __typename?: 'JobAppointment'
        id: number
        caption?: string | null
      } | null
      jobAppointments?: Array<{
        __typename?: 'JobAppointment'
        id: number
        caption?: string | null
      } | null> | null
      job?: {
        __typename?: 'Job'
        id: number
        caption: string
        number: string
        startDate: any
        project?: {
          __typename?: 'Project'
          id: number
          caption: string
          number: string
        } | null
      } | null
    } | null
    extra?: {
      __typename?: 'ResourceAllocationExtra'
      notice?: string | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
      isWebfleetVehicle?: boolean | null
    } | null
    resourceAllocationState?: {
      __typename?: 'ResourceAllocationState'
      id: number
      caption: string
    } | null
    resourceAllocationDriver?: {
      __typename?: 'ResourceAllocation'
      id: number
      resourceFunctionAllocation?: {
        __typename?: 'ResourceFunctionAllocation'
        id: number
        job?: { __typename?: 'Job'; id: number; number: string } | null
      } | null
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      vehicle?: {
        __typename?: 'Vehicle'
        id: number
        caption?: string | null
      } | null
    } | null
    resourceRequest?: {
      __typename?: 'ResourceRequest'
      id: number
      dateOfExpire?: any | null
      state?: ResourceRequestState | null
      updated?: any | null
      subject?: string | null
      updatedBy?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      feedback?: {
        __typename?: 'ResourceRequestFeedback'
        availableFrom?: any | null
        availableTo?: any | null
        comment?: string | null
        userState?: ResourceRequestUserStatus | null
      } | null
    } | null
  } | null> | null
}

export type ResourceStateTypesQueryVariables = Exact<{ [key: string]: never }>

export type ResourceStateTypesQuery = {
  __typename?: 'Query'
  resourceStateTypes?: Array<{
    __typename?: 'ResourceStateType'
    id: number
    caption: string
    resourceType: { __typename?: 'ResourceType'; id: number; caption: string }
  } | null> | null
}

export type SaveSettingMutationVariables = Exact<{
  values: Scalars['String']['input']
}>

export type SaveSettingMutation = {
  __typename?: 'Mutation'
  saveSetting?: {
    __typename?: 'Setting'
    name: string
    value?: string | null
  } | null
}

export type StaticDataQueryVariables = Exact<{ [key: string]: never }>

export type StaticDataQuery = {
  __typename?: 'Query'
  resourceAllocationStates?: Array<{
    __typename?: 'ResourceAllocationState'
    id: number
    caption: string
  } | null> | null
  resourceFunctions?: Array<{
    __typename?: 'ResourceFunction'
    id: number
    caption?: string | null
    resourceType?: {
      __typename?: 'ResourceType'
      id: number
      type: string
      caption: string
    } | null
  } | null> | null
  qualifications?: Array<{
    __typename?: 'Qualification'
    id: number
    caption?: string | null
  } | null> | null
  addressResourceTypes?: Array<{
    __typename?: 'AddressResourceType'
    id: number
    caption: string
  } | null> | null
}

export type UpdateProjectGroupMutationVariables = Exact<{
  projectId: Scalars['Int']['input']
  groupNumber: Scalars['Int']['input']
}>

export type UpdateProjectGroupMutation = {
  __typename?: 'Mutation'
  setProjectGroup?: number | null
}

export type AddressFragment = {
  __typename?: 'Address'
  displayName?: any | null
  firstName?: string | null
  surname?: string | null
  company?: string | null
  addressResourceType?: {
    __typename?: 'AddressResourceType'
    id: number
    caption: string
  } | null
}

export type AppointmentFragment = {
  __typename?: 'JobAppointment'
  id: number
  caption?: string | null
  startDate?: any | null
  endDate?: any | null
  comment?: string | null
  job?: {
    __typename?: 'Job'
    id: number
    number: string
    comment?: string | null
    jobState?: {
      __typename?: 'JobState'
      id: number
      jobType?: { __typename?: 'JobType'; id: number } | null
    } | null
    project?: { __typename?: 'Project'; id: number; number: string } | null
  } | null
}

export type AppointmentShortFragment = {
  __typename?: 'JobAppointment'
  id: number
  caption?: string | null
  startDate?: any | null
  endDate?: any | null
  comment?: string | null
}

export type CalendarEventFragment = {
  __typename?: 'CalendarEvent'
  id: number
  caption?: string | null
  endDate?: any | null
  startDate?: any | null
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null
}

export type ContactDetailsFragment = {
  __typename?: 'Address'
  phone?: string | null
  phoneCompany?: string | null
  phoneMobile?: string | null
  phonePrivate?: string | null
  wwwAddress?: string | null
  email?: string | null
}

export type ContactFragment = {
  __typename?: 'Contact'
  firstName?: string | null
  surname?: string | null
  mobile?: string | null
  phone?: string | null
  email?: string | null
  custom1?: string | null
  custom2?: string | null
}

export type EasyJobEventFragment = {
  __typename?: 'EasyJobEvent'
  id: string
  caption?: string | null
  startDate?: any | null
  endDate?: any | null
  description?: string | null
  color?: any | null
  number?: string | null
  calendar?: { __typename?: 'Calendar'; id: string; caption: string } | null
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    street?: string | null
    zip?: string | null
    city?: string | null
  } | null
}

export type EmployeeGroupFragment = {
  __typename?: 'AddressType'
  id: number
  caption: string
  addresses?: Array<{
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null> | null
}

export type EventFragment = {
  __typename?: 'Event'
  id: number
  location?: string | null
  endDate?: any | null
  startDate?: any | null
  caption?: string | null
  relatedAddresses?: any | null
  attendees?: Array<{
    __typename?: 'Attendee'
    displayName?: string | null
    status?: string | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
  } | null> | null
}

export type ExpenseReportFragment = {
  __typename?: 'ExpenseReport'
  id: number
  created: any
  updated: any
  date?: any | null
  details?: string | null
  paymentType?: PaymentType | null
  paymentInfo?: string | null
  route?: string | null
  kilometer?: number | null
  publicTransportation?: boolean | null
  dayOfCatering?: any | null
  location?: string | null
  host?: string | null
  servedPersons?: string | null
  reason?: string | null
  invoiceAmount?: number | null
  tipAmount?: number | null
  totalAmount?: number | null
  expenseType?: {
    __typename?: 'ExpenseType'
    id: number
    caption: string
  } | null
  job?: {
    __typename?: 'Job'
    id: number
    caption: string
    number: string
  } | null
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
  } | null
}

export type ExternalServiceRequestFragment = {
  __typename?: 'ExternalServiceRequest'
  id: number
  startDate: any
  endDate: any
  state: ExternalServiceRequestState
  eventName: string
  client: string
  customerOfClient: string
  location: string
  jobFunction: string
  user: { __typename?: 'User'; id: number; fullName?: string | null }
  communications?: Array<{
    __typename?: 'ExternalServiceRequestCommunication'
    newState: ExternalServiceRequestState
    action: ExternalServiceRequestAction
    timestamp: any
    comment?: string | null
    user: { __typename?: 'User'; id: number; fullName?: string | null }
  }> | null
}

export type JobFragment = {
  __typename?: 'Job'
  id: number
  caption: string
  number: string
  startDate: any
  endDate: any
  numberOfMissingResources?: number | null
  comment?: string | null
  jobState?: {
    __typename?: 'JobState'
    id: number
    jobType?: { __typename?: 'JobType'; id: number } | null
  } | null
  globalSetting?: {
    __typename?: 'GlobalSetting'
    key: string
    value?: string | null
  } | null
  project?: {
    __typename?: 'Project'
    id: number
    caption: string
    number: string
    projectState?: { __typename?: 'ProjectState'; id: number } | null
    setting?: {
      __typename?: 'ProjectSetting'
      isJobLayerHidden?: boolean | null
    } | null
    group?: {
      __typename?: 'ProjectGroup'
      number?: number | null
      isSortedManually?: boolean | null
    } | null
  } | null
}

export type LeaveRequestFragment = {
  __typename?: 'LeaveRequest'
  id: number
  type: LeaveRequestType
  startDate: any
  endDate: any
  state: LeaveRequestState
  user: { __typename?: 'User'; id: number; fullName?: string | null }
  communications?: Array<{
    __typename?: 'LeaveRequestCommunication'
    previousState?: LeaveRequestState | null
    newState: LeaveRequestState
    previousType?: LeaveRequestType | null
    newType?: LeaveRequestType | null
    action: LeaveRequestAction
    timestamp: any
    comment?: string | null
    user: { __typename?: 'User'; id: number; fullName?: string | null }
  }> | null
}

export type LocationFragment = {
  __typename?: 'Address'
  firstName?: string | null
  surname?: string | null
  company?: string | null
  street?: string | null
  zip?: string | null
  city?: string | null
  country?: {
    __typename?: 'Country'
    id: number
    caption?: string | null
  } | null
}

export type ProjectFragment = {
  __typename?: 'Project'
  id: number
  startDate?: any | null
  endDate?: any | null
  numberOfMissingResources?: number | null
  caption: string
  number: string
  comment?: string | null
  group?: {
    __typename?: 'ProjectGroup'
    number?: number | null
    isSortedManually?: boolean | null
  } | null
  projectState?: { __typename?: 'ProjectState'; id: number } | null
  globalSetting?: {
    __typename?: 'GlobalSetting'
    key: string
    value?: string | null
  } | null
  setting?: {
    __typename?: 'ProjectSetting'
    isJobLayerHidden?: boolean | null
  } | null
}

export type PurchaseOrderFragment = {
  __typename?: 'PurchaseOrder'
  id: number
  caption?: string | null
  number?: string | null
  orderedAt?: any | null
}

export type ResourceAllocationFragment = {
  __typename?: 'ResourceAllocation'
  id: number
  factor?: number | null
  travel?: string | null
  hotel?: string | null
  extra?: {
    __typename?: 'ResourceAllocationExtra'
    notice?: string | null
  } | null
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null
  vehicle?: {
    __typename?: 'Vehicle'
    id: number
    caption?: string | null
    isWebfleetVehicle?: boolean | null
  } | null
  resourceAllocationState?: {
    __typename?: 'ResourceAllocationState'
    id: number
    caption: string
  } | null
  resourceFunctionAllocation?: {
    __typename?: 'ResourceFunctionAllocation'
    id: number
    startDate?: any | null
    endDate?: any | null
    job?: { __typename?: 'Job'; id: number } | null
  } | null
  resourceAllocationDriver?: {
    __typename?: 'ResourceAllocation'
    id: number
    resourceFunctionAllocation?: {
      __typename?: 'ResourceFunctionAllocation'
      id: number
      job?: { __typename?: 'Job'; id: number; number: string } | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
    } | null
  } | null
  resourceRequest?: {
    __typename?: 'ResourceRequest'
    id: number
    dateOfExpire?: any | null
    state?: ResourceRequestState | null
    updated?: any | null
    subject?: string | null
    updatedBy?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    feedback?: {
      __typename?: 'ResourceRequestFeedback'
      availableFrom?: any | null
      availableTo?: any | null
      comment?: string | null
      userState?: ResourceRequestUserStatus | null
    } | null
  } | null
}

export type ResourceFragment = {
  __typename?: 'ResourceFunctionAllocation'
  id: number
  startDate?: any | null
  endDate?: any | null
  quantity?: number | null
  numberOfResourceAllocations?: number | null
  timesScheduled?: boolean | null
  resourceFunction?: {
    __typename?: 'ResourceFunction'
    id: number
    abbreviation?: string | null
    caption?: string | null
    sortOrder?: number | null
    resourceType?: {
      __typename?: 'ResourceType'
      id: number
      caption: string
    } | null
  } | null
  allocationStatistics?: Array<{
    __typename?: 'KeyValue'
    key: string
    value?: string | null
  } | null> | null
  job?: { __typename?: 'Job'; id: number } | null
  jobAppointment?: {
    __typename?: 'JobAppointment'
    id: number
    startDate?: any | null
    endDate?: any | null
  } | null
}

export type ResourceRequestFeedbackFragment = {
  __typename?: 'ResourceRequest'
  feedback?: {
    __typename?: 'ResourceRequestFeedback'
    availableFrom?: any | null
    availableTo?: any | null
    comment?: string | null
    userState?: ResourceRequestUserStatus | null
  } | null
}

export type ResourceRequestFragment = {
  __typename?: 'ResourceRequest'
  id: number
  dateOfExpire?: any | null
  state?: ResourceRequestState | null
  updated?: any | null
  subject?: string | null
  updatedBy?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null
}

export type ResourceStateFragment = {
  __typename?: 'ResourceState'
  id: number
  caption?: string | null
  startDate?: any | null
  endDate?: any | null
  comment?: string | null
  resourceStateType?: {
    __typename?: 'ResourceStateType'
    id: number
    caption: string
    color?: any | null
  } | null
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null
  vehicle?: { __typename?: 'Vehicle'; id: number } | null
}

type ResourceTimelineAddress_CalendarEvent_Fragment = {
  __typename?: 'CalendarEvent'
  id: number
  caption?: string | null
  endDate?: any | null
  startDate?: any | null
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null
}

type ResourceTimelineAddress_Event_Fragment = {
  __typename?: 'Event'
  id: number
  location?: string | null
  endDate?: any | null
  startDate?: any | null
  caption?: string | null
  relatedAddresses?: any | null
  attendees?: Array<{
    __typename?: 'Attendee'
    displayName?: string | null
    status?: string | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
  } | null> | null
}

type ResourceTimelineAddress_ResourceAllocation_Fragment = {
  __typename?: 'ResourceAllocation'
  id: number
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null
  extra?: {
    __typename?: 'ResourceAllocationExtra'
    notice?: string | null
  } | null
  resourceAllocationState?: {
    __typename?: 'ResourceAllocationState'
    id: number
    caption: string
  } | null
  resourceFunctionAllocation?: {
    __typename?: 'ResourceFunctionAllocation'
    id: number
    startDate?: any | null
    endDate?: any | null
    job?: {
      __typename?: 'Job'
      id: number
      caption: string
      jobState?: { __typename?: 'JobState'; id: number; caption: string } | null
    } | null
  } | null
}

type ResourceTimelineAddress_ResourceState_Fragment = {
  __typename?: 'ResourceState'
  id: number
  caption?: string | null
  startDate?: any | null
  endDate?: any | null
  comment?: string | null
  resourceStateType?: {
    __typename?: 'ResourceStateType'
    id: number
    caption: string
    color?: any | null
  } | null
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null
  vehicle?: { __typename?: 'Vehicle'; id: number } | null
}

export type ResourceTimelineAddressFragment =
  | ResourceTimelineAddress_CalendarEvent_Fragment
  | ResourceTimelineAddress_Event_Fragment
  | ResourceTimelineAddress_ResourceAllocation_Fragment
  | ResourceTimelineAddress_ResourceState_Fragment

type ResourceTimelineVehicle_ResourceAllocation_Fragment = {
  __typename?: 'ResourceAllocation'
  id: number
  vehicle?: { __typename?: 'Vehicle'; id: number } | null
  extra?: {
    __typename?: 'ResourceAllocationExtra'
    notice?: string | null
  } | null
  resourceAllocationState?: {
    __typename?: 'ResourceAllocationState'
    id: number
    caption: string
  } | null
  resourceFunctionAllocation?: {
    __typename?: 'ResourceFunctionAllocation'
    id: number
    startDate?: any | null
    endDate?: any | null
    job?: {
      __typename?: 'Job'
      id: number
      caption: string
      jobState?: { __typename?: 'JobState'; id: number; caption: string } | null
    } | null
  } | null
}

type ResourceTimelineVehicle_ResourceState_Fragment = {
  __typename?: 'ResourceState'
  id: number
  caption?: string | null
  startDate?: any | null
  endDate?: any | null
  comment?: string | null
  resourceStateType?: {
    __typename?: 'ResourceStateType'
    id: number
    caption: string
    color?: any | null
  } | null
  address?: {
    __typename?: 'Address'
    id: number
    displayName?: any | null
    firstName?: string | null
    surname?: string | null
    company?: string | null
    addressResourceType?: {
      __typename?: 'AddressResourceType'
      id: number
      caption: string
    } | null
  } | null
  vehicle?: { __typename?: 'Vehicle'; id: number } | null
}

export type ResourceTimelineVehicleFragment =
  | ResourceTimelineVehicle_ResourceAllocation_Fragment
  | ResourceTimelineVehicle_ResourceState_Fragment

export type ResourceWithAllocationsFragment = {
  __typename?: 'ResourceFunctionAllocation'
  id: number
  startDate?: any | null
  endDate?: any | null
  quantity?: number | null
  numberOfResourceAllocations?: number | null
  timesScheduled?: boolean | null
  resourceAllocations?: Array<{
    __typename?: 'ResourceAllocation'
    id: number
    factor?: number | null
    travel?: string | null
    hotel?: string | null
    extra?: {
      __typename?: 'ResourceAllocationExtra'
      notice?: string | null
    } | null
    address?: {
      __typename?: 'Address'
      id: number
      displayName?: any | null
      firstName?: string | null
      surname?: string | null
      company?: string | null
      addressResourceType?: {
        __typename?: 'AddressResourceType'
        id: number
        caption: string
      } | null
    } | null
    vehicle?: {
      __typename?: 'Vehicle'
      id: number
      caption?: string | null
      isWebfleetVehicle?: boolean | null
    } | null
    resourceAllocationState?: {
      __typename?: 'ResourceAllocationState'
      id: number
      caption: string
    } | null
    resourceFunctionAllocation?: {
      __typename?: 'ResourceFunctionAllocation'
      id: number
      startDate?: any | null
      endDate?: any | null
      job?: { __typename?: 'Job'; id: number } | null
    } | null
    resourceAllocationDriver?: {
      __typename?: 'ResourceAllocation'
      id: number
      resourceFunctionAllocation?: {
        __typename?: 'ResourceFunctionAllocation'
        id: number
        job?: { __typename?: 'Job'; id: number; number: string } | null
      } | null
      address?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      vehicle?: {
        __typename?: 'Vehicle'
        id: number
        caption?: string | null
      } | null
    } | null
    resourceRequest?: {
      __typename?: 'ResourceRequest'
      id: number
      dateOfExpire?: any | null
      state?: ResourceRequestState | null
      updated?: any | null
      subject?: string | null
      updatedBy?: {
        __typename?: 'Address'
        id: number
        displayName?: any | null
        firstName?: string | null
        surname?: string | null
        company?: string | null
        addressResourceType?: {
          __typename?: 'AddressResourceType'
          id: number
          caption: string
        } | null
      } | null
      feedback?: {
        __typename?: 'ResourceRequestFeedback'
        availableFrom?: any | null
        availableTo?: any | null
        comment?: string | null
        userState?: ResourceRequestUserStatus | null
      } | null
    } | null
  } | null> | null
  resourceFunction?: {
    __typename?: 'ResourceFunction'
    id: number
    abbreviation?: string | null
    caption?: string | null
    sortOrder?: number | null
    resourceType?: {
      __typename?: 'ResourceType'
      id: number
      caption: string
    } | null
  } | null
  allocationStatistics?: Array<{
    __typename?: 'KeyValue'
    key: string
    value?: string | null
  } | null> | null
  job?: { __typename?: 'Job'; id: number } | null
  jobAppointment?: {
    __typename?: 'JobAppointment'
    id: number
    startDate?: any | null
    endDate?: any | null
  } | null
}

export type StockTypeServiceFragment = {
  __typename?: 'StockTypeService'
  id: number
  startDate: any
  endDate: any
  caption: string
  number: string
  stockType?: {
    __typename?: 'StockType'
    number: string
    caption: string
  } | null
  job?: {
    __typename?: 'Job'
    number: string
    caption: string
    id: number
  } | null
}

export type VehicleFragment = {
  __typename?: 'Vehicle'
  id: number
  caption?: string | null
  comment?: string | null
  abbreviation?: string | null
  loadingCapacity?: number | null
  loadingVolume?: number | null
  supplier?: {
    __typename?: 'Address'
    id: number
    company?: string | null
  } | null
  resourceFunction?: {
    __typename?: 'ResourceFunction'
    id: number
    caption?: string | null
  } | null
}

export type ViewFragment = {
  __typename?: 'PersoPlanView'
  id: number
  title: string
  public?: boolean | null
  query: string
}

export type MarkAllNotificationsAsReadMutationVariables = Exact<{
  [key: string]: never
}>

export type MarkAllNotificationsAsReadMutation = {
  __typename?: 'Mutation'
  markAllNotificationAsRead?: boolean | null
}

export type NotificationsQueryVariables = Exact<{
  includeRead?: InputMaybe<Scalars['Boolean']['input']>
}>

export type NotificationsQuery = {
  __typename?: 'Query'
  notifications?: Array<{
    __typename?: 'Notification'
    id: number
    createdAt: any
    updatedAt: any
    state: NotificationStatus
    type: NotificationType
    meta?:
      | { __typename?: 'ExternalServiceRequestAccepted' }
      | {
          __typename?: 'ExternalServiceRequestCreated'
          startDate: any
          endDate: any
          user: {
            __typename?: 'Address'
            firstName?: string | null
            surname?: string | null
          }
        }
      | { __typename?: 'ExternalServiceRequestDeclined' }
      | { __typename?: 'ExternalServiceRequestUpdated' }
      | { __typename?: 'LeaveRequestAccepted' }
      | {
          __typename?: 'LeaveRequestCreated'
          type: LeaveRequestType
          startDate: any
          endDate: any
          user: {
            __typename?: 'Address'
            firstName?: string | null
            surname?: string | null
          }
        }
      | { __typename?: 'LeaveRequestDeclined' }
      | { __typename?: 'LeaveRequestUpdated' }
      | {
          __typename?: 'ResourceRequestFeedbackStateUpdated'
          stateBefore?: ResourceRequestUserStatus | null
          stateAfter?: ResourceRequestUserStatus | null
        }
      | { __typename?: 'ResourceRequestUpdated' }
      | null
    details?:
      | {
          __typename?: 'ExternalServiceRequest'
          id: number
          startDate: any
          endDate: any
          user: {
            __typename?: 'User'
            id: number
            firstName?: string | null
            surname?: string | null
          }
        }
      | { __typename?: 'Job' }
      | {
          __typename?: 'LeaveRequest'
          id: number
          startDate: any
          endDate: any
          type: LeaveRequestType
          user: {
            __typename?: 'User'
            id: number
            firstName?: string | null
            surname?: string | null
          }
        }
      | { __typename?: 'Project' }
      | {
          __typename?: 'ResourceRequest'
          resourceAllocationIds?: Array<number | null> | null
          id: number
          dateOfExpire?: any | null
          state?: ResourceRequestState | null
          updated?: any | null
          subject?: string | null
          resourceAllocations?: Array<{
            __typename?: 'ResourceAllocation'
            address?: {
              __typename?: 'Address'
              id: number
              displayName?: any | null
              firstName?: string | null
              surname?: string | null
              company?: string | null
              addressResourceType?: {
                __typename?: 'AddressResourceType'
                id: number
                caption: string
              } | null
            } | null
            resourceFunctionAllocation?: {
              __typename?: 'ResourceFunctionAllocation'
              id: number
              job?: {
                __typename?: 'Job'
                id: number
                startDate: any
                endDate: any
                project?: {
                  __typename?: 'Project'
                  id: number
                  number: string
                  caption: string
                  startDate?: any | null
                  endDate?: any | null
                  arrangedBy?: {
                    __typename?: 'EasyJobUser'
                    id: number
                    firstName?: string | null
                    surname?: string | null
                  } | null
                  setting?: {
                    __typename?: 'ProjectSetting'
                    isJobLayerHidden?: boolean | null
                  } | null
                } | null
              } | null
            } | null
          } | null> | null
          updatedBy?: {
            __typename?: 'Address'
            id: number
            displayName?: any | null
            firstName?: string | null
            surname?: string | null
            company?: string | null
            addressResourceType?: {
              __typename?: 'AddressResourceType'
              id: number
              caption: string
            } | null
          } | null
        }
      | null
  } | null> | null
}

export type UpdateNotificationStatusMutationVariables = Exact<{
  id: Scalars['Int']['input']
  status: NotificationStatus
}>

export type UpdateNotificationStatusMutation = {
  __typename?: 'Mutation'
  updateNotificationState?: {
    __typename?: 'Notification'
    id: number
    state: NotificationStatus
  } | null
}

export const AppointmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAppointment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentFragment, unknown>
export const AppointmentShortFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AppointmentShort' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAppointment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentShortFragment, unknown>
export const ContactDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phonePrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wwwAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactDetailsFragment, unknown>
export const ContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactFragment, unknown>
export const EasyJobEventFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EasyJobEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EasyJobEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EasyJobEventFragment, unknown>
export const AddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressFragment, unknown>
export const EmployeeGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployeeGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployeeGroupFragment, unknown>
export const ExpenseReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'details' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'route' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kilometer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicTransportation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dayOfCatering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'host' } },
          { kind: 'Field', name: { kind: 'Name', value: 'servedPersons' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tipAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalAmount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExpenseReportFragment, unknown>
export const ExternalServiceRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExternalServiceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExternalServiceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerOfClient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobFunction' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExternalServiceRequestFragment, unknown>
export const JobFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Job' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Job' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isJobLayerHidden' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isSortedManually' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobFragment, unknown>
export const LeaveRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeaveRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LeaveRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousState' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaveRequestFragment, unknown>
export const LocationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Location' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationFragment, unknown>
export const ProjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSortedManually' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isJobLayerHidden' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectFragment, unknown>
export const PurchaseOrderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PurchaseOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PurchaseOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PurchaseOrderFragment, unknown>
export const EventFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relatedAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventFragment, unknown>
export const ResourceStateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceStateFragment, unknown>
export const CalendarEventFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CalendarEventFragment, unknown>
export const ResourceTimelineAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceTimelineAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AddressCalendarItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceAllocation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Event' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceState' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CalendarEvent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarEvent' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relatedAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceTimelineAddressFragment, unknown>
export const ResourceTimelineVehicleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceTimelineVehicle' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VehicleCalendarItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceAllocation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceState' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceTimelineVehicleFragment, unknown>
export const ResourceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfResourceAllocations' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timesScheduled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobAppointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceFragment, unknown>
export const ResourceRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceRequestFragment, unknown>
export const ResourceRequestFeedbackFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceRequestFeedbackFragment, unknown>
export const ResourceAllocationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceAllocationFragment, unknown>
export const ResourceWithAllocationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceWithAllocations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resource' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resourceAllocationIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeAddressIds' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeResourceAllocationIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'resourceAllocationStateIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationStateIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'excludeResourceAllocationStateIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeResourceAllocationStateIds',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfResourceAllocations' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timesScheduled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobAppointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceWithAllocationsFragment, unknown>
export const StockTypeServiceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTypeService' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTypeService' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StockTypeServiceFragment, unknown>
export const VehicleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Vehicle' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Vehicle' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loadingCapacity' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'loadingVolume' },
            name: { kind: 'Name', value: 'netVolume' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VehicleFragment, unknown>
export const ViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'View' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PersoPlanView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'query' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewFragment, unknown>
export const ChangePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'oldPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oldPassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'oldPassword' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newPassword' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const CreateExpenseReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateExpenseReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'expenseTypeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'details' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PaymentType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentInfo' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'files' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'FileUpload' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'route' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'kilometer' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publicTransportation' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dayOfCatering' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'location' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'servedPersons' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reason' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceAmount' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tipAmount' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'totalAmount' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createExpenseReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'expenseTypeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'expenseTypeId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'date' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'details' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'details' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentInfo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentInfo' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'files' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'files' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'route' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'route' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'kilometer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'kilometer' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publicTransportation' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'publicTransportation' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dayOfCatering' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dayOfCatering' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'location' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'location' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'servedPersons' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'servedPersons' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reason' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceAmount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceAmount' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tipAmount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tipAmount' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'totalAmount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'totalAmount' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateExpenseReportMutation,
  CreateExpenseReportMutationVariables
>
export const CreateExpenseReportStaticDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateExpenseReportStaticData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateExpenseReportStaticDataQuery,
  CreateExpenseReportStaticDataQueryVariables
>
export const CreateExternalServiceRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateExternalServiceRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerOfClient' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'location' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobFunction' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createExternalServiceRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'client' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerOfClient' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerOfClient' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'location' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'location' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobFunction' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobFunction' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'note' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateExternalServiceRequestMutation,
  CreateExternalServiceRequestMutationVariables
>
export const CreateLeaveRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLeaveRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LeaveRequestType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLeaveRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'note' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLeaveRequestMutation,
  CreateLeaveRequestMutationVariables
>
export const DropdownAddressDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DropdownAddressData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DropdownAddressDataQuery,
  DropdownAddressDataQueryVariables
>
export const DropdownAddressSearchQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DropdownAddressSearchQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchterm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'onlyEmployees' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchterm' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyEmployees' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'onlyEmployees' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DropdownAddressSearchQueryQuery,
  DropdownAddressSearchQueryQueryVariables
>
export const DropdownJobDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DropdownJobData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DropdownJobDataQuery,
  DropdownJobDataQueryVariables
>
export const DropdownJobSearchQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DropdownJobSearchQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchterm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchterm' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DropdownJobSearchQueryQuery,
  DropdownJobSearchQueryQueryVariables
>
export const ApiVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ApiVersion' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentRelease' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'api' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApiVersionQuery, ApiVersionQueryVariables>
export const ReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Reports' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'href' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportsQuery, ReportsQueryVariables>
export const AbortExternalServiceRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AbortExternalServiceRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateExternalServiceRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'EnumValue', value: 'abort' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AbortExternalServiceRequestMutation,
  AbortExternalServiceRequestMutationVariables
>
export const AbortLeaveRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AbortLeaveRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLeaveRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'EnumValue', value: 'abort' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AbortLeaveRequestMutation,
  AbortLeaveRequestMutationVariables
>
export const MyRequestsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyRequestsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalServiceRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyRequestsListQuery, MyRequestsListQueryVariables>
export const NoteAppointmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NoteAppointment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'appointment' },
            name: { kind: 'Name', value: 'jobAppointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NoteAppointmentQuery,
  NoteAppointmentQueryVariables
>
export const NoteJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NoteJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NoteJobQuery, NoteJobQueryVariables>
export const NoteProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NoteProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NoteProjectQuery, NoteProjectQueryVariables>
export const UpdateNoteAppointmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNoteAppointment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateJobAppointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'note' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNoteAppointmentMutation,
  UpdateNoteAppointmentMutationVariables
>
export const UpdateNoteJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNoteJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'note' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNoteJobMutation,
  UpdateNoteJobMutationVariables
>
export const UpdateNoteProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNoteProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'note' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNoteProjectMutation,
  UpdateNoteProjectMutationVariables
>
export const ExpenseReportDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExpenseReportDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'details' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'route' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kilometer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicTransportation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dayOfCatering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'host' } },
          { kind: 'Field', name: { kind: 'Name', value: 'servedPersons' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tipAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalAmount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExpenseReportDetailsQuery,
  ExpenseReportDetailsQueryVariables
>
export const ExternalServiceRequestDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExternalServiceRequestDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalServiceRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExternalServiceRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExternalServiceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExternalServiceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerOfClient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobFunction' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExternalServiceRequestDetailsQuery,
  ExternalServiceRequestDetailsQueryVariables
>
export const LeaveRequestDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeaveRequestDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LeaveRequest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalType' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeaveRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LeaveRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousState' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LeaveRequestDetailsQuery,
  LeaveRequestDetailsQueryVariables
>
export const ApproveExternalServiceRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveExternalServiceRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateExternalServiceRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'EnumValue', value: 'accept' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'comment' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExternalServiceRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExternalServiceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExternalServiceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerOfClient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobFunction' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApproveExternalServiceRequestMutation,
  ApproveExternalServiceRequestMutationVariables
>
export const ApproveLeaveRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveLeaveRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LeaveRequestType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLeaveRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'EnumValue', value: 'accept' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'comment' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LeaveRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeaveRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LeaveRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousState' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApproveLeaveRequestMutation,
  ApproveLeaveRequestMutationVariables
>
export const RejectExternalServiceRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectExternalServiceRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateExternalServiceRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'EnumValue', value: 'decline' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'comment' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExternalServiceRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExternalServiceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExternalServiceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerOfClient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobFunction' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RejectExternalServiceRequestMutation,
  RejectExternalServiceRequestMutationVariables
>
export const RejectLeaveRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectLeaveRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLeaveRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'EnumValue', value: 'decline' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'comment' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LeaveRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeaveRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LeaveRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousState' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RejectLeaveRequestMutation,
  RejectLeaveRequestMutationVariables
>
export const RequestArchiveListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RequestArchiveList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'OptionalStartEndFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalServiceRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'surname' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestArchiveListQuery,
  RequestArchiveListQueryVariables
>
export const RequestOverviewListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RequestOverviewList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveRequestsToRevise' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalServiceRequestsToRevise' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestOverviewListQuery,
  RequestOverviewListQueryVariables
>
export const ResourceRequestOverviewFilterJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceRequestOverviewFilterJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceRequestOverviewFilterJobQuery,
  ResourceRequestOverviewFilterJobQueryVariables
>
export const ResourceRequestOverviewFilterJobSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceRequestOverviewFilterJobSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchTerm' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceRequestOverviewFilterJobSearchQuery,
  ResourceRequestOverviewFilterJobSearchQueryVariables
>
export const ResourceRequestOverviewFilterProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceRequestOverviewFilterProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceRequestOverviewFilterProjectQuery,
  ResourceRequestOverviewFilterProjectQueryVariables
>
export const ResourceRequestOverviewFilterProjectSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'ResourceRequestOverviewFilterProjectSearch',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchTerm' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceRequestOverviewFilterProjectSearchQuery,
  ResourceRequestOverviewFilterProjectSearchQueryVariables
>
export const ResourceRequestOverviewFilterStaticDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceRequestOverviewFilterStatic' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'easyJobUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeDeactivated' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceRequestOverviewFilterStaticQuery,
  ResourceRequestOverviewFilterStaticQueryVariables
>
export const ResourceRequestOverviewListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceRequestOverviewList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'arrangedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'arrangedBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobAppointments' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filter' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'startDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'startDate' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'endDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'endDate' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'resourceFunctionAllocations',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'startDate',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'startDate',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'endDate',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'endDate',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'resourceFunction',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'caption',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'abbreviation',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'resourceAllocations',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'address',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'Address',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'resourceRequest',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'state',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'feedback',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'userState',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'updated',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'updatedBy',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'Address',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'created',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'createdBy',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'Address',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceRequestOverviewListQuery,
  ResourceRequestOverviewListQueryVariables
>
export const FullAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FullAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Location' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactDetails' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Location' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phonePrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wwwAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FullAddressQuery, FullAddressQueryVariables>
export const ActionbarAddressFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActionbarAddressFilter' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActionbarAddressFilterQuery,
  ActionbarAddressFilterQueryVariables
>
export const ActionbarAddressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActionbarAddresses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchterm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceFunctionIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'qualificationIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressResourceTypeIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'itemsAddress' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchterm' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'resourceFunctionIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resourceFunctionIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'qualificationIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'qualificationIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressResourceTypeIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'addressResourceTypeIds' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '250' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunction2Address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceFunction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratingQuality' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratingSelf' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceQualifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratingSelf' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratingQuality' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualification' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActionbarAddressesQuery,
  ActionbarAddressesQueryVariables
>
export const ActionbarVehicleFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActionbarVehicleFilter' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActionbarVehicleFilterQuery,
  ActionbarVehicleFilterQueryVariables
>
export const ActionbarVehiclesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActionbarVehicles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchterm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceFunctionIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'itemsVehicle' },
            name: { kind: 'Name', value: 'vehicles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchterm' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyProMusik' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'resourceFunctionIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resourceFunctionIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Vehicle' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Vehicle' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Vehicle' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loadingCapacity' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'loadingVolume' },
            name: { kind: 'Name', value: 'netVolume' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActionbarVehiclesQuery,
  ActionbarVehiclesQueryVariables
>
export const AppointmentDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppointmentDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobAppointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobAppointmentType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobState' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobAppointments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'projectState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceFunction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceAllocations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'resourceAllocationState',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'displayName',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vehicle' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'resourceAllocationDriver',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'displayName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'surname',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'company',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'actionIds' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'IntValue', value: '22' }],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeActionIds' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AppointmentDetailsQuery,
  AppointmentDetailsQueryVariables
>
export const DataModalEventDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataModalEventDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'event' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'importance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataModalEventDetailsQuery,
  DataModalEventDetailsQueryVariables
>
export const DataModalJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataModalJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobService' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Location' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContactDetails' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Contact' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryAddressNote' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'arrangedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'surname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phoneMobile' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Address' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Location' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ContactDetails' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectState' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceFunction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'actionIds' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'IntValue', value: '22' }],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeActionIds' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Location' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phonePrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wwwAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataModalJobQuery, DataModalJobQueryVariables>
export const DataModalProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataModalProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Location' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContactDetails' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Contact' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'arrangedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priority' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isJobLayerHidden' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobState' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Location' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneMobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phonePrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wwwAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataModalProjectQuery,
  DataModalProjectQueryVariables
>
export const ProjectSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSortedManually' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isJobLayerHidden' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectSettingsQuery,
  ProjectSettingsQueryVariables
>
export const UpdateProjectSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProjectSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collectAllJobsOfProject' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setProjectSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isJobLayerHidden' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'collectAllJobsOfProject' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isJobLayerHidden' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectSettingsMutation,
  UpdateProjectSettingsMutationVariables
>
export const ResourceSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resource' },
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timesScheduled' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceSettingsQuery,
  ResourceSettingsQueryVariables
>
export const UpdateResourceSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateResourceSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timesScheduled' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResourceFunctionAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timesScheduled' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timesScheduled' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Resource' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfResourceAllocations' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timesScheduled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobAppointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateResourceSettingsMutation,
  UpdateResourceSettingsMutationVariables
>
export const DataModalResourceDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataModalResourceDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resource' },
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Resource' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobAppointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationState',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'surname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicle' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationDriver',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'displayName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'actionIds' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'IntValue', value: '22' }],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeActionIds' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfResourceAllocations' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timesScheduled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobAppointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataModalResourceDetailsQuery,
  DataModalResourceDetailsQueryVariables
>
export const DataModalAppointmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataModalAppointment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobAppointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobAppointmentType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobState' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'street' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobAppointments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'projectState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceFunction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'abbreviation' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resourceType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceAllocations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'resourceAllocationState',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'displayName',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vehicle' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'resourceAllocationDriver',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'displayName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'surname',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'company',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vehicle' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'caption',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataModalAppointmentQuery,
  DataModalAppointmentQueryVariables
>
export const DataModalResourceAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataModalResourceAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'travel' },
                  name: { kind: 'Name', value: 'custom1' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'hotel' },
                  name: { kind: 'Name', value: 'custom2' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webfleetState' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneCompany' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneMobile' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phonePrivate' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isWebfleetVehicle' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationDriver' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'surname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceFunction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobAppointment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'number' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'projectState',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'caption',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateOfExpire' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceAllocationIds' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feedback' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userState' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'actionIds' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'IntValue', value: '22' }],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeActionIds' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataModalResourceAllocationQuery,
  DataModalResourceAllocationQueryVariables
>
export const DataModalResourceRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataModalResourceRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceAllocationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'lastName' },
                        name: { kind: 'Name', value: 'surname' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'lastName' },
                        name: { kind: 'Name', value: 'surname' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateOfExpire' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationIds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Address' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationState',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceFunctionAllocation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resourceFunction' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'job' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'number' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobState' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'caption',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'project' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'number',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'caption',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'projectState',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'caption',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Address' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timestamp' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previousUserState' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newUserState' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previousProMusikState' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newProMusikState' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableFrom' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feedback' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userState' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableFrom' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataModalResourceRequestQuery,
  DataModalResourceRequestQueryVariables
>
export const AppointmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Appointment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceTypeIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceTypeIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceFunctionIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceFunctionIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'appointment' },
            name: { kind: 'Name', value: 'jobAppointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Appointment' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'resources' },
                  name: { kind: 'Name', value: 'resourceFunctionAllocations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resourceTypeIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'resourceTypeIds' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'excludeResourceTypeIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeResourceTypeIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'resourceFunctionIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'resourceFunctionIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'excludeResourceFunctionIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeResourceFunctionIds',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceFunction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'abbreviation' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortOrder' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAppointment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentQuery, AppointmentQueryVariables>
export const CalendarCacheDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CalendarCache' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDateAlternativeStartOfDay' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeClientIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectLeaderIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeProjectLeaderIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeProjectIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectStateIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeProjectStateIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectTypeIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeProjectTypeIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobLeaderIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeJobLeaderIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobStateIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeJobStateIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobServiceIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeJobServiceIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceTypeIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceTypeIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceFunctionIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceFunctionIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceAllocationIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationStateIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceAllocationStateIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showOnlyWithResources' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showOnlyActive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skipFreelancers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skipEmployees' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skipVehicles' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skipOperations' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freelancerCalendar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'skip' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'skipFreelancers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceTimelineAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employeeCalendar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'skip' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'skipEmployees' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceTimelineAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicleCalendar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'skip' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'skipVehicles' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceTimelineVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'customerAddressIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'excludeCustomerAddressIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'excludeClientIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectArrangedByIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectLeaderIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'excludeProjectArrangedByIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeProjectLeaderIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeProjectIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'excludeProjectIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectStateIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectStateIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeProjectStateIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'excludeProjectStateIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectTypeIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectTypeIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeProjectTypeIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'excludeProjectTypeIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyWithResources' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'showOnlyWithResources' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeInactive' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'showOnlyActive' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'arrangedByIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'jobLeaderIds' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'excludeArrangedByIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeJobLeaderIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'jobStateIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'jobStateIds' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeJobStateIds' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeJobStateIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'jobServiceIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'jobServiceIds' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'excludeJobServiceIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeJobServiceIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'onlyWithResources' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'showOnlyWithResources',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeInactive' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'showOnlyActive' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Job' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'appointments' },
                        name: { kind: 'Name', value: 'jobAppointments' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filter' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'startDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'startDateAlternativeStartOfDay',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'endDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'endDate' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Appointment' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'resourceFunctionAllocations',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'startDate',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'startDate',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'endDate',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'endDate',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'resourceTypeIds',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'resourceTypeIds',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'excludeResourceTypeIds',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'excludeResourceTypeIds',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'resourceFunctionIds',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'resourceFunctionIds',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'excludeResourceFunctionIds',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'excludeResourceFunctionIds',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ResourceWithAllocations',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: {
                          kind: 'Name',
                          value: 'resourcesWithoutAppointment',
                        },
                        name: {
                          kind: 'Name',
                          value: 'resourceFunctionAllocations',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filter' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'startDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'startDateAlternativeStartOfDay',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'endDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'endDate' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resourceTypeIds',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'resourceTypeIds',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'excludeResourceTypeIds',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'excludeResourceTypeIds',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resourceFunctionIds',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'resourceFunctionIds',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'excludeResourceFunctionIds',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'excludeResourceFunctionIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ResourceWithAllocations',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTypeServices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyWithResources' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'showOnlyWithResources' },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'skip' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'skipOperations' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTypeService' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Resource' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyWithResources' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'showOnlyWithResources' },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'skip' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'skipOperations' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PurchaseOrder' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Resource' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relatedAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfResourceAllocations' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timesScheduled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobAppointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceTimelineAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AddressCalendarItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceAllocation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Event' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceState' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CalendarEvent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarEvent' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceTimelineVehicle' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VehicleCalendarItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceAllocation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceState' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSortedManually' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isJobLayerHidden' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Job' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Job' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isJobLayerHidden' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isSortedManually' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAppointment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceWithAllocations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resource' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resourceAllocationIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeAddressIds' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeResourceAllocationIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'resourceAllocationStateIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationStateIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'excludeResourceAllocationStateIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeResourceAllocationStateIds',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTypeService' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTypeService' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PurchaseOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PurchaseOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CalendarCacheQuery, CalendarCacheQueryVariables>
export const ResourceDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceAllocationIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationStateIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceAllocationStateIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lazyloadedResourceDetails' },
            name: { kind: 'Name', value: 'resourceFunctionAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'addressIds' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'resourceAllocationIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeAddressIds' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeResourceAllocationIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'resourceAllocationStateIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'resourceAllocationStateIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'excludeResourceAllocationStateIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeResourceAllocationStateIds',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dutyOrder' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'overlappingAllocationIds',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceDetailsQuery,
  ResourceDetailsQueryVariables
>
export const BirthdaysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Birthdays' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthdays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BirthdaysQuery, BirthdaysQueryVariables>
export const CalendarEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CalendarEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'calendarEvents' },
            name: { kind: 'Name', value: 'events' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'importance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CalendarEventsQuery, CalendarEventsQueryVariables>
export const GeneralEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GeneralEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicHolidays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAllDay' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'easyJobEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EasyJobEvent' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EasyJobEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EasyJobEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GeneralEventsQuery, GeneralEventsQueryVariables>
export const JobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Job' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDateAlternativeStartOfDay' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceTypeIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceTypeIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceFunctionIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceFunctionIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Job' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'appointments' },
                  name: { kind: 'Name', value: 'jobAppointments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startDate' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'startDateAlternativeStartOfDay',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'resourcesWithoutAppointment' },
                  name: { kind: 'Name', value: 'resourceFunctionAllocations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startDate' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'startDateAlternativeStartOfDay',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resourceTypeIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'resourceTypeIds' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'excludeResourceTypeIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeResourceTypeIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'resourceFunctionIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'resourceFunctionIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'excludeResourceFunctionIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeResourceFunctionIds',
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceFunction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortOrder' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Job' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Job' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isJobLayerHidden' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isSortedManually' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobQuery, JobQueryVariables>
export const UpdateAlternativeColorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAlternativeColor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveGlobalSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAlternativeColorMutation,
  UpdateAlternativeColorMutationVariables
>
export const AddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Address' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressQuery, AddressQueryVariables>
export const ResourcesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Resources' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resources' },
            name: { kind: 'Name', value: 'resourceFunctionAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobAppointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abbreviation' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourcesQuery, ResourcesQueryVariables>
export const VehicleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Vehicle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Vehicle' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Vehicle' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Vehicle' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loadingCapacity' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'loadingVolume' },
            name: { kind: 'Name', value: 'netVolume' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VehicleQuery, VehicleQueryVariables>
export const CreateResourceRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createResourceRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateOfExpire' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subject' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResourceRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceAllocationIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateOfExpire' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateOfExpire' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subject' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateResourceRequestMutation,
  CreateResourceRequestMutationVariables
>
export const ProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Project' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDateAlternativeStartOfDay' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobLeaderIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeJobLeaderIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobStateIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeJobStateIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobServiceIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeJobServiceIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceTypeIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceTypeIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceFunctionIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceFunctionIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showOnlyWithResources' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showOnlyActive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'arrangedByIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'jobLeaderIds' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'excludeArrangedByIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeJobLeaderIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'jobStateIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'jobStateIds' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeJobStateIds' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeJobStateIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'jobServiceIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'jobServiceIds' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'excludeJobServiceIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeJobServiceIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'onlyWithResources' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'showOnlyWithResources',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeInactive' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'showOnlyActive' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Job' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'appointments' },
                        name: { kind: 'Name', value: 'jobAppointments' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filter' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'startDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'startDateAlternativeStartOfDay',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'endDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'endDate' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: {
                          kind: 'Name',
                          value: 'resourcesWithoutAppointment',
                        },
                        name: {
                          kind: 'Name',
                          value: 'resourceFunctionAllocations',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filter' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'startDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'startDateAlternativeStartOfDay',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'endDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'endDate' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resourceTypeIds',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'resourceTypeIds',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'excludeResourceTypeIds',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'excludeResourceTypeIds',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resourceFunctionIds',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'resourceFunctionIds',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'excludeResourceFunctionIds',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'excludeResourceFunctionIds',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resourceFunction' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sortOrder' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSortedManually' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isJobLayerHidden' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Job' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Job' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isJobLayerHidden' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isSortedManually' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectQuery, ProjectQueryVariables>
export const PurchaseOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PurchaseOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceAllocationIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationStateIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceAllocationStateIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PurchaseOrder' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'resources' },
                  name: { kind: 'Name', value: 'resourceFunctionAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ResourceWithAllocations',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfResourceAllocations' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timesScheduled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobAppointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PurchaseOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PurchaseOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceWithAllocations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resource' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resourceAllocationIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeAddressIds' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeResourceAllocationIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'resourceAllocationStateIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationStateIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'excludeResourceAllocationStateIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeResourceAllocationStateIds',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PurchaseOrderQuery, PurchaseOrderQueryVariables>
export const AllConsideredOfAppointmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllConsideredOfAppointment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appointmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resources' },
            name: { kind: 'Name', value: 'resourceFunctionAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'jobAppointmentIds' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'appointmentId' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'resourceAllocationStateIds',
                            },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'IntValue', value: '4' }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationState',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllConsideredOfAppointmentQuery,
  AllConsideredOfAppointmentQueryVariables
>
export const AllConsideredOfJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllConsideredOfJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resources' },
            name: { kind: 'Name', value: 'resourceFunctionAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'jobIds' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'jobId' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'resourceAllocationStateIds',
                            },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'IntValue', value: '4' }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationState',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllConsideredOfJobQuery,
  AllConsideredOfJobQueryVariables
>
export const AllConsideredOfProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllConsideredOfProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resources' },
            name: { kind: 'Name', value: 'resourceFunctionAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'jobIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'resourceAllocationStateIds',
                            },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'IntValue', value: '4' }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationState',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllConsideredOfProjectQuery,
  AllConsideredOfProjectQueryVariables
>
export const AllJobIdsOfProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllJobIdsOfProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllJobIdsOfProjectQuery,
  AllJobIdsOfProjectQueryVariables
>
export const CreateResourceAllocationAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createResourceAllocationAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationStateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notice' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'travel' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hotel' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAddressAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceFunctionAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationStateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceAllocationStateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notice' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notice' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'custom1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'travel' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'custom2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hotel' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateResourceAllocationAddressMutation,
  CreateResourceAllocationAddressMutationVariables
>
export const CreateResourceAllocationVehicleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createResourceAllocationVehicle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vehicleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationStateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notice' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createVehicleAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceFunctionAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vehicleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vehicleId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationStateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceAllocationStateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notice' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notice' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateResourceAllocationVehicleMutation,
  CreateResourceAllocationVehicleMutationVariables
>
export const ResourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Resource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceAllocationIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationStateIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceAllocationStateIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resource' },
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceWithAllocations' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfResourceAllocations' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timesScheduled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobAppointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceWithAllocations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resource' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resourceAllocationIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeAddressIds' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeResourceAllocationIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'resourceAllocationStateIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationStateIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'excludeResourceAllocationStateIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeResourceAllocationStateIds',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceQuery, ResourceQueryVariables>
export const DeleteConnectionToDriverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteConnectionToDriver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vehicleAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAllocationDriver' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vehicleAllocationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteConnectionToDriverMutation,
  DeleteConnectionToDriverMutationVariables
>
export const DeleteConnectionToVehicleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteConnectionToVehicle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'driverAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAllocationDriver' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'driverAllocationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteConnectionToVehicleMutation,
  DeleteConnectionToVehicleMutationVariables
>
export const DeleteResourceAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteResourceAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceAllocationId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteResourceAllocationMutation,
  DeleteResourceAllocationMutationVariables
>
export const DriverAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DriverAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'driverAllocation' },
            name: { kind: 'Name', value: 'resourceAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DriverAllocationQuery,
  DriverAllocationQueryVariables
>
export const ResourceAllocationDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceAllocationDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resourceAllocationDetails' },
            name: { kind: 'Name', value: 'resourceAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dutyOrder' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'overlappingAllocationIds',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceAllocationDetailsQuery,
  ResourceAllocationDetailsQueryVariables
>
export const ResourceAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceAllocationQuery,
  ResourceAllocationQueryVariables
>
export const ResourceAllocationStatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceAllocationStates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationStates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceAllocationStatesQuery,
  ResourceAllocationStatesQueryVariables
>
export const UpdateConnectionToDriverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateConnectionToDriver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vehicleAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'driverAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setAllocationDriver' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vehicleAllocationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationDriverId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'driverAllocationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateConnectionToDriverMutation,
  UpdateConnectionToDriverMutationVariables
>
export const UpdateConnectionToVehicleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateConnectionToVehicle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vehicleAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'driverAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setAllocationDriver' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vehicleAllocationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationDriverId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'driverAllocationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateConnectionToVehicleMutation,
  UpdateConnectionToVehicleMutationVariables
>
export const UpdateResourceAllocationAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateResourceAllocationAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stateId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notice' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'travel' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hotel' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAddressAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceAllocationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationStateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'stateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notice' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notice' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'custom1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'travel' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'custom2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hotel' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceRequestMessage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateResourceAllocationAddressMutation,
  UpdateResourceAllocationAddressMutationVariables
>
export const UpdateResourceAllocationVehicleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateResourceAllocationVehicle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vehicleId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stateId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notice' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateVehicleAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceAllocationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vehicleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vehicleId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationStateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'stateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notice' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notice' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateResourceAllocationVehicleMutation,
  UpdateResourceAllocationVehicleMutationVariables
>
export const UpdateResourceRequestStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateResourceRequestStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceRequestState' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResourceRequestState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationIds' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'resourceAllocationId' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateResourceRequestStatusMutation,
  UpdateResourceRequestStatusMutationVariables
>
export const VehicleAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VehicleAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'vehicleAllocation' },
            name: { kind: 'Name', value: 'resourceAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VehicleAllocationQuery,
  VehicleAllocationQueryVariables
>
export const FreelancerConflictsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreelancerConflicts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freelancerCalendar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ResourceAllocation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extra' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'notice' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationState',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceFunctionAllocation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'job' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobState' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'caption',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ResourceState' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ResourceState' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FreelancerConflictsQuery,
  FreelancerConflictsQueryVariables
>
export const ResourceDayAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceDayAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeInactive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agenda' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'date' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'surname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'events' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEvents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeInactive' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'excludeInactive' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extra' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'notice' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dutyOrder' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'overlappingAllocationIds',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationState',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceFunctionAllocation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resourceFunction' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'abbreviation',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'job' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'number' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobState' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'caption',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobAppointment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceStates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ResourceState' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locked' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceDayAddressQuery,
  ResourceDayAddressQueryVariables
>
export const ResourceDayVehicleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceDayVehicle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeInactive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agenda' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vehicleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'date' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeInactive' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'excludeInactive' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extra' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'notice' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dutyOrder' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'overlappingAllocationIds',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationState',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'resourceFunctionAllocation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resourceFunction' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'abbreviation',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'job' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'number' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobState' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'caption',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobAppointment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceStates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ResourceState' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceDayVehicleQuery,
  ResourceDayVehicleQueryVariables
>
export const AddressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Addresses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'addressIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressesQuery, AddressesQueryVariables>
export const VehiclesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Vehicles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vehicleIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'vehicleIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vehicleIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Vehicle' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Vehicle' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Vehicle' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loadingCapacity' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'loadingVolume' },
            name: { kind: 'Name', value: 'netVolume' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VehiclesQuery, VehiclesQueryVariables>
export const CreateResourceStateAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateResourceStateAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceStateTypeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResourceState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceStateTypeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceStateTypeId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'note' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateResourceStateAddressMutation,
  CreateResourceStateAddressMutationVariables
>
export const CreateResourceStateVehicleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateResourceStateVehicle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vehicleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceStateTypeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResourceState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vehicleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vehicleId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceStateTypeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceStateTypeId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'note' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateResourceStateVehicleMutation,
  CreateResourceStateVehicleMutationVariables
>
export const DeleteResourceStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteResourceState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceStateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteResourceState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceStateId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteResourceStateMutation,
  DeleteResourceStateMutationVariables
>
export const FreelancersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Freelancers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchterm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'freelancerSearchResults' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchterm' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressResourceTypeIds' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'IntValue', value: '2' },
                          { kind: 'IntValue', value: '3' },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreelancersQuery, FreelancersQueryVariables>
export const ResourceStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceStateQuery, ResourceStateQueryVariables>
export const SelectedFreelancerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectedFreelancer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'selectedFreelancer' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressIds' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'id' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressResourceTypeIds' },
                      value: { kind: 'IntValue', value: '2' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '250' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectedFreelancerQuery,
  SelectedFreelancerQueryVariables
>
export const UpdateResourceStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateResourceState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceStateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceStateTypeId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResourceState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceStateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceStateTypeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceStateTypeId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'note' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateResourceStateMutation,
  UpdateResourceStateMutationVariables
>
export const ResourceTimelineAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceTimelineAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resourceTimeline' },
            name: { kind: 'Name', value: 'employeeCalendar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceTimelineAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relatedAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceTimelineAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AddressCalendarItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceAllocation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Event' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceState' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CalendarEvent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarEvent' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceTimelineAddressQuery,
  ResourceTimelineAddressQueryVariables
>
export const ResourceTimelineFreelancerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceTimelineFreelancer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resourceTimeline' },
            name: { kind: 'Name', value: 'freelancerCalendar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceTimelineAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relatedAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceTimelineAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AddressCalendarItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceAllocation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Event' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceState' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CalendarEvent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarEvent' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceTimelineFreelancerQuery,
  ResourceTimelineFreelancerQueryVariables
>
export const ResourceTimelineVehicleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceTimelineVehicle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resourceTimeline' },
            name: { kind: 'Name', value: 'vehicleCalendar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceTimelineVehicle' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceTimelineVehicle' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VehicleCalendarItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceAllocation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceState' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceTimelineVehicleQuery,
  ResourceTimelineVehicleQueryVariables
>
export const AddResourceRequestCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddResourceRequestComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addResourceRequestComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceAllocationIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddResourceRequestCommentMutation,
  AddResourceRequestCommentMutationVariables
>
export const MessageRecipientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageRecipients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'resourceAllocationIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resourceAllocationIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageRecipientsQuery,
  MessageRecipientsQueryVariables
>
export const DeleteInWebfleetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteInWebfleet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWebfleetOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceAllocationId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteInWebfleetMutation,
  DeleteInWebfleetMutationVariables
>
export const SendToWebfleetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendToWebfleet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWebfleetOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceAllocationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notice' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'note' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendToWebfleetMutation,
  SendToWebfleetMutationVariables
>
export const SendToWebfleetPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SendToWebfleetPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webfleetOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceAllocationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resourceAllocationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderDateTime' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'orderText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactPerson' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactPhoneNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Location' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Location' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendToWebfleetPreviewQuery,
  SendToWebfleetPreviewQueryVariables
>
export const ViewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Views' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'views' },
            name: { kind: 'Name', value: 'persoPlanViews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public' } },
                { kind: 'Field', name: { kind: 'Name', value: 'query' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewsQuery, ViewsQueryVariables>
export const SidebarCacheDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SidebarCache' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeClientIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectLeaderIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeProjectLeaderIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeProjectIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectStateIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeProjectStateIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectTypeIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeProjectTypeIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobLeaderIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeJobLeaderIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobStateIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeJobStateIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobServiceIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeJobServiceIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showOnlyWithResources' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showOnlyActive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skipFreelancers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freelancerCalendar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'skip' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'skipFreelancers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceTimelineAddress' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'customerAddressIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'excludeCustomerAddressIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'excludeClientIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectArrangedByIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectLeaderIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'excludeProjectArrangedByIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeProjectLeaderIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeProjectIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'excludeProjectIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectStateIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectStateIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeProjectStateIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'excludeProjectStateIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectTypeIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectTypeIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeProjectTypeIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'excludeProjectTypeIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyWithResources' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'showOnlyWithResources' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeInactive' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'showOnlyActive' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'arrangedByIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'jobLeaderIds' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'excludeArrangedByIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeJobLeaderIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'jobStateIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'jobStateIds' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeJobStateIds' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeJobStateIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'jobServiceIds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'jobServiceIds' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'excludeJobServiceIds',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'excludeJobServiceIds',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'onlyWithResources' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'showOnlyWithResources',
                              },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'excludeInactive' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'showOnlyActive' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Job' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relatedAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceTimelineAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AddressCalendarItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceAllocation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Event' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceState' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CalendarEvent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarEvent' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSortedManually' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isJobLayerHidden' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Job' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Job' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isJobLayerHidden' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isSortedManually' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SidebarCacheQuery, SidebarCacheQueryVariables>
export const SidebarFreelancersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SidebarFreelancers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freelancerCalendar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceTimelineAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relatedAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceState' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceTimelineAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AddressCalendarItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceAllocation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extra' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceAllocationState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobState' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Event' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResourceState' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CalendarEvent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarEvent' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SidebarFreelancersQuery,
  SidebarFreelancersQueryVariables
>
export const SidebarJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SidebarJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Job' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Job' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Job' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isJobLayerHidden' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isSortedManually' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SidebarJobQuery, SidebarJobQueryVariables>
export const SidebarOperationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SidebarOperations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showOnlyWithResources' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTypeServices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyWithResources' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'showOnlyWithResources' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTypeService' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PurchaseOrder' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTypeService' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTypeService' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PurchaseOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PurchaseOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SidebarOperationsQuery,
  SidebarOperationsQueryVariables
>
export const SidebarProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SidebarProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSortedManually' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isJobLayerHidden' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SidebarProjectQuery, SidebarProjectQueryVariables>
export const SidebarProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SidebarProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfMissingResources' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSortedManually' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isJobLayerHidden' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SidebarProjectsQuery,
  SidebarProjectsQueryVariables
>
export const SidebarPurchaseOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SidebarPurchaseOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PurchaseOrder' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PurchaseOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PurchaseOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SidebarPurchaseOrderQuery,
  SidebarPurchaseOrderQueryVariables
>
export const SidebarStockTypeServiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SidebarStockTypeService' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTypeService' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTypeService' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTypeService' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTypeService' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SidebarStockTypeServiceQuery,
  SidebarStockTypeServiceQueryVariables
>
export const StockTypeServiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StockTypeService' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceAllocationIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceAllocationStateIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludeResourceAllocationStateIds' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTypeService' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTypeService' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'resources' },
                  name: { kind: 'Name', value: 'resourceFunctionAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ResourceWithAllocations',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Resource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfResourceAllocations' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timesScheduled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobAppointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTypeService' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTypeService' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceWithAllocations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceFunctionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resource' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'resourceAllocationIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeAddressIds' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeResourceAllocationIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'resourceAllocationStateIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'resourceAllocationStateIds',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'excludeResourceAllocationStateIds',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'excludeResourceAllocationStateIds',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StockTypeServiceQuery,
  StockTypeServiceQueryVariables
>
export const CreateViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'public' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPersoPlanView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'title' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'public' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public' } },
                { kind: 'Field', name: { kind: 'Name', value: 'query' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateViewMutation, CreateViewMutationVariables>
export const DeleteViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePersoPlanView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteViewMutation, DeleteViewMutationVariables>
export const UpdateViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'public' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePersoPlanView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'title' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'public' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'View' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'View' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PersoPlanView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'query' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateViewMutation, UpdateViewMutationVariables>
export const SearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Search' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchterm' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'searchResults' },
            name: { kind: 'Name', value: 'jobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchterm' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setting' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isJobLayerHidden' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'arrangedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchQuery, SearchQueryVariables>
export const ViewEditorClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ViewEditorClients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'clients' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressTypeIds' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'IntValue', value: '1' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ViewEditorClientsQuery,
  ViewEditorClientsQueryVariables
>
export const ViewEditorClientsSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ViewEditorClientsSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchterm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'clientsSearch' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressTypeIds' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'IntValue', value: '1' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchterm' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ViewEditorClientsSearchQuery,
  ViewEditorClientsSearchQueryVariables
>
export const ViewEditorProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ViewEditorProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ViewEditorProjectsQuery,
  ViewEditorProjectsQueryVariables
>
export const ViewEditorProjectsSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ViewEditorProjectsSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchterm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'projectsSearch' },
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchterm' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ViewEditorProjectsSearchQuery,
  ViewEditorProjectsSearchQueryVariables
>
export const ViewEditorResourceAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ViewEditorResourceAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resourceAllocations' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressResourceTypeIds' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'IntValue', value: '1' },
                          { kind: 'IntValue', value: '2' },
                          { kind: 'IntValue', value: '3' },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ViewEditorResourceAllocationsQuery,
  ViewEditorResourceAllocationsQueryVariables
>
export const ViewEditorResourceAllocationsSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ViewEditorResourceAllocationsSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchterm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'resourceAllocationsSearch' },
            name: { kind: 'Name', value: 'addresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'addressResourceTypeIds' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'IntValue', value: '1' },
                          { kind: 'IntValue', value: '2' },
                          { kind: 'IntValue', value: '3' },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchterm' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ViewEditorResourceAllocationsSearchQuery,
  ViewEditorResourceAllocationsSearchQueryVariables
>
export const ViewEditorStaticDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ViewEditorStatic' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employeeGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'easyJobUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeDeactivated' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectStates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobStates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobServices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationStates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ViewEditorStaticQuery,
  ViewEditorStaticQueryVariables
>
export const AppSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'appSettings' },
            name: { kind: 'Name', value: 'appSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'coureHoursStart' },
                  name: { kind: 'Name', value: 'coreTimeStart' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'coreHoursEnd' },
                  name: { kind: 'Name', value: 'coreTimeEnd' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openingTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closingTime' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppSettingsQuery, AppSettingsQueryVariables>
export const CurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'user' },
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'easyJobRoles' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'changePasswordNextLogin' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserQuery, CurrentUserQueryVariables>
export const EmployeeGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmployeeGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employeeGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmployeeGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployeeGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployeeGroupsQuery, EmployeeGroupsQueryVariables>
export const GetSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSettingsQuery, GetSettingsQueryVariables>
export const ProMusikVehiclesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProMusikVehicles' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyProMusik' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Vehicle' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Vehicle' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Vehicle' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loadingCapacity' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'loadingVolume' },
            name: { kind: 'Name', value: 'netVolume' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProMusikVehiclesQuery,
  ProMusikVehiclesQueryVariables
>
export const ResourceAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'resourceAllocationIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ids' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceAllocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resourceFunction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobAppointment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobAppointments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caption' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'number' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequestFeedback' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFrom' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'availableTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userState' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'travel' },
            name: { kind: 'Name', value: 'custom1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hotel' },
            name: { kind: 'Name', value: 'custom2' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'notice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWebfleetVehicle' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctionAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationDriver' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceFunctionAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Address' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResourceRequestFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceAllocationsQuery,
  ResourceAllocationsQueryVariables
>
export const ResourceStateTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceStateTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceStateTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResourceStateTypesQuery,
  ResourceStateTypesQueryVariables
>
export const SaveSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'values' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'StringValue',
                  value: 'persoplan',
                  block: false,
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'values' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveSettingMutation, SaveSettingMutationVariables>
export const StaticDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StaticData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceAllocationStates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceFunctions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resourceType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'caption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StaticDataQuery, StaticDataQueryVariables>
export const UpdateProjectGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProjectGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setProjectGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupNumber' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectGroupMutation,
  UpdateProjectGroupMutationVariables
>
export const MarkAllNotificationsAsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkAllNotificationsAsRead' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markAllNotificationAsRead' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkAllNotificationsAsReadMutation,
  MarkAllNotificationsAsReadMutationVariables
>
export const NotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Notifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeRead' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeRead' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'includeRead' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ResourceRequestFeedbackStateUpdated',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stateBefore' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stateAfter' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'LeaveRequestCreated' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ExternalServiceRequestCreated',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'details' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ResourceRequest' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ResourceRequest' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'resourceAllocationIds',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'resourceAllocations',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'Address',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'resourceFunctionAllocation',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'job' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'startDate',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'endDate',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'project',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'number',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'caption',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'startDate',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'endDate',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'arrangedBy',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'firstName',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'surname',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'setting',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'isJobLayerHidden',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'LeaveRequest' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ExternalServiceRequest',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressResourceType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ResourceRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfExpire' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subject' },
            name: { kind: 'Name', value: 'title' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NotificationsQuery, NotificationsQueryVariables>
export const UpdateNotificationStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNotificationStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NotificationStatus' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotificationState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNotificationStatusMutation,
  UpdateNotificationStatusMutationVariables
>
