import {
  format,
  parse,
  isSameDay,
  startOfDay,
  endOfDay,
  getHours,
  getMinutes,
  subDays,
  set,
  isWithinInterval,
  isAfter,
  isValid,
  formatRelative,
  parseISO,
  addDays,
  isDate,
} from 'date-fns'

import localeBase from 'date-fns/locale/de'

import {
  FORMAT_DATE_SERVER,
  FORMAT_TIME_SERVER,
  FORMAT_DATETIME_SERVER,
  FORMAT_DATE_DEFAULT,
  FORMAT_DATETIME_SHORT,
  FORMAT_DATETIME_DEFAULT,
  FORMAT_DATE_SHORT,
  FORMAT_TIME_DEFAULT,
  END_OF_DAY_HOURS_FLOAT,
  END_OF_DAY_HOURS,
  END_OF_DAY_MINUTES,
} from '@/constants/persoplan'
import type { Nilable } from '@/types/misc'

/**
 * @todo: Use https://date-fns.org/v2.29.3/docs/intlFormatDistance instead
 */
const formatRelativeLocale = {
  lastWeek: `'letzten' eeee',' ${FORMAT_TIME_DEFAULT}`,
  yesterday: `'gestern,' ${FORMAT_TIME_DEFAULT}`,
  today: `'heute,' ${FORMAT_TIME_DEFAULT}`,
  tomorrow: `'morgen,' ${FORMAT_TIME_DEFAULT}`,
  nextWeek: `eeee',' ${FORMAT_TIME_DEFAULT}`,
  other: `P',' ${FORMAT_TIME_DEFAULT}`,
}

const de = {
  ...localeBase,
  // @ts-ignore Don't know how to properly import types here
  formatRelative: (token) => formatRelativeLocale[token],
}

export const dateFnsLocale = de

/**
 * Check if value is a valid date
 */
export function isValidDate(date: unknown): date is Date {
  return isDate(date) && isValid(date)
}

/**
 * Formats to server date string
 */
export function formatToServerDateString(
  date: Date,
  options?: {
    type: 'date' | 'time' | 'dateTime'
  }
) {
  const { type = 'dateTime' } = options ?? {}

  let formatString:
    | typeof FORMAT_DATE_SERVER
    | typeof FORMAT_TIME_SERVER
    | typeof FORMAT_DATETIME_SERVER
    | undefined = undefined

  if (type === 'date') formatString = FORMAT_DATE_SERVER
  if (type === 'time') formatString = FORMAT_TIME_SERVER
  if (type === 'dateTime') formatString = FORMAT_DATETIME_SERVER
  if (!formatString) throw new Error('format is undefined')

  // Convert to timestamp to remove timezone information
  const timestamp = date.valueOf()
  return format(timestamp, formatString)
}

export function formatWithLocale(
  date?: Date,
  formatString = FORMAT_DATE_DEFAULT
) {
  if (!isValidDate(date)) return

  // TODO: If outside of current year, add additional information for date formats

  return format(date, formatString, { locale: de })
}

function startEndDateFormat(
  isSameDay: boolean,
  showDateAnyway: boolean,
  showFull: boolean,
  showTime: boolean
) {
  if (showFull) {
    if (showTime) return FORMAT_DATETIME_DEFAULT
    else return FORMAT_DATE_DEFAULT
  }

  if (isSameDay) {
    if (showDateAnyway) {
      if (showTime) return FORMAT_DATETIME_SHORT
      else return FORMAT_DATE_SHORT
    }

    return FORMAT_TIME_DEFAULT
  }

  if (showTime) return FORMAT_DATETIME_SHORT
  return FORMAT_DATE_SHORT
}

export function startEndDate(
  startDate: Date,
  endDate: Date,
  options: {
    showDateAnyway?: boolean
    showFull?: boolean
    showDateOnce?: boolean
    showTime?: boolean
  } = {}
) {
  const { showDateAnyway = false, showFull = false, showTime = true } = options

  if (!isValidDate(startDate)) return
  if (!isValidDate(endDate)) return

  let endDateForSameDateComparison = endDate

  const endDateIsAlternativeEndOfDay =
    isAfterMidnightAndBeforeAlternativeEndOfDay(endDate)

  if (endDateIsAlternativeEndOfDay) {
    const endDateAlternativeEndOfDay = alternativeEndOfDay(endDate)
    if (endDateAlternativeEndOfDay) {
      endDateForSameDateComparison = endDateAlternativeEndOfDay
    }
  }

  const format = startEndDateFormat(
    isSameDay(startDate, endDateForSameDateComparison),
    showDateAnyway,
    showFull,
    showTime
  )

  return `${formatWithLocale(startDate, format)} — ${formatWithLocale(
    endDate,
    format
  )}`
}

export function startEndDateForText(
  startDate: Date,
  endDate: Date,
  options: {
    showTime?: boolean
  } = {}
) {
  const { showTime = true } = options

  if (!isValidDate(startDate)) return
  if (!isValidDate(endDate)) return

  const sameDay = isSameDay(startDate, endDate)

  if (showTime) {
    if (sameDay) {
      return `${formatWithLocale(
        startDate,
        FORMAT_DATETIME_DEFAULT
      )} – ${formatWithLocale(endDate, FORMAT_TIME_DEFAULT)}`
    }

    return `${formatWithLocale(
      startDate,
      FORMAT_DATETIME_DEFAULT
    )} – ${formatWithLocale(endDate, FORMAT_DATETIME_DEFAULT)}`
  }

  if (!showTime) {
    if (sameDay) {
      return `${formatWithLocale(startDate, FORMAT_DATE_DEFAULT)} `
    }

    return `${formatWithLocale(
      startDate,
      FORMAT_DATE_DEFAULT
    )} – ${formatWithLocale(endDate, FORMAT_DATE_DEFAULT)}`
  }
}

export function formatToRelativeDate(date?: Date) {
  if (!isValidDate(date)) return

  return formatRelative(date, new Date(), { locale: de, weekStartsOn: 1 })
}

/**
 * Get the start datetime which will be send ot the server as query variables
 */
export function startDateForServer(date: Date) {
  return formatToServerDateString(startOfDay(date), { type: 'date' })
}

/**
 * Get the end datetime which will be send ot the server as query variables
 */
export function endDateForServer(date: Date) {
  return formatToServerDateString(endOfDay(date), { type: 'date' })
}

export function parseServerDateString(
  dateString?: Nilable<string>,
  options?: {
    type?: 'date' | 'time' | 'dateTime' | 'iso'
  }
) {
  const { type = 'dateTime' } = options ?? {}
  if (!dateString) return undefined

  if (type === 'iso') {
    return parseISO(dateString)
  }

  let format:
    | typeof FORMAT_DATE_SERVER
    | typeof FORMAT_TIME_SERVER
    | typeof FORMAT_DATETIME_SERVER
    | undefined = undefined

  if (type === 'date') format = FORMAT_DATE_SERVER
  if (type === 'time') format = FORMAT_TIME_SERVER
  if (type === 'dateTime') format = FORMAT_DATETIME_SERVER
  if (!format) throw new Error('format is undefined')

  const parsedDate = parse(dateString, format, new Date())
  if (!isValid(parsedDate)) {
    throw new Error('parsedDate is not valid')
  }

  return parsedDate
}

export function getHoursAsFloat(date: Date) {
  const hours = getHours(date) + getMinutes(date) / 60
  return hours
}

export function getTotalHoursOfDay() {
  return 24
}

export function getAlternativeFormattingIfOutside({
  date,
  start,
  end,
  options = {
    formattingDefault: FORMAT_TIME_DEFAULT,
    formattingSpecial: FORMAT_DATETIME_SHORT,
  },
}: {
  date: Date
  start: Date
  end: Date
  options?: {
    formattingDefault?: string
    formattingSpecial?: string
  }
}) {
  let isOutside = false

  try {
    isOutside =
      isWithinInterval(date, {
        start,
        end,
      }) === false
  } catch {
    // slient error
  }

  return isOutside
    ? formatWithLocale(date, options.formattingSpecial)
    : formatWithLocale(date, options.formattingDefault)
}

// TODO: Rename function to `getAlternativeEndOfDay`, does not transform but returns a new date
export function transformToAlternativeEndOfDay(date: Date) {
  let transformedDate = new Date(date.getTime())
  transformedDate = set(transformedDate, {
    hours: END_OF_DAY_HOURS,
    minutes: END_OF_DAY_MINUTES,
    seconds: 0,
  })

  return transformedDate
}

export function intervalIsAfterMidnightAndBeforeAlternativeEndOfDay({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) {
  const startAndEndOnSameDay = isSameDay(startDate, endDate)
  if (!startAndEndOnSameDay) return false

  /**
   * From here on we know start and end are on the same day, so we just need to
   * check if both are before the alternative end of day
   */
  const alternativeEndOfDay = transformToAlternativeEndOfDay(startDate)

  if (isAfter(startDate, alternativeEndOfDay)) return false
  if (isAfter(endDate, alternativeEndOfDay)) return false

  return true
}

export function isAfterMidnightAndBeforeAlternativeEndOfDay(date: Date) {
  const dateMidnight = set(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  const dateAlternativeEndOfDay = transformToAlternativeEndOfDay(date)

  const isAfterMidnightAndBeforeAlternativeEndOfDay = isWithinInterval(date, {
    start: dateMidnight,
    end: dateAlternativeEndOfDay,
  })

  return isAfterMidnightAndBeforeAlternativeEndOfDay
}

/**
 * Returns the alternative end of day for the specified date
 * @example
 * ```
 * 02:00:00 → 23:59:59
 * 05:31:00 → 05:31:00
 * ```
 */
export function alternativeEndOfDay(date?: Date) {
  if (!date) return

  const hours = getHours(date)
  const minutes = getMinutes(date)
  const hoursFloat = (hours * 60 + minutes) / 60

  // Is after treshold, we don't need transformation and return null
  if (hoursFloat > END_OF_DAY_HOURS_FLOAT) return null

  let transformedDate = new Date(date.getTime())
  transformedDate = subDays(transformedDate, 1)
  transformedDate = set(transformedDate, {
    hours: 23,
    minutes: 59,
    seconds: 59,
  })

  return transformedDate
}

export function getFirstValidDate(dates: (Date | undefined)[]) {
  return dates.find((date) => {
    return isValid(date)
  })
}

/**
 * Get default start and end dates for calendar and request overview
 */
let defaultStartDate = new Date()

if (import.meta.env.DEV === true) {
  defaultStartDate = new Date('2022-04-01')
}

export function getDefaultStartDate() {
  const today = startOfDay(new Date(defaultStartDate))
  const yesterday = subDays(today, 1)

  return yesterday
}

export function getDefaultEndDate({ additionalDays } = { additionalDays: 7 }) {
  const startDate = getDefaultStartDate()

  if (import.meta.env.DEV === true) {
    additionalDays = 2
  }

  let endDate = addDays(startDate, additionalDays)
  endDate = endOfDay(endDate)

  return endDate
}
