<template>
  <div class="PmDataModalAppointmentPure">
    <PmLoadingPure v-if="isLoading" size="default" />

    <template v-if="!isLoading">
      <div class="PmDataModalAppointmentPure-content">
        <PmKeyValueListPure>
          <PmKeyValuePure v-if="IS_DEVELOPMENT" label="Id" variant="debug">
            {{ id }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Bezeichnung">
            {{ name }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Typ">
            {{ type }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Start/Ende">
            <template v-if="props.startDate && props.endDate">
              {{ startEndDateForText(props.startDate, props.endDate) }}
            </template>
          </PmKeyValuePure>

          <PmKeyValuePure label="Veranstaltungsort">
            <PmAddressPure :address="location" />
          </PmKeyValuePure>

          <PmKeyValuePure label="Projekt">
            <div
              v-if="project?.id && project?.status && project?.number"
              class="PmDataModalAppointmentPure-project"
            >
              <PmStatusPillPure
                type="project"
                :label="project.number"
                :project-status="project.status"
              />

              <PmLinkPure
                v-if="project.label"
                :label="project.label"
                @click="
                  project?.id ? emit('openProject', project.id) : undefined
                "
              />
            </div>
          </PmKeyValuePure>

          <PmKeyValuePure label="Job">
            <div
              v-if="job?.id && job?.status && job?.number"
              class="PmDataModalAppointmentPure-job"
            >
              <PmStatusPillPure
                type="job"
                :label="job.number"
                :job-status="job.status"
              />

              <PmLinkPure
                v-if="job.label"
                :label="job.label"
                @click="emit('openJob', job.id)"
              />
            </div>
          </PmKeyValuePure>

          <PmKeyValuePure label="Ressourcen">
            <PmRichTextPure v-if="resources?.length">
              <div
                v-for="resource in resources"
                :key="resource.id"
                class="PmDataModalAppointmentPure-resource"
              >
                <div class="PmDataModalAppointmentPure-resourceTitle">
                  <PmResourceShortPure
                    v-if="resource.abbreviation"
                    :interactive="false"
                    :label="resource.abbreviation"
                    :use-min-width="false"
                  />

                  {{ resource.title }}

                  <template v-if="resource.startDate && resource.endDate">
                    {{
                      startEndDateForText(resource.startDate, resource.endDate)
                    }}
                  </template>
                </div>

                <ul>
                  <li
                    v-for="resourceAllocation in resource.resourceAllocations"
                    :key="resourceAllocation.id"
                  >
                    <div class="PmDataModalAppointmentPure-resourceAllocation">
                      <PmResourceAllocationStatusPure
                        v-if="resourceAllocation.status"
                        :status="resourceAllocation.status"
                      />
                      {{ resourceAllocation.name }}
                    </div>
                  </li>
                </ul>
              </div>
            </PmRichTextPure>
          </PmKeyValuePure>
        </PmKeyValueListPure>

        <div class="PmDataModalAppointmentPure-note">
          <slot name="note" />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  type ProjectStatus,
  type JobStatus,
  type ResourceAllocationStatus,
} from '@/constants/persoplan'
import { IS_DEVELOPMENT } from '@/constants/general'

import { startEndDateForText } from '@/utilities/date'

import PmRichTextPure from '@/components/basics/PmRichText/PmRichTextPure.vue'
import PmLinkPure from '@/components/basics/PmLink/PmLinkPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import PmResourceShortPure from '@/components/persoplan/PmResource/PmResourceShortPure.vue'
import PmResourceAllocationStatusPure from '@/components/persoplan/PmResourceAllocation/PmResourceAllocationStatusPure.vue'
import PmKeyValueListPure from '@/components/basics/PmKeyValueList/PmKeyValueListPure.vue'
import PmKeyValuePure from '@/components/basics/PmKeyValueList/PmKeyValuePure.vue'
import PmAddressPure from '@/components/basics/PmAddress/PmAddressPure.vue'

import type { Nilable } from '@/types/misc'

export interface Props {
  isLoading?: boolean
  id: Nilable<number>
  name?: Nilable<string>
  type?: Nilable<string>
  startDate?: Nilable<Date>
  endDate?: Nilable<Date>
  location?: Nilable<Location>
  project?: Nilable<Project>
  job?: Nilable<Job>
  resources?: Resource[]
}

export type Location = {
  street?: Nilable<string>
  zip?: Nilable<string>
  city?: Nilable<string>
  country?: Nilable<string>
}

export type Project = {
  id: number
  number: Nilable<string>
  label: Nilable<string>
  status: Nilable<ProjectStatus>
}

export type Job = {
  id: number
  number: Nilable<string>
  label: Nilable<string>
  status: Nilable<JobStatus>
}

export type Resource = {
  id: number
  title: Nilable<string>
  abbreviation: Nilable<string>
  startDate: Nilable<Date>
  endDate: Nilable<Date>
  resourceAllocations: ResourceAllocation[]
}

export type ResourceAllocation = {
  id: number
  name: Nilable<string>
  status: Nilable<ResourceAllocationStatus>
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'openProject', id: number): void
  (event: 'openJob', id: number): void
}>()
</script>

<style lang="scss">
.PmDataModalAppointmentPure {
  $block: &;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-project {
    display: flex;
    gap: 8px;
  }

  &-job {
    display: flex;
    gap: 8px;
  }

  &-resource {
    margin-bottom: var(--space-gutters);
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &-resourceTitle {
    display: flex;
    gap: 4px;
  }

  &-resourceAllocation {
    display: flex;
    gap: 4px;
  }
}
</style>
