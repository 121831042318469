import { setup } from 'xstate5'

export const PmDataModalResourceRequestSendMessagePureState = setup({
  types: {
    events: {} as { type: 'send' } | { type: 'newMessage' },

    meta: {} as {
      error?: boolean
      message?: string
    },
  },
}).createMachine({
  id: 'PmDataModalResourceRequestSendMessagePureState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {},

    write: {
      initial: 'write',

      states: {
        write: {},

        send: {
          initial: 'sending',

          states: {
            sending: {},

            error: {
              meta: {
                error: true,
                message: 'Unknown error',
              },
            },

            success: {
              type: 'final',
            },
          },
        },
      },
    },
  },
})
