import { computed, type Ref } from 'vue'
import { isNil } from 'lodash-es'

import {
  type ExternalServiceRequestStatus,
  EXTERNAL_SERVICE_REQUEST_STATUS_LABEL_LOOKUP,
} from '@/constants/externalServiceRequest'

export { type ExternalServiceRequestStatus } from '@/constants/externalServiceRequest'
import scssVariables from '@/assets/scss/scssVariables'
import { useComponentClass } from '@thomasaull-shared/composables'

interface Options {
  status: Ref<ExternalServiceRequestStatus | undefined | null>
}

export function useExternalServiceRequestStatus(options: Options) {
  const componentClass = useComponentClass()

  const statusNormalized = computed<ExternalServiceRequestStatus>(() => {
    if (isNil(options.status.value)) return 'notProcessed'
    return options.status.value
  })

  const classes = computed(() => {
    return [
      componentClass.propModifier({
        name: 'status',
        value: statusNormalized.value,
      }),
    ]
  })

  const color = computed(() => {
    if (statusNormalized.value === 'approved') {
      return scssVariables.color['status-confirmed']
    }

    if (statusNormalized.value === 'rejected') {
      return scssVariables.color['status-cancelled']
    }

    return undefined
  })

  const label = computed(() => {
    return EXTERNAL_SERVICE_REQUEST_STATUS_LABEL_LOOKUP[statusNormalized.value]
  })

  return {
    classes,
    color,
    label,
  }
}
