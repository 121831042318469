import { assertEvent, fromPromise, setup } from 'xstate5'
import {
  assertFriendlyError,
  type FriendlyError,
} from '@/functional/friendlyErrors'
import { type Emits as EmitsDataModalResourceRequestSendMessagePure } from '@/components/persoplan/DataModal/PmDataModalResourceRequest/PmDataModalResourceRequestSendMessage/PmDataModalResourceRequestSendMessagePure.vue'

export const PmDataModalResourceRequestSendMessageState = setup({
  types: {
    events: {} as
      | {
          type: 'send'
          variables: EmitsDataModalResourceRequestSendMessagePure['send'][0]
        }
      | {
          type: 'newMessage'
        }
      | { type: 'cancel' },

    context: {} as {
      error: FriendlyError | undefined
    },
  },

  actions: {
    showSuccessNotification: () => {
      throw new Error('showSuccessNotification not implemented')
    },
  },

  actors: {
    send: fromPromise<
      void,
      { variables: EmitsDataModalResourceRequestSendMessagePure['send'][0] }
    >(async () => {
      throw new Error('send not implemented')
    }),
  },
}).createMachine({
  id: 'PmDataModalResourceRequestSendMessageState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        newMessage: 'write',
      },
    },

    write: {
      initial: 'write',

      states: {
        write: {
          on: {
            send: 'send',
            cancel: '#PmDataModalResourceRequestSendMessageState.default',
          },
        },

        send: {
          initial: 'sending',

          states: {
            sending: {
              invoke: {
                id: 'send',
                src: 'send',
                input: ({ event }) => {
                  assertEvent(event, 'send')
                  return { variables: event.variables }
                },
                onDone: {
                  target: 'success',
                },
                onError: {
                  target: 'error',
                  actions: ({ context, event }) => {
                    assertFriendlyError(event.error)
                    context.error = event.error.errors
                  },
                },
              },
            },

            success: {
              entry: ['showSuccessNotification'],
              always: '#PmDataModalResourceRequestSendMessageState.default',
            },

            error: {
              on: {
                cancel: '#PmDataModalResourceRequestSendMessageState.default',
              },
            },
          },
        },
      },
    },
  },
})
