<template>
  <PmSendMessagePure
    :recipients="messageRecipients?.map((value) => value.name)"
    :is-loading-initial-data="messageRecipientsQuery.loading.value"
    :state="state.path.value"
    :error-message="state.snapshot.context.error"
    :error-details="state.snapshot.context.errorDetails"
    :number-of-message-recipients-without-request="
      numberOfMessageRecipientsWithoutRequest
    "
    @send="
      (payload) => {
        state.send({ type: 'send', variables: payload })
      }
    "
    @close="emit('close')"
  />
</template>

<script setup lang="ts">
import {
  AddResourceRequestCommentDocument,
  MessageRecipientsDocument,
} from '@/../generated/graphql'
import PmSendMessagePure, {
  type Emits as EmitsSendMessagePure,
} from '@/components/persoplan/PmSendMessage/PmSendMessagePure.vue'
import { throwFriendlyError } from '@/functional/friendlyErrors'
import { getDisplayNameOfAddress } from '@/utilities/string'
import { useComponentClass } from '@thomasaull-shared/composables'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { computed } from 'vue'
import { fromPromise } from 'xstate5'
import { useXState } from '@/composition/useXState5'
import { PmSendMessageState } from '@/components/persoplan/PmSendMessage/PmSendMessageState'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { ICONS } from '@/constants/icons'

export interface Props {
  resourceAllocationIds: number[]
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  close: []
}>()

const store = useStore()
const componentClass = useComponentClass()

const { t } = useI18n({
  messages: {
    de: {
      successNotification: 'Nachricht gesendet',
    },
  },
})

const state = useXState(
  PmSendMessageState.provide({
    actions: {
      close: () => emit('close'),
      showSuccessNotification: () => {
        store.commit('notification/add', {
          variant: 'success',
          icon: ICONS.CHECK,
          title: t('successNotification'),
        })
      },
    },

    actors: {
      send: fromPromise(async ({ input }) => send(input.variables)),
    },
  })
)

const messageRecipientsQuery = useQuery(
  MessageRecipientsDocument,
  () => ({
    resourceAllocationIds: props.resourceAllocationIds,
  }),
  () => ({
    enabled: props.resourceAllocationIds.length > 0,
  })
)

const messageRecipients = computed(() => {
  if (!messageRecipientsQuery.result.value?.resourceAllocations) return

  const result: { resourceAllocationId: number; name: string }[] = []

  messageRecipientsQuery.result.value.resourceAllocations.forEach(
    (resourceAllocation) => {
      if (!resourceAllocation?.resourceRequest?.id) return

      const displayName =
        getDisplayNameOfAddress(resourceAllocation?.address) ?? 'n/a'

      result.push({
        resourceAllocationId: resourceAllocation.id,
        name: displayName,
      })
    }
  )

  return result
})

const numberOfMessageRecipientsWithoutRequest = computed(() => {
  if (!messageRecipientsQuery.result.value?.resourceAllocations) return

  let result = 0

  messageRecipientsQuery.result.value.resourceAllocations.forEach(
    (resourceAllocation) => {
      if (!resourceAllocation?.resourceRequest?.id) {
        result += 1
      }
    }
  )

  return result
})

const addResourceRequestCommentMutation = useMutation(
  AddResourceRequestCommentDocument
)

async function send(payload: EmitsSendMessagePure['send'][0]) {
  try {
    const resourceAllocationIds = messageRecipients.value?.map(
      (value) => value.resourceAllocationId
    )
    if (!resourceAllocationIds)
      throw new Error('no resourceAllocationIds to send a message to')

    await addResourceRequestCommentMutation.mutate({
      resourceAllocationIds,
      message: payload.message,
    })
  } catch (error) {
    throwFriendlyError(error)
  }
}
</script>

<style lang="scss">
.PmSendMessage {
  $block: &;
}
</style>
