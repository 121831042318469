import { createMachine } from 'xstate'

import type { Typegen0 } from './PmDataModalProjectSettingsState.typegen'
import {
  assertFriendlyError,
  type FriendlyError,
} from '@/functional/friendlyErrors'

export const PmDataModalProjectSettingsState = createMachine({
  tsTypes: {} as import('./PmDataModalProjectSettingsState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'SAVE' },
    context: {} as {
      error: FriendlyError | undefined
    },
    services: {} as {
      save: any
    },
  },

  id: 'PmDataModalProjectSettingsState',
  initial: 'default',

  context: {
    error: undefined,
  },

  states: {
    default: {
      on: {
        SAVE: 'saving',
      },
    },

    saving: {
      invoke: {
        id: 'saving',
        src: 'save',
        onError: {
          target: 'failed',
          actions: (context, error) => {
            assertFriendlyError(error.data)
            context.error = error.data.errors
          },
        },
        onDone: 'default',
      },
    },
    failed: {
      on: {
        SAVE: 'saving',
      },
    },
  },
})

export type TState = Typegen0['matchesStates']
