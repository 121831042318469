import { groupBy } from 'lodash-es'

import {
  createMongoAbility,
  AbilityBuilder,
  type MongoAbility,
  type CreateAbility,
  type InferSubjects,
} from '@casl/ability'

import { IS_DEVELOPMENT } from '@/constants/general'

/**
 * Possible roles defined in the backend
 */
const ROLE = {
  ADMIN: 'ROLE_ADMIN',
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ADMINISTRATION: 'ROLE_ADMINISTRATION',
  USER: 'ROLE_USER',
  EMPLOYEE: 'ROLE_EMPLOYEE',
  MANAGEMENT: 'ROLE_MANAGEMENT',
  MILESTONES_RELEASES: 'ROLE_MILESTONES_RELEASES',
  PERSOPLAN: 'ROLE_PERSOPLAN',
  PERSOPLAN_ADMIN: 'ROLE_PERSOPLAN_ADMIN',
  PERSOPLAN_ALLOCATOR: 'ROLE_PERSOPLAN_ALLOCATOR',
  PERSOPLAN_EDITOR: 'ROLE_PERSOPLAN_EDITOR',
  RESOURCE_REQUEST: 'ROLE_RESOURCE_REQUEST',
  RESOURCE_REQUEST_ADMIN: 'ROLE_RESOURCE_REQUEST_ADMIN',
} as const

/**
 * Possible easyjob roles defined in the backend
 */
const EASYJOB_ROLE = {
  MANAGEMENT: 'ROLE_MANAGEMENT',
} as const

interface ResourceState {
  kind: 'ResourceState'
  addressId: number
}

type Abilities =
  | ['update' | 'create', InferSubjects<ResourceState>]
  | ['edit', 'persoplan']
  | ['edit', 'resourceRequest']
  | ['use', 'resourceRequestOverview']
  | ['view', 'adminArea']
  | ['create' | 'update', 'ResourceStateAddress']
  | ['create' | 'update', 'ResourceStateVehicle']
  | ['create', 'PublicViews']
  | ['view', 'featureRequests']
  | ['create', 'webfleetOrder']
  | ['create', 'requests']
  | ['approve', 'requests']
  | ['view', 'requestsArchive']

export type AppAbility = MongoAbility<Abilities>
export const createAppAbility = createMongoAbility as CreateAbility<AppAbility>
export const ability = defineBaseAbility()

function defineBaseAbility() {
  const { can, cannot, build } = new AbilityBuilder(createAppAbility)

  // Add rules

  return build()
}

function hasOneOf(roles: string | string[], userRoles) {
  if (!Array.isArray(roles)) {
    roles = [roles]
  }

  return roles.some((role) => {
    return userRoles.includes(role)
  })
}

/**
 * Update user specific abilities
 * How to update rules?
 * @see https://github.com/stalniy/casl/tree/master/packages/casl-vue#update-ability-instance
 *
 * @todo Use predefined permissions per role
 * @see https://casl.js.org/v6/en/cookbook/roles-with-static-permissions
 */
export function addUserAbility(user: {
  /** Roles defined in admin area */
  roles: string[]
  /** Roles defined via easyjob database */
  easyJobRoles: string[]
  addressId: number
}) {
  const { can, rules } = new AbilityBuilder(createAppAbility)

  // TODO: Update when https://gitlab.dev.innovation-agents.de/pro-musik/frontend/-/issues/655 is resolved
  // can('update', 'ResourceState', { addressId: user.addressId })
  // can('create', 'ResourceState', { addressId: user.addressId })

  if (hasOneOf(ROLE.PERSOPLAN_EDITOR, user.roles)) {
    can('edit', 'persoplan')
  }

  // Can use persoplan edit mode and create webfleet orders
  if (hasOneOf(ROLE.PERSOPLAN_ALLOCATOR, user.roles)) {
    can('edit', 'persoplan')
    can('create', 'webfleetOrder')
  }

  // Can create resource requests and view requests overview
  if (
    hasOneOf([ROLE.RESOURCE_REQUEST, ROLE.RESOURCE_REQUEST_ADMIN], user.roles)
  ) {
    can('edit', 'resourceRequest')
    can('use', 'resourceRequestOverview')
  }

  if (hasOneOf(ROLE.EMPLOYEE, user.roles)) {
    can('create', 'requests')
    can('approve', 'requests')
  }

  if (hasOneOf(EASYJOB_ROLE.MANAGEMENT, user.roles)) {
    can('view', 'requestsArchive')
  }

  // Admins
  if (hasOneOf(ROLE.ADMINISTRATION, user.roles)) {
    can('view', 'adminArea')
    can('create', 'ResourceStateAddress')
    can(['create', 'update'], 'ResourceStateAddress')
    can(['create', 'update'], 'ResourceStateVehicle')
    can('create', 'PublicViews')
    can('edit', 'resourceRequest')
    can('use', 'resourceRequestOverview')
  }

  // Winfried Kornberg can view feature requests link
  if (user.addressId === 6801) {
    can('view', 'featureRequests')
  }

  /**
   * Could not figure out a good way to implement a super-admin feature
   * without recreating all abilities again
   * @todo find better solution
   */
  if (hasOneOf(ROLE.SUPER_ADMIN, user.roles)) {
    can('create', 'requests')
    can('approve', 'requests')
    can('view', 'requestsArchive')
  }

  ability.update(rules)
  logAbilities(user)
}

function logAbilities(user: Parameters<typeof addUserAbility>[0]) {
  const rulesHumanReadable = {}
  const rulesGrouped = groupBy(ability.rules, 'subject')

  Object.entries(rulesGrouped).forEach(([subject, rules]) => {
    let actions = rules.map((rule) => rule.action)
    actions = actions.sort()
    rulesHumanReadable[subject] = actions.join(', ')
  })

  console.table({
    roles: user.roles.join(', '),
    easyJobRoles: user.easyJobRoles.join(', '),
    ...rulesHumanReadable,
  })
}
