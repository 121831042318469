<template>
  <div :class="componentClass.root">
    <template v-if="state.snapshot.matches('default')">
      <PmButtonPure
        label="Neue Nachricht"
        icon="mail"
        variant="secondary"
        alternative="default"
        @click="emit('newMessage')"
      />
    </template>

    <template v-if="state.snapshot.matches('write')">
      <PmFormPure @submit.prevent="send">
        <PmTextareaPure v-model:value="message" :rows="5" label="Nachricht" />

        <template v-if="state.meta.value?.error" #error>
          <PmErrorNotificationPure
            :error="error"
            :message="state.meta.value?.message"
          />
        </template>

        <template #control>
          <PmButtonListPure align="right">
            <PmButtonPure
              label="Abbrechen"
              icon="close"
              :disabled="state.snapshot.matches({ write: { send: 'sending' } })"
              @click="emit('cancel')"
            />

            <PmButtonListDividerPure />

            <PmButtonPure
              variant="primary"
              icon="send"
              :disabled="isSendButtonDisabled"
              label="Senden"
              type="submit"
              :loading="state.snapshot.matches({ write: { send: 'sending' } })"
            />
          </PmButtonListPure>
        </template>
      </PmFormPure>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useComponentClass } from '@thomasaull-shared/composables'
import PmTextareaPure from '@/components/basics/PmTextarea/PmTextareaPure.vue'
import PmFormPure from '@/components/basics/PmForm/PmFormPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import { useXState, type StatePathOrValue } from '@/composition/useXState5'
import { PmDataModalResourceRequestSendMessagePureState } from '@/components/persoplan/DataModal/PmDataModalResourceRequest/PmDataModalResourceRequestSendMessage/PmDataModalResourceRequestSendMessagePureState'
import { computed, ref, toRef } from 'vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonListDividerPure from '@/components/basics/PmButtonListDivider/PmButtonListDividerPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import type { FriendlyError } from '@/functional/friendlyErrors'

export interface Props {
  state?: StatePathOrValue<
    typeof PmDataModalResourceRequestSendMessagePureState
  >
  error?: FriendlyError
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  newMessage: []
  cancel: []
  send: [{ message: string }]
  resetAfterSuccess: []
}

const emit = defineEmits<Emits>()

const state = useXState(PmDataModalResourceRequestSendMessagePureState, {
  syncStateWith: toRef(props, 'state'),
})

const componentClass = useComponentClass()
const message = ref<string>()

const hasMessage = computed(() => {
  if (!message.value) return
  return message.value.trim().length > 0
})

const isSendButtonDisabled = computed(() => {
  if (!hasMessage.value) return true
  if (state.value.snapshot.matches({ write: { send: 'error' } })) return true

  return false
})

function send() {
  if (!hasMessage.value) throw new Error('message is undefined or empty')
  if (!message.value) return

  emit('send', { message: message.value })
}
</script>

<style lang="scss">
.PmDataModalResourceRequestSendMessagePure {
  $block: &;
}
</style>
