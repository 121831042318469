import { computed, type Ref } from 'vue'
import scssVariables from '@/assets/scss/scssVariables'

import {
  STATUS_RESOURCE_REQUEST_FEEDBACK,
  type StatusResourceRequestFeedback,
  STATUS_RESOURCE_REQUEST_FEEDBACK_LOOKUP_LABEL,
} from '@/constants/persoplan'

import { lookup } from '@/utilities/misc'
import { isNil } from 'lodash-es'

export { type StatusResourceRequestFeedback, STATUS_RESOURCE_REQUEST_FEEDBACK }

interface Options {
  status: Ref<StatusResourceRequestFeedback | undefined | null>
}

export function useResourceRequestFeedbackStatus(options: Options) {
  const statusNormalized = computed(() => {
    if (isNil(options.status.value))
      return STATUS_RESOURCE_REQUEST_FEEDBACK.unknown

    return options.status.value
  })

  const classes = computed(() => {
    if (!statusNormalized.value) return

    return {
      'is-statusUnknown':
        statusNormalized.value === STATUS_RESOURCE_REQUEST_FEEDBACK.unknown,
      'is-statusAccepted':
        statusNormalized.value === STATUS_RESOURCE_REQUEST_FEEDBACK.accept,
      'is-statusMaybe':
        statusNormalized.value === STATUS_RESOURCE_REQUEST_FEEDBACK.maybe,
      'is-statusDeclined':
        statusNormalized.value === STATUS_RESOURCE_REQUEST_FEEDBACK.decline,
    }
  })

  const color = computed(() => {
    if (!statusNormalized.value) return

    return scssVariables.color[
      `status-resourceRequest-${statusNormalized.value}`
    ]
  })

  const label = computed(() => {
    return lookup(
      statusNormalized.value,
      STATUS_RESOURCE_REQUEST_FEEDBACK_LOOKUP_LABEL
    )
  })

  return {
    classes,
    color,
    label,
  }
}
