<template>
  <portal to="modal">
    <PmModalPure
      title="Ressourcen anfragen"
      :can-be-closed="!xstate.state.value.matches('request.requesting')"
      class="PmMultipleResourceAllocationsRequestPure"
      :is-loading="xstate.state.value.matches('loading')"
      @close="emit('close')"
    >
      <div class="PmMultipleResourceAllocationsRequestPure-content">
        <template v-if="xstate.state.value.matches('empty')">
          <PmAppNotificationPure
            variant="warning"
            title="Es gibt keine angedachten Ressourcen für diesen Termin"
          />
        </template>

        <template v-if="!xstate.state.value.matches('empty')">
          <PmAppNotificationPure
            v-if="ignoredItems && numberOfIgnoredItems > 0 && !isFinished"
            title="Einige ausgewählte Ressourcen-Zuordnungen werden ignoriert"
          >
            <ul>
              <li v-if="ignoredItems.statusIsNotConsidered">
                {{
                  t(
                    'ignoredBecauseStatusIsNotConsidered',
                    ignoredItems.statusIsNotConsidered
                  )
                }}
              </li>

              <li v-if="ignoredItems.resourceAllocationExistsAlready">
                {{
                  t(
                    'ignoredBecauseResourceAllocationExistsAlready',
                    ignoredItems.resourceAllocationExistsAlready
                  )
                }}
              </li>
            </ul>
          </PmAppNotificationPure>

          <PmTablePure
            v-if="columns"
            :columns="columns"
            class="PmMultipleResourceAllocationsRequestPure-table"
            :is-grouped="true"
          >
            <slot name="table" />
          </PmTablePure>

          <form
            :id="formId"
            ref="elForm"
            class="PmMultipleResourceAllocationsRequestPure-form"
          >
            <PmDateTimePickerPure
              label="Anfrage gültig bis"
              :value="dateOfExpireNormalized"
              :min="new Date()"
              :has-reset="!isUsingSensibleDateOfExpire"
              :disabled="isFormDisabled"
              @input="onDateOfExpireInput"
            />

            <PmAppNotificationPure
              v-if="
                !isFinished &&
                isUsingSensibleDateOfExpire &&
                sensibleDateOfExpire?.label
              "
              :title="`Der Wert wurde passend zum Anfangsdatum des Jobs automatisch auf ${sensibleDateOfExpire.label} eingestellt`"
            />

            <PmInputPure
              v-model:value="subject"
              label="Betreff"
              :disabled="isFormDisabled"
            />

            <PmTextareaPure
              v-model:value="message"
              label="Nachricht"
              :rows="3"
              :disabled="isFormDisabled"
            />
          </form>

          <slot />

          <PmAppNotificationPure
            v-if="!isFinished && hasNoItemsToRequest"
            variant="warning"
            title="Es gibt keine Ressourcen-Zuordnungen die angefagt werden können."
          >
          </PmAppNotificationPure>

          <PmAppNotificationPure
            v-if="xstate.state.value.matches('request.requesting')"
            :is-loading="true"
            :title="`${t(
              'titleRequesting',
              numberOfItemsToRequest
            )}: ${indexCurrentlyRequesting} von ${numberOfItemsToRequest}`"
          />

          <PmErrorNotificationPure
            v-if="xstate.meta.value.error"
            class="PmMultipleResourceAllocationsDeletePure-notification"
            :error="error"
            :message="xstate.meta.value.errorMessage"
          />

          <PmAppNotificationPure
            v-if="xstate.state.value.matches('request.success')"
            variant="success"
            :title="t('success', numberOfItemsToRequest)"
          />
        </template>
      </div>

      <template #footer>
        <template v-if="xstate.state.value.matches('empty')">
          <PmButtonListPure align="right">
            <PmButtonPure
              label="Schließen"
              icon="close"
              variant="primary"
              @click="emit('close')"
            />
          </PmButtonListPure>
        </template>

        <template v-if="xstate.state.value.matches('request.success')">
          <PmButtonListPure align="right">
            <PmButtonPure
              label="Schließen"
              icon="close"
              :disabled="isCancelButtonDisabled"
              variant="primary"
              @click="emit('close')"
            />
          </PmButtonListPure>
        </template>

        <template
          v-if="
            xstate.state.value.matches('default') ||
            xstate.state.value.matches('request.requesting')
          "
        >
          <PmButtonListPure align="right">
            <PmButtonPure
              label="Abbrechen"
              icon="close"
              :disabled="isCancelButtonDisabled"
              @click="emit('close')"
            />

            <PmButtonListDividerPure />

            <PmButtonPure
              :label="t('confirm', numberOfItemsToRequest)"
              variant="primary"
              icon="mailQuestion"
              :disabled="isRequestButtonDisabled"
              @click="confirmAndClose"
            />
          </PmButtonListPure>
        </template>

        <template v-if="xstate.state.value.matches('request.failed')">
          <PmButtonListPure align="right">
            <PmButtonPure
              label="Schließen"
              icon="close"
              :disabled="isCancelButtonDisabled"
              variant="primary"
              @click="emit('close')"
            />
          </PmButtonListPure>
        </template>
      </template>
    </PmModalPure>
  </portal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { TableColumns } from '@/components/basics/PmTable/PmTablePure.vue'
import { addHours, differenceInCalendarDays } from 'date-fns'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsRequestPure'

export const propTypes = {
  state: {
    allowed: states,
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})

export const columns: TableColumns = [
  { id: 'status', label: 'Status' },
  { id: 'job', label: 'Job' },
  { id: 'appointment', label: 'Termin' },
  {
    id: 'resourceFunction',
    label: 'Ressourcenfunktion',
  },
  { id: 'resonForIgnore' },
]
</script>

<script setup lang="ts">
import { toRef, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import cuid from '@paralleldrive/cuid2'

import { useXState } from '@/composition/useXState'

import {
  PmMultipleResourceAllocationsRequestPureState,
  states,
  type State,
} from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequestPureState'

import PmTablePure from '@/components/basics/PmTable/PmTablePure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmTextareaPure from '@/components/basics/PmTextarea/PmTextareaPure.vue'
import PmButtonListDividerPure from '@/components/basics/PmButtonListDivider/PmButtonListDividerPure.vue'
import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmDateTimePickerPure from '@/components/basics/PmDateTimePicker/PmDateTimePickerPure.vue'

import type { Props as PropsMultipleResourceAllocationsRequestItemPure } from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequestItemPure.vue'
import type { FriendlyError } from '@/functional/friendlyErrors'

export interface StatusOption {
  id: number
  label: string
}
export type IgnoredItems = Partial<
  Record<
    Required<PropsMultipleResourceAllocationsRequestItemPure>['reasonForIgnore'],
    number
  >
>

export interface Props {
  state: State
  error?: FriendlyError
  numberOfItems: number
  indexCurrentlyRequesting: number
  startDateOfJob?: Date
  ignoredItems?: IgnoredItems
}

export type FormData = {
  dateOfExpire?: Date
  subject?: string
  message?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'confirmAndClose', payload: FormData): void
  (event: 'close'): void
}>()

const xstate = useXState(PmMultipleResourceAllocationsRequestPureState, {
  syncStateWith: toRef(props, 'state'),
})

const { t } = useI18n({
  messages: {
    de: {
      success:
        'Die Ressourcen-Zuordnung wurde angefragt | Die Ressourcen-Zuordnungen wurden angefragt',
      ignoredBecauseStatusIsNotConsidered:
        '{n} Ressourcen-Zuordnung hat einen anderen Status als „Angedacht” | {n} Ressourcen-Zuordnung haben einen anderen Status als „Angedacht”',
      ignoredBecauseResourceAllocationExistsAlready:
        '{n} Ressourcen-Zuordnung hat bereits eine bestehende Anfrage | {n} Ressourcen-Zuordnung haben bereites eine bestehende Anfrage',
      titleRequesting:
        'Ressourcen-Zuordnung wird angefragt | Ressourcen-Zuordnungen werden angefragt',
      confirm: 'Anfrage senden | Anfragen senden',
    },
  },
})

/**
 * Date of expire
 * Gets pre-filled to a sensible value when startDateOfJob is provided
 */
const dateOfExpire = ref<Date | undefined>()

function onDateOfExpireInput(date: Date | null) {
  dateOfExpire.value = date ?? undefined
}

const dateOfExpireNormalized = computed(() => {
  if (!dateOfExpire.value && sensibleDateOfExpire.value?.date) {
    return sensibleDateOfExpire.value.date
  }

  return dateOfExpire.value
})

const isUsingSensibleDateOfExpire = computed(() => {
  return !dateOfExpire.value && sensibleDateOfExpire.value
})

const sensibleDateOfExpire = computed<
  { date: Date; label: string | null } | undefined
>(() => {
  if (!props.startDateOfJob) {
    const oneWeek = addHours(new Date(), 24 * 7)
    return {
      date: oneWeek,
      label: null,
    }
  }

  const numberOfDaysUntil = differenceInCalendarDays(
    props.startDateOfJob,
    new Date()
  )

  if (numberOfDaysUntil <= 5) {
    const twentyFourHours = addHours(new Date(), 24)

    return {
      date: twentyFourHours,
      label: '24 Stunden',
    }
  }

  if (numberOfDaysUntil <= 7) {
    const twoDays = addHours(new Date(), 24 * 2)

    return {
      date: twoDays,
      label: '2 Tage',
    }
  }

  if (numberOfDaysUntil <= 30) {
    const fiveDays = addHours(new Date(), 24 * 5)

    return {
      date: fiveDays,
      label: '5 Tage',
    }
  }

  const oneWeek = addHours(new Date(), 24 * 7)

  return {
    date: oneWeek,
    label: 'eine Woche',
  }
})

// TODO: Subject has a max character limit of 255
const subject = ref<string>()
const message = ref<string>()

const elForm = ref<HTMLFormElement>()
const formId = `${COMPONENT_NAME}.${cuid.createId()}`

const areButtonsDisabled = computed(() => {
  if (xstate.state.value.matches('request.requesting')) return true

  return false
})

const isFormDisabled = computed(() => {
  if (xstate.state.value.matches('request.requesting')) return true
  if (xstate.state.value.matches('request.success')) return true
  if (xstate.state.value.matches('request.successAndClose')) return true

  return undefined
})

const numberOfIgnoredItems = computed(() => {
  if (!props.ignoredItems) return 0

  const result = Object.values(props.ignoredItems).reduce(
    (partialSum, numberOfIgnoredItems) => partialSum + numberOfIgnoredItems,
    0
  )

  return result
})

const numberOfItemsToRequest = computed(() => {
  return props.numberOfItems - numberOfIgnoredItems.value
})

const hasNoItemsToRequest = computed(() => {
  return props.numberOfItems === numberOfIgnoredItems.value
})

const isRequestButtonDisabled = computed(() => {
  if (areButtonsDisabled.value === true) return true
  if (xstate.state.value.matches('request.success')) return true
  if (hasNoItemsToRequest.value) return true

  return false
})

const isCancelButtonDisabled = computed(() => {
  if (areButtonsDisabled.value === true) return true

  return false
})

const isFinished = computed(() => {
  if (xstate.state.value.matches('request.success')) return true
  if (xstate.state.value.matches('request.successAndClose')) return true

  return false
})

function checkIfFormIsValid() {
  if (!elForm.value) throw new Error('elForm is undefined')

  const isValid = elForm.value.reportValidity()
  return isValid
}

function confirmAndClose() {
  if (!checkIfFormIsValid()) return

  emit('confirmAndClose', {
    dateOfExpire: dateOfExpire.value,
    subject: subject.value,
    message: message.value,
  })
}
</script>

<style lang="scss">
.PmMultipleResourceAllocationsRequestPure {
  $block: &;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
