<template>
  <PmTimelineItemPure
    :date="date"
    :label="label"
    :additional-meta="additionalMetaNormalized"
    :class="componentClass.root"
  >
    <template #header>
      <template v-if="status">
        <PmStatusPillPure
          type="resourceRequest"
          :resource-request-status="status"
        />
      </template>

      <template v-if="userStatus">
        <PmStatusPillPure
          type="resourceRequestFeedback"
          :resource-request-feedback-status="userStatus"
        />
      </template>
    </template>

    <PmNotePure
      v-if="subject || message"
      name="Nachricht"
      :note="message"
      :subject="subject"
    />
  </PmTimelineItemPure>
</template>

<script setup lang="ts">
import PmTimelineItemPure, {
  type Props as PropsTimelineItemPure,
} from '@/components/basics/PmTimeline/PmTimelineItemPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmNotePure from '@/components/PmNote/PmNotePure.vue'
import type {
  ResourceRequestStatus,
  ResourceRequestUserStatus,
} from '@/constants/resourceRequest'
import type { Nilable } from '@/types/misc'
import { startEndDateForText } from '@/utilities/date'
import { useComponentClass } from '@thomasaull-shared/composables'
import type { DataModalResourceRequestQuery } from '@/../generated/graphql'
import { computed } from 'vue'
import type { Get } from 'type-fest'
import { isSameMinute, isValid as isValidDate } from 'date-fns'

type CommunicationItem = NonNullable<
  Get<DataModalResourceRequestQuery, 'resourceRequest.communications[0]'>
>

export interface Props {
  date: Date
  action: `${CommunicationItem['action']}`
  name?: string
  status?: Nilable<ResourceRequestStatus>
  statusFrom?: Nilable<ResourceRequestStatus>
  userStatus?: Nilable<ResourceRequestUserStatus>
  userStatusFrom?: Nilable<ResourceRequestUserStatus>
  subject?: Nilable<string>
  message?: Nilable<string>
  availableStartDate?: Nilable<Date>
  availableEndDate?: Nilable<Date>
  additionalMeta?: PropsTimelineItemPure['additionalMeta']
  timeframe?: {
    start: Date
    end: Date
  }
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()

const additionalMetaNormalized = computed(() => {
  const result: PropsTimelineItemPure['additionalMeta'] = []

  if (
    props.availableStartDate &&
    props.availableEndDate &&
    hasDeviatingTimes.value === true
  ) {
    const dates = startEndDateForText(
      props.availableStartDate,
      props.availableEndDate
    )
    if (dates) result.push(`für ${dates}`)
  }

  return [...result, ...(props.additionalMeta ?? [])]
})

const hasDeviatingTimes = computed(() => {
  if (!props.timeframe) return true
  if (!props.availableStartDate || !isValidDate(props.availableStartDate))
    return true
  if (!props.availableEndDate || !isValidDate(props.availableEndDate))
    return true

  const startIsSame = isSameMinute(
    props.timeframe.start,
    props.availableStartDate
  )
  const endIsSame = isSameMinute(props.timeframe.end, props.availableEndDate)

  if (!startIsSame) return true
  if (!endIsSame) return true

  return false
})

const label = computed(() => {
  if (props.status || props.userStatus) return `von ${props.name}`

  const lookupLabel = {
    create: `Anfrage von ${props.name}`,
    update: `Aktualisiert von ${props.name}`,
    decline: `Abgelehnt von ${props.name}`,
    cancel: `Abgebrochen von ${props.name}`,
    confirm: `Bestätigt von ${props.name}`,
  } satisfies Record<`${CommunicationItem['action']}`, string>

  return lookupLabel[props.action]
})
</script>

<style lang="scss">
.PmResourceRequestCommunicationItem {
  $block: &;
}
</style>
