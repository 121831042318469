import { setup } from 'xstate5'

export const PmSendMessagePureState = setup({
  types: {
    events: { type: 'my-type' },

    meta: {} as {
      error?: boolean
      message?: string
    },
  },
}).createMachine({
  id: 'PmSendMessagePureState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {},

    send: {
      initial: 'sending',

      states: {
        sending: {},

        error: {
          meta: {
            error: true,
            message: 'Unbekannter Fehler',
          },
        },

        success: {
          type: 'final',
        },
      },
    },
  },
})
