<template>
  <div class="PmDataModalResourceRequestPure">
    <PmKeyValueListPure :is-loading="isLoading">
      <template v-if="!isLoadingInitialData">
        <PmKeyValuePure v-if="IS_DEVELOPMENT" label="Id" variant="debug">
          {{ id }}
        </PmKeyValuePure>

        <PmKeyValuePure label="angefragte Resource">
          {{ resource?.name }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Status Ressource">
          <PmStatusPillPure
            v-if="statusFeedback"
            type="resourceRequestFeedback"
            :resource-request-feedback-status="statusFeedback"
          />
        </PmKeyValuePure>

        <PmKeyValuePure label="Status">
          <PmStatusPillPure
            v-if="status"
            type="resourceRequest"
            :resource-request-status="status"
          />

          <template v-else> — </template>
        </PmKeyValuePure>

        <PmKeyValuePure label="gültig bis">
          <template v-if="dateOfExpire">
            {{ formatToRelativeDate(dateOfExpire) }}
          </template>
        </PmKeyValuePure>

        <PmKeyValuePure label="Projekt">
          <div v-if="project" class="PmDataModalResourceRequestPure-project">
            <PmPillPure
              :label="project.number ?? undefined"
              :color="projectStatus.color.value"
            />
            <PmLinkPure
              v-if="project.label"
              :label="project.label"
              @click="emit('openProject')"
            />
          </div>
        </PmKeyValuePure>

        <PmKeyValuePure label="Jobs und angefragte Ressourcen-Zuweisungen">
          <ul class="PmDataModalResourceRequestPure-jobs">
            <li
              v-for="job in jobsWithResourceAllocations"
              :key="job.id"
              class="PmDataModalResourceRequestPure-jobsItem"
            >
              <div class="PmDataModalResourceRequestPure-job">
                <PmPillPure
                  :label="job.number ?? undefined"
                  :color="job.color"
                />
                <PmLinkPure
                  v-if="job.label"
                  :label="job.label"
                  @click="emit('openJob')"
                />
              </div>

              <ul
                v-if="job.resourceAllocations?.length"
                class="PmDataModalResourceRequestPure-resourceAllocations"
              >
                <li
                  v-for="resourceAllocation in job.resourceAllocations"
                  :key="resourceAllocation.id"
                  class="PmDataModalResourceRequestPure-resourceAllocation"
                >
                  <PmStatusDotPure
                    type="resourceAllocation"
                    :resource-allocation-status="
                      resourceAllocation.status ?? undefined
                    "
                  />

                  {{ resourceAllocation.title }},
                  <template
                    v-if="
                      resourceAllocation.startDate && resourceAllocation.endDate
                    "
                  >
                    {{
                      startEndDateForText(
                        resourceAllocation.startDate,
                        resourceAllocation.endDate
                      )
                    }}
                  </template>
                </li>
              </ul>
            </li>
          </ul>
        </PmKeyValuePure>

        <!-- <PmKeyValuePure label="Kommunikation (alt)">
          <PmTimelinePure>
            <PmTimelineItemPure
              v-if="requestMessage"
              :label="`Anfrage von ${requestMessage.from}`"
              :date="requestMessage.date"
            >
              <PmNotePure
                :subject="requestMessage.subject"
                name="Nachricht"
                :note="requestMessage.body"
              />
            </PmTimelineItemPure>

            <PmTimelineItemPure
              v-if="responseMessage"
              :label="`Antwort von ${responseMessage.from}`"
            >
              <div class="PmDataModalResourceRequestPure-responseStatus">
                <PmStatusPillPure
                  v-if="statusFeedback"
                  type="resourceRequestFeedback"
                  :resource-request-feedback-status="statusFeedback"
                />

                <template v-if="availableStartDate && availableEndDate">
                  für
                  {{
                    startEndDateForText(availableStartDate, availableEndDate)
                  }}
                </template>
              </div>

              <PmNotePure
                class="PmDataModalResourceRequestPure-responseMessage"
                :subject="responseMessage.subject"
                name="Nachricht"
                :note="responseMessage.body"
              />
            </PmTimelineItemPure>

            <PmTimelineItemPure v-if="status">
              <PmStatusPillPure
                type="resourceRequest"
                :resource-request-status="status"
              />
            </PmTimelineItemPure>
          </PmTimelinePure>
        </PmKeyValuePure> -->

        <PmKeyValuePure label="Verlauf">
          <PmTimelinePure>
            <PmResourceRequestCommunicationItem
              v-for="item in communication"
              :key="`${item.status}.${item.date.getTime()}`"
              v-bind="item"
              :timeframe="timeframe"
            />

            <template #actions>
              <slot name="sendMessage">
                <PmSlotPlaceholderPure> sendMessage </PmSlotPlaceholderPure>
              </slot>
            </template>
          </PmTimelinePure>
        </PmKeyValuePure>

        <PmKeyValuePure label="Erstellt">
          <template v-if="props.createdDate && props.createdBy">
            {{ formatToRelativeDate(props.createdDate) }}
            von {{ props.createdBy }}
          </template>
        </PmKeyValuePure>

        <PmKeyValuePure label="Zuletzt geändert">
          <template v-if="props.editedDate && props.editedBy">
            {{ formatToRelativeDate(props.editedDate) }}
            von {{ props.editedBy }}
          </template>
        </PmKeyValuePure>
      </template>
    </PmKeyValueListPure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { isValidDate } from '@/utilities/date'

const COMPONENT_NAME = 'PmDataModalResourceRequestPure'

export const propTypes = {
  type: {
    allowed: [RESOURCE_TYPE.ADDRESS, RESOURCE_TYPE.VEHICLE],
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import {
  type ResourceAllocationStatus,
  STATUS_TRAVEL_AND_HOTEL_LOOKUP,
  STATUS_RESOURCE_ALLOCATION_LOOKUP_LABEL,
  type ProjectStatus,
  type JobStatus,
  type StatusResourceRequestFeedback,
  type StatusResourceRequest,
  RESOURCE_TYPE,
} from '@/constants/persoplan'
import { IS_DEVELOPMENT } from '@/constants/general'
import { FEATURE_FLAG } from '@/constants/featureFlags'
import { max as maxDate, min as minDate } from 'date-fns'

import {
  extractStatusAndValueFromString,
  stringifyValueWithStatus,
} from '@/utilities/string'
import { lookup } from '@/utilities/misc'
import {
  formatWithLocale,
  startEndDateForText,
  formatToRelativeDate,
} from '@/utilities/date'

import { useProjectStatus } from '@/composition/useProjectStatus'
import { getJobColor } from '@/composition/useJobStatus'

import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmLinkPure from '@/components/basics/PmLink/PmLinkPure.vue'
import PmStatusDotPure from '@/components/persoplan/PmStatusDot/PmStatusDotPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmNotePure from '@/components/PmNote/PmNotePure.vue'
import PmKeyValueListPure from '@/components/basics/PmKeyValueList/PmKeyValueListPure.vue'
import PmKeyValuePure from '@/components/basics/PmKeyValueList/PmKeyValuePure.vue'
import PmTimelinePure from '@/components/basics/PmTimeline/PmTimelinePure.vue'
import PmResourceRequestCommunicationItem, {
  type Props as PropsResourceRequestCommunicationItem,
} from '@/components/persoplan/DataModal/PmDataModalResourceRequest/PmResourceRequestCommunicationItem.vue'
import type { Nilable } from '@/types/misc'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmTimelineItemPure from '@/components/basics/PmTimeline/PmTimelineItemPure.vue'
import PmSlotPlaceholderPure from '@/components/basics/PmSlotPlaceholder/PmSlotPlaceholderPure.vue'

export interface Props {
  isLoading?: boolean
  isLoadingInitialData?: boolean
  type?: (typeof propTypes.type.allowed)[number]
  id: Nilable<number>
  statusFeedback?: Nilable<StatusResourceRequestFeedback>
  status?: Nilable<StatusResourceRequest>
  createdDate?: Nilable<Date>
  createdBy?: Nilable<string>
  editedDate?: Nilable<Date>
  editedBy?: Nilable<string>
  dateOfExpire?: Nilable<Date>
  resource?: Nilable<Resource>
  project?: Nilable<Project>
  jobs?: Nilable<Job[]>
  resourceAllocations: Nilable<ResourceAllocation[]>
  communication?: PropsResourceRequestCommunicationItem[]
}

export type Message = {
  date?: Date
  from?: string
  subject?: Nilable<string>
  body?: Nilable<string>
}

export type Project = {
  number: Nilable<string>
  label: Nilable<string>
  status: Nilable<ProjectStatus>
}

export type Job = {
  id: number
  number: Nilable<string>
  label: Nilable<string>
  status: Nilable<JobStatus>
}

export type Resource = {
  name?: Nilable<string>
}

export type ResourceAllocation = {
  id: number
  jobId: number
  startDate: Nilable<Date>
  endDate: Nilable<Date>
  title?: Nilable<string>
  status?: Nilable<ResourceAllocationStatus>
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'openProject'): void
  (event: 'openJob'): void
  (event: 'openAppointment'): void
  (event: 'openResource'): void
}>()

const statusNormalized = computed(() => {
  return lookup(props.statusFeedback, STATUS_RESOURCE_ALLOCATION_LOOKUP_LABEL)
})

const projectStatusNormalized = computed(() => props.project?.status)

const projectStatus = useProjectStatus({
  status: projectStatusNormalized,
})

type JobWithResourceAllocation = Job & {
  color?: string
  resourceAllocations: ResourceAllocation[]
}

const jobsWithResourceAllocations = computed(() => {
  const result: JobWithResourceAllocation[] = []

  props.jobs?.forEach((job) => {
    if (!job) return

    const resourceAllocations =
      props.resourceAllocations?.filter(
        (resourceAllocation) => resourceAllocation.jobId === job.id
      ) ?? []

    result.push({
      ...job,
      color: getJobColor(job.status),
      resourceAllocations: resourceAllocations,
    })
  })

  return result
})

const timeframe = computed(() => {
  if (!props.resourceAllocations) return

  const allStartDates: Date[] = []
  const allEndDates: Date[] = []

  props.resourceAllocations.forEach((resourceAllocation) => {
    if (isValidDate(resourceAllocation.startDate)) {
      allStartDates.push(resourceAllocation.startDate)
    }

    if (isValidDate(resourceAllocation.endDate)) {
      allEndDates.push(resourceAllocation.endDate)
    }
  })

  const earliestStartDate = minDate(allStartDates)
  const latestEndDate = maxDate(allEndDates)

  if (!isValidDate(earliestStartDate)) return
  if (!isValidDate(latestEndDate)) return

  return {
    start: earliestStartDate,
    end: latestEndDate,
  }
})
</script>

<style lang="scss">
@use 'sass:math';

.PmDataModalResourceRequestPure {
  $block: &;

  &-project {
    display: flex;
    gap: 8px;
  }

  &-jobs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-jobsItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-job {
    display: flex;
    gap: 8px;
  }

  &-resourceAllocations {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &-resourceAllocation {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &-responseStatus {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &-responseMessage {
    margin-top: 12px;
  }
}
</style>
