<template>
  <div class="PmModalPure" :class="classes" @keydown.esc="close">
    <div class="PmModalPure-wrapper">
      <div class="PmModalPure-container">
        <PmButtonPure
          v-if="canBeClosed"
          class="PmModalPure-close"
          icon="close"
          alternative="ghost"
          @click="close"
        />

        <div class="PmModalPure-inner">
          <div v-if="title" class="PmModalPure-title">
            {{ title }}
          </div>

          <div v-if="isLoading" class="PmModalPure-loading">
            <PmLoadingPure size="default" />
          </div>

          <template v-if="!isLoading">
            <div class="PmModalPure-content">
              <slot />
            </div>

            <div
              v-if="$slots.additionalContent"
              class="PmModalPure-additionalContent"
            >
              <slot name="additionalContent" />
            </div>

            <footer
              v-if="$slots.footer || (buttons && buttons.length)"
              class="PmModalPure-footer"
            >
              <slot name="footer" />

              <div class="PmModalPure-buttons">
                <PmButtonListPure :full-width-on-mobile="true">
                  <PmButtonPure
                    v-for="button in buttons"
                    :key="button.title"
                    :label="button.label"
                    :variant="button.variant"
                    nope-class="[
                  figureOutModifier(button.type),
                  { 'is-loading': button.loading }
                ]"
                    nope-click="onButtonClick(button.type || button.title)"
                  />
                </PmButtonListPure>
              </div>
            </footer>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmModalPure'

export const propTypes = {
  layout: {
    allowed: ['default', 'fullWidth'],
    default: 'default',
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { onMounted, onBeforeUnmount, computed, provide } from 'vue'

// import createFocusTrap from 'focus-trap'

import PmButtonPure, {
  type Props as PropsButtonPure,
} from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import { PmModalPureKey } from '@/components/basics/PmModalPure'

export interface Props {
  title?: string
  buttons?: PropsButtonPure[]
  canBeClosed?: boolean
  layout?: (typeof propTypes.layout.allowed)[number]
  isLoading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  canBeClosed: true,
  layout: 'default',
})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--layoutDefault`]: props.layout === 'default',
    [`${COMPONENT_NAME}--layoutFullWidth`]: props.layout === 'fullWidth',
  }
})

provide(PmModalPureKey, true)

onMounted(() => {
  addBodyClass()

  // this.focusTrap = createFocusTrap(this.$el)
  // this.focusTrap.activate()
})

onBeforeUnmount(() => {
  removeBodyClass()
  // this.focusTrap.deactivate()
})

function addBodyClass() {
  // // save scroll position, when modal was opened
  // this.$store.commit('setObject', {
  //   modalOpen: true,
  //   savedScrollPosition: {
  //     x: document.scrollingElement.scrollLeft,
  //     y: document.scrollingElement.scrollTop
  //   }
  // })

  // // apply "fake" scroll position to fixed body
  // TweenMax.set(document.body, {
  //   left: -document.scrollingElement.scrollLeft,
  //   top: -document.scrollingElement.scrollTop
  // })

  document.documentElement.classList.add('-modalOpen')
  // window.addEventListener('touchmove', this.preventBackgroundScroll, false)
}

function removeBodyClass() {
  // this.$store.commit('setObject', { modalOpen: false })

  document.documentElement.classList.remove('-modalOpen')
  // window.removeEventListener(
  //   'touchmove',
  //   this.preventBackgroundScroll,
  //   false
  // )

  // // scroll back to saved position
  // TweenMax.set(document.scrollingElement, {
  //   scrollTo: {
  //     x: this.$store.state.savedScrollPosition.x,
  //     y: this.$store.state.savedScrollPosition.y
  //   }
  // })

  // // remove fake scroll position
  // TweenMax.set(document.body, {
  //   clearProps: 'left, top'
  // })
}

// function preventBackgroundScroll(event) {
//   if (event.target === this.$el) {
//     event.preventDefault()
//   }
// }

/* closeIfPossible () {
      if (!this.confirmClose) this.close()
    },

    close () {
      // if (!this.confirmClose) this.$store.commit('modal/destroy')
      this.$store.commit('modal/destroy')
    },

    closeAndReturn (value) {
      // console.log('close and return: ' + value)
      this.callback(value)
      this.$store.commit('modal/destroy')
    } */

function close() {
  if (!props.canBeClosed) return

  // console.warn('close modal')
  emit('close')
}
</script>

<style lang="scss">
@use 'sass:map';
@use '@/assets/scss/shadows.scss' as shadow;

// Prevet iOS from scrolling in background:
/* stylelint-disable */
html.-modalOpen {
  pointer-events: none;

  // body {
  //   height: 100vw;
  //   overflow: hidden;
  //   position: fixed;
  // }
}
/* stylelint-enable */

.PmModalPure {
  $block: &;

  @include cssVar.define($block, 'spaceOutside', var(--space-default));
  @include cssVar.define($block, 'spaceInside', 30px);

  @include mixin.media('<=mobile') {
    @include cssVar.define($block, 'spaceOutside', 8px);
    @include cssVar.define($block, 'spaceInside', 12px);
  }

  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: constant.$zIndex-modal;
  background-color: rgba(color.$black, 0.4);
  pointer-events: auto;

  // overflow-x: hidden;
  // overflow-y: auto;

  &-wrapper {
    min-height: calc(100% - (cssVar.use($block, 'spaceOutside') * 2));
    height: calc(100% - (cssVar.use($block, 'spaceOutside') * 2));
    margin-top: cssVar.use($block, 'spaceOutside');
    margin-bottom: cssVar.use($block, 'spaceOutside');
    width: calc(100% - (cssVar.use($block, 'spaceOutside') * 2));
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    pointer-events: none;
    margin-left: auto;
    margin-right: auto;

    #{$block}--layoutDefault & {
      max-width: 1000px;
    }
  }

  &-container {
    @include shadow.default('high');

    border-radius: constant.$borderRadius-default;
    background-color: #fff;
    z-index: 10;
    width: 100%;
    max-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    overflow: hidden;
  }

  &-close {
    position: absolute !important;
    right: 5px;
    top: 5px;
    z-index: 2;
  }

  &-inner {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
  }

  &-title {
    grid-area: title;
    // font: $font-size-large $font-stack-default;
    font-weight: 500;

    // margin-bottom: 30px;
    // margin-left: -30px;
    // margin-right: -30px;
    // margin-top: -10px;

    margin-top: 20px;
    padding-left: cssVar.use($block, 'spaceInside');
    padding-right: calc(cssVar.use($block, 'spaceInside') + 30px);
    padding-top: 10px;
    padding-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;

    @include mixin.media('<=mobile') {
      margin-top: 0;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: cssVar.use($block, 'spaceInside');
      right: cssVar.use($block, 'spaceInside');
      height: 2px;
      background: color.$gray-200;
      pointer-events: none;
    }

    // Gradient for soft Transition between title and content
    // &::before {
    //   content: '';
    //   background: linear-gradient(0deg, rgba(#fff, 0), rgba(#fff, 1));
    //   position: absolute;
    //   left: 0;
    //   top: 100%;
    //   right: 0;
    //   height: 50px;
    //   pointer-events: none;
    // }
  }

  &-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-small);
  }

  &-content {
    padding: cssVar.use($block, 'spaceInside');
  }

  &-additionalContent {
    padding: cssVar.use($block, 'spaceInside');
    background: color.$gray-50;
    border-top: 2px solid color.$gray-200;
  }

  &-footer {
    position: sticky;
    bottom: 0;
    padding: calc(cssVar.use($block, 'spaceInside') / 2)
      calc(cssVar.use($block, 'spaceInside') / 2);
    background-color: color.$white;
    border-top: 2px solid color.$gray-200;

    @include mixin.media('<=mobile') {
      padding: cssVar.use($block, 'spaceInside');
    }

    // Shdow
    &::before {
      content: '';
      width: 100%;
      height: 30px;
      position: absolute;
      bottom: 100%;
      left: 0;
      pointer-events: none;
      background: linear-gradient(
        0deg,
        rgba(color.$black, 0.1),
        ease-out,
        rgba(color.$black, 0)
      );
    }
  }
}
</style>
