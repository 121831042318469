<template>
  <div
    ref="elPopover"
    class="PmPopoverPure"
    :class="classes"
    :style="stylesRoot"
    @mouseenter="maybeMoveAwayFromMouse"
  >
    <div ref="elContainer" class="PmPopoverPure-container">
      <div class="PmPopoverPure-backdrop">
        <div
          v-show="isArrowVisible"
          ref="elArrow"
          class="PmPopoverPure-arrow"
          :style="stylesArrow"
        >
          <svg viewBox="0 0 10 10" preserveAspectRatio="none">
            <polygon points="0,0 10,0 5,10"></polygon>
          </svg>
        </div>
      </div>

      <div class="PmPopoverPure-content" :style="stylesContent">
        <slot v-if="isSlotVisible" />

        <div class="PmPopoverPure-control">
          <PmLoadingPure
            v-if="isLoading && isLoadingInline"
            size="smallest"
            class="PmPopoverPure-loaderInline"
          />

          <PmButtonPure
            v-if="isCloseButtonVisibleNormalized"
            class="PmPopoverPure-close"
            icon="close"
            alternative="ghost"
            @click="emit('close')"
          />
        </div>

        <PmLoadingPure
          v-if="isLoading && !isLoadingInline"
          class="PmPopoverPure-loader"
          size="small"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'

const COMPONENT_NAME = 'PmPopoverPure'

export const LOADER_POSITION = {
  DEFAULT: 'default',
  INLINE: 'inline',
} as const

export const propTypes = {
  isPositionedOn: {
    allowed: ['pointer', 'element'] as const,
    default: 'pointer' as const,
  },

  loaderPosition: {
    allowed: [LOADER_POSITION.DEFAULT, LOADER_POSITION.INLINE] as const,
    default: LOADER_POSITION.DEFAULT,
  },

  placement: {
    // See: https://floating-ui.com/docs/computePosition#placement
    allowed: [
      'top',
      'top-start',
      'top-end',
      'right',
      'right-start',
      'right-end',
      'bottom',
      'bottom-start',
      'bottom-end',
      'left',
      'left-start',
      'left-end',
    ] as const,
    default: 'top' as const,
  },
  strategy: {
    allowed: ['fixed', 'absolute'] as const,
    default: 'absolute' as const,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import {
  ref,
  watch,
  nextTick,
  computed,
  onBeforeUnmount,
  onMounted,
  type StyleValue,
} from 'vue'

import {
  computePosition,
  autoUpdate,
  offset as offsetMiddleware,
  flip as flipMiddleware,
  shift as shiftMiddleware,
  arrow as arrowMiddleware,
  size as sizeMiddleware,
  limitShift,
  type ReferenceElement,
  type Placement,
  type VirtualElement,
} from '@floating-ui/dom'

import { viewport } from '@/composition/useViewportObserver'
import { lookup } from '@/utilities/misc'

import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'

export interface Props {
  isPositionedOn?: (typeof propTypes.isPositionedOn.allowed)[number]
  element?: HTMLElement
  isLoading?: boolean
  loaderPosition?: (typeof propTypes.loaderPosition.allowed)[number]
  padding?: boolean
  isShy?: boolean
  placement?: (typeof propTypes.placement.allowed)[number]
  fallbackElements?: HTMLElement[]
  isArrowVisible?: boolean
  strategy?: (typeof propTypes.strategy.allowed)[number]
  isCloseButtonVisible?: boolean
  useMaxDimensions?: boolean
  useMaxHeight?: boolean
  scrollContent?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isPositionedOn: propTypes.isPositionedOn.default,
  loaderPosition: propTypes.loaderPosition.default,
  padding: true,
  placement: propTypes.placement.default,
  fallbackElements: () => [],
  isArrowVisible: true,
  strategy: propTypes.strategy.default,
})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const componentClass = useComponentClass()

const arrowWidth = 20
const arrowHeight = 8
const arrowSize = Math.max(arrowWidth, arrowHeight)

const elPopover = ref()
const elArrow = ref()
const elContainer = ref()

const popoverPosition = ref({ x: 0, y: 0 })
const popoverMaxDimensions = ref<{ width?: number; height?: number }>({
  width: undefined,
  height: undefined,
})
const currentPlacement = ref()
const shyPlacement = ref()
const arrowPosition = ref<{ x?: number; y?: number }>({ x: 0, y: 0 })

type ArrowStaticSide = 'top' | 'left' | 'bottom' | 'right'
const arrowStaticSide = ref<ArrowStaticSide | undefined>('bottom')
let floatingUi: { destroy: () => void } | undefined
let referenceElement: ReferenceElement | undefined

const placementNormalized = computed(() => {
  if (props.isPositionedOn === 'element' && props.isShy && shyPlacement.value) {
    return shyPlacement.value
  }

  return props.placement
})

const paddingOutside = computed(() => {
  // TODO: Use 8px on mobile for main navigation dropdown for better visuals
  return 8
})

const arrowPadding = computed(() => {
  if (!props.isArrowVisible) return 0
  return 8
})

const offset = computed(() => {
  if (!props.isArrowVisible) {
    return {
      along: 0,
      away: 4,
    }
  }

  return {
    along: 0,
    away: arrowHeight / 2 - 2,
  }
})

/**
 * Watch all dynamic popover options to update when they change
 */
const optionsToWatch = computed(() => {
  return {
    placementNormalized: placementNormalized.value,
    paddingOutside: paddingOutside.value,
    offset: offset.value,
    isArrowVisible: props.isArrowVisible,
  }
})

watch(optionsToWatch, () => placePopover())

const virtualElement = computed<VirtualElement>(() => {
  // Reference the dynamic variables, so this
  // computed property stays reactive:
  viewport.mouseX
  viewport.mouseY

  return {
    getBoundingClientRect: () => {
      return {
        width: 0,
        height: 0,
        top: viewport.mouseY,
        right: viewport.mouseX,
        bottom: viewport.mouseY,
        left: viewport.mouseX,
        x: viewport.mouseX,
        y: viewport.mouseY,
      }
    },
  }
})

const useMaxDimensionsNormalized = computed(() => {
  if (props.scrollContent) return true
  if (props.useMaxDimensions) return true

  return false
})

const stylesRoot = computed<StyleValue>(() => {
  const root: {
    transform: string
    position: Props['strategy']
    maxWidth?: string
    maxHeight?: string
  } = {
    transform: `translate(${popoverPosition.value.x}px, ${popoverPosition.value.y}px)`,
    position: props.strategy,
  }

  if (useMaxDimensionsNormalized.value && popoverMaxDimensions.value.width) {
    root.maxWidth = `${popoverMaxDimensions.value.width}px`
  }

  if (useMaxDimensionsNormalized.value && popoverMaxDimensions.value.width) {
    root.maxHeight = `${popoverMaxDimensions.value.height}px`
  }

  return {
    [`--${COMPONENT_NAME}-arrowSize`]: `${arrowSize}px`,
    [`--${COMPONENT_NAME}-arrowWidth`]: `${arrowWidth}px`,
    [`--${COMPONENT_NAME}-arrowHeight`]: `${arrowHeight}px`,
    [`--${COMPONENT_NAME}-paddingOutside`]: `${paddingOutside.value}px`,
    ...root,
  }
})

const stylesArrow = computed<StyleValue>(() => {
  const arrow = {
    left: arrowPosition.value.x != null ? `${arrowPosition.value.x}px` : '',
    top: arrowPosition.value.y != null ? `${arrowPosition.value.y}px` : '',
    right: '',
    bottom: '',
  }

  if (arrowStaticSide.value) {
    arrow[arrowStaticSide.value] = `${-arrowSize}px`
  }

  return arrow
})

const stylesContent = computed<StyleValue>(() => {
  const styles: StyleValue = {}

  if (useMaxDimensionsNormalized.value && popoverMaxDimensions.value.width) {
    styles.maxHeight = `${popoverMaxDimensions.value.height}px`
  }

  return styles
})

const classes = computed(() => {
  return [
    {
      [`${COMPONENT_NAME}--padding`]: props.padding === true,
      [`${COMPONENT_NAME}--useMaxDimensions`]: props.useMaxDimensions === true,
      [`${COMPONENT_NAME}--scrollContent`]: props.scrollContent === true,
      'is-positionedOnPointer': props.isPositionedOn === 'pointer',
    },
    componentClass.propModifier({
      name: 'arrow',
      value: arrowStaticSide.value,
    }),
  ]
})

const isLoadingInline = computed(() => {
  return props.isLoading && props.loaderPosition === LOADER_POSITION.INLINE
})

const isSlotVisible = computed(() => {
  if (props.isLoading && !isLoadingInline.value) return false
  return true
})

const placePopover = async () => {
  if (!referenceElement) return
  if (!elPopover.value) return

  const { x, y, middlewareData, placement } = await computePosition(
    referenceElement,
    elPopover.value,
    {
      strategy: props.strategy,
      placement: placementNormalized.value,
      middleware: [
        offsetMiddleware({
          mainAxis: offset.value.away,
          crossAxis: offset.value.along,
        }),

        flipMiddleware({
          padding: paddingOutside.value,
        }),

        shiftMiddleware({
          padding: paddingOutside.value,
          limiter: limitShift({
            offset: paddingOutside.value + arrowPadding.value * 2,
          }),
        }),

        sizeMiddleware({
          apply({ availableWidth, availableHeight }) {
            popoverMaxDimensions.value.width = Math.max(100, availableWidth)
            popoverMaxDimensions.value.height = Math.max(100, availableHeight)
          },
          padding: paddingOutside.value,
        }),

        arrowMiddleware({
          element: elArrow.value,
          padding: arrowPadding.value,
        }),
      ],
    }
  )

  popoverPosition.value.x = x
  popoverPosition.value.y = y

  currentPlacement.value = placement

  arrowPosition.value.x = middlewareData?.arrow?.x
  arrowPosition.value.y = middlewareData?.arrow?.y

  arrowStaticSide.value = getArrowStaticSide(placement)
}

function getArrowStaticSide(placement: Placement): ArrowStaticSide | undefined {
  const placementSide = placement.split('-')[0]

  if (
    placementSide !== 'top' &&
    placementSide !== 'right' &&
    placementSide !== 'bottom' &&
    placementSide !== 'left'
  ) {
    return undefined
  }

  const lookupTable = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  } as const

  const arrowStaticSide = lookup(placementSide, lookupTable)

  return arrowStaticSide
}

const initFloatingUi = () => {
  if (floatingUi) throw new Error('floatingUi is already initialized!')
  if (!referenceElement) throw new Error('referenceElement is undefined')

  const destroy = autoUpdate(referenceElement, elPopover.value, placePopover)

  return {
    destroy,
  }
}

onBeforeUnmount(() => {
  if (!floatingUi?.destroy) return
  floatingUi.destroy()
})

if (props.isPositionedOn === 'element') {
  watch(
    () => props.element,
    async () => {
      if (!props.element) return
      if (floatingUi) return

      await nextTick()
      referenceElement = props.element
      floatingUi = initFloatingUi()
    },
    {
      immediate: true,
    }
  )
}

if (props.isPositionedOn === 'pointer') {
  onMounted(() => {
    referenceElement = virtualElement.value
    initFloatingUi()
    watch(virtualElement, placePopover)
  })
}

/**
 * Shy Beaviour
 */
if (props.isShy && props.placement !== 'top' && props.placement !== 'bottom') {
  throw new Error(`"isShy" only works with placement top or bottom!`)
}

const maybeMoveAwayFromMouse = () => {
  if (!floatingUi) return

  if (currentPlacement.value === 'top') {
    shyPlacement.value = 'bottom'
  }

  if (currentPlacement.value === 'bottom') {
    shyPlacement.value = 'top'
  }
}

/**
 * Close Button
 */
const isCloseButtonVisibleNormalized = computed(() => {
  if (props.isPositionedOn === 'pointer') return false
  if (props.isLoading && props.loaderPosition === LOADER_POSITION.DEFAULT) {
    return false
  }

  return props.isCloseButtonVisible
})
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmPopoverPure {
  $block: &;

  $colorBorder: color.$gray-300--alpha;
  $colorBackground: color.$white;
  $borderRadius: constant.$borderRadius-default;

  @include cssVar.define($block, 'paddingOutside', 0);

  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;

  &:not(&--useMaxDimensions) {
    max-width: calc(100vw - cssVar.use($block, 'paddingOutside') * 2);
  }

  &.is-positionedOnPointer {
    pointer-events: none;
  }

  &.v-enter-active,
  &.v-leave-active {
    // This fixes a bug for PmTooltipPure, where the tooltip would open/close rapidly
    // when the mouse would overlap with the popover
    pointer-events: none;
  }

  &-container {
    border-radius: $borderRadius;

    #{$block}.is-fixed & {
      pointer-events: auto;

      /* stylelint-disable */
      html.-modalOpen & {
        pointer-events: none;
      }
      /* stylelint-enable */
    }
  }

  &-backdrop {
    @include shadow.default(
      'low',
      $filter: true,
      $outline: true,
      $outlineColor: $colorBorder
    );

    position: absolute;
    inset: 0;
    border-radius: $borderRadius;
    background-color: $colorBackground;
  }

  &-arrow {
    width: cssVar.use($block, 'arrowSize');
    height: cssVar.use($block, 'arrowSize');
    position: absolute;

    #{$block}--arrowLeft & {
      transform: rotate(90deg);
    }

    #{$block}--arrowRight & {
      transform: rotate(-90deg);
    }

    #{$block}--arrowTop & {
      transform: rotate(180deg);
    }

    & svg {
      display: block;
      width: 100%;
      height: cssVar.use($block, 'arrowHeight');
      position: absolute;
      top: 0;
      left: 0;
      overflow: visible;
      fill: $colorBackground;

      /* stylelint-disable-next-line plugin/selector-bem-pattern */
      & * {
        vector-effect: non-scaling-stroke;
      }
    }
  }

  &-content {
    position: relative;
    z-index: 2;

    #{$block}--scrollContent & {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      overscroll-behavior: contain;
    }

    #{$block}--useMaxDimensions:not(#{$block}--scrollContent) & {
      overflow: hidden;
    }

    #{$block}--padding & {
      padding: 12px;
    }
  }

  &-label {
    @include mixin.textLabel;

    margin-bottom: 5px;
  }

  &-control {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
    display: flex;
    // gap: 4px;
  }

  &-loader {
    z-index: 1;

    #{$block}:not(#{$block}--padding) & {
      padding: 8px;
      box-sizing: content-box;
    }
  }

  &-loaderInline {
    box-sizing: content-box;
    padding: 8px 4px;
  }
}
</style>
