<template>
  <div class="PmResourceAllocationEdit">
    <portal to="popover">
      <PmPopoverWithTransitionPure
        :is-visible="isPopoverVisible"
        :element="popoverElement"
        is-positioned-on="element"
        :is-shy="popoverIsShy"
        :is-loading="isLoadingInitialData"
        loader-position="inline"
      >
        <div v-if="formData && resourceAllocationStates.statusOptions.value">
          <PmResourceAllocationEditPure
            v-show="true"
            :id="id"
            v-model:value="formData"
            v-model:userIntentsToDelete="userIntentsToDeleteResourceAllocation"
            :saved-status-id="savedFormDataNormalized.statusId"
            :label="label"
            :status-options="resourceAllocationStates.statusOptions.value"
            :type="type"
            :state="xstate.path.value"
            :error-message="xstate.state.value.context.error"
            :error-details="xstate.state.value.context.errorDetails"
            :driver="resourceAllocationEditVehicle.driverNormalized.value"
            :driver-loading="resourceAllocationEditVehicle.driverLoading.value"
            :vehicle="resourceAllocationEditAddress.vehicleNormalized.value"
            :vehicle-loading="
              resourceAllocationEditAddress.vehicleLoading.value
            "
            :resource-request="resourceRequest"
            :can-not-be-requested-reason="canNotBeRequestedReason"
            @start-vehicle-allocation="startVehicleAllocation"
            @unlink-vehicle-allocation="
              resourceAllocationEditAddress.newVehicleAllocationId.value = null
            "
            @start-driver-allocation="startDriverAllocation"
            @unlink-driver-allocation="
              resourceAllocationEditVehicle.newDriverAllocationId.value = null
            "
            @cancel="$emit('close')"
            @save="xstate.service.value.send('SAVE')"
            @save-and-request="xstate.service.value.send('SAVE_AND_REQUEST')"
            @create="xstate.service.value.send('CREATE')"
            @create-and-request="
              xstate.service.value.send('CREATE_AND_REQUEST')
            "
            @delete="xstate.service.value.send('DELETE')"
          >
            <template #resourceDay>
              <PmAppNotificationPure
                v-if="
                  isResourceDayNotificationVisible &&
                  resourceStartDate &&
                  resourceEndDate &&
                  !isTooManyDays
                "
                open-details-label="Resourcen-Tag anzeigen"
                :title="resourceDayNotificationTitle"
                :is-details-visible="isResourceDayVisible"
                @open-details="isResourceDayVisible = true"
              >
                {{ resourceDayNotificationText }}

                <template #details>
                  <PmResourceDay
                    :address-id="resourceAllocation?.address?.id || addressId"
                    :vehicle-id="resourceAllocation?.vehicle?.id || vehicleId"
                    :start-date="resourceStartDate"
                    :end-date="resourceEndDate"
                    :type="type"
                    show-in="none"
                    @close="isResourceDayVisible = false"
                  />
                </template>
              </PmAppNotificationPure>

              <PmAppNotificationPure
                v-if="isResourceDayNotificationVisible && isTooManyDays"
                :title="resourceDayNotificationTitle"
              >
                Für Resourcen mit mehr als
                {{ RESOURCE_DAY_MAX_DAYS }} Tagen kann der Ressourcen-Tag nicht
                angezeigt werden
              </PmAppNotificationPure>
            </template>

            <template #conflicts>
              <PmResourceAllocationConflict
                v-if="
                  resourceIdNormalized &&
                  type &&
                  isConflictsVisible &&
                  !isResourceDayNotificationVisible
                "
                :address-id="resourceAllocation?.address?.id || addressId"
                :vehicle-id="resourceAllocation?.vehicle?.id || vehicleId"
                :resource-id="resourceIdNormalized"
                :resource-allocation-id="resourceAllocation?.id"
                :number-of-days="numberOfDays"
                :type="
                  resourceAllocationEditAddress.isFreelancer.value
                    ? RESOURCE_TYPE.FREELANCER
                    : type
                "
              />

              <PmAppNotificationPure
                v-if="resourceAllocationEditAddress.isStaffAgency.value"
              >
                Zuordnungen von Personaldienstleistern werden nicht auf
                Zeitkonflikte geprüft.
              </PmAppNotificationPure>
            </template>
          </PmResourceAllocationEditPure>
        </div>
      </PmPopoverWithTransitionPure>
    </portal>

    <portal
      v-if="driverVehicleControllerVisible && driverVehicleControllerType"
      to="context"
    >
      <PmDriverVehicleControllerPure
        :type="driverVehicleControllerType"
        @cancel="xstate.service.value.send('CANCEL')"
      />
    </portal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useResourceAllocationStates } from '@/composition/useResourceAllocationStates'

const COMPONENT_NAME = 'PmResourceAllocationEdit'

export const propTypes = {
  type: {
    allowed: [
      RESOURCE_TYPE.ADDRESS,
      RESOURCE_TYPE.FREELANCER,
      RESOURCE_TYPE.VEHICLE,
    ] as const,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
/**
 * @todo This does not work with transitions since upgrade to vue 3
 */

import { ref, onMounted, nextTick, computed, watch, toRef } from 'vue'
import { cloneDeep, isNil } from 'lodash-es'
import { useStore } from 'vuex'
import { useMutation, useQuery } from '@vue/apollo-composable'
import type { ApolloCache } from '@apollo/client/cache'
import { isSameDay, differenceInCalendarDays, isValid } from 'date-fns'

import { throwFriendlyError } from '@/functional/error'
import {
  RESOURCE_TYPE,
  STATUS_RESOURCE_ALLOCATION_SORT_ORDER,
  STATUS_RESOURCE_ALLOCATION,
  STATUS_RESOURCE_ALLOCATION_ID_LOOKUP,
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
  RESOURCE_DAY_MAX_DAYS,
  type ResourceType,
  type ResourceAllocationStatus,
} from '@/constants/persoplan'
import { ERROR } from '@/constants/errors'
import { EVENT } from '@/constants/events'

import { PmResourceAllocationEditState } from '@/components/persoplan/PmResourceAllocation/PmResourceAllocationEditState'
import { useXState } from '@/composition/useXState'
import EventHub from '@/eventHub'
import { persoplanStateKey, injectStrict } from '@/utilities/inject'
import { useResourceAllocationEditAddress } from '@/components/persoplan/PmResourceAllocation/useResourceAllocationEditAddress'
import { useResourceAllocationEditVehicle } from '@/components/persoplan/PmResourceAllocation/useResourceAllocationEditVehicle'
import { parseServerDateString } from '@/utilities/date'
import { lookup } from '@/utilities/misc'

import PmResourceAllocationEditPure, {
  type FormData,
  type Props as PropsResourceAllocationEditPure,
} from '@/components/persoplan/PmResourceAllocation/PmResourceAllocationEditPure.vue'
import PmPopoverWithTransitionPure from '@/components/basics/PmPopover/PmPopoverWithTransitionPure.vue'
import PmDriverVehicleControllerPure from '@/components/persoplan/PmDriverVehicleController/PmDriverVehicleControllerPure.vue'
import PmResourceAllocationConflict from '@/components/persoplan/PmResourceAllocationConflict/PmResourceAllocationConflict.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmResourceDay from '@/components/persoplan/PmResourceDay/PmResourceDay.vue'

import {
  DeleteResourceAllocationDocument,
  ResourceDocument,
  ResourceAllocationDocument,
} from '@/../generated/graphql'

export interface Props {
  id?: number | undefined
  resourceId?: number | undefined
  addressId?: number | undefined
  vehicleId?: number | undefined
  type: (typeof propTypes.type.allowed)[number]
  popoverElement: any
}

export type Emits = {
  createResourceRequest: { resourceAllocationId: number }
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  close: []
  createResourceRequest: [Emits['createResourceRequest']]
}>()

const store = useStore()
const persoplanState = injectStrict(persoplanStateKey)
const resourceAllocationStates = useResourceAllocationStates()

// Hack for popover transition
const isPopoverVisible = ref(false)
onMounted(async () => {
  await nextTick()
  isPopoverVisible.value = true
})

const DEFAULT_STATUS_LOOKUP: Record<ResourceType, ResourceAllocationStatus> = {
  vehicle: 'requested',
  address: 'considered',
  freelancer: 'considered',
}

const formData = ref<FormData>({
  statusId:
    STATUS_RESOURCE_ALLOCATION_ID_LOOKUP[DEFAULT_STATUS_LOOKUP[props.type]],
})

const mode = computed(() => {
  return props.id ? 'update' : 'create'
})

const xstate = useXState(PmResourceAllocationEditState, {
  services: {
    waitForClick: () => waitForClick,
    createResourceAllocation: (context) => {
      return createResourceAllocation({
        requestAfterCreated: context.requestAfterCreatedOrUpdated,
      })
    },
    updateResourceAllocation: (context) => {
      return updateResourceAllocation({
        requestAfterUpdated: context.requestAfterCreatedOrUpdated,
      })
    },
    deleteResourceAllocation: () => deleteResourceAllocation(),
  },

  actions: {
    startVehicleAllocation: (context, { jobId, allocatedVehicleId }) => {
      persoplanState.service.value.send('START_VEHICLE_ALLOCATION', {
        jobId,
        allocatedVehicleId,
      })
    },

    startDriverAllocation: (context, { jobId, allocatedDriverId }) => {
      persoplanState.service.value.send('START_DRIVER_ALLOCATION', {
        jobId,
        allocatedDriverId,
      })
    },

    finishAllocation: () => {
      persoplanState.service.value.send('FINISH_ALLOCATON')
    },
  },

  initialStatePath: mode.value,
})

const startVehicleAllocation = () => {
  const jobId =
    resource?.value?.job?.id ||
    resourceAllocation?.value?.resourceFunctionAllocation?.job?.id

  const allocatedVehicleId =
    resourceAllocation.value?.resourceAllocationDriver?.id

  xstate.service.value.send('START_VEHICLE_ALLOCATION', {
    jobId,
    allocatedVehicleId,
  })
}

const startDriverAllocation = () => {
  const jobId =
    resource?.value?.job?.id ||
    resourceAllocation?.value?.resourceFunctionAllocation?.job?.id

  const allocatedDriverId =
    resourceAllocation.value?.resourceAllocationDriver?.id

  xstate.service.value.send('START_DRIVER_ALLOCATION', {
    jobId,
    allocatedDriverId,
  })
}

const canNotBeRequestedReason = computed<
  PropsResourceAllocationEditPure['canNotBeRequestedReason'] | undefined
>(() => {
  const statusId = formData.value.statusId

  if (isNil(statusId)) return
  if (typeof statusId !== 'number') return

  const hasResourceRequest = !!resourceRequest.value
  if (hasResourceRequest) return 'hasResourceRequest'

  const isConsidered =
    lookup(statusId, STATUS_RESOURCE_ALLOCATION_LOOKUP) === 'considered'
  if (!isConsidered) return 'statusNotConsidered'

  return undefined
})

const resourceQuery = useQuery(
  ResourceDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => ({
    id: props.resourceId,
  }),
  () => ({
    enabled: props.resourceId ? true : false,
    fetchPolicy: 'cache-and-network', // Make sure request status is up to date
  })
)

const resource = computed(() => {
  return resourceQuery.result.value?.resource
})

const resourceAllocationQuery = useQuery(
  ResourceAllocationDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => ({
    id: props.id,
  }),
  () => ({
    enabled: props.id ? true : false,
    fetchPolicy: 'cache-and-network', // Make sure request status is up to date
  })
)

const resourceAllocation = computed(
  () => resourceAllocationQuery.result.value?.resourceAllocation
)

const resourceIdNormalized = computed(() => {
  if (props.resourceId) return props.resourceId

  const resourceId = resourceAllocation?.value?.resourceFunctionAllocation?.id
  if (resourceId) return resourceId

  return undefined
})

const isMultipleDayResource = computed(() => {
  if (resource.value) {
    const startDate = parseServerDateString(resource.value?.startDate)
    const endDate = parseServerDateString(resource.value?.endDate)

    if (!startDate) throw new Error('startDate is not defined')
    if (!endDate) throw new Error('endDate is not defined')

    return !isSameDay(startDate, endDate)
  }

  if (resourceAllocation.value?.resourceFunctionAllocation) {
    const resource = resourceAllocation.value.resourceFunctionAllocation
    const startDate = parseServerDateString(resource.startDate)
    const endDate = parseServerDateString(resource.endDate)

    if (!startDate) throw new Error('startDate is not defined')
    if (!endDate) throw new Error('endDate is not defined')

    return !isSameDay(startDate, endDate)
  }

  return undefined
})

const savedFormDataNormalized = computed(() => {
  const data: FormData = {
    statusId: resourceAllocation.value?.resourceAllocationState?.id,
    note: resourceAllocation.value?.extra?.notice,
    travel: resourceAllocation.value?.travel,
    hotel: resourceAllocation.value?.hotel,
  }

  return data
})

/**
 * Create copy of the saved value for editing
 */
watch(
  resourceAllocation,
  () => {
    if (!resourceAllocation.value) return
    formData.value = cloneDeep(savedFormDataNormalized.value)
  },
  {
    immediate: true,
  }
)

const resourceAllocationEditAddress = useResourceAllocationEditAddress({
  formData: formData,
  resourceAllocation: resourceAllocation,
  resourceId: toRef(props, 'resourceId'),
  addressId: toRef(props, 'addressId'),
})

const resourceAllocationEditVehicle = useResourceAllocationEditVehicle({
  formData: formData,
  resourceAllocation: resourceAllocation,
  resourceId: toRef(props, 'resourceId'),
  vehicleId: toRef(props, 'vehicleId'),
})

const resourceStartDate = computed(() => {
  const date =
    resourceAllocation.value?.resourceFunctionAllocation?.startDate ||
    resource.value?.startDate

  if (!date) return
  return parseServerDateString(date)
})

const resourceEndDate = computed(() => {
  const date =
    resourceAllocation.value?.resourceFunctionAllocation?.endDate ||
    resource.value?.endDate

  if (!date) return
  return parseServerDateString(date)
})

const numberOfDays = computed(() => {
  if (!resourceStartDate.value || !isValid(resourceStartDate.value)) return
  if (!resourceEndDate.value || !isValid(resourceEndDate.value)) return

  const result = differenceInCalendarDays(
    resourceEndDate.value,
    resourceStartDate.value
  )

  return result
})

const isTooManyDays = computed(() => {
  if (!numberOfDays.value) return false
  return numberOfDays.value > RESOURCE_DAY_MAX_DAYS
})

const label = computed(() => {
  if (
    props.type === RESOURCE_TYPE.ADDRESS ||
    props.type === RESOURCE_TYPE.FREELANCER
  ) {
    return resourceAllocationEditAddress.label.value
  }

  if (props.type === RESOURCE_TYPE.VEHICLE) {
    return resourceAllocationEditVehicle.label.value
  }

  return undefined
})

const resourceRequest = computed(() => {
  if (
    props.type === RESOURCE_TYPE.ADDRESS ||
    props.type === RESOURCE_TYPE.FREELANCER
  ) {
    return resourceAllocationEditAddress.resourceRequest.value
  }

  return undefined
})

const isConflictsVisible = computed(() => {
  if (numberOfDays.value === undefined) return false

  if (
    props.type === RESOURCE_TYPE.ADDRESS ||
    props.type === RESOURCE_TYPE.FREELANCER
  ) {
    return resourceAllocationEditAddress.isConflictsVisible.value
  }

  if (props.type === RESOURCE_TYPE.VEHICLE) {
    return resourceAllocationEditVehicle.isConflictsVisible.value
  }

  return true
})

const userIntentsToCancelResourceAllocation = computed(() => {
  // When status is set to cancelled
  const statusId = formData.value.statusId

  if (statusId) {
    const status = lookup(statusId, STATUS_RESOURCE_ALLOCATION_LOOKUP)

    if (status == STATUS_RESOURCE_ALLOCATION.CANCELLED) {
      return true
    }
  }

  return false
})

const userIntentsToDeleteResourceAllocation = ref(false)
const isResourceDayVisible = ref(false)

const isResourceDayNotificationVisible = computed(() => {
  if (xstate.state.value.matches('create')) return false

  return (
    userIntentsToDeleteResourceAllocation.value ||
    userIntentsToCancelResourceAllocation.value
  )
})

const resourceDayNotificationTitle = computed(() => {
  if (userIntentsToDeleteResourceAllocation.value) return 'Zuordnung löschen'
  if (userIntentsToCancelResourceAllocation.value) return 'Zuordnung absagen'

  return undefined
})

const resourceDayNotificationText = computed(() => {
  if (userIntentsToDeleteResourceAllocation.value)
    return 'Möchtest du einen Ressourcen-Status erstellen oder bearbeiten bevor du diese Zuordnung löscht?'

  if (userIntentsToCancelResourceAllocation.value)
    return 'Möchtest du einen Ressourcen-Status erstellen oder bearbeiten bevor du diese Zuordnung absagst?'

  return undefined
})

/**
 * Close when exiting edit mode
 */
const isPersoplanInEditMode = computed(() => {
  return persoplanState.state.value.matches('edit')
})

watch(isPersoplanInEditMode, () => {
  if (!isPersoplanInEditMode.value) emit('close')
})

/**
 * Visual stuff
 */
const waitForClick = () => {
  return new Promise<void>((resolve) => {
    EventHub.$once(
      EVENT.RESOURCE_ALLOCATION_CLICK,
      ({ id, type }: { id: number; type: 'address' | 'vehicle' }) => {
        if (type === RESOURCE_TYPE.ADDRESS) {
          resourceAllocationEditVehicle.newDriverAllocationId.value = id
        }

        if (type === RESOURCE_TYPE.VEHICLE) {
          resourceAllocationEditAddress.newVehicleAllocationId.value = id
        }

        resolve()
      }
    )
  })
}

const popoverIsShy = computed(() => {
  return driverVehicleControllerType.value
})

const driverVehicleControllerVisible = computed(() => {
  if (
    xstate.state.value.matches('create.vehicleAllocation') ||
    xstate.state.value.matches('update.vehicleAllocation')
  )
    return true
  if (
    xstate.state.value.matches('create.driverAllocation') ||
    xstate.state.value.matches('update.driverAllocation')
  )
    return true

  return false
})

const driverVehicleControllerType = computed(() => {
  if (
    xstate.state.value.matches('create.vehicleAllocation') ||
    xstate.state.value.matches('update.vehicleAllocation')
  )
    return RESOURCE_TYPE.ADDRESS

  if (
    xstate.state.value.matches('create.driverAllocation') ||
    xstate.state.value.matches('update.driverAllocation')
  )
    return RESOURCE_TYPE.VEHICLE

  return false
})

// const resourceAllocationStatesQuery = useQuery(ResourceAllocationStatesDocument)

// const resourceAllocationStates = computed(
//   () => resourceAllocationStatesQuery.result.value?.resourceAllocationStates
// )

// const resourceAllocationStatesNormalized = computed(() => {
//   if (!resourceAllocationStates.value) return []

//   const states = resourceAllocationStates.value.reduce<
//     {
//       id: number
//       label: string
//     }[]
//   >((result, state) => {
//     if (!state) return result

//     result.push({
//       id: state.id,
//       label: state.caption,
//     })

//     return result
//   }, [])

//   const statesOrdered = sortBy(states, (state) => {
//     // TODO: Type it better
//     // @ts-expect-error This needs better typing
//     const index = STATUS_RESOURCE_ALLOCATION_SORT_ORDER.indexOf(state.id)
//     return index
//   })

//   return statesOrdered
// })

/**
 * Create resource allocation
 */
async function createResourceAllocation(options: {
  requestAfterCreated?: boolean
}) {
  if (
    props.type === RESOURCE_TYPE.ADDRESS ||
    props.type === RESOURCE_TYPE.FREELANCER
  ) {
    const result = await resourceAllocationEditAddress.create()
    EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
    if (!result) throw new Error('result is undefined')

    if (options.requestAfterCreated) {
      if (!result.resourceAllocationId) {
        throw new Error('resourceAllocationId is undefined')
      }

      emit('createResourceRequest', {
        resourceAllocationId: result.resourceAllocationId,
      })
    }

    emit('close')

    return
  }

  if (props.type === RESOURCE_TYPE.VEHICLE) {
    await resourceAllocationEditVehicle.create()
    EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)

    emit('close')
    return
  }

  throw new Error('createResourceAllocation: No type defined')
}

/**
 * Update resource allocation
 */
async function updateResourceAllocation(options: {
  requestAfterUpdated?: boolean
}) {
  if (
    props.type === RESOURCE_TYPE.ADDRESS ||
    props.type === RESOURCE_TYPE.FREELANCER
  ) {
    const result = await resourceAllocationEditAddress.update()
    EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
    if (!result) throw new Error('result is undefined')

    if (options.requestAfterUpdated) {
      if (!result.resourceAllocationId) {
        throw new Error('resourceAllocationId is undefined')
      }

      emit('createResourceRequest', {
        resourceAllocationId: result.resourceAllocationId,
      })
    }

    emit('close')
    return
  }

  if (props.type === RESOURCE_TYPE.VEHICLE) {
    await resourceAllocationEditVehicle.update()

    EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
    emit('close')

    return
  }

  throw new Error('updateResourceAllocation: No type defined')
}

/**
 * Delete Resource Allocation
 */
const deleteResourceAllocationMutation = useMutation(
  DeleteResourceAllocationDocument
)

const deleteResourceAllocation = async () => {
  if (!resourceAllocation.value?.resourceFunctionAllocation?.id) {
    throw new Error(
      'resourceAllocation.resourceFunctionAllocation.id is undefined'
    )
  }

  if (!props.id) throw new Error('props.id is undefined')

  const variables = {
    resourceId: resourceAllocation.value.resourceFunctionAllocation.id,
    resourceAllocationId: props.id,
  }

  try {
    await deleteResourceAllocationMutation.mutate(variables, {
      update: (cache) => {
        deleteResourceAllocationUpdateCache(cache, variables)
      },
    })

    EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
  } catch (error) {
    throwFriendlyError(error)
  }
}

const deleteResourceAllocationUpdateCache = (
  cache: ApolloCache<any>,
  variables: { resourceId: number; resourceAllocationId: number }
) => {
  const queryVariables = {
    ...store.getters['queryVariables/calendar'],
    id: variables.resourceId,
  }

  const readQueryResult = cache.readQuery({
    query: ResourceDocument,
    variables: queryVariables,
  })

  // readQuery is readonly, thus we need to create a deep copy
  const data = cloneDeep(readQueryResult)

  if (!data?.resource?.resourceAllocations) {
    throw new Error('resourceAllocations is undefined')
  }

  const index = data.resource.resourceAllocations?.findIndex(
    (item) => item?.id === variables.resourceAllocationId
  )

  if (index === -1) throw new Error(ERROR.CACHEUPDATE_FAILED)

  data.resource.resourceAllocations.splice(index, 1)

  cache.writeQuery({
    query: ResourceDocument,
    variables: queryVariables,
    data,
  })
}

/**
 * Watch loading state for initial data fetching
 */
const isLoading = computed(() => {
  if (resourceAllocationQuery.loading.value === true) return true
  if (resourceQuery.loading.value === true) return true
  if (resourceAllocationStates.loading.value) return true

  return false
})

const isLoadingInitialData = ref(true)

watch(
  isLoading,
  () => {
    if (isLoadingInitialData.value === false) return
    if (isLoading.value === true) return

    isLoadingInitialData.value = false
  },
  { immediate: true }
)
</script>

<style lang="scss">
.PmResourceAllocationEdit {
  $block: &;
}
</style>
