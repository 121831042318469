<template>
  <PmLogoutPure
    :state="xstate.path.value"
    :error="xstate.state.value.context.error"
  />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useXState } from '@/composition/useXState'

import { PmLogoutState } from '@/views/PmLogout/PmLogoutState'
import { appStateKey, injectStrict } from '@/utilities/inject'
import PmLogoutPure from '@/views/PmLogout/PmLogoutPure.vue'

const store = useStore()
const appState = injectStrict(appStateKey)

const xstate = useXState(PmLogoutState, {
  services: {
    logout: () => {
      return store.dispatch('auth/logout')
    },
  },

  actions: {
    logoutSuccessful: () => {
      appState.service.value.send('LOGGED_OUT')
    },
  },
})

onMounted(() => {
  xstate.service.value.send('SIGN_OUT')
})
</script>
