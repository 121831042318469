<template>
  <div class="PmActivatePure" :class="classes">
    <PmCheckboxPure
      v-model:value="isActivatedInternal"
      :disabled="disabled"
      size="small"
    >
      {{ label }}
    </PmCheckboxPure>

    <div class="PmActivatePure-content">
      <slot
        :is-disabled="isFormElementDisabled"
        :is-activated="isActivatedInternal"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'

const COMPONENT_NAME = 'PmActivatePure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ref, computed, type VNode } from 'vue'

import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'

export interface Props {
  label: string
  disabled?: boolean
  isActivated?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'activated'): void
  (event: 'deactivated'): void
  (event: 'update:isActivated', value: boolean): void
}>()

const isActivatedInternal = ref(props.isActivated || false)

watch(
  () => props.isActivated,
  () => {
    if (props.isActivated === undefined) return
    isActivatedInternal.value = props.isActivated
  }
)

watch(isActivatedInternal, () => {
  emit('update:isActivated', isActivatedInternal.value)

  if (isActivatedInternal.value === true) {
    emit('activated')
  } else {
    emit('deactivated')
  }
})

const classes = computed(() => {
  return {
    'is-activated': isActivatedInternal.value === true,
  }
})

const isFormElementDisabled = computed(() => {
  if (props.disabled) return true
  if (isActivatedInternal.value) return false

  return true
})
</script>

<style lang="scss">
.PmActivatePure {
  $block: &;

  border: 2px solid color.$gray-200;
  border-radius: constant.$borderRadius-default;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px;

  &.is-activated {
    border: 2px solid color.$primary-500;
    background-color: color.$primary-50;
  }

  &-content {
    #{$block}:not(.is-activated) & {
      opacity: 0.5;
    }

    &:empty {
      display: none;
    }
  }
}
</style>
