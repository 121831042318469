import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'
import type { Typegen0 } from './PmMultipleResourceAllocationsUpdateState.typegen'
import {
  assertFriendlyError,
  type FriendlyError,
} from '@/functional/friendlyErrors'

export type State = Typegen0['matchesStates']

export const PmMultipleResourceAllocationsUpdateState = createMachine(
  {
    tsTypes:
      {} as import('./PmMultipleResourceAllocationsUpdateState.typegen').Typegen0,

    schema: {
      events: {} as {
        type: 'UPDATE_RESOURCE_ALLOCATIONS'
        closeAfterSuccess: boolean
      },

      context: {} as {
        error: FriendlyError | undefined
        closeAfterSuccess: undefined | boolean
      },
    },

    id: 'PmMultipleResourceAllocationsUpdateState',
    initial: 'default',

    context: {
      error: undefined,
      closeAfterSuccess: undefined,
    },

    states: {
      default: {
        on: {
          UPDATE_RESOURCE_ALLOCATIONS: 'update',
        },
      },

      update: {
        initial: 'updating',

        states: {
          updating: {
            entry: (context, event) => {
              context.closeAfterSuccess = event.closeAfterSuccess === true
            },

            invoke: {
              id: 'updateResourceAllocations',
              src: 'updateResourceAllocations',

              onError: {
                target: 'failed',
                actions: (context, error) => {
                  assertFriendlyError(error.data)
                  context.error = error.data.errors
                },
              },

              onDone: [
                {
                  target:
                    '#PmMultipleResourceAllocationsUpdateState.successAndClose',
                  cond: 'shouldCloseAfterSuccess',
                },
                { target: 'success' },
              ],
            },
          },

          success: {
            on: {
              UPDATE_RESOURCE_ALLOCATIONS:
                '#PmMultipleResourceAllocationsUpdateState.update',
            },
          },

          failed: {
            meta: {
              error: true,
              errorMessage:
                'Es gab einen unbekannten Fehler beim Ändern der Ressourcen-Zuordnungen',
            },

            on: {
              UPDATE_RESOURCE_ALLOCATIONS:
                '#PmMultipleResourceAllocationsUpdateState.update',
            },
          },
        },
      },

      successAndClose: {
        type: 'final',
        entry: ['showSuccessNotification', 'emitClose'],
      },
    },
  },
  {
    guards: {
      shouldCloseAfterSuccess: (context) => {
        return context.closeAfterSuccess === true
      },
    },
  }
)

export const states = extractAllStates(PmMultipleResourceAllocationsUpdateState)
