<template>
  <div class="PmDataModalProjectSettingsPure" :class="classes">
    <PmLoadingPure v-if="loading" size="default" />

    <div v-if="!loading" class="PmDataModalProjectSettingsPure-content">
      <PmSectionWrapperPure class="PmDataModalProjectSettingsPure-settings">
        <PmSectionPure title="Ansicht">
          <PmCheckboxPure
            v-model:value="collectAllJobsOfProject"
            note="Diese Einstellung gilt für alle Benutzer"
          >
            Alle Jobs dieses Projektes gesammelt anzeigen
          </PmCheckboxPure>
        </PmSectionPure>
      </PmSectionWrapperPure>

      <div class="PmDataModalProjectSettingsPure-control">
        <PmErrorNotificationPure
          v-if="xstate.state.value.matches('failed')"
          class="PmDataModalProjectSettingsPure-notification"
          :error="props.error"
          :message="xstate.meta.value.errorMessage"
        />

        <PmButtonPure
          label="Speichern"
          variant="primary"
          :loading="xstate.state.value.matches('saving')"
          @click="emit('save')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue'
import { useXState } from '@/composition/useXState'
import {
  PmDataModalProjectSettingsPureState,
  type State,
} from '@/components/persoplan/DataModal/DataModalProject/PmDataModalProjectSettingsPureState'

import PmSectionWrapperPure from '@/components/basics/PmSectionWrapperPure.vue'
import PmSectionPure from '@/components/basics/PmSectionPure.vue'
import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import type { FriendlyError } from '@/functional/friendlyErrors'

export type Props = {
  error?: FriendlyError
  state?: State
  value?: boolean
  loading?: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

type Emits = {
  save: []
}

const emit = defineEmits<Emits>()

type Models = {
  collectAllJobsOfProject: boolean
}

const collectAllJobsOfProject = defineModel<Models['collectAllJobsOfProject']>(
  'collectAllJobsOfProject'
)

const xstate = useXState(PmDataModalProjectSettingsPureState, {
  syncStateWith: toRef(props, 'state'),
})

const classes = computed(() => {
  return {
    'is-disabled': props.disabled,
  }
})
</script>

<style lang="scss">
.PmDataModalProjectSettingsPure {
  $block: &;

  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &-control {
    margin-top: 32px;
  }

  &-notification {
    margin-bottom: var(--space-gutters);
  }
}
</style>
