<template>
  <PmDataModalProjectSettingsPure
    v-if="project"
    v-model:collectAllJobsOfProject="collectAllJobsOfProject"
    :loading="isLoading"
    :state="xstate.path.value"
    :error="xstate.state.value.context.error"
    :disabled="!can('edit', 'persoplan')"
    @save="xstate.service.value.send('SAVE')"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useXState } from '@/composition/useXState'
import { throwFriendlyError } from '@/functional/error'
import { PmDataModalProjectSettingsState } from '@/components/persoplan/DataModal/DataModalProject/PmDataModalProjectSettingsState'
import PmDataModalProjectSettingsPure from '@/components/persoplan/DataModal/DataModalProject/PmDataModalProjectSettingsPure.vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  ProjectSettingsDocument,
  UpdateProjectSettingsDocument,
  ProjectFragmentDoc,
} from '@/../generated/graphql'
import { useAppAbility } from '@/composition/useAppAbility'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash-es'

export interface Props {
  projectId: number
}

const props = withDefaults(defineProps<Props>(), {})

const { can } = useAppAbility()
const store = useStore()

const xstate = useXState(PmDataModalProjectSettingsState, {
  services: {
    save: () => save(),
  },
})

const projectSettingsQuery = useQuery(ProjectSettingsDocument, () => ({
  id: props.projectId,
}))

const project = computed(() => projectSettingsQuery.result.value?.project)
const collectAllJobsOfProject = ref<boolean>(false)

watch(
  project,
  () => {
    collectAllJobsOfProject.value = !!project.value?.setting?.isJobLayerHidden
  },
  { immediate: true }
)

const isLoading = computed(() => {
  return projectSettingsQuery.loading.value
})

const updateProjectSettingsMutation = useMutation(UpdateProjectSettingsDocument)

async function save() {
  try {
    await updateProjectSettingsMutation.mutate(
      {
        projectId: props.projectId,
        collectAllJobsOfProject: collectAllJobsOfProject.value,
      },
      {
        update: (cache) => {
          const queryVariables = store.getters['queryVariables/calendar']

          const shared = {
            id: `Project:${props.projectId}`,
            fragment: ProjectFragmentDoc,
            variables: {
              startDate: queryVariables.startDate,
              endDate: queryVariables.endDate,
            },
          }

          // Read
          const readFragmentResult = cache.readFragment({
            ...shared,
          })

          // Nothing to update
          if (!readFragmentResult) return

          const data = cloneDeep(readFragmentResult)

          // Modify
          if (!data?.setting) data.setting = {}
          data.setting.isJobLayerHidden = collectAllJobsOfProject.value

          // Write
          cache.writeFragment({
            ...shared,
            data,
          })
        },
      }
    )
  } catch (error) {
    throwFriendlyError(error)
  }
}
</script>
