<template>
  <PmInputContainerPure
    :label="label"
    :error-message="errorMessage"
    :invalid-message="formValidation.invalidMessage.value"
    :required="required"
    :note="note"
  >
    <div :class="[componentClass.root, $attrs.class, classes]">
      <div :class="componentClass.element('inputContainer')">
        <div
          :class="componentClass.element('autosize')"
          :data-replicated-value="value"
        >
          <textarea
            ref="elInput"
            :type="type"
            class="PmTextareaPure-input"
            :value="value"
            :placeholder="placeholder"
            :disabled="isDisabledNormalized"
            :rows="rows"
            v-bind="separateAttrs($attrs).attributes"
            :required="required"
            :readonly="readonly"
            @input.stop="onInput"
            @blur="(event) => emit('blur', event)"
            @keydown="(event) => emit('keydown', event)"
            @invalid="onInvalid"
          />
        </div>

        <div :class="componentClass.element('actions')">
          <PmButtonPure
            v-if="resetVisible"
            class="PmTextareaPure-action"
            :icon="ICONS.CLOSE"
            variant="danger"
            size="small"
            @click.stop="reset"
          />
        </div>
      </div>
    </div>
  </PmInputContainerPure>
</template>

<script setup lang="ts">
/**
 * @todo Make autosizing work without the dependency to v-model
 */
import { onMounted, ref, computed } from 'vue'
import { ICONS } from '@/constants/icons'
import {
  useFormValidation,
  useComponentClass,
} from '@thomasaull-shared/composables'
import { type Props as PropsUseFormValidation } from '@thomasaull-shared/composables/src/useFormValidation.ts'

import { separateAttrs } from '@/utilities/misc'
import { useForm } from '@/composition/useForm'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmInputContainerPure from '@/components/basics/PmInputContainer/PmInputContainerPure.vue'
import type { Nilable } from '@/types/misc'

export interface Props extends PropsUseFormValidation {
  value?: Nilable<string>
  label?: string
  note?: string
  error?: boolean
  errorMessage?: string
  type?: 'text'
  placeholder?: string
  disabled?: boolean
  readonly?: boolean
  hasReset?: boolean
  rows?: number
  focusInputOnMount?: boolean
  required?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  rows: 1,
})

const emit = defineEmits<{
  input: [Nilable<string>]
  'update:value': [Nilable<string>]
  keydown: [Event]
  blur: [Event]
}>()

defineOptions({
  inheritAttrs: false,
})

const componentClass = useComponentClass()
const form = useForm()
const elInput = ref<HTMLInputElement>()
const formValidation = useFormValidation({
  elInput: elInput,
  type: 'textarea',
})

if (props.focusInputOnMount) {
  onMounted(() => {
    if (!elInput.value) return
    elInput.value.focus()
  })
}

const isDisabledNormalized = computed(() => {
  if (props.disabled) return true
  if (form.disabled?.value) return true

  return false
})

const isInvalid = ref<boolean>()
const invalidMessage = ref<string>()

const classes = computed(() => {
  return {
    'has-error': props.error || formValidation.isInvalid.value ? true : false,
    'is-disabled': isDisabledNormalized.value,
    'is-readonly': props.readonly,
    'is-notEditable': props.readonly || isDisabledNormalized.value,
  }
})

const resetVisible = computed(() => {
  if (!props.hasReset) return false
  return props.value ? true : false
})

function onInvalid(event: Event) {
  formValidation.onInvalid(event)
}

function onInput(event: Event) {
  if (!(event.target instanceof HTMLTextAreaElement)) {
    throw new Error('event.target is not a HTMLInputElement')
  }

  const value = normalizeInputValue(event.target.value)
  emitInput(value)
}

function normalizeInputValue(value?: string | null) {
  const isEmptyString = value?.trim() === ''
  if (isEmptyString) return null

  return value
}

function reset() {
  emitInput(null)
}

function emitInput(value?: string | null) {
  emit('input', value)
  emit('update:value', value)
}

// mounted() {
//   this.$refs.elInput.addEventListener('invalid', this.onInvalid)
//   this.$refs.elInput.addEventListener('input', this.onInput)
// },

// beforeUnmount() {
//   this.$refs.elInput.removeEventListener('invalid', this.onInvalid)
//   this.$refs.elInput.removeEventListener('input', this.onInput)
// },
</script>

<style lang="scss">
.PmTextareaPure {
  $block: &;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'label error'
    'input input'
    'note note';

  &-inputContainer {
    @include mixin.transition-hover((border-color), $block);

    grid-area: input;
    background-color: color.$white;
    border: 1px solid color.$gray-300;
    border-radius: constant.$borderRadius-default;
    display: flex;
    position: relative;

    #{$block}:not(.is-notEditable) &:hover,
    #{$block}:not(.is-notEditable).is-hover & {
      background-color: color.$gray-50;
      border-color: color.$gray-400;
    }

    #{$block}:not(.is-notEditable) &:focus-within,
    #{$block}:not(.is-notEditable).is-focus & {
      outline: none;
      border-color: color.$primary-500;
      background-color: color.$primary-50;
      color: color.$primary-900;
    }

    #{$block}.is-disabled & {
      background-color: color.$gray-100;
      border-color: color.$gray-200;
    }

    #{$block}.has-error & {
      border-color: color.$danger-500;
      background-color: color.$danger-200;
      color: color.$danger-500;
    }

    #{$block}.is-readonly & {
      background-color: color.$gray-100;
    }
  }

  &-autosize {
    // Source: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
    display: grid;
    width: 100%;

    &::after {
      // Note the weird space! Needed to preventy jumpy behavior
      content: attr(data-replicated-value) ' ';
      white-space: pre-wrap;
      visibility: hidden;
      pointer-events: none;
    }
  }

  &-input,
  &-autosize::after {
    font: inherit;
    grid-area: 1 / 1 / 2 / 2;
    min-height: 30px;
    min-width: 30px;
    width: 100%;
    padding: 7px;
    padding-bottom: 6px;
    margin: 0;
    font-weight: 500;
    line-height: constant.$lineHeight-default;
  }

  &-input {
    resize: none;
    box-shadow: none;
    background-color: transparent;
    overflow: hidden;
    color: inherit;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-weight: normal;
      color: color.$gray-500;
      opacity: 1;

      #{$block}.has-error & {
        color: color.$danger-400;
      }
    }

    #{$block}.is-disabled & {
      color: color.$text-default;
      opacity: 0.5;
    }
  }

  &-actions {
    max-height: 30px;
    display: flex;
    align-items: center;
  }

  &-action {
    margin-right: 4px;
  }
}
</style>
