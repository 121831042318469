<template>
  <div class="PmLogoutPure">
    <div class="PmLogoutPure-container">
      <div class="PmLogoutPure-content">
        <PmStatusPure
          v-if="xstate.state.value.matches('signOut.signingOut')"
          :loading="true"
        >
          Abmeldung läuft…
        </PmStatusPure>

        <template v-if="xstate.state.value.matches('signOut.success')">
          <PmStatusPure :icon="ICONS.CHECK" class="PmLogoutPure-statusSuccess">
            Abmeldung erfolgreich.<br />
            Auf Wiedersehen!
          </PmStatusPure>

          <PmButtonPure label="Wieder anmelden" :to="{ name: 'login' }" />
        </template>

        <PmErrorNotificationPure
          v-if="xstate.state.value.matches('signOut.failed')"
          :error="error"
          :message="xstate.meta.value.errorMessage"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRef } from 'vue'
import { ICONS } from '@/constants/icons'
import { useXState } from '@/composition/useXState'

import {
  PmLogoutPureState,
  type State,
} from '@/views/PmLogout/PmLogoutPureState'

import PmStatusPure from '@/components/basics/PmStatusPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import type { FriendlyError } from '@/functional/friendlyErrors'

export type Props = {
  state?: State
  error?: FriendlyError
}

const props = withDefaults(defineProps<Props>(), {})

const xstate = useXState(PmLogoutPureState, {
  syncStateWith: toRef(props, 'state'),
})
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmLogoutPure {
  $block: &;

  background-color: color.$gray-300;
  height: 100%;

  &-container {
    // Shift container bit to the top for better vertical alignment
    transform: translateY(-3%);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    // min-height: -webkit-fill-available; // Does not seem to work correctly in Chrome
    padding-left: var(--space-default);
    padding-right: var(--space-default);
  }

  &-content {
    @include shadow.default('medium');

    padding: var(--space-small);
    background-color: color.$white;
    border-radius: constant.$borderRadius-large;
    width: 100%;
    max-width: 600px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-statusSuccess {
    margin-bottom: var(--space-smallest);
  }
}
</style>
