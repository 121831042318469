<template>
  <PmRequestDetailPure
    v-if="type === 'leaveRequest'"
    :id="id"
    :details="detailsLeaveRequest"
    :is-loading="isLoading"
    :type="type"
    @close="emit('close')"
  />

  <PmExternalServiceRequestDetailPure
    v-if="type === 'externalServiceRequest'"
    :id="id"
    :details="detailsExternalServiceRequest"
    :is-loading="isLoading"
    :type="type"
    @close="emit('close')"
  />

  <PmExpenseReportDetailPure
    v-if="type === 'expenseReport'"
    :id="id"
    :details="detailsExpenseReport"
    :is-loading="isLoading"
    @close="emit('close')"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { Get } from 'type-fest'

import PmRequestDetailPure, {
  type Props as PropsRequestDetailPure,
} from '@/components/PmRequestDetail/PmLeaveRequestDetailPure.vue'
import {
  LEAVE_REQUEST_STATUS_LOOKUP,
  type LeaveRequestStatus,
} from '@/constants/leaveRequest'
import { useQuery } from '@vue/apollo-composable'
import { parseServerDateString } from '@/utilities/date'
import {
  LeaveRequestDetailsDocument,
  type LeaveRequestDetailsQuery,
  type LeaveRequestState,
  LeaveRequestAction,
  ExternalServiceRequestDetailsDocument,
  type ExternalServiceRequestDetailsQuery,
  ExpenseReportDetailsDocument,
} from '@/../generated/graphql'
import type { Nilable } from '@/types/misc'
import PmExpenseReportDetailPure, {
  type Props as PropsExpenseReportDetailPure,
} from '@/components/PmRequestDetail/PmExpenseReportDetailPure.vue'
import { getDisplayNameOfAddress } from '@/utilities/string'
import PmExternalServiceRequestDetailPure, {
  type Props as PropsExternalServiceRequestDetailPure,
} from '@/components/PmRequestDetail/PmExternalServiceRequestDetailPure.vue'

export interface Props {
  id: number
  type: 'leaveRequest' | 'externalServiceRequest' | 'expenseReport'
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  close: []
}>()

const leaveRequestDetailsQuery = useQuery(
  LeaveRequestDetailsDocument,
  () => ({
    id: props.id,
  }),
  () => {
    return {
      enabled: props.type === 'leaveRequest',
      fetchPolicy: 'cache-and-network',
    }
  }
)

const externalServiceRequestDetailsQuery = useQuery(
  ExternalServiceRequestDetailsDocument,
  () => ({
    id: props.id,
  }),
  () => {
    return {
      enabled: props.type === 'externalServiceRequest',
      fetchPolicy: 'cache-and-network',
    }
  }
)
const expenseReportDetailsQuery = useQuery(
  ExpenseReportDetailsDocument,
  () => ({
    id: props.id,
  }),
  () => {
    return {
      enabled: props.type === 'expenseReport',
      fetchPolicy: 'cache-and-network',
    }
  }
)

const isLoading = computed(() => {
  if (props.type === 'leaveRequest')
    return leaveRequestDetailsQuery.loading.value

  if (props.type === 'externalServiceRequest')
    return externalServiceRequestDetailsQuery.loading.value

  if (props.type === 'expenseReport')
    return expenseReportDetailsQuery.loading.value

  return false
})

const detailsLeaveRequest = computed(() => {
  if (props.type !== 'leaveRequest') return

  const leaveRequest = leaveRequestDetailsQuery.result.value?.leaveRequest
  if (!leaveRequest) return

  const startDate = parseServerDateString(leaveRequest.startDate)
  const endDate = parseServerDateString(leaveRequest.endDate)

  if (!startDate || !endDate)
    throw new Error('startDate or endDate is undefined')

  const result: PropsRequestDetailPure['details'] = {
    type: leaveRequest.type,
    name: leaveRequest.user.fullName,
    startDate,
    endDate,
    status: LEAVE_REQUEST_STATUS_LOOKUP[leaveRequest.state],
  }

  // Normalize communication
  const communication: Get<PropsRequestDetailPure, 'details.communication'> = []

  leaveRequest.communications?.forEach((communicationsItem) => {
    const communicationItemNormalized =
      normalizeCommunicationItem(communicationsItem)

    if (!communicationItemNormalized) return
    communication.push(communicationItemNormalized)
  })

  result.communication = communication

  return result
})

const detailsExternalServiceRequest = computed(() => {
  if (props.type !== 'externalServiceRequest') return

  const externalServiceRequest =
    externalServiceRequestDetailsQuery.result.value?.externalServiceRequest
  if (!externalServiceRequest) return

  const startDate = parseServerDateString(externalServiceRequest.startDate)
  const endDate = parseServerDateString(externalServiceRequest.endDate)

  if (!startDate || !endDate)
    throw new Error('startDate or endDate is undefined')

  const result: PropsExternalServiceRequestDetailPure['details'] = {
    name: externalServiceRequest.user.fullName,
    startDate,
    endDate,
    status: LEAVE_REQUEST_STATUS_LOOKUP[externalServiceRequest.state],
    nameOfProduction: externalServiceRequest.eventName,
    client: externalServiceRequest.client,
    customerOfClient: externalServiceRequest.customerOfClient,
    location: externalServiceRequest.location,
    task: externalServiceRequest.jobFunction,
  }

  // Normalize communication
  const communication: Get<
    PropsExternalServiceRequestDetailPure,
    'details.communication'
  > = []

  externalServiceRequest.communications?.forEach((communicationsItem) => {
    const communicationItemNormalized =
      normalizeCommunicationItem(communicationsItem)

    if (!communicationItemNormalized) return
    communication.push(communicationItemNormalized)
  })

  result.communication = communication

  return result
})

const detailsExpenseReport = computed(() => {
  if (props.type !== 'expenseReport') return

  const expenseReport = expenseReportDetailsQuery.result.value?.expenseReport
  if (!expenseReport) return

  const date = parseServerDateString(expenseReport.date)
  if (!date) throw new Error('date is undefined')

  let type: Get<PropsExpenseReportDetailPure, 'details.type'> = 'default'
  if (expenseReport.expenseType?.id === 2) type = 'travelExpense'
  if (expenseReport.expenseType?.id === 3) type = 'catering'

  const result: PropsExpenseReportDetailPure['details'] = {
    type,
    name: getDisplayNameOfAddress(expenseReport.address),
    date: date,
    expenseType: expenseReport.expenseType?.caption,
    job: {
      number: expenseReport.job?.number,
      title: expenseReport.job?.caption,
    },
    notes: expenseReport.details,
    paymentType: expenseReport.paymentType,
    paymentNotes: expenseReport.paymentInfo,

    // Travel Expense
    typeofTransport: expenseReport.publicTransportation
      ? 'cabOrPublicTransport'
      : 'private',
    route: expenseReport.route,
    numberOfKilometers: expenseReport.kilometer,

    // Catering
    dateOfCatering: parseServerDateString(expenseReport.dayOfCatering),
    placeOfHospitalityAddress: expenseReport.location,
    personsServed: expenseReport.servedPersons,
    reasonForCatering: expenseReport.reason,
    amountOfExpenses: expenseReport.invoiceAmount,
    amountTip: expenseReport.tipAmount,
    amountTotal: expenseReport.totalAmount,
  }

  return result
})

type CommunicationItemNormalized = Get<
  PropsRequestDetailPure,
  'details.communication[0]'
>

type LeaveRequestCommunicationItem = Get<
  LeaveRequestDetailsQuery,
  'leaveRequest.communications[0]'
>

type ExternalServiceRequestCommunicationItem = Get<
  ExternalServiceRequestDetailsQuery,
  'externalServiceRequest.communications[0]'
>

function addName(message: string | null, name: Nilable<string>) {
  if (!message && name) return `von ${name}`
  if (name) return `${message} von ${name}`
  return message ?? undefined
}

function normalizeLabel(status: `${LeaveRequestState}`, name: Nilable<string>) {
  if (!status) return
  if (status === 'created') return addName('Antrag erstellt', name)
  if (status === 'aborted') return addName(null, name)
  if (status === 'updated') return addName(null, name)
  if (status === 'accepted') return addName(null, name)
  if (status === 'declined') return addName(null, name)
}

const LOOKUP_STATUS_COMMUNICATION = {
  accept: 'approved',
  decline: 'rejected',
  abort: 'aborted',
} as const satisfies Partial<
  Record<`${LeaveRequestAction}`, LeaveRequestStatus>
>

function normalizeCommunicationItem(
  data: LeaveRequestCommunicationItem | ExternalServiceRequestCommunicationItem
) {
  if (!data) return

  const date = parseServerDateString(data.timestamp)
  if (!date) throw new Error('date is undefined')

  const result: CommunicationItemNormalized = {
    status: LOOKUP_STATUS_COMMUNICATION[data.action],
    label: normalizeLabel(data.newState, data.user.fullName),
    date,
    note: data.comment,
  }

  if (data.__typename === 'LeaveRequestCommunication') {
    if (data.previousType) result.typeBefore = data.previousType
    if (data.newType) result.typeAfter = data.newType
  }

  return result
}
</script>
