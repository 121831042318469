<template>
  <Portal to="modal">
    <PmModalPure
      :title="t('title', numberOfRecipients)"
      :is-loading="isLoadingInitialData"
      :can-be-closed="false"
    >
      <div :class="componentClass.root">
        <PmAppNotificationPure
          v-if="numberOfMessageRecipientsWithoutRequest"
          :class="componentClass.element('notification')"
          :title="
            t(
              'notificationIgnoredRecipients',
              numberOfMessageRecipientsWithoutRequest
            )
          "
        />

        <PmFormPure :id="formId" @submit.prevent="send">
          <PmInputContainerPure
            label="Empfänger"
            :class="componentClass.element('recipients')"
          >
            <div :class="componentClass.element('recipientsList')">
              <PmPillPure
                v-for="recipient in recipients"
                :key="recipient"
                :label="recipient"
              />
            </div>
          </PmInputContainerPure>

          <PmTextareaPure v-model:value="message" :rows="5" label="Nachricht" />

          <template v-if="state.meta.value?.error" #error>
            <PmErrorNotificationPure
              :message="errorMessage || state.meta.value?.message"
              :details="errorDetails"
            />
          </template>
        </PmFormPure>
      </div>

      <template #footer>
        <PmButtonListPure align="right">
          <PmButtonPure
            label="Abbrechen"
            icon="close"
            :disabled="state.snapshot.matches({ send: 'sending' })"
            @click="emit('close')"
          />

          <PmButtonListDividerPure />

          <PmButtonPure
            variant="primary"
            icon="send"
            :disabled="isSendButtonDisabled"
            label="Senden"
            type="submit"
            :form="formId"
            :loading="state.snapshot.matches({ send: 'sending' })"
          />
        </PmButtonListPure>
      </template>
    </PmModalPure>
  </Portal>
</template>

<script setup lang="ts">
import { Portal } from 'portal-vue'
import { computed, ref, toRef, useId } from 'vue'

import { useComponentClass } from '@thomasaull-shared/composables'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmTextareaPure from '@/components/basics/PmTextarea/PmTextareaPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmInputContainerPure from '@/components/basics/PmInputContainer/PmInputContainerPure.vue'
import { useI18n } from 'vue-i18n'
import PmFormPure from '@/components/basics/PmForm/PmFormPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import { useXState, type StatePathOrValue } from '@/composition/useXState5'
import { PmSendMessagePureState } from '@/components/persoplan/PmSendMessage/PmSendMessagePureState'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmButtonListDividerPure from '@/components/basics/PmButtonListDivider/PmButtonListDividerPure.vue'

export interface Props {
  isLoadingInitialData?: boolean
  state?: StatePathOrValue<typeof PmSendMessagePureState>
  recipients?: string[]
  numberOfMessageRecipientsWithoutRequest?: number
  errorMessage?: string
  errorDetails?: string[]
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  close: []
  send: [{ message: string }]
}

const emit = defineEmits<Emits>()

const message = ref<string>()
const componentClass = useComponentClass()
const formId = useId()

const state = useXState(PmSendMessagePureState, {
  syncStateWith: toRef(props, 'state'),
})

const { t } = useI18n({
  messages: {
    de: {
      title: 'Nachricht an Ressource | Nachricht an Ressourcen',
      notificationIgnoredRecipients:
        'Eine ausgewählte Ressourcen-Zuordnung wird ignoriert, für sie existiert keine Anfrage | {n} ausgewählte Ressourcen-Zuordnungen werden ignoriert, für sie existieren keine Anfragen',
    },
  },
})

const numberOfRecipients = computed(() => {
  return props.recipients?.length ?? 0
})

const hasMessage = computed(() => {
  if (!message.value) return
  return message.value.trim().length > 0
})

const isSendButtonDisabled = computed(() => {
  if (!hasMessage.value) return true
  if (state.value.snapshot.matches({ send: 'error' })) return true

  return false
})

function send() {
  if (!hasMessage.value) throw new Error('message is undefined or empty')
  if (!message.value) return

  emit('send', { message: message.value })
}
</script>

<style lang="scss">
.PmSendMessagePure {
  $block: &;

  display: flex;
  flex-direction: column;
  gap: var(--space-smallest);

  &-recipientsList {
    display: flex;
    gap: 8px;
  }
}
</style>
