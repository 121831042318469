import { createMachine } from 'xstate'
import type { Typegen0 } from './PmLogoutState.typegen'
import {
  assertFriendlyError,
  type FriendlyError,
} from '@/functional/friendlyErrors'
export type TState = Typegen0['matchesStates']

export const PmLogoutState = createMachine({
  tsTypes: {} as import('./PmLogoutState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'SIGN_OUT' },

    services: {} as {
      logout: any
    },

    context: {} as {
      error: FriendlyError | undefined
    },
  },

  id: 'PmLogoutState',
  initial: 'default',
  context: {
    error: undefined,
  },

  states: {
    default: {
      on: {
        SIGN_OUT: 'signOut',
      },
    },

    signOut: {
      initial: 'signingOut',

      states: {
        signingOut: {
          invoke: {
            id: 'signing-out',
            src: 'logout',

            onError: {
              target: 'failed',
              actions: (context, error) => {
                assertFriendlyError(error.data)
                context.error = error.data.errors
              },
            },

            onDone: 'success',
          },
        },

        success: {
          type: 'final',
        },

        failed: {},
      },

      onDone: {
        actions: 'logoutSuccessful',
      },
    },
  },
})
