import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmDataModalProjectSettingsPureState.typegen'

export type State = Typegen0['matchesStates']

export const PmDataModalProjectSettingsPureState = createMachine({
  tsTypes:
    {} as import('./PmDataModalProjectSettingsPureState.typegen').Typegen0,
  schema: {},

  id: 'PmDataModalProjectSettingsPureState',
  initial: 'default',

  states: {
    default: {},
    saving: {},
    failed: {
      meta: {
        error: true,
        errorMessage:
          'Es gab einen unbekannten Fehler beim Speichern der Einstellungen',
      },
    },
  },
})

export const states = extractAllStates(PmDataModalProjectSettingsPureState)
