<template>
  <portal to="modal">
    <PmModalPure
      title="Ressourcen-Zuordnungen bearbeiten"
      :can-be-closed="!xstate.state.value.matches('update.updating')"
      class="PmMultipleResourceAllocationsUpdatePure"
      @close="emit('close')"
    >
      <div class="PmMultipleResourceAllocationsUpdatePure-content">
        <PmTablePure
          v-if="columns"
          :columns="columns"
          class="PmMultipleResourceAllocationsUpdatePure-table"
        >
          <slot name="table" />
        </PmTablePure>

        <div class="PmMultipleResourceAllocationsUpdatePure-changesContainer">
          <div class="PmMultipleResourceAllocationsUpdatePure-changesTitle">
            Was möchtest du ändern?
          </div>

          <div class="PmMultipleResourceAllocationsUpdatePure-changes">
            <PmActivatePure
              v-model:isActivated="isActivated.status"
              label="Status"
              :disabled="isFormDisabled"
            >
              <template #default="{ isDisabled }">
                <div class="PmMultipleResourceAllocationsUpdatePure-changeItem">
                  <PmDropdownPure
                    v-model:value="valuesToChange.status.value"
                    :options="statusOptions"
                    label="Status"
                    :disabled="isDisabled"
                  />
                </div>
              </template>
            </PmActivatePure>

            <PmActivatePure
              v-model:isActivated="isActivated.note"
              label="Bemerkung"
              :disabled="isFormDisabled"
            >
              <template #default="{ isDisabled }">
                <div class="PmMultipleResourceAllocationsUpdatePure-changeItem">
                  <PmInputPure
                    v-model:value="valuesToChange.note.value"
                    label="Bemerkung"
                    :disabled="isDisabled"
                  />

                  <PmCheckboxPure
                    v-model:value="valuesToChange.note.overwrite"
                    :disabled="isDisabled"
                    size="small"
                  >
                    Bemerkung bei bestehenden Einträgen überschreiben
                  </PmCheckboxPure>
                </div>
              </template>
            </PmActivatePure>

            <PmActivatePure
              v-if="
                type === RESOURCE_TYPE.ADDRESS ||
                type === RESOURCE_TYPE.FREELANCER
              "
              v-model:isActivated="isActivated.travel"
              label="Travel"
              :disabled="isFormDisabled"
            >
              <template #default="{ isDisabled }">
                <div class="PmMultipleResourceAllocationsUpdatePure-changeItem">
                  <PmInputWithStatusPure
                    v-model:value="valuesToChange.travel.value"
                    label="Travel"
                    :disabled="isDisabled"
                  />

                  <PmCheckboxPure
                    v-model:value="valuesToChange.travel.overwrite"
                    :disabled="isDisabled"
                    size="small"
                  >
                    Travel bei bestehenden Einträgen überschreiben
                  </PmCheckboxPure>
                </div>
              </template>
            </PmActivatePure>

            <PmActivatePure
              v-if="
                type === RESOURCE_TYPE.ADDRESS ||
                type === RESOURCE_TYPE.FREELANCER
              "
              v-model:isActivated="isActivated.hotel"
              label="Hotel"
              :disabled="isFormDisabled"
            >
              <template #default="{ isDisabled }">
                <div class="PmMultipleResourceAllocationsUpdatePure-changeItem">
                  <PmInputWithStatusPure
                    v-model:value="valuesToChange.hotel.value"
                    label="Hotel"
                    :disabled="isDisabled"
                  />

                  <PmCheckboxPure
                    v-model:value="valuesToChange.hotel.overwrite"
                    :disabled="isDisabled"
                    size="small"
                  >
                    Hotel bei bestehenden Einträgen überschreiben
                  </PmCheckboxPure>
                </div>
              </template>
            </PmActivatePure>
          </div>
        </div>

        <PmAppNotificationPure
          v-if="xstate.state.value.matches('update.updating')"
          :is-loading="true"
          :title="`${t(
            'titleUpdating',
            numberOfItems
          )}: ${indexCurrentlyUpdating} von ${numberOfItems}`"
        />

        <PmErrorNotificationPure
          v-if="xstate.meta.value.error"
          class="PmMultipleResourceAllocationsDeletePure-notification"
          :error="error"
          :message="xstate.meta.value.errorMessage"
        />

        <PmAppNotificationPure
          v-if="xstate.state.value.matches('update.success')"
          variant="success"
          :title="t('success', numberOfItems)"
        />
      </div>

      <template #footer>
        <PmButtonListPure align="right">
          <PmButtonPure
            label="Abbrechen"
            icon="close"
            :disabled="isCancelButtonDisabled"
            @click="emit('close')"
          />

          <PmButtonListDividerPure />

          <PmButtonPure
            label="Speichern"
            variant="secondary"
            icon="save"
            :disabled="isSaveButtonDisabled"
            @click="emit('confirm')"
          />

          <PmButtonPure
            label="Speichern + Schließen"
            variant="primary"
            icon="save"
            :disabled="isSaveButtonDisabled"
            @click="emit('confirmAndClose')"
          />
        </PmButtonListPure>
      </template>
    </PmModalPure>
  </portal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { TableColumns } from '@/components/basics/PmTable/PmTablePure.vue'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsUpdatePure'

export const propTypes = {
  state: {
    allowed: states,
  },
  type: {
    allowed: Object.values(RESOURCE_TYPE),
  },
} as const

export const columnsVehicle: TableColumns = [
  { id: 'status', label: 'Status' },
  { id: 'note', label: 'Bemerkung' },
  { id: 'state', width: 45 },
]

export const columnsAddress: TableColumns = [
  { id: 'status', label: 'Status' },
  { id: 'note', label: 'Bemerkung' },
  { id: 'travel', label: 'Travel' },
  { id: 'hotel', label: 'Hotel' },
  { id: 'state', width: 45 },
]

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { toRef, reactive, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { RESOURCE_TYPE, type ResourceType } from '@/constants/persoplan'

import { useXState } from '@/composition/useXState'

import {
  PmMultipleResourceAllocationsUpdatePureState,
  states,
  type State,
} from '@/components/persoplan/PmMultipleResourceAllocationsUpdate/PmMultipleResourceAllocationsUpdatePureState'

import PmActivatePure from '@/components/basics/PmActivate/PmActivatePure.vue'
import PmDropdownPure from '@/components/basics/PmDropdownPure.vue'
import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmInputWithStatusPure from '@/components/basics/PmInputWithStatus/PmInputWithStatusPure.vue'
import PmTablePure from '@/components/basics/PmTable/PmTablePure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmErrorNotificationPure, {
  type Props as PropsErrorNotificationPure,
} from '@/components/basics/PmErrorNotificationPure.vue'
import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmButtonListDividerPure from '@/components/basics/PmButtonListDivider/PmButtonListDividerPure.vue'

import type { Nilable } from '@/types/misc'

export interface StatusOption {
  id: number
  label: string
}

export interface Props {
  state: State
  statusOptions: StatusOption[]
  type: ResourceType
  error?: PropsErrorNotificationPure['error']
  numberOfItems: number
  indexCurrentlyUpdating: number
}

export type ValuesToChange = {
  status: {
    value: number | undefined
  }
  note: {
    value: Nilable<string>
    overwrite: boolean
  }
  travel: {
    value: Nilable<string>
    overwrite: boolean
  }
  hotel: {
    value: Nilable<string>
    overwrite: boolean
  }
}

// export interface ValuesToChange {
//   statusId: number | undefined
//   note: Nilable<string>
//   travel: Nilable<string>
//   hotel: Nilable<string>
// }

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'update:valuesToChange', values: ValuesToChange): void
  (event: 'confirmAndClose'): void
  (event: 'confirm'): void
  (event: 'close'): void
}>()

const xstate = useXState(PmMultipleResourceAllocationsUpdatePureState, {
  actions: {
    clearValues: clearValues,
  },

  syncStateWith: toRef(props, 'state'),
})

const { t } = useI18n({
  messages: {
    de: {
      title: 'Zuordnung ändern | Zuordnungen ändern',
      success:
        'Die Ressourcen-Zuordnung wurde geändert | Die Ressourcen-Zuordnungen wurden geändert',
      titleUpdating:
        'Ressourcen-Zuordnung wird geändert | Ressourcen-Zuordnungen werden geändert',
    },
  },
})

let columns: typeof columnsAddress | typeof columnsVehicle | undefined =
  undefined

if (
  props.type === RESOURCE_TYPE.ADDRESS ||
  props.type === RESOURCE_TYPE.FREELANCER
) {
  columns = columnsAddress
}

if (props.type === RESOURCE_TYPE.VEHICLE) {
  columns = columnsVehicle
}

if (!columns) {
  throw new Error('columns is undefined')
}

const isActivated = reactive<{
  status: boolean
  note: boolean
  travel: boolean
  hotel: boolean
}>({
  status: false,
  note: false,
  travel: false,
  hotel: false,
})

const valuesToChange = reactive<ValuesToChange>({
  status: {
    value: undefined,
  },

  note: {
    value: undefined,
    overwrite: true,
  },

  travel: {
    value: undefined,
    overwrite: true,
  },

  hotel: {
    value: undefined,
    overwrite: true,
  },
})

function normalizeStringValue(value: Nilable<string>) {
  if (!value) return null
  if (value.trim().length === 0) return null

  return value
}

const valuesToChangeNormalized = computed<ValuesToChange>(() => {
  return {
    status: {
      value: isActivated.status ? valuesToChange.status.value : undefined,
    },

    note: {
      value: isActivated.note
        ? normalizeStringValue(valuesToChange.note.value)
        : undefined,
      overwrite: valuesToChange.note.overwrite,
    },

    travel: {
      value: isActivated.travel
        ? normalizeStringValue(valuesToChange.travel.value)
        : undefined,
      overwrite: valuesToChange.travel.overwrite,
    },

    hotel: {
      value: isActivated.hotel
        ? normalizeStringValue(valuesToChange.hotel.value)
        : undefined,
      overwrite: valuesToChange.hotel.overwrite,
    },
  }
})

watch(valuesToChangeNormalized, emitUpdateValuesToChange, {
  deep: true,
})

watch(isActivated, emitUpdateValuesToChange, {
  deep: true,
})

function emitUpdateValuesToChange() {
  emit('update:valuesToChange', valuesToChangeNormalized.value)
}

function clearValues() {
  valuesToChange.status.value = undefined
  valuesToChange.note.value = undefined
  valuesToChange.note.overwrite = true
  valuesToChange.travel.value = undefined
  valuesToChange.travel.overwrite = true
  valuesToChange.hotel.value = undefined
  valuesToChange.hotel.overwrite = true

  isActivated.status = false
  isActivated.note = false
  isActivated.travel = false
  isActivated.hotel = false
}

const hasChanges = computed(() => {
  return Object.values(valuesToChangeNormalized.value).reduce(
    (result: boolean, item) => {
      if (result === true) return true

      if (item.value === undefined) return false
      if (item.value === null) return true

      if (typeof item.value === 'string' && item.value.trim().length === 0) {
        return false
      }

      return true
    },
    false
  )
})

const areButtonsDisabled = computed(() => {
  if (xstate.state.value.matches('update.updating')) return true

  return false
})

const isFormDisabled = computed(() => {
  if (xstate.state.value.matches('update.updating')) return true
  return undefined
})

const isSaveButtonDisabled = computed(() => {
  if (areButtonsDisabled.value === true) return true
  if (hasChanges.value !== true) return true

  return false
})

const isCancelButtonDisabled = computed(() => {
  if (areButtonsDisabled.value === true) return true

  return false
})
</script>

<style lang="scss">
.PmMultipleResourceAllocationsUpdatePure {
  $block: &;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &-changesTitle {
    @include typography.h4($includeMargin: false);

    margin-bottom: 8px;
  }

  &-changes {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 12px;

    @include mixin.media('<=mobile') {
      grid-template-columns: 1fr;
    }
  }

  &-changeItem {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
